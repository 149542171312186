import { ChangeDetectionStrategy, Component, HostListener, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Utils } from '@core/utilities/utils';
import { CropSubType } from '@crop-nutrient/model/crop-sub-type';
import { CropType } from '@crop-nutrient/model/crop-type';
import { BaseComponent } from '@shared/components/base.component';
import { Observable, of } from 'rxjs';
import { CropRotation } from '../../model/crop-rotation.model';

@Component({
  selector: 'app-add-crop-rotation-dialog',
  templateUrl: './add-crop-rotation-dialog.component.html',
  styleUrls: ['./add-crop-rotation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AddCropRotationDialogComponent extends BaseComponent implements OnInit {
  f: UntypedFormGroup;
  formReady = false;
  numbers = [1, 2, 3, 4, 5, 6, 7];
  range: number[];

  allCropRotations$: Observable<CropRotation[]>;
  allCropTypes$: Observable<CropType[]>;
  allCropSubTypes$: Observable<CropSubType[]>;
  cropSubTypesMap = new Map();

  constructor(private injector: Injector, private dialogRef: MatDialogRef<AddCropRotationDialogComponent>) {
    super(injector);
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.bindCropRotation(1);
    super.ngOnInit();
  }

  save() {
    this.dialogRef.close(this.f.value);
  }

  close() {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }

  bindObservables() {
    this.allCropRotations$ = of(this.cache.cropRotations);
    this.allCropTypes$ = of(this.cache.allCropTypes);
  }

  bindCropRotation(cropRotationId: number) {
    const cropRotation = this.cache.cropRotations.find(v => v.cropRotationId === cropRotationId);
    this.f = new CropRotation(cropRotation, this.cache.allCropSubTypes).toForm();
    this.formReady = true;

    const year = this.croppingYear.value;
    this.range = Utils.range(year + 10, year - 10, -1);

    if (!this.hasOwnProperty(`cropSubType1`)) {
      this.numbers.map(i => {
        // Define getters
        Object.defineProperty(this, `cropSubType${i}`, {
          get: () => this.f.get(`cropSubType${i}`)
        });
        Object.defineProperty(this, `cropTypeId${i}`, {
          get: () => this.f.get(`cropTypeId${i}`)
        });
      });
    }

    this.numbers.map(i => {
      const crops = this.cache.allCropSubTypes.filter(crop => crop.cropTypeId === this[`cropSubType${i}`].value.cropTypeId);
      this.cropSubTypesMap.set(i, crops);
    });
  }

  compareTypes(c1: any, c2: any): boolean {
    return c1.cropTypeId === c1.cropTypeId;
  }

  compareSubTypes(c1: any, c2: any): boolean {
    return Utils.matchStr(c1.id, c2.id);
    // return c1.cropTypeId === c1.cropTypeId && c1.cropId === c2.cropId;
  }

  onCropRotationChange(event) {
    this.bindCropRotation(event.value);
  }

  onCropTypeChange(index: number, event) {
    const crops = this.cache.allCropSubTypes.filter(crop => crop.cropTypeId === event.value);
    this.cropSubTypesMap.set(index, crops);
    this[`cropSubType${index}`].patchValue(crops[0]);
  }

  get cropRotationId() {
    return this.f ? this.f.get('cropRotationId') : undefined;
  }

  get numberOfYears() {
    return this.f ? this.f.get('numberOfYears') : undefined;
  }

  get croppingYear() {
    return this.f ? this.f.get('croppingYear') : undefined;
  }
}
