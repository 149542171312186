import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MDSMaterialCapacity } from './mds-material-capacity.model';

export class MDSMaterial extends BaseModel {
    name: string;
    calculationId: string;
    materialTypeId = Constants.DEFAULT_MDS_MATERIAL_TYPE_ID;
    materialSubTypeId = Constants.DEFAULT_MDS_MATERIAL_SUB_TYPE_ID;
    otherDescription: string;
    isBarnEmpty: boolean;
    materialForm: MaterialForm;
    storageTypeId = Constants.DEFAULT_MDS_STORAGE_TYPE_ID;
    materialCapacities: MDSMaterialCapacity[] = [];

    constructor(init?: Partial<MDSMaterial>) {
        super();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
            name: new UntypedFormControl(this.name),
            calculationId: new UntypedFormControl(this.calculationId),
            materialTypeId: new UntypedFormControl(this.materialTypeId),
            materialSubTypeId: new UntypedFormControl(this.materialSubTypeId),
            otherDescription: new UntypedFormControl(this.otherDescription),
            isBarnEmpty: new UntypedFormControl(this.isBarnEmpty),
            materialForm: new UntypedFormControl(this.materialForm),
            storageTypeId: new UntypedFormControl(this.storageTypeId),
            materialCapacities: new UntypedFormArray(this.materialCapacities && this.materialCapacities.length ?
                this.materialCapacities.map(w => new MDSMaterialCapacity(w).toForm()) : [])
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        const materialCapacities = form.get('materialCapacities') as UntypedFormArray;
        return new MDSMaterial({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            notes: v.notes,
            name: v.name,
            calculationId: v.calculationId,
            materialTypeId: v.materialTypeId,
            materialSubTypeId: v.materialSubTypeId,
            otherDescription: v.otherDescription,
            isBarnEmpty: v.isBarnEmpty,
            materialForm: v.materialForm,
            storageTypeId: v.storageTypeId,
            materialCapacities: materialCapacities?.controls.map((w: UntypedFormGroup) => new MDSMaterialCapacity().toModel(w))
        });
    }
}
