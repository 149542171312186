import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from './BaseModel';

export class SoilTestValue extends BaseModel {
  testCode: string;
  testValue: number;
  soilSampleId: string;

  constructor(init?: Partial<SoilTestValue>) {
    super(init);

    this.testCode = init?.testCode;
    this.testValue = init?.testValue;
    this.soilSampleId = init?.soilSampleId;
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      soilSampleId: new UntypedFormControl(this.soilSampleId),
      testCode: new UntypedFormControl(this.testCode),
      testValue: new UntypedFormControl(this.testValue)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new SoilTestValue({
      id: v.id,
      createdBy: v.createdBy,
      soilSampleId: v.soilSampleId,
      testCode: v.testCode,
      testValue: v.testValue
    });
  }
}
