import { FittingParameter } from '@ag-erosion/enum/fitting-parameter.enum';
import { IdfCurveSource } from '@ag-erosion/enum/idf-curve-source.enum';
import { PeakFlowCalculationMethod } from '@ag-erosion/enum/peak-flow-calculation-method.enum';
import { TimeOfConcentrationMethod } from '@ag-erosion/enum/time-of-concentration-method.enum';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { HydrologicSoilGroup } from './hydrologic-soil-group.enum';
import { IdfCurve } from './idf-curve.model';
import { RunoffCurve } from './runoff-curve.model';

export class Watershed extends BaseModel {
  name: string;
  upperTierCode: number;
  watershedSizeInMetric: number;
  watershedLengthInMetric: number;
  elevationDifferenceInMetric: number;
  peakFlowCalculationMethod = PeakFlowCalculationMethod.OmafraPublication832Tables;
  timeOfConcentrationMethod = TimeOfConcentrationMethod.Kirpich;
  averageSlope: number;
  idfCurveSource = IdfCurveSource.Generic;
  useAllClimateStations = true;
  climateStation: number;
  idfCurveDescription: string;
  numberOfFittingParameters = FittingParameter.TwoParameters;
  worksheetId: string;
  runoffCurves: RunoffCurve[];
  idfCurves: IdfCurve[];
  currentRunoffCurveId: string;

  constructor(init?: Partial<Watershed>) {
    super();
    this.name = this.isEn ? 'New watershed' : 'Nouveau bassin versant';
    this.setOneRunoffCurve();
    Object.assign(this, init);
  }

  private setOneRunoffCurve() {
    if (!this.runoffCurves?.length) {
      const runoffCurve = new RunoffCurve({
        hydrologicSoilGroup: HydrologicSoilGroup.D,
        percentageOfWatershed: Constants.DEFAULT_AGE_RUNOFF_CURVE_FIRST_PERCENTAGE,
        runoffCurveId: Constants.DEFAULT_AGE_RUNOFF_CURVE_LAND_USE_COVER,
        watershedId: this.id
      });
      this.runoffCurves = [runoffCurve];
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      upperTierCode: new UntypedFormControl(this.upperTierCode),
      watershedSizeInMetric: new UntypedFormControl(this.watershedSizeInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      watershedLengthInMetric: new UntypedFormControl(this.watershedLengthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      elevationDifferenceInMetric: new UntypedFormControl(this.elevationDifferenceInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      peakFlowCalculationMethod: new UntypedFormControl(this.peakFlowCalculationMethod),
      timeOfConcentrationMethod: new UntypedFormControl(this.timeOfConcentrationMethod),
      averageSlope: new UntypedFormControl(this.averageSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      idfCurveSource: new UntypedFormControl(this.idfCurveSource),
      useAllClimateStations: new UntypedFormControl(this.useAllClimateStations),
      climateStation: new UntypedFormControl(this.climateStation),
      idfCurveDescription: new UntypedFormControl(this.idfCurveDescription, {
        validators: [Validators.maxLength(30)]
      }),
      numberOfFittingParameters: new UntypedFormControl(this.numberOfFittingParameters),
      worksheetId: new UntypedFormControl(this.worksheetId),
      runoffCurves: new UntypedFormArray(this.runoffCurves.map(w => new RunoffCurve(w).toForm())),
      idfCurves: new UntypedFormArray(this.idfCurves?.length ? this.idfCurves.map(w => new IdfCurve(w).toForm()) : []),
      currentRunoffCurveId: new UntypedFormControl(this.currentRunoffCurveId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const runoffCurves = form.get('runoffCurves') as UntypedFormArray;
    const idfCurves = form.get('idfCurves') as UntypedFormArray;
    return new Watershed({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      upperTierCode: v.upperTierCode,
      watershedSizeInMetric: v.watershedSizeInMetric,
      watershedLengthInMetric: v.watershedLengthInMetric,
      elevationDifferenceInMetric: v.elevationDifferenceInMetric,
      peakFlowCalculationMethod: v.peakFlowCalculationMethod,
      timeOfConcentrationMethod: v.timeOfConcentrationMethod,
      averageSlope: v.averageSlope,
      idfCurveSource: v.idfCurveSource,
      useAllClimateStations: v.useAllClimateStations,
      climateStation: v.climateStation,
      idfCurveDescription: v.idfCurveDescription,
      numberOfFittingParameters: v.numberOfFittingParameters,
      worksheetId: v.worksheetId,
      runoffCurves: runoffCurves.controls.map((w: UntypedFormGroup) => new RunoffCurve().toModel(w)),
      idfCurves: idfCurves.controls.map((w: UntypedFormGroup) => new IdfCurve().toModel(w)),
      currentRunoffCurveId: v.currentRunoffCurveId
    });
  }
}
