export enum FlagLevelType {
  None = 0,
  Economic = 1,
  Caution = 2,
  BMP = 3,
  AdditionalInfo = 4,
  Legislative = 5,
  MissingInfo = 6,
  DirectorsApproval = 7,
  MdsRequired = 8
}

export const RestrictedNmspFlagLevelTypes = [FlagLevelType.Economic, FlagLevelType.Caution];

export const DefaultPreSelectedFlagLevelTypesInModel = [
  FlagLevelType.BMP,
  FlagLevelType.AdditionalInfo,
  FlagLevelType.Legislative,
  FlagLevelType.MissingInfo,
  FlagLevelType.DirectorsApproval,
  FlagLevelType.MdsRequired
];

export const DefaultPreSelectedFlagLevelTypesInComponent = [
  FlagLevelType.None,
  FlagLevelType.Economic,
  FlagLevelType.Caution,
  FlagLevelType.BMP,
  FlagLevelType.AdditionalInfo,
  FlagLevelType.Legislative,
  FlagLevelType.MissingInfo,
  FlagLevelType.DirectorsApproval
];

export const MdsPreSelectedFlagLevelTypes = [FlagLevelType.Caution, FlagLevelType.MissingInfo, FlagLevelType.MdsRequired];

export const AgePreSelectedFlagLevelTypes = [FlagLevelType.Caution, FlagLevelType.BMP, FlagLevelType.MissingInfo];
