import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { LocationLotDataService } from '@mstor/service/location-lot-data.service';
import { BaseComponent } from '@shared/components/base.component';
import { DataType } from '@shared/models/common/data-type.enum';
import { SharedLocationLotService } from '@shared/services/shared-location-lot.service';
import { SharedLocationRollNumberService } from '@shared/services/shared-location-roll-number.service';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { EMPTY, of } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'app-location-information-compact',
  templateUrl: './location-information-compact.component.html',
  styleUrls: ['./location-information-compact.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LocationInformationCompactComponent extends BaseComponent implements OnInit {
  @Input() f: UntypedFormGroup;
  @Input() parentType: DataType;
  @Input() showLotSize = false;
  @Input() flags: any;

  concessionOptions = [];
  lotOptions = [];
  concessionLotMap = new Map();

  constructor(private injector: Injector,
    private locationLotDataService: LocationLotDataService,
    private sharedLocationLotService: SharedLocationLotService,
    private sharedLocationRollNumberService: SharedLocationRollNumberService) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshFormControls().subscribe();
  }

  syncData() {
    if (this.locationLot.valid && !this.locationLot.pristine) {
      this.sharedLocationLotService.update(this.locationLot.value, this.isAuthenticated).subscribe();
      this.calculationService.calculate();
    }
  }

  syncParentData() {
    this.onUpdate.emit();
  }

  syncRollNumberData() {
    if (this.rollNumber.valid && !this.rollNumber.pristine) {
      this.sharedLocationRollNumberService.update(this.rollNumber.value, this.isAuthenticated).subscribe();
      this.calculationService.calculate();
    }
  }

  onUpperTierCodeChange(event) {
    this.locationLot.controls.upperTierCode.patchValue(event.value);
    this.locationLot.controls.lowerTierCode.reset(null);
    this.resetConcessionAndLot();
    this.configureRollNumber();
    this.syncData();
    this.syncRollNumberData();
  }

  onLowerTierCodeChange(event) {
    this.locationLot.controls.lowerTierCode.patchValue(event.value);
    this.locationLot.controls.geotownship.reset(null);
    this.resetConcessionAndLot();
    this.configureRollNumber();
    this.syncData();
    this.syncRollNumberData();
  }

  onGeotownshipChange(event) {
    this.locationLot.controls.geotownship.patchValue(event.value);
    this.resetConcessionAndLot();
    this.refreshFormControls().subscribe();
  }

  onConcessionChange(event) {
    this.locationLot.controls.concessionName.patchValue(event.value);
    this.configureLotOptions();
    this.syncData();
  }

  onLotChange(event) {
    this.locationLot.controls.lotName.patchValue(event.value);
    const selectedLot = this.lotOptions.find(l => l.lot === event.value);
    this.locationLot.controls.lotId.patchValue(selectedLot.lotId);
    this.syncData();
  }

  onUnlistedConcessionChange(event) {
    this.locationLot.controls.overrideConcession.patchValue(event.checked);
    this.locationLot.controls.overrideLot.patchValue(event.checked);
    this.configureConcessionName();
    this.configureLotName();
    this.syncData();
  }

  onUnlistedLotChange(event) {
    this.locationLot.controls.overrideLot.patchValue(event.checked);
    this.configureLotName();
    this.syncData();
  }

  sliceInput(element: UntypedFormGroup, event) {
    const numOfDigits = element.controls.numOfDigits as UntypedFormControl;
    const rollNumber = element.controls.rollNumber as UntypedFormControl;
    const length = String(event.target.value).length;
    numOfDigits.patchValue(length);
    if (length > 19) {
      const sliced = String(event.target.value).slice(0, 19);
      rollNumber.patchValue(sliced);
      numOfDigits.patchValue(19);
    }
  }

  toggleFocus(element: UntypedFormGroup, value: boolean) {
    element.controls.isFocused.patchValue(value);
    if (value === false) {
      this.syncRollNumberData();
    }
  }

  private refreshFormControls() {
    if (!this.canSetConcession) {
      return EMPTY;
    }

    return this.locationLotDataService.get(this.locationLot.controls.geotownship.value).pipe(
      takeWhile(() => this.alive),
      switchMap(locationLots => this.refreshConcessionLotMap(locationLots)),
      tap(() => this.changeDetectorRef.detectChanges())
    )
  }

  private refreshConcessionLotMap(locationLots) {
    this.concessionLotMap = new Map();
    this.concessionOptions = [];
    this.lotOptions = [];
    return locationLots.map(lot => {
      // update concession/lot mapping here
      const concessionKey = lot.concession;
      if (!this.concessionLotMap.has(concessionKey)) {
        this.concessionLotMap.set(concessionKey, [lot]);
        // add unique concession to option list if first time adding to map
        this.concessionOptions.push(lot);
      } else {
        this.concessionLotMap.get(concessionKey).push(lot);
      }
      if (this.locationLot.controls.concessionName.value && this.locationLot.controls.concessionName.value === concessionKey) {
        this.lotOptions.push(lot);
      }
      return lot;
    });
  }

  private configureConcessionName() {
    if (this.locationLot.controls.overrideConcession.value === true) {
      this.locationLot.controls.concessionName.setValidators([Validators.maxLength(50), ValidationFunctions.empty]);
      this.f.updateValueAndValidity();
    } else {
      this.locationLot.controls.concessionName.clearValidators();
      this.f.updateValueAndValidity();
    }
  }

  private configureLotName() {
    if (this.locationLot.controls.overrideLot.value === true) {
      this.locationLot.controls.lotName.setValidators([Validators.maxLength(50), ValidationFunctions.empty]);
      this.locationLot.controls.lotId.patchValue(0);
      this.f.updateValueAndValidity();
    } else {
      this.locationLot.controls.lotName.clearValidators();
      this.f.updateValueAndValidity();
    }
  }

  private configureRollNumber() {
    if (this.isLowerTierSet) {
      this.rollNumber.controls.rollNumber.patchValue(this.currentLowerTier.rollNumberCode);
      this.rollNumber.controls.numOfDigits.patchValue(String(this.currentLowerTier.rollNumberCode).length);
    } else if (this.isUpperTierSet && this.lowerTierMunicipalityOptions.length === 1) {
      this.rollNumber.controls.rollNumber.patchValue(this.lowerTierMunicipalityOptions[0].rollNumberCode,
      );
      this.rollNumber.controls.numOfDigits.patchValue(String(this.lowerTierMunicipalityOptions[0].rollNumberCode).length);
    } else {
      this.rollNumber.controls.rollNumber.patchValue(null);
      this.rollNumber.controls.numOfDigits.patchValue(0);
    }
  }

  private configureLotOptions() {
    this.lotOptions = this.concessionLotMap.get(this.locationLot.controls.concessionName.value);
  }

  private resetConcessionAndLot() {
    this.locationLot.controls.concessionName.reset(null);
    this.configureLotOptions();
    this.locationLot.controls.lotName.reset(null);
  }

  get form() {
    return this.f?.controls;
  }

  get locationLot() {
    const locationLot = this.f?.get('locationLot') as UntypedFormGroup;
    if (locationLot && !locationLot.controls.parentType.value) {
      locationLot.controls.parentType.patchValue(this.parentType);
    }
    return locationLot;
  }

  get rollNumber() {
    const rollNumber = this.f?.get('locationRollNumber') as UntypedFormGroup;
    if (rollNumber && !rollNumber.controls.parentType.value) {
      rollNumber.controls.parentType.patchValue(this.parentType);
    }
    return rollNumber;
  }

  get upperTierMunicipalityOptions() {
    return this.cache.upperTierMunicipalities;
  }

  get lowerTierMunicipalityOptions() {
    return this.cache.lowerTierMunicipalities
      .filter(v => v.upperTierCode === this.locationLot.controls.upperTierCode.value)
      .map(v => { v.lowerTierCode = v.lowerTierCode.toString(); return v; });
  }

  get geotownshipOptions() {
    return this.cache.geoTownships.filter(v => (
      v.upperTierCode === this.locationLot.controls.upperTierCode.value &&
      v.lowerTierCode === this.locationLot.controls.lowerTierCode.value));
  }

  get concessionOptions$() {
    return this.locationLotDataService.get(this.locationLot.controls.geotownship.value)
  }

  get canEditLowerTier() {
    return this.locationLot.controls.upperTierCode.value !== 0;
  }

  get currentUpperTier() {
    return this.upperTierMunicipalityOptions.find(v => v.upperTierCode === this.locationLot.controls.upperTierCode.value);
  }

  get currentLowerTier() {
    return this.lowerTierMunicipalityOptions.find(v => v.lowerTierCode === this.locationLot.controls.lowerTierCode.value);
  }

  get isUpperTierSet() {
    return this.locationLot.controls.upperTierCode.value !== null && this.locationLot.controls.upperTierCode.value !== 0;
  }

  get isLowerTierSet() {
    return this.locationLot.controls.lowerTierCode.value !== null && this.locationLot.controls.lowerTierCode.value !== 0;
  }

  get canEditGeotownship() {
    return this.locationLot.controls.lowerTierCode.value !== null && this.locationLot.controls.lowerTierCode.value !== 0;
  }

  get canSetConcession() {
    return this.locationLot.controls.geotownship && this.locationLot.controls.geotownship.value;
  }

  get canSetLot() {
    return this.locationLot.controls.geotownship && this.locationLot.controls.geotownship.value;
  }

  get canAddLocationLot() {
    return this.locationLot.controls.geotownship.value && this.locationLot.controls.concessionName.value && this.locationLot.controls.lotName.value;
  }

  get isConcessionOverriden() {
    return this.locationLot.controls.overrideConcession.value === true;
  }

  get isLotOverriden() {
    return this.locationLot.controls.overrideLot.value === true;
  }

}
