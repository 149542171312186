import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { SoilTestCode } from '@shared/models/worksheet/soil-test-code.model';
import { SoilTestValue } from '@shared/models/worksheet/soil-test-value.model';

let soilTestCodes = null; // well this is still awkard

export class FmpSoilSample extends BaseModel {
  name: string;
  fieldSectionId: string;
  sampleDate = Utils.getDefaultApplicationDate();
  focusedTestValue: string;
  soilTestValues: SoilTestValue[];
  selectedTestCodes = [];
  includeAllTestCodes = false;

  constructor(init?: Partial<FmpSoilSample>) {
    super();
    soilTestCodes = JSON.parse(localStorage.getItem('soilTestCodes'));
    this.name = null;
    this.id = Utils.uuid();
    this.configureDefaultTestValues(init);
    Object.assign(this, init);
  }

  toForm() {
    const f = new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      fieldSectionId: new UntypedFormControl(this.fieldSectionId),
      name: new UntypedFormControl(this.name),
      sampleDate: new UntypedFormControl(this.sampleDate),
      focusedTestValue: new UntypedFormControl(this.focusedTestValue),
      selectedTestCodes: new UntypedFormControl(this.selectedTestCodes)
    });
    if (this.soilTestValues && this.soilTestValues.length) {
      this.soilTestValues.forEach(v => f.addControl(v.testCode,
        new UntypedFormControl(v.testValue || '', { validators: this.nutrientValidators(v.testCode) })
      ));
    } else {
      this.defaultTestCodes.forEach(v => f.addControl(v.testCode,
        new UntypedFormControl('', { validators: this.nutrientValidators(v.testCode) })
      ));
    }
    return f;
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const codes = soilTestCodes.
      filter(c => v[c.testCode] !== undefined).
      map(c => c.testCode);
    return new FmpSoilSample({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      fieldSectionId: v.fieldSectionId,
      name: v.name,
      sampleDate: v.sampleDate,
      focusedTestValue: v.focusedTestValue,
      selectedTestCodes: v.selectedTestCodes,
      soilTestValues: codes?.map(key => ({
        testCode: key,
        testValue: form.get(key).value
      })) as SoilTestValue[]
    });
  }

  private configureDefaultTestValues(init: Partial<FmpSoilSample>): void {
    const testValues = init?.includeAllTestCodes ? soilTestCodes.map(c => c.testCode) :
      ArrayConstants.DEFAULT_SOIL_TEST_VALUES;

    this.soilTestValues = init?.soilTestValues ||
      testValues.map(v => new SoilTestValue({ testCode: v }));
  }

  private nutrientValidators(testCode: string): ValidatorFn[] {
    const nutrient = soilTestCodes.find(v => Utils.matchStr(v.testCode, testCode));
    if (Utils.matchStr(nutrient.testCode, 'PH')) {
      return [Validators.min(0), Validators.max(14)];
    }
    return [Validators.min(0), Validators.max(nutrient?.unit === '%' ? 100 : 1000000)];
  }

  get defaultTestCodes(): SoilTestCode[] {
    return this.includeAllTestCodes ? soilTestCodes :
      soilTestCodes.filter(v => v.requiredFlag === true && v.defaultList === true).
        sort((a, b) => a.displaySequence - b.displaySequence);
  }
}