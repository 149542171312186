import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';
import { Subscription } from 'rxjs';

@Directive({ selector: '[appMassCost]' })
export class AppMassCostDirective implements OnInit, OnDestroy {
  // @Input() appMassCost: AbstractControl;
  @Input('appMassCost') control: AbstractControl;
  @Input('materialForm') materialForm: MaterialForm;
  private alive = true;
  private subscription: Subscription = new Subscription();

  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  ngOnInit() {
    this.subscription.add(
      this.control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
        this.unitConverterService.from = this.measurementService.measurementSystem;
        this.unitConverterService.to = MeasurementSystem.Metric;
        const converted = this.convert(val, this.materialForm);
        this.control.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
      })
    );
  }

  convert(value: number, materialForm: MaterialForm) {
    if (materialForm !== undefined) {
      // cost of application
      if (materialForm === MaterialForm.Liquid) {
        return this.unitConverterService.convertVolumeM3Cost(value);
      } else {
        return this.unitConverterService.convertMassLargeCost(value);
      }
    } else {
      return this.unitConverterService.convertMassCost(value);
    }
  }

  ngOnDestroy(): void {
    this.alive = false;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
