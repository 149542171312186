export enum Tabs {
  GeneralInfo = 0,
  Farms = 1,
  Materials = 2,
  Storages = 3,
  Fields = 4,
  Report = 5,
  Resources = 6,
  FarmFields = 7
}
