import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class BuildingLivestock extends BaseModel {
  name: string;
  notes: string;
  numberOfLivestock = Constants.DEFAULT_NUM_OF_LIVESTOCK;
  materialTypeId = Constants.DEFAULT_MATERIAL_TYPE_ID;
  livestockTypeId = Constants.DEFAULT_LIVESTOCK_SUBTYPE_ID;
  buildingId: string;
  isBarnFloorWet = false;

  constructor(init?: Partial<BuildingLivestock>) {
    super();
    this.name = this.isEn ? 'Livestock 1' : 'Bétail 1';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      numberOfLivestock: new UntypedFormControl(this.numberOfLivestock, { validators: [Validators.min(0), Validators.max(1000000)] }),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      livestockTypeId: new UntypedFormControl(this.livestockTypeId),
      buildingId: new UntypedFormControl(this.buildingId),
      isBarnFloorWet: new UntypedFormControl(this.isBarnFloorWet)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new BuildingLivestock({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      numberOfLivestock: v.numberOfLivestock,
      materialTypeId: v.materialTypeId,
      livestockTypeId: v.livestockTypeId,
      buildingId: v.buildingId,
      isBarnFloorWet: v.isBarnFloorWet
    });
  }
}
