
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { OrganicAmendmentField } from './organic-amendment-field.model';

export class OrganicAmendmentFarm extends BaseModel {
  worksheetId: string;
  name: string;
  upperTierName: string;
  upperTierCode: string;
  geotownshipName: string;
  geotownshipId: string;
  fields: OrganicAmendmentField[] = [];

  constructor(init?: Partial<OrganicAmendmentFarm>) {
    super();
    this.name = this.isEn ? 'Farm A' : 'Ferme A';
    this.fields[0] = new OrganicAmendmentField(init);
    Object.assign(this, init);
  }
}

