import { Component, Input } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { TooltipDialogComponent } from '@shared/components/tooltip-dialog/tooltip-dialog.component';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styles: []
})
export class TooltipComponent extends BaseComponent {

  @Input() titleKey: string;
  @Input() title: string;
  @Input() message: String;
  @Input() msgKey: string;
  @Input() msgParams: string[];
  @Input() btnClass: string = 'button--blue-tertiary';

  openTooltipDialog() {
    this.dialogService.instance(TooltipDialogComponent, {
      btnClass: this.btnClass,
      titleKey: this.titleKey,
      title: this.title,
      message: this.message,
      msgKey: this.msgKey,
      msgParams: this.msgParams
    });
  }
}
