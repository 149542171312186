import { Utils } from '@core/utilities/utils';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { OrganicAmendmentWorksheet } from './organic-amendment-worksheet.model';

export class MaterialApplication extends BaseModel {
  name: string;
  applicationDate: any;
  applicationIncorporation = 0;
  applicationMethod = 0;
  applicationRateInMetric = 0;
  applicationTiming = 0;
  coverCropExists = false;

  constructor(init?: Partial<OrganicAmendmentWorksheet>) {
    super();
    this.id = this.id || Utils.uuid();
    this.name = this.isEn ? 'Material App A' : 'Épan matière A';
    Object.assign(this, init);
  }
}
