import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { Constants } from '@shared/constants/constants';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { DialogService } from '@shared/services/dialog.service';
import { ExpansionPanelService } from '@shared/services/expansion-panel.service';
import { FormService } from '@shared/services/form.service';
import { LanguageService } from '@shared/services/language.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Livestock } from '../model/livestock.model';
import { MaterialProducedService } from './material-produced.service';

@Injectable({ providedIn: 'root' })
export class LivestockService {
  constructor(
    private httpClient: HttpClient,
    private formService: FormService,
    private languageService: LanguageService,
    private dialogService: DialogService,
    private unitConverterService: UnitConverterService,
    private cache: CacheService,
    private expansionPanelService: ExpansionPanelService,
    private materialsProducedService: MaterialProducedService
  ) {}

  get(id: string): Observable<Livestock> {
    return this.httpClient.get(`${URLs.SHARED_LIVESTOCK_ENDPOINT}/${id}`) as Observable<Livestock>;
  }

  save(): Observable<any> {
    const app = new Livestock();
    this.setGhgDefaults(app);
    app.id = Utils.uuid();
    app.storageSystemId = this.currentStorageSystem.get('id').value;
    app.name = this.livestockName();
    app.startDate = this.currentStorageSystem.get('startDate').value;
    app.endDate = this.currentStorageSystem.get('endDate').value;
    return this.httpClient.post(URLs.SHARED_LIVESTOCK_WITH_MATERIALS_PRODUCED_ENDPOINT, app).pipe(
      tap((res: any) => {
        const liveStock = new Livestock(res.livestock).toForm();
        this.livestock.push(liveStock);

        // add three materials produced
        this.materialsProducedService.pushMaterialsProduced(res.relatedMaterialsProduced);
        this.expansionPanelService.next(res.livestock.id);
      }),
      map(res => res.livestock)
    );
  }

  saveLivestockCombination(combination): Observable<any> {
    return forkJoin(
      combination.map((item, index) =>
        this.httpClient.post(URLs.SHARED_LIVESTOCK_WITH_MATERIALS_PRODUCED_ENDPOINT, this.livestockFrom(item, index))
      )
    ).pipe(
      tap((livestock: any[]) => {
        if (livestock && livestock.length) {
          livestock.forEach(res => {
            this.livestock.push(new Livestock(res.livestock).toForm());
            this.materialsProducedService.pushMaterialsProduced(res.relatedMaterialsProduced);
          });
          this.expansionPanelService.next(livestock[livestock.length - 1].id);
          this.openCombinationConfirmation();
        }
      })
    );
  }

  livestockTypeNumber(item, form) {
    return item.combinationValue * form.numberOfLivestock.value;
  }

  livestockTypeName(item) {
    return this.allLivestockTypes.find(v => v.livestockTypeId === item.livestockTypeId);
  }

  expandLivestockCombinationDetails(details, form: any) {
    if (details) {
      details.forEach(item => {
        const livestockType = this.allLivestockTypes.find(v => v.livestockTypeId === item.livestockTypeId);
        if (livestockType) {
          const factorUtilized = percentage => (percentage * livestockType.factorUtilization) / 100;
          const isAllInOut = form.isAllInOut.value;
          const materialForm = form.materialForm.value;
          // need to set values for weights here base on livestockType
          this.weightsByLivestockType(livestockType, item);
          item.materialTypeId = form.materialTypeId.value;
          item.livestockTypeId = item.livestockTypeId;
          item.isAllInOut = isAllInOut;
          item.numberOfLivestock = this.livestockTypeNumber(item, form);
          const maxPercentage = Math.max(livestockType.produceLiquidPercent, livestockType.produceSolidPercent);
          if (materialForm === MaterialForm.Liquid) {
            item.produceLiquidPercent = isAllInOut ? factorUtilized(maxPercentage) : maxPercentage;
          }
          if (materialForm === MaterialForm.Solid) {
            item.produceSolidPercent = isAllInOut ? factorUtilized(maxPercentage) : maxPercentage;
          }
        }
      });
    }
  }

  livestockName(index?: number) {
    const increment = index !== undefined ? index + 1 : 1;
    return this.languageService.languageType === LanguageType.English
      ? `Livestock ${this.livestock.length + increment}`
      : `Bétail ${this.livestock.length + increment}`;
  }

  update(livestock: Livestock): Observable<any> {
    const url = `${URLs.SHARED_LIVESTOCK_ENDPOINT}/${livestock.id}`;
    return this.httpClient.put(url, livestock);
  }

  delete(id: string): Observable<any> {
    const url = `${URLs.SHARED_LIVESTOCK_ENDPOINT}/${id}`;
    return this.httpClient.delete(url).pipe(
      tap(() => {
        const index = this.livestock.controls.findIndex(v => Utils.matchStr(v.get('id').value, id));
        this.livestock.removeAt(index);
        // remove materials produced by this livestock from form
        if (index >= 0) {
          this.materialsProducedService.deleteMaterialsProducedBySourceId(id);
        }
      })
    );
  }

  copy(srcLivestockId: any): Observable<any> {
    return this.httpClient.post(`${URLs.SHARED_LIVESTOCK_WITH_MATERIALS_PRODUCED_ENDPOINT}/copy/${srcLivestockId}`, undefined).pipe(
      tap((res: any) => {
        const liveStock = new Livestock(res.livestock).toForm();
        this.livestock.push(liveStock);
        // add three materials produced
        this.materialsProducedService.pushMaterialsProduced(res.relatedMaterialsProduced);
        this.expansionPanelService.next(res.livestock.id);
      }),
      map(res => res.livestock)
    );
  }

  livestockTypes(materialTypeId) {
    const unordered = this.allLivestockTypes
      .filter(v => v.materialTypeId === materialTypeId)
      .reduce((r, c: any) => {
        // this complexity is due to the need for the option groups to be sorted
        // to make it easier for the user to read in both English and French
        // livestockType record has a 'name1' field that looks like { name1: { en: '...', fr: '...'} }
        if (this.languageService.languageType === 'en') {
          if (!r[c.name1.en]) {
            r[c.name1.en] = [c];
          } else {
            r[c.name1.en].push(c);
          }
        } else {
          if (!r[c.name1.fr]) {
            r[c.name1.fr] = [c];
          } else {
            r[c.name1.fr].push(c);
          }
        }
        return r;
      }, {});
    // sort the object keys here
    const ordered = {};
    Object.keys(unordered)
      .sort()
      .forEach(key => (ordered[key] = unordered[key]));
    return ordered;
  }

  hasValidSubtypes(materialTypeId, livestockTypeName) {
    if (!this.livestockTypes(materialTypeId)[livestockTypeName]) {
      return false;
    }
    return this.livestockTypes(materialTypeId)[livestockTypeName].filter(t => t.name2.en !== '-').length;
  }

  // #14336 factor in measurement system to calculate its relative decimal places
  weightDecimalPlaces$(control: any, measurementSystem$: Observable<MeasurementSystem>) {
    if (!control) {
      return of(0);
    }
    this.unitConverterService.from = MeasurementSystem.Metric;
    return measurementSystem$.pipe(
      switchMap(to => {
        this.unitConverterService.to = to;
        const value = this.unitConverterService.convertMass(control.value);
        return of(this.weightDecimalPlaces(value));
      })
    );
  }

  	weightDecimalPlacesDirectValue$(weight: number, measurementSystem$: Observable<MeasurementSystem>): Observable<number>
	{
		if(weight === 0) return of(0);

		this.unitConverterService.from = MeasurementSystem.Metric;
		
		return measurementSystem$.pipe(switchMap(to =>
		{
			this.unitConverterService.to = to;
		
			return of(this.weightDecimalPlaces(this.unitConverterService.convertMass(weight)));
		}));
	}

  // #14336 this is a enhancement feature later introduced
  private weightDecimalPlaces(weight: number) {
    if (weight > 100) {
      return 0;
    } else if (weight > 10 && weight < 100) {
      return 1;
    } else if (weight > 0 && weight < 10) {
      return 2;
    }
    return 0;
  }

  setGhgDefaults(model: Livestock) {
    const livestockType = this.cache.livestockTypes.find(v => v.livestockTypeId === model.livestockTypeId);
    if (livestockType) {
      model.milkProduction = livestockType.defaultMilkProduction;
      model.livestockRatio = Constants.DEFAULT_GHG_LIVESTOCK_RATIO;
      model.feedLength = livestockType.defaultFeedLength;
      const feedDietOptions = this.cache.livestockTypeDietCommon.filter(v => v.materialTypeId === model.materialTypeId);
      if (feedDietOptions?.length) {
        model.feedDiet = feedDietOptions[0].dietId;
      }
    }
    return model;
  }

  setGhgDefaultsForm(form: UntypedFormGroup) {
    const controls = form.controls;
    const livestockType = this.cache.livestockTypes.find(v => v.livestockTypeId === controls.livestockTypeId.value);
    if (livestockType) {
      controls.milkProduction.patchValue(livestockType.defaultMilkProduction, Utils.SILENT);
      controls.livestockRatio.patchValue(Constants.DEFAULT_GHG_LIVESTOCK_RATIO, Utils.SILENT);
      controls.feedLength.patchValue(livestockType.defaultFeedLength, Utils.SILENT);
      const feedDietOptions = this.cache.livestockTypeDietCommon.filter(v => v.materialTypeId === controls.materialTypeId.value);
      if (feedDietOptions?.length) {
        controls.feedDiet.patchValue(feedDietOptions[0].dietId, Utils.SILENT);
      }
    }
    return form;
  }

  private livestockFrom(livestock, index) {
    const model = new Livestock({
      id: Utils.uuid(),
      storageSystemId: this.currentStorageSystem.get('id').value,
      name: this.livestockName(index),
      materialTypeId: livestock.materialTypeId,
      livestockTypeId: livestock.livestockTypeId,
      isAllInOut: livestock.isAllInOut,
      weightAverageInMetric: livestock.weightAverageInMetric,
      weightInInMetric: livestock.weightInInMetric,
      weightOutInMetric: livestock.weightOutInMetric,
      numberOfLivestock: livestock.numberOfLivestock,
      produceLiquidPercent: livestock.produceLiquidPercent,
      produceSolidPercent: livestock.produceSolidPercent,
      producePasturePercent: livestock.producePasturePercent
    });
    this.setGhgDefaults(model);
    return new Livestock(model);
  }

  private openCombinationConfirmation() {
    this.dialogService.instance(
      ConfirmationDialogComponent,
      {
        title: 'dialog.title.confirmation',
        message: 'dialog.message.livestock.combo.added',
        btnCancel: 'dialog.action.ok',
        btnOk: 'dialog.action.ok',
        btnOkStyle: 'button--green-primary',
        displayBtnOk: false
      },
      { disableClose: true }
    );
  }

  private weightsByLivestockType(livestockType: any, item: any) {
    if (livestockType.useAverageWeight) {
      item.weightAverageInMetric = livestockType.weightAverage;
    } else {
      item.weightInInMetric = livestockType.weightIn;
      item.weightOutInMetric = livestockType.weightOut;
    }
  }

  get allLivestockTypes() {
    return this.cache.livestockTypes;
  }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem() {
    return this.storageSystems?.controls.find(v =>
      Utils.matchStr(v.get('id').value, this.formService.f?.get('currentStorageSystemId')?.value)
    );
  }

  get livestock() {
    return this.currentStorageSystem?.get('livestock') as UntypedFormArray;
  }
}
