<div [formGroup]="fieldSection" id="nutrientBalanceSelector">
    <fieldset class="fieldset">
        <legend>
            {{ "nutrient.balance" | translate | async }}
        </legend>
        <mat-radio-group
            class="radio-group-row"
            (change)="onNutrientBalanceTypeChange($event)"
            formControlName="displayNutrientBalanceType"
            attr.aria-label="{{ 'calculator.fmp.field.input.summary' | translate | async }}">
            <mat-radio-button
                aria-label="{{ item.i18nkey | translate | async }}"
                class="radio-button-row"
                id="{{ nutrientBalanceEnumType[item.key] }}Id"
                *ngFor="let item of nutrientBalanceTypes"
                [value]="item.key">
                {{ item.i18nkey | translate | async }}

                <button
                    type="button"
                    class="button button--green-tertiary hybrid-info-button"
                    (click)="openHybridInfo()"
                    attr.aria-label="{{ 'calculator.fmp.hybridbalance.tooltip.text' | translate | async }}"
                    matTooltip="{{ 'calculator.fmp.hybridbalance.tooltip.text' | translate | async }}"
                    matTooltipPosition="right"
                    tabindex="0"
                    *ngIf="item.key === nutrientBalanceEnumType.Hybrid">
                    <mat-icon> info </mat-icon>
                </button>
            </mat-radio-button>
        </mat-radio-group>
    </fieldset>
</div>
