import { ContourCalculationType } from '@ag-erosion/enum/contour-calculation-type.enum';
import { DegreeOfAngularity } from '@ag-erosion/enum/degree-of-angularity.enum';
import { DropPipeInletType } from '@ag-erosion/enum/drop-pipe-inlet-type.enum';
import { FittingParameter } from '@ag-erosion/enum/fitting-parameter.enum';
import { GeotextileUnderlayType } from '@ag-erosion/enum/geotextile-underlay-type.enum';
import { GradeControlType } from '@ag-erosion/enum/grade-control-type.enum';
import { IdfCurveSource } from '@ag-erosion/enum/idf-curve-source.enum';
import { OutfallType } from '@ag-erosion/enum/outfall-type.enum';
import { PeakFlowCalculationMethod } from '@ag-erosion/enum/peak-flow-calculation-method.enum';
import { RockChuteCalculationType } from '@ag-erosion/enum/rock-chute-calculation-type.enum';
import { SpillwayType } from '@ag-erosion/enum/spillway-type.enum';
import { TillagePractice } from '@ag-erosion/enum/tillage-practice.enum';
import { TimeOfConcentrationMethod } from '@ag-erosion/enum/time-of-concentration-method.enum';
import { TypeOfJoint } from '@ag-erosion/enum/type-of-joint.enum';
import { WaterwayShape } from '@ag-erosion/enum/waterway-shape.enum';
import { CalculationType } from '@fertilizer/model/fertilizer-calculation-type.enum';
import { FarmStatus } from '@fmp/model/fmp-farm-status.enum';
import { FmpReportType } from '@fmp/model/fmp-report-type.model';
import { CoverCrop } from '@ghg/model/cover-crop.enum';
import { DestratificationFan } from '@ghg/model/destratification-fan.enum';
import { ReportType as GhgReportType } from '@ghg/model/ghg-report-type.enum';
import { HeatExchangerEfficiencyLevel } from '@ghg/model/heat-exchanger-efficiency-level.enum';
import { NitrogenFertilizerReduction } from '@ghg/model/nitrogen-fertilizer-reduction.enum';
import { TreePlantingType } from '@ghg/model/tree-planting-type.enum';
import { ReportType as GnfReportType } from '@gnf/model/gnf-report-type.enum';
import { BuildingPermitAnswer } from '@mds1/models/buildling-permit-answer.enum';
import { AmountType } from '@mstor/model/amount-type.enum';
import { MaterialProductionFrequency } from '@mstor/model/material-production-frequency.enum';
import { MilkingAnswer } from '@mstor/model/milking-answer.enum';
import { MilkingLivestockType } from '@mstor/model/milking-livestock-type.enum';
import { MilkingSystemType } from '@mstor/model/milking-system-type.enum';
import { MoistureLevel } from '@mstor/model/moisture-level.enum';
import { OutgoingTransferRecurringFrequency } from '@mstor/model/outgoing-transfer-recurring-frequency.enum';
import { SiloCovered } from '@mstor/model/silo-covered.enum';
import { SiloFillDate } from '@mstor/model/silo-fill-date.enum';
import { SiloType } from '@mstor/model/silo-type.enum';
import { ConstructionDate } from '@nasm/model/construction-date.enum';
import { MaterialComponentType } from '@nasm/model/material-component-type.enum';
import { ReportType as NasmReportType } from '@nasm/model/nasm-report-type.enum';
import { StorageCategory } from '@nasm/model/storage-category.enum';
import { ExemptionReason } from '@nmsp/model/exemption-reason.enum';
import { ReportType as NmspReportType } from '@nmsp/model/nmsp-report-type.enum';
import { SubmissionReason } from '@nmsp/model/submission-reason.enum';
import { SubmissionType } from '@nmsp/model/submission-type.enum';
import { ApplicationTiming } from '@organic-amendment/model/material-application-timing.enum';
import { BuildingType } from '@shared/models/common/building-type.enum';
import { ConstructionMaterial } from '@shared/models/common/construction-material.enum';
import { Country } from '@shared/models/common/country.enum';
import { CropAmountType } from '@shared/models/common/crop-amount-type.enum';
import { DelayedCoolingType } from '@shared/models/common/delayed-cooling-type.enum';
import { DeleteKey } from '@shared/models/common/delete-key.enum';
import { DepthToBedrock } from '@shared/models/common/depth-to-bedrock.enum';
import { DirectionRoofSlope } from '@shared/models/common/direction-root-slope.enum';
import { FieldInputFilter } from '@shared/models/common/field-Input-filter.enum';
import { FieldInputType } from '@shared/models/common/field-input-type.enum';
import { i18nText } from '@shared/models/common/i18n-text';
import { LightingControl } from '@shared/models/common/lighting-control.enum';
import { LivestockFacility } from '@shared/models/common/livestock-facility.enum';
import { LivestockManureInfoAnswer } from '@shared/models/common/livestock-manure-info-answer.enum';
import { MaterialApplicationSeasonType } from '@shared/models/common/material-application-season-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { Month } from '@shared/models/common/month.enum';
import { Month2 } from '@shared/models/common/month2.enum';
import { NutrientBalanceType } from '@shared/models/common/nutrient-balance-type.enum';
import { OdourCategory } from '@shared/models/common/odour-category';
import { OperationType } from '@shared/models/common/operation-type.enum';
import { Province } from '@shared/models/common/province.enum';
import { RunoffPotentialType } from '@shared/models/common/runoff-potential-type.enum';
import { SeasonEmptying } from '@shared/models/common/season-emptying.enum';
import { SoilErosionMethod } from '@shared/models/common/soil-erosion-method.enum';
import { TileDrainageSystem } from '@shared/models/common/tile-drainage-system.enum';
import { TotalPhosphorusIndex } from '@shared/models/common/total-phosphorus-index.enum';
import { WorksheetSubmissionReason } from '@shared/models/common/worksheet-submission-reason.enum';
import { ManureCovering } from '@shared/models/manure-covering.enum';
import { LivestockType } from '@shared/models/material/livestock-type.enum';

/**
 * This file is meant for storing array like business constants,
 * every variable stored here is of an array
 */
export class ArrayConstants {
  public static readonly DEFAULT_SOIL_TEST_VALUES = ['P', 'K', 'PH', 'OM'];
  public static readonly DEFAULT_NASM_SOIL_TEST_VALUES = [
    'P',
    'K',
    'PH',
    'OM',
    'AS',
    'CD',
    'CO',
    'CR',
    'CU',
    'HG',
    'MO',
    'NI',
    'PB',
    'SE',
    'ZN'
  ];

  public static readonly N_FERTILIZER_TIMINGS = [
    {
      id: ApplicationTiming.Preplant,
      name: 'Preplant',
      i18nkey: 'calculator.cropnutri.nFertilizerTiming.preplant'
    },
    {
      id: ApplicationTiming.Sidedress,
      name: 'Sidedress',
      i18nkey: 'calculator.cropnutri.nFertilizerTiming.sidedress'
    }
  ];

  public static readonly MATERIAL_FORM_OPTIONS = [
    {
      id: MaterialForm.Liquid,
      name: 'Liquid',
      i18nkey: 'calculator.material.form.liquid'
    },
    {
      id: MaterialForm.Solid,
      name: 'Solid',
      i18nkey: 'calculator.material.form.solid'
    }
  ];

  public static readonly CONSTRUCTION_MATERIAL_FORM_OPTIONS = [
    {
      id: ConstructionMaterial.Steel,
      name: 'Steel',
      i18nkey: 'calculator.construction.material.form.steel'
    },
    {
      id: ConstructionMaterial.Concrete,
      name: 'Concrete',
      i18nkey: 'calculator.construction.material.form.concrete'
    },
    {
      id: ConstructionMaterial.RigidPlastic,
      name: 'Rigid Plastic',
      i18nkey: 'calculator.construction.material.form.rigid.plastic'
    },
    {
      id: ConstructionMaterial.Earth,
      name: 'Earth',
      i18nkey: 'calculator.construction.material.form.earth'
    }
  ];

  public static readonly SOIL_EROSION_METHODS = [
    {
      id: SoilErosionMethod.EnterValue,
      name: 'Enter value',
      i18nkey: 'calculator.plato.soil.erosion.enter-value'
    },
    {
      id: SoilErosionMethod.CalculateValue,
      name: 'Calculate value',
      i18nkey: 'calculator.plato.soil.erosion.calculate-value'
    }
  ];

  public static readonly TILE_DRAINAGE_SYSTEM_OPTIONS = [
    {
      id: TileDrainageSystem.None,
      name: 'None',
      i18nkey: 'calculator.plato.tile.drainage.system.none'
    },
    {
      id: TileDrainageSystem.Random,
      name: 'Random',
      i18nkey: 'calculator.plato.tile.drainage.system.random'
    },
    {
      id: TileDrainageSystem.Systematic,
      name: 'Systematic',
      i18nkey: 'calculator.plato.tile.drainage.system.systematic'
    }
  ];

  public static readonly NUTRIENT_VALUE_SOURCE_OPTIONS = [
    {
      id: 0,
      name: 'Nutrient Lab Analysis',
      i18nkey: 'calculator.material.source.nutrient-lab-analysis'
    },
    {
      id: 1,
      name: 'Nutrient Databank',
      i18nkey: 'calculator.material.source.nutrient-databank'
    }
  ];

  public static readonly NUTRIENT_VALUE_BASIS_OPTIONS = [
    {
      id: 0,
      name: 'Wet Basis',
      i18nkey: 'calculator.material.basis.wet'
    },
    {
      id: 1,
      name: 'Dry Basis',
      i18nkey: 'calculator.material.basis.dry'
    }
  ];

  public static readonly MSTOR_NUTRIENT_VALUE_BASIS_OPTIONS = [
    {
      id: 0,
      name: 'Wet Basis',
      i18nkey: 'calculator.material.basis.wet'
    },
    {
      id: 1,
      name: 'Dry Basis',
      i18nkey: 'calculator.material.basis.dry'
    }
  ];

  public static readonly MATERIAL_APPLICATION_SEASONS = [
    {
      id: MaterialApplicationSeasonType.Spring,
      name: 'Spring',
      i18nkey: 'calculaotr.material.season.spring'
    },
    {
      id: MaterialApplicationSeasonType.Summer,
      name: 'Summer',
      i18nkey: 'calculaotr.material.season.summer'
    },
    {
      id: MaterialApplicationSeasonType.LateSummer,
      name: 'Late Summer',
      i18nkey: 'calculaotr.material.season.late-summer'
    },
    {
      id: MaterialApplicationSeasonType.EarlyFall,
      name: 'Early Fall',
      i18nkey: 'calculaotr.material.season.early-fall'
    },
    {
      id: MaterialApplicationSeasonType.LateFall,
      name: 'Late Fall',
      i18nkey: 'calculaotr.material.season.late-fall'
    },
    {
      id: MaterialApplicationSeasonType.Winter,
      name: 'Winter',
      i18nkey: 'calculaotr.material.season.winter'
    }
  ];

  public static readonly SEASON_EMPTYING_OPTIONS = [
    {
      id: SeasonEmptying.Fall,
      name: 'Fall',
      i18nkey: 'calculator.season.empty.0'
    },
    {
      id: SeasonEmptying.Spring,
      name: 'Spring',
      i18nkey: 'calculator.season.empty.1'
    },
    {
      id: SeasonEmptying.Summer,
      name: 'Summer',
      i18nkey: 'calculator.season.empty.2'
    },
    {
      id: SeasonEmptying.SpringAndFall,
      name: 'Spring and fall',
      i18nkey: 'calculator.season.empty.3'
    }
  ];

  public static readonly MATERIAL_APPLICATION_TIMINGS = [
    {
      id: ApplicationTiming.Preplant,
      name: 'Preplant',
      i18nkey: 'calculator.cropnutri.nFertilizerTiming.preplant'
    },
    {
      id: ApplicationTiming.Sidedress,
      name: 'Sidedress',
      i18nkey: 'calculator.cropnutri.nFertilizerTiming.sidedress'
    }
  ];

  public static readonly FERTILIZER_CALCULATION_TYPES = [
    {
      id: CalculationType.Applied,
      name: 'Calculate Nutrients Applied',
      i18nkey: 'calculator.material.calculate-nutrient-applied'
    },
    {
      id: CalculationType.Blend,
      name: 'Calculate Fertilizer Blend & Application Rates',
      i18nkey: 'calculator.material.calculate-fertilizer-blend'
    }
  ];

  public static readonly FMP_FERTILIZER_CALCULATION_TYPES = [
    {
      id: CalculationType.Applied,
      name: 'Calculate Nutrients Applied',
      i18nkey: 'calculator.material.calculate-nutrient-applied'
    },
    {
      id: CalculationType.Blend,
      name: 'Calculate Fertilizer Blend & Application Rates',
      i18nkey: 'calculator.material.calculate-fertilizer-blend'
    },
    {
      id: CalculationType.Entered,
      name: 'Enter Nutrients Applied',
      i18nkey: 'calculator.material.enter-nutrients-applied'
    }
  ];

  public static readonly FMP_REPORT_TYPES = [
    {
      id: FmpReportType.currentFieldOnly,
      name: 'Current Field',
      i18nkey: 'calculator.fmp.report.current.field.only'
    },
    {
      id: FmpReportType.allFarmsAndFields,
      name: 'All Fields',
      i18nkey: 'calculator.fmp.report.all.fields'
    }
  ];

  public static readonly PLATO_FERTILIZER_CALCULATION_TYPES = [
    {
      id: CalculationType.Applied,
      name: 'Calculate Phosphate Applied',
      i18nkey: 'calculator.plato.fertilizer.calculation.type.applied'
    },
    {
      id: CalculationType.Entered,
      name: 'Enter Phosphate Applied',
      i18nkey: 'calculator.plato.fertilizer.calculation.type.entered'
    }
  ];

  public static readonly FERTILIZER_FORM_OPTIONS = [
    {
      id: MaterialForm.Liquid,
      name: 'Liquid',
      i18nkey: 'calculator.fertilizer.app.form.liquid'
    },
    {
      id: MaterialForm.Solid,
      name: 'Dry',
      i18nkey: 'calculator.fertilizer.app.form.dry'
    }
  ];

  public static readonly DEPTH_TO_BEDROCK_OPTIONS = [
    {
      id: DepthToBedrock.LessThan30,
      name: '< 30 cm'
    },
    {
      id: DepthToBedrock.Between30And50,
      name: '30 - 50 cm'
    },
    {
      id: DepthToBedrock.Between50And100,
      name: '50 - 100 cm'
    },
    {
      id: DepthToBedrock.GreaterThan100,
      name: '> 100 cm'
    }
  ];

  public static readonly ODOUR_CATEGORY_OPTIONS = [
    {
      id: OdourCategory.OC1,
      name: 'OC1'
    },
    {
      id: OdourCategory.OC2,
      name: 'OC2'
    },
    {
      id: OdourCategory.OC3,
      name: 'OC3'
    }
  ];

  public static readonly FERTILIZER_APPLIED_NUTRIENTS = [
    {
      key: 'N',
      i18nkey: 'calculator.fertilizer.nutrient.n',
      objectKey: 'targetNitrogenInMetric',
      name: 'Nitrogen',
      displayOrder: 1
    },
    {
      key: 'P2O5',
      i18nkey: 'calculator.fertilizer.nutrient.p2o5',
      objectKey: 'targetPhosphateInMetric',
      name: 'Phosphorus',
      displayOrder: 2
    },
    {
      key: 'K2O',
      i18nkey: 'calculator.fertilizer.nutrient.k2o',
      objectKey: 'targetPotashInMetric',
      name: 'Potassium',
      displayOrder: 3
    },
    {
      key: 'Ca',
      i18nkey: 'calculator.fertilizer.nutrient.ca',
      objectKey: 'targetCalciumInMetric',
      name: 'Calcium',
      displayOrder: 4
    },
    {
      key: 'Cl',
      i18nkey: 'calculator.fertilizer.nutrient.cl',
      objectKey: '',
      name: 'Chlorine',
      displayOrder: 5
    },
    {
      key: 'Mg',
      i18nkey: 'calculator.fertilizer.nutrient.mg',
      objectKey: 'targetMagnesiumInMetric',
      name: 'Magnesium',
      displayOrder: 6
    },
    {
      key: 'S',
      i18nkey: 'calculator.fertilizer.nutrient.s',
      objectKey: 'targetSulfurInMetric',
      name: 'Sulfur',
      displayOrder: 7
    },
    {
      key: 'B',
      i18nkey: 'calculator.fertilizer.nutrient.b',
      objectKey: 'targetBoronInMetric',
      name: 'Boron',
      displayOrder: 8
    },
    {
      key: 'Mn',
      i18nkey: 'calculator.fertilizer.nutrient.mn',
      objectKey: 'targetManganeseInMetric',
      name: 'Manganese',
      displayOrder: 9
    },
    {
      key: 'Mo',
      i18nkey: 'calculator.fertilizer.nutrient.mo',
      objectKey: 'targetMolybdenumInMetric',
      name: 'Molybdenum',
      displayOrder: 10
    },
    {
      key: 'Zn',
      i18nkey: 'calculator.fertilizer.nutrient.zn',
      objectKey: 'targetZincInMetric',
      name: 'Zinc',
      displayOrder: 11
    },
    {
      key: 'Cu',
      i18nkey: 'calculator.fertilizer.nutrient.cu',
      name: 'Copper',
      displayOrder: 12
    }
  ];

  public static readonly RECOMMENDATION_REMOVALS = [
    {
      key: 'agronomicNitrogenInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.n',
      subKey: 'n'
    },
    {
      key: 'agronomicPhosphateInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.p2o5',
      subKey: 'p2o5'
    },
    {
      key: 'agronomicPotashInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.k2o',
      subKey: 'k2o'
    },
    {
      key: 'removalNitrogenInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.n',
      subKey: 'n'
    },
    {
      key: 'removalPhosphateInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.p2o5',
      subKey: 'p2o5'
    },
    {
      key: 'removalPotashInMetric',
      i18nkey: 'calculator.fertilizer.nutrient.k2o',
      subKey: 'k2o'
    }
  ];

  public static readonly PROD_RECOMMENDATION_KEYS = ['agronomicNitrogenInMetric', 'agronomicPhosphateInMetric', 'agronomicPotashInMetric'];
  public static readonly CROP_REMOVAL_KEYS = ['removalNitrogenInMetric', 'removalPhosphateInMetric', 'removalPotashInMetric'];

  public static readonly STORAGE_SIZING_REPORT_SECTIONS = [
    {
      key: 'systemInformation',
      i18nKey: 'calculator.ss.report.section.systemInformation'
    },
    {
      key: 'sourceMaterial',
      i18nKey: 'calculator.ss.report.section.sourceMaterial'
    },
    {
      key: 'treatments',
      i18nKey: 'calculator.ss.report.section.treatments'
    },
    {
      key: 'storages',
      i18nKey: 'calculator.ss.report.section.storages'
    },
    {
      key: 'nutrientUtilization',
      i18nKey: 'calculator.ss.report.section.nutrientUtilization'
    }
  ];

  public static readonly NUTRIENT_MGMT_REPORT_SECTIONS = [
    {
      key: 'generalInformation',
      i18nKey: 'calculator.nmsp.report.section.generalInformation'
    },
    {
      key: 'reasonForSubmission',
      i18nKey: 'calculator.nmsp.report.section.reasonForSubmission'
    },
    {
      key: 'preparerInformation',
      i18nKey: 'calculator.nmsp.report.section.preparerInformation'
    },
    {
      key: 'agriculturalOperationInfo',
      i18nKey: 'calculator.nmsp.report.section.agriculturalOperationInfo'
    },
    {
      key: 'nutrientMgmtStrategy',
      i18nKey: 'calculator.nmsp.report.section.nutrientMgmtStrategy'
    },
    {
      key: 'farmUnit',
      i18nKey: 'calculator.nmsp.report.section.farmUnit'
    },
    {
      key: 'systemInformation',
      i18nKey: 'calculator.ss.report.section.systemInformation'
    },
    {
      key: 'sourceMaterial',
      i18nKey: 'calculator.ss.report.section.sourceMaterial'
    },
    {
      key: 'treatments',
      i18nKey: 'calculator.ss.report.section.treatments'
    },
    {
      key: 'storages',
      i18nKey: 'calculator.ss.report.section.storages'
    },
    {
      key: 'nutrientUtilization',
      i18nKey: 'calculator.ss.report.section.nutrientUtilization'
    },
    {
      key: 'transferContacts',
      i18nKey: 'calculator.nmsp.report.section.transferContacts'
    },
    {
      key: 'outgoingTransfer',
      i18nKey: 'calculator.nmsp.report.section.outgoingTransfer'
    },
    {
      key: 'flagSummary',
      i18nKey: 'calculator.nmsp.report.section.flagSummary'
    },
    {
      key: 'farmUnitDeclaration',
      i18nKey: 'calculator.nmsp.report.section.farmUnitDeclaration'
    },
    {
      key: 'appendix',
      i18nKey: 'calculator.nmsp.report.section.appendix'
    }
  ];

  public static readonly GHG_REPORT_SECTIONS = [
    {
      key: 'generalInformation',
      i18nKey: 'calculator.nmsp.report.section.generalInformation'
    },
    {
      key: 'preparerInformation',
      i18nKey: 'calculator.nmsp.report.section.preparerInformation'
    },
    {
      key: 'systemInformation',
      i18nKey: 'calculator.ss.report.section.systemInformation'
    },
    {
      key: 'sourceMaterial',
      i18nKey: 'calculator.ss.report.section.sourceMaterial'
    },
    {
      key: 'storageEmissions',
      i18nKey: 'calculator.ss.report.section.storageEmissions'
    },
    {
      key: 'farmInformation',
      i18nKey: 'calculator.fmp.report.section.farmInformation'
    },
    {
      key: 'fieldProperties',
      i18nKey: 'calculator.fmp.report.section.fieldProperties'
    },
    {
      key: 'fieldEmissions',
      i18nKey: 'calculator.ghg.report.section.fieldEmissions'
    },
    {
      key: 'buildingInformation',
      i18nKey: 'calculator.ghg.report.section.buildingInformation'
    },
    {
      key: 'buildingEmissions',
      i18nKey: 'calculator.ghg.report.section.buildingEmissions'
    }
  ];

  public static readonly FMP_REPORT_SECTIONS = [
    {
      key: 'materialType',
      i18nKey: 'calculator.fmp.report.section.materialType'
    },
    {
      key: 'farmInformation',
      i18nKey: 'calculator.fmp.report.section.farmInformation'
    },
    {
      key: 'fieldProperties',
      i18nKey: 'calculator.fmp.report.section.fieldProperties'
    },
    {
      key: 'soilTestValues',
      i18nKey: 'calculator.cropnutri.soilTestValues.label'
    },
    {
      key: 'fieldInputsSummary',
      i18nKey: 'calculator.fmp.report.section.fieldInputsSummary'
    },
    {
      key: 'crops',
      i18nKey: 'calculator.fmp.report.section.crops'
    },
    {
      key: 'materialApplication',
      i18nKey: 'calculator.fmp.report.section.materialApplication'
    },
    {
      key: 'fertilizerApplication',
      i18nKey: 'calculator.fmp.report.section.fertilizerApplication'
    }
  ];

  public static readonly FERTILIZER_MICRO_NUTRIENTS = [
    {
      key: 'Ca',
      i18nkey: 'calculator.fertilizer.nutrient.ca',
      objectKey: 'targetCalciumInMetric'
    },
    {
      key: 'Mg',
      i18nkey: 'calculator.fertilizer.nutrient.mg',
      objectKey: 'targetMagnesiumInMetric'
    },
    {
      key: 'S',
      i18nkey: 'calculator.fertilizer.nutrient.s',
      objectKey: 'targetSulfurInMetric'
    },
    {
      key: 'B',
      i18nkey: 'calculator.fertilizer.nutrient.b',
      objectKey: 'targetBoronInMetric'
    },
    {
      key: 'Mn',
      i18nkey: 'calculator.fertilizer.nutrient.mn',
      objectKey: 'targetManganeseInMetric'
    },
    {
      key: 'Mo',
      i18nkey: 'calculator.fertilizer.nutrient.mo',
      objectKey: 'targetMolybdenumInMetric'
    },
    {
      key: 'Zn',
      i18nkey: 'calculator.fertilizer.nutrient.zn',
      objectKey: 'targetZincInMetric'
    }
  ];

  public static readonly FLAG_LEVELS = [
    {
      flagLevelId: 4,
      i18nkey: 'flag.level.4.text',
      image: 'default.png',
      displayOrder: 1,
      checked: true,
      active: true
    },
    {
      flagLevelId: 3,
      i18nkey: 'flag.level.3.text',
      image: 'default.png',
      displayOrder: 2,
      checked: true,
      active: true
    },
    {
      flagLevelId: 2,
      i18nkey: 'flag.level.2.text',
      image: 'default.png',
      displayOrder: 3,
      checked: false,
      active: true
    },
    {
      flagLevelId: 7,
      i18nkey: 'flag.level.7.text',
      image: 'default.png',
      displayOrder: 4,
      checked: true,
      active: true
    },
    {
      flagLevelId: 1,
      i18nkey: 'flag.level.1.text',
      image: 'default.png',
      displayOrder: 5,
      checked: false,
      active: true
    },
    {
      flagLevelId: 5,
      i18nkey: 'flag.level.5.text',
      image: 'FlagLegislative.png',
      displayOrder: 6,
      checked: true,
      active: true
    },
    {
      flagLevelId: 6,
      i18nkey: 'flag.level.6.text',
      image: 'default.png',
      displayOrder: 7,
      checked: true,
      active: true
    },
    {
      flagLevelId: 8,
      i18nkey: 'flag.level.8.text',
      image: 'default.png',
      displayOrder: 8,
      checked: true,
      active: true
    }
  ];

  public static readonly FIELD_INPUT_TYPES = [
    {
      key: FieldInputType.Material,
      i18nkey: 'calculator.field.input.type.material'
    },
    {
      key: FieldInputType.Fertilizer,
      i18nkey: 'calculator.field.input.type.fertilizer'
    }
  ];

  public static readonly BUFFER_ZONES = [
    ['> 10 m', '3 - 10 m', '< 3 m'],
    ['> 33 ft', '10 - 33 ft', '< 10 ft'],
    ['> 33 ft', '10 - 33 ft', '< 10 ft']
  ];

  public static readonly BUFFER_ZONES_I18N = [
    ['>10m', '3-10m', '<3m'],
    ['>33ft', '10-33ft', '<10ft'],
    ['>33ft', '10-33 ft', '<10ft']
  ];

  public static readonly TOTAL_P_INDEX = [
    {
      key: TotalPhosphorusIndex.VeryLow,
      i18nkey: 'calculator.plato.total.pindex.very.low'
    },
    {
      key: TotalPhosphorusIndex.Low,
      i18nkey: 'calculator.plato.total.pindex.low'
    },
    {
      key: TotalPhosphorusIndex.Moderate,
      i18nkey: 'calculator.plato.total.pindex.moderate'
    },
    {
      key: TotalPhosphorusIndex.High,
      i18nkey: 'calculator.plato.total.pindex.high'
    }
  ];

  public static readonly FARM_STATUS = [
    {
      key: FarmStatus.Owned,
      i18nkey: 'calculator.fmp.farm.status.owned'
    },
    {
      key: FarmStatus.Rented,
      i18nkey: 'calculator.fmp.farm.status.rented'
    },
    {
      key: FarmStatus.Leased,
      i18nkey: 'calculator.fmp.farm.status.leased'
    }
  ];

  public static readonly NUTRIENT_BALANCE = [
    {
      key: NutrientBalanceType.Agronomic,
      i18nkey: 'nutrient.balance.type.agronomic',
      objectKeyN: 'agronomicN',
      objectKeyP: 'agronomicP2O5',
      objectKeyK: 'agronomicK2O'
    },
    {
      key: NutrientBalanceType.CropRemoval,
      i18nkey: 'nutrient.balance.type.crop.removal',
      objectKeyN: 'cropRemovalN',
      objectKeyP: 'cropRemovalP2O5',
      objectKeyK: 'cropRemovalK2O'
    },
    {
      key: NutrientBalanceType.Hybrid,
      i18nkey: 'nutrient.balance.type.hybrid',
      objectKeyN: 'agronomicN',
      objectKeyP: 'cropRemovalP2O5',
      objectKeyK: 'cropRemovalK2O'
    }
  ];

  public static readonly RUNOFF_POTENTIAL = [
    {
      key: RunoffPotentialType.High,
      i18nkey: 'runoff.potential.high'
    },
    {
      key: RunoffPotentialType.Moderate,
      i18nkey: 'runoff.potential.moderate'
    },
    {
      key: RunoffPotentialType.Low,
      i18nkey: 'runoff.potential.low'
    },
    {
      key: RunoffPotentialType.VeryLow,
      i18nkey: 'runoff.potential.veryLow'
    },
    {
      key: RunoffPotentialType.NoLiquidApp,
      i18nkey: 'runoff.potential.noLiquidApp'
    }
  ];

  public static readonly MATERIAL_PRODUCTION_FREQUENCY = [
    {
      key: MaterialProductionFrequency.Daily,
      i18nkey: 'calculator.ss.other.source.frequency.daily'
    },
    {
      key: MaterialProductionFrequency.Weekly,
      i18nkey: 'calculator.ss.other.source.frequency.weekly'
    },
    {
      key: MaterialProductionFrequency.Monthly,
      i18nkey: 'calculator.ss.other.source.frequency.monthly'
    },
    {
      key: MaterialProductionFrequency.Yearly,
      i18nkey: 'calculator.ss.other.source.frequency.yearly'
    }
  ];

  public static readonly OUTGOING_TRANSFER_FREQUENCY = [
    {
      key: OutgoingTransferRecurringFrequency.Weekly,
      i18nkey: 'calculator.ss.ot.transfer.freq.weekly'
    },
    {
      key: OutgoingTransferRecurringFrequency.Monthly,
      i18nkey: 'calculator.ss.ot.transfer.freq.monthly'
    },
    {
      key: OutgoingTransferRecurringFrequency.Yearly,
      i18nkey: 'calculator.ss.ot.transfer.freq.yearly'
    }
  ];

  public static readonly LIVESTOCK_TYPE_OPTIONS = [
    {
      key: LivestockType.Swine,
      i18nkey: 'calculator.ss.livestock.combo.type.swine'
    },
    {
      key: LivestockType.Dairy,
      i18nkey: 'calculator.ss.livestock.combo.type.dairy'
    },
    {
      key: LivestockType.Sheep,
      i18nkey: 'calculator.ss.livestock.combo.type.sheep'
    }
  ];

  public static SILO_TYPE_OPTIONS = [
    {
      id: SiloType.Horizontal,
      i18nkey: 'calculator.ss.silo.type.h'
    },
    {
      id: SiloType.Tower,
      i18nkey: 'calculator.ss.silo.type.t'
    }
  ];

  public static MOISTURE_LEVEL_OPTIONS = [
    {
      id: MoistureLevel.LessThan70,
      i18nkey: 'calculator.ss.silo.moisture.level1'
    },
    {
      id: MoistureLevel.Between70And80,
      i18nkey: 'calculator.ss.silo.moisture.level2'
    },
    {
      id: MoistureLevel.GreaterThan80,
      i18nkey: 'calculator.ss.silo.moisture.level3'
    }
  ];

  public static DIRECTION_ROOF_SLOPE_OPTIONS = [
    {
      id: DirectionRoofSlope.NorthSouth,
      i18nkey: 'calculator.ghg.building.direction.roof.slope.opt.1'
    },
    {
      id: DirectionRoofSlope.EastWest,
      i18nkey: 'calculator.ghg.building.direction.roof.slope.opt.2'
    }
  ];

  public static SILO_FILL_DATE_OPTIONS = [
    {
      id: SiloFillDate.May,
      i18nkey: 'calculator.ss.silo.fill.date.5'
    },
    {
      id: SiloFillDate.June,
      i18nkey: 'calculator.ss.silo.fill.date.6'
    },
    {
      id: SiloFillDate.July,
      i18nkey: 'calculator.ss.silo.fill.date.7'
    },
    {
      id: SiloFillDate.August,
      i18nkey: 'calculator.ss.silo.fill.date.8'
    },
    {
      id: SiloFillDate.September,
      i18nkey: 'calculator.ss.silo.fill.date.9'
    },
    {
      id: SiloFillDate.October,
      i18nkey: 'calculator.ss.silo.fill.date.10'
    },
    {
      id: SiloFillDate.November,
      i18nkey: 'calculator.ss.silo.fill.date.11'
    }
  ];

  public static SILO_CAPTURE_PERIODS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  public static readonly DEFAULT_CALCULATOR_NAMES = ['Crop Nutrient', 'Culture'];
  public static readonly DEFAULT_FARM_NAMES = ['Farm A', 'Ferme A'];
  public static readonly DEFAULT_FIELD_NAMES = ['Field 1', 'Champ 1'];

  public static SILO_COVERED_OPTIONS = [
    {
      id: SiloCovered.NotCovered,
      i18nkey: 'calculator.ss.silo.covered.opt1'
    },
    {
      id: SiloCovered.WaterproofCoverRainwaterDiverted,
      i18nkey: 'calculator.ss.silo.covered.opt2'
    },
    {
      id: SiloCovered.PermanentRoot,
      i18nkey: 'calculator.ss.silo.covered.opt3'
    }
  ];

  public static MILKING_LIVESTOCK_TYPE_OPTIONS = [
    {
      id: MilkingLivestockType.DairyCows,
      i18nkey: 'calculator.ss.milking.livestock.type.opt1'
    },
    {
      id: MilkingLivestockType.Goats,
      i18nkey: 'calculator.ss.milking.livestock.type.opt2'
    },
    {
      id: MilkingLivestockType.Sheep,
      i18nkey: 'calculator.ss.milking.livestock.type.opt3'
    }
  ];

  public static MILKING_SYSTEM_TYPE_OPTIONS = [
    {
      id: MilkingSystemType.Bucket,
      i18nkey: 'calculator.ss.milking.system.type.1'
    },
    {
      id: MilkingSystemType.Pipeline,
      i18nkey: 'calculator.ss.milking.system.type.2'
    },
    {
      id: MilkingSystemType.Parlour,
      i18nkey: 'calculator.ss.milking.system.type.3'
    },
    {
      id: MilkingSystemType.RoboticBrush,
      i18nkey: 'calculator.ss.milking.system.type.4'
    },
    {
      id: MilkingSystemType.RoboticWater,
      i18nkey: 'calculator.ss.milking.system.type.5'
    }
  ];

  public static MILKING_ANSWER_OPTIONS = [
    {
      id: MilkingAnswer.NotSpecified,
      i18nkey: 'calculator.ss.milking.answer.1'
    },
    {
      id: MilkingAnswer.No,
      i18nkey: 'calculator.ss.milking.answer.2'
    },
    {
      id: MilkingAnswer.Yes,
      i18nkey: 'calculator.ss.milking.answer.3'
    }
  ];

  public static readonly ALL_MILKING_QUESTIONS = [
    'milkingDairyWash1',
    'milkingDairyWash2',
    'milkingDairyWash3',
    'milkingDairyWash4',
    'milkingGoatWash1',
    'milkingGoatWash2',
    'milkingGoatWash3'
  ];

  public static readonly STORAGE_SIZING_ENTITIES = ['livestock', 'storageGroups', 'treatments', 'otherSources'];

  public static AMOUNT_TYPE_OPTIONS = [
    {
      id: AmountType.doesNotChangeThroughoutYear,
      i18nkey: 'calculator.ss.other.source.amount.type.opt1'
    },
    {
      id: AmountType.changesThroughoutYear,
      i18nkey: 'calculator.ss.other.source.amount.type.opt2'
    }
  ];

  public static readonly MONTHS = [
    {
      id: Month.January,
      i18nkey: 'month.1',
      shortKey: 'month.1.short'
    },
    {
      id: Month.February,
      i18nkey: 'month.2',
      shortKey: 'month.2.short'
    },
    {
      id: Month.March,
      i18nkey: 'month.3',
      shortKey: 'month.3.short'
    },
    {
      id: Month.April,
      i18nkey: 'month.4',
      shortKey: 'month.4.short'
    },
    {
      id: Month.May,
      i18nkey: 'month.5',
      shortKey: 'month.5.short'
    },
    {
      id: Month.June,
      i18nkey: 'month.6',
      shortKey: 'month.6.short'
    },
    {
      id: Month.July,
      i18nkey: 'month.7',
      shortKey: 'month.7.short'
    },
    {
      id: Month.August,
      i18nkey: 'month.8',
      shortKey: 'month.8.short'
    },
    {
      id: Month.September,
      i18nkey: 'month.9',
      shortKey: 'month.9.short'
    },
    {
      id: Month.October,
      i18nkey: 'month.10',
      shortKey: 'month.10.short'
    },
    {
      id: Month.November,
      i18nkey: 'month.11',
      shortKey: 'month.11.short'
    },
    {
      id: Month.December,
      i18nkey: 'month.12',
      shortKey: 'month.12.short'
    }
  ];

  public static readonly MONTHS2 = [
    {
      id: Month2.January,
      i18nkey: 'month.1',
      shortKey: 'month.1.short'
    },
    {
      id: Month2.February,
      i18nkey: 'month.2',
      shortKey: 'month.2.short'
    },
    {
      id: Month2.March,
      i18nkey: 'month.3',
      shortKey: 'month.3.short'
    },
    {
      id: Month2.April,
      i18nkey: 'month.4',
      shortKey: 'month.4.short'
    },
    {
      id: Month2.May,
      i18nkey: 'month.5',
      shortKey: 'month.5.short'
    },
    {
      id: Month2.June,
      i18nkey: 'month.6',
      shortKey: 'month.6.short'
    },
    {
      id: Month2.July,
      i18nkey: 'month.7',
      shortKey: 'month.7.short'
    },
    {
      id: Month2.August,
      i18nkey: 'month.8',
      shortKey: 'month.8.short'
    },
    {
      id: Month2.September,
      i18nkey: 'month.9',
      shortKey: 'month.9.short'
    },
    {
      id: Month2.October,
      i18nkey: 'month.10',
      shortKey: 'month.10.short'
    },
    {
      id: Month2.November,
      i18nkey: 'month.11',
      shortKey: 'month.11.short'
    },
    {
      id: Month2.December,
      i18nkey: 'month.12',
      shortKey: 'month.12.short'
    }
  ];

  public static readonly PROVINCE_OPTIONS = [
    {
      id: Province.AB,
      i18nkey: 'province.AB'
    },
    {
      id: Province.BC,
      i18nkey: 'province.BC'
    },
    {
      id: Province.MB,
      i18nkey: 'province.MB'
    },
    {
      id: Province.NB,
      i18nkey: 'province.NB'
    },
    {
      id: Province.NF,
      i18nkey: 'province.NF'
    },
    {
      id: Province.NS,
      i18nkey: 'province.NS'
    },
    {
      id: Province.ON,
      i18nkey: 'province.ON'
    },
    {
      id: Province.PE,
      i18nkey: 'province.PE'
    },
    {
      id: Province.PQ,
      i18nkey: 'province.PQ'
    },
    {
      id: Province.SK,
      i18nkey: 'province.SK'
    }
  ];

  public static readonly COUNTRY_OPTIONS = [
    {
      id: Country.Canada,
      i18nkey: 'country.Canada'
    },
    {
      id: Country.US,
      i18nkey: 'country.US'
    }
  ];

  public static readonly BUILDING_PERMITS_ISSUED_ANSWER_OPTIONS = [
    {
      id: BuildingPermitAnswer.Yes,
      i18nkey: 'calculator.mds.building.permits.common.answer.1'
    },
    {
      id: BuildingPermitAnswer.No,
      i18nkey: 'calculator.mds.building.permits.common.answer.2'
    }
  ];

  public static readonly LIVESTOCK_MANURE_STORAGE_ANSWER_OPTIONS = [
    {
      id: LivestockFacility.BarnManure,
      shortKey: 'barn.manure',
      i18nkey: 'calculator.mds.livestock.manure.q1.answer.1'
    },
    {
      id: LivestockFacility.BarnOnly,
      shortKey: 'barn.only',
      i18nkey: 'calculator.mds.livestock.manure.q1.answer.2'
    },
    {
      id: LivestockFacility.ManureOnly,
      shortKey: 'manure.only',
      i18nkey: 'calculator.mds.livestock.manure.q1.answer.3'
    }
  ];

  public static readonly LIVESTOCK_MANURE_INFO_ANSWER_OPTIONS = [
    {
      id: LivestockManureInfoAnswer.Yes,
      i18nkey: 'calculator.mds.livestock.manure.common.answer.1'
    },
    {
      id: LivestockManureInfoAnswer.No,
      i18nkey: 'calculator.mds.livestock.manure.common.answer.2'
    }
  ];

  public static readonly WORKSHEET_SUBMISSION_REASON_OPTIONS = [
    {
      id: WorksheetSubmissionReason.ConstructBuildingOrStorage,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.0'
    },
    {
      id: WorksheetSubmissionReason.ConstructAnaerobicDigestor,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.1'
    },
    {
      id: WorksheetSubmissionReason.Certification,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.2'
    },
    {
      id: WorksheetSubmissionReason.ChangeInOwnership,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.3'
    },
    {
      id: WorksheetSubmissionReason.HorticulturalMaterial,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.4'
    },
    {
      id: WorksheetSubmissionReason.Other,
      i18nkey: 'calculator.nmsp.worksheet.submission.reason.5'
    }
  ];

  public static readonly FEDERAL_BUSINESS_NUMBER_EXEMPTION_REASON_OPTIONS = [
    {
      id: ExemptionReason.CRA,
      i18nkey: 'calculator.nmsp.general.info.federal.business.number.exempt.reason.cra'
    },
    {
      id: ExemptionReason.Religious,
      i18nkey: 'calculator.nmsp.general.info.federal.business.number.exempt.reason.religious'
    }
  ];

  public static readonly FEDERAL_BUSINESS_NUMBER_EXEMPTION_REASON_TEXT_IN_REPORT = [
    {
      id: ExemptionReason.CRA,
      i18nkey: 'calculator.nmsp.report.section.declarationForm.federal.business.number.exempt.reason.cra'
    },
    {
      id: ExemptionReason.Religious,
      i18nkey: 'calculator.nmsp.report.section.declarationForm.federal.business.number.exempt.reason.religious'
    }
  ];

  public static readonly OPERATION_TYPE_OPTIONS = [
    {
      id: OperationType.SoleProprietorship,
      i18nkey: 'calculator.nmsp.general.info.operation.type.0'
    },
    {
      id: OperationType.PartnershipJointSubmission,
      i18nkey: 'calculator.nmsp.general.info.operation.type.1'
    },
    {
      id: OperationType.DivisionOfCorporation,
      i18nkey: 'calculator.nmsp.general.info.operation.type.2'
    },
    {
      id: OperationType.Corporation,
      i18nkey: 'calculator.nmsp.general.info.operation.type.3'
    }
  ];

  public static readonly FIELD_INPUT_FILTER = [
    {
      id: FieldInputFilter.AllFieldInputs,
      i18nkey: 'calculator.fmp.fi.filter.opt1'
    },
    {
      id: FieldInputFilter.CropsOnly,
      i18nkey: 'calculator.fmp.fi.filter.opt2'
    },
    {
      id: FieldInputFilter.MaterialApplicationsOnly,
      i18nkey: 'calculator.fmp.fi.filter.opt3'
    },
    {
      id: FieldInputFilter.FertilizerApplicationsOnly,
      i18nkey: 'calculator.fmp.fi.filter.opt4'
    }
  ];

  public static readonly NMSP_REPORT_TYPE_OPTIONS = [
    {
      id: NmspReportType.NutrientMgmtStrategy,
      name: 'Nutrient Management Strategy',
      i18nkey: 'calculator.nmsp.report.report.type.nutrient.mgmt.strategy'
    },
    {
      id: NmspReportType.NutrientMgmtPlan,
      name: 'Nutrient Management Plan',
      i18nkey: 'calculator.nmsp.report.report.type.nutrient.mgmt.plan'
    },
    {
      id: NmspReportType.NutrientMgmtFarm,
      name: 'Nutrient Management Farm Registration',
      i18nkey: 'calculator.nmsp.report.report.type.nutrient.mgmt.farm'
    }
  ];

  public static readonly RNM_SUBMISSION_TYPE_OPTIONS = [
    {
      id: SubmissionType.NewSubmission,
      name: 'New Submission',
      i18nkey: 'calculator.nmsp.report.submission.type.new'
    },
    {
      id: SubmissionType.ResponseToRequest,
      name: 'Response to Information Request',
      i18nkey: 'calculator.nmsp.report.submission.type.info.request'
    },
    {
      id: SubmissionType.Amendment,
      name: 'Amendment',
      i18nkey: 'calculator.nmsp.report.submission.type.amendment'
    },
    {
      id: SubmissionType.RecordKeeping,
      name: 'Record Keeping Purposes',
      i18nkey: 'calculator.nmsp.report.submission.type.record.keeping'
    }
  ];

  public static readonly GNF_SUBMISSION_REASON_STRATEGY_OPTIONS = [
    {
      id: SubmissionReason.NewSubmission,
      name: 'New Submission',
      i18nkey: 'calculator.gnf.report.submission.reason.new'
    },
    {
      id: SubmissionReason.Revised,
      name: 'Revised submission due to operational change',
      i18nkey: 'calculator.gnf.report.submission.reason.revised'
    },
    {
      id: SubmissionReason.RecordKeeping,
      name: 'Updated submission for record keeping purposes',
      i18nkey: 'calculator.gnf.report.submission.reason.record.keeping'
    }
  ];

  public static readonly GNF_SUBMISSION_REASON_REGISTRATION_OPTIONS = ArrayConstants.GNF_SUBMISSION_REASON_STRATEGY_OPTIONS.concat({
    id: SubmissionReason.Renewal,
    name: 'Renewal',
    i18nkey: 'calculator.gnf.report.submission.reason.renewal'
  });

  public static readonly NASM_REPORT_TYPE_OPTIONS: i18nText[] = [
    {
      id: NasmReportType.PlanApprovals,
      name: 'NASM Plan Approvals Submission',
      i18nkey: 'calculator.nasm.report.report.type.plan.approvals'
    },
    {
      id: NasmReportType.PlanRegistration,
      name: 'NASM Plan Registration Submission',
      i18nkey: 'calculator.nasm.report.report.type.plan.registration'
    },
    {
      id: NasmReportType.LandApplication,
      name: 'NASM Land Application Schedule',
      i18nkey: 'calculator.nasm.report.report.type.land.application'
    },
    {
      id: NasmReportType.PostApplication,
      name: 'NASM Post Application Report',
      i18nkey: 'calculator.nasm.report.report.type.post.application'
    }
  ];

  public static readonly NASM_STORAGE_CATEGORY_OPTIONS = [
    {
      id: StorageCategory.nasmStorageFacility,
      i18nkey: 'calculator.nasm.storage.category.nasm'
    },
    {
      id: StorageCategory.ecaStorage,
      i18nkey: 'calculator.nasm.storage.category.eca'
    }
  ];

  public static readonly NASM_STORAGE_CONSTRUCTION_DATE_OPTIONS = [
    {
      id: ConstructionDate.afterJan1,
      i18nkey: 'calculator.nasm.storage.cd.1'
    },
    {
      id: ConstructionDate.betweenJun30AndJan1,
      i18nkey: 'calculator.nasm.storage.cd.2'
    },
    {
      id: ConstructionDate.beforeJun30,
      i18nkey: 'calculator.nasm.storage.cd.3'
    }
  ];

  public static readonly BUILDING_PERMIT_REQUIRED_FIELDS = ['buildingPermitNumber', 'issueDate'];

  public static readonly NASM_COMPONENT_TYPES = [
    {
      id: MaterialComponentType.Nasm,
      i18nkey: 'calculator.nasm.comp.type.nasm'
    },
    {
      id: MaterialComponentType.Fertilizer,
      i18nkey: 'calculator.nasm.comp.type.fertilizer'
    },
    {
      id: MaterialComponentType.Compost,
      i18nkey: 'calculator.nasm.comp.type.compost'
    },
    {
      id: MaterialComponentType.Asm,
      i18nkey: 'calculator.nasm.comp.type.asm'
    }
  ];

  public static readonly GNF_REPORT_TYPE_OPTIONS: i18nText[] = [
    {
      id: GnfReportType.Registration,
      name: 'GNF Registration',
      i18nkey: 'calculator.gnf.report.report.type.registration'
    },
    {
      id: GnfReportType.Document,
      name: 'GNF Document (< 5 NU)',
      i18nkey: 'calculator.gnf.report.report.type.document'
    },
    {
      id: GnfReportType.Record,
      name: 'GNF Record (< 5NU)',
      i18nkey: 'calculator.gnf.report.report.type.record'
    },
    {
      id: GnfReportType.Strategy,
      name: 'GNF Strategy',
      i18nkey: 'calculator.gnf.report.report.type.strategy'
    },
    {
      id: GnfReportType.PlanSubmission,
      name: 'GNF Plan Submission',
      i18nkey: 'calculator.gnf.report.report.type.plan.submission'
    },
    {
      id: GnfReportType.LandApplication,
      name: 'GNF Land Application Schedule ',
      i18nkey: 'calculator.gnf.report.report.type.land.application'
    },
    {
      id: GnfReportType.LandApplicationBMP,
      name: 'GNF Land Application Schedule (BMP)',
      i18nkey: 'calculator.gnf.report.report.type.land.application.bmp'
    },
    {
      id: GnfReportType.LandApplicationRecord,
      name: 'GNF Record of Land Application',
      i18nkey: 'calculator.gnf.report.report.type.land.application.record'
    },
    {
      id: GnfReportType.TransferDocument,
      name: 'GNF Transfer Document',
      i18nkey: 'calculator.gnf.report.report.type.transfer.document'
    }
  ];

  public static readonly NITROGEN_FERTILIZER_REDUCTION_OPTIONS: i18nText[] = [
    {
      id: NitrogenFertilizerReduction.None,
      name: 'No reduction',
      i18nkey: 'calculator.ghg.summary.nitrogen.reduction.option.1'
    },
    {
      id: NitrogenFertilizerReduction.ReduceBy10,
      name: 'Reduced by 10%',
      i18nkey: 'calculator.ghg.summary.nitrogen.reduction.option.2'
    },
    {
      id: NitrogenFertilizerReduction.ReduceBy15,
      name: 'Reduced by 15%',
      i18nkey: 'calculator.ghg.summary.nitrogen.reduction.option.3'
    },
    {
      id: NitrogenFertilizerReduction.ReduceBy20,
      name: 'Reduced by 20%',
      i18nkey: 'calculator.ghg.summary.nitrogen.reduction.option.4'
    }
  ];

  public static readonly COVER_CROP_OPTIONS: i18nText[] = [
    {
      id: CoverCrop.None,
      name: 'None',
      i18nkey: 'calculator.ghg.summary.cover.crop.option.1'
    },
    {
      id: CoverCrop.Legume,
      name: 'Legume',
      i18nkey: 'calculator.ghg.summary.cover.crop.option.2'
    },
    {
      id: CoverCrop.NonLegume,
      name: 'Non-legume',
      i18nkey: 'calculator.ghg.summary.cover.crop.option.3'
    },
    {
      id: CoverCrop.MixedLegume,
      name: 'Mixed legume',
      i18nkey: 'calculator.ghg.summary.cover.crop.option.4'
    }
  ];

  public static readonly TREE_PLANTING_TYPE_OPTIONS: i18nText[] = [
    {
      id: TreePlantingType.WindbreakEstablishment,
      name: 'Establishment of windbreak/shelterbelt',
      i18nkey: 'calculator.ghg.summary.type.of.planting.option.1'
    },
    {
      id: TreePlantingType.CroplandConversion,
      name: 'Conversion of cropland to woodlot',
      i18nkey: 'calculator.ghg.summary.type.of.planting.option.2'
    },
    {
      id: TreePlantingType.RiparianEstablishment,
      name: 'Establishment of riparian forest buffer',
      i18nkey: 'calculator.ghg.summary.type.of.planting.option.3'
    }
  ];

  public static readonly GHG_REPORT_TYPE_OPTIONS: i18nText[] = [
    {
      id: GhgReportType.GhgSummary,
      name: 'GHG Summary',
      i18nkey: 'calculator.ghg.report.report.type.summary'
    },
    {
      id: GhgReportType.EntireFarm,
      name: 'Cropping Report',
      i18nkey: 'calculator.ghg.report.report.type.farm'
    },
    {
      id: GhgReportType.BuildingEnergy,
      name: 'Building Energy Report',
      i18nkey: 'calculator.ghg.report.report.type.building'
    }
  ];

  public static readonly MANURE_COVERING_OPTIONS: i18nText[] = [
    {
      id: ManureCovering.None,
      name: 'None',
      i18nkey: 'calculator.ghg.manure.covering.0'
    },
    {
      id: ManureCovering.StrawCover,
      name: 'Straw cover (>= 15 cm) thick',
      i18nkey: 'calculator.ghg.manure.covering.1'
    }
  ];

  public static readonly BUILDING_TYPE_OPTIONS: i18nText[] = [
    {
      id: BuildingType.Barn,
      name: 'Barn',
      i18nkey: 'calculator.ghg.building.type.1'
    },
    {
      id: BuildingType.Greenhouse,
      name: 'Greenhouse',
      i18nkey: 'calculator.ghg.building.type.2'
    },
    {
      id: BuildingType.GrainDryer,
      name: 'Grain dryer',
      i18nkey: 'calculator.ghg.building.type.3'
    }
  ];

  public static readonly DESTRATIFICATION_FAN_OPTIONS: i18nText[] = [
    {
      id: DestratificationFan.None,
      i18nkey: 'none'
    },
    {
      id: DestratificationFan.IncreasedInletVelocity,
      i18nkey: 'calculator.ghg.building.destratificationFans.opt2'
    },
    {
      id: DestratificationFan.CeilingFans,
      i18nkey: 'calculator.ghg.building.destratificationFans.opt3'
    },
    {
      id: DestratificationFan.HorizontalMixingFans,
      i18nkey: 'calculator.ghg.building.destratificationFans.opt4'
    }
  ];

  public static readonly HEAT_EXCHANGER_EFFICIENCY_LEVEL_OPTIONS: i18nText[] = [
    {
      id: HeatExchangerEfficiencyLevel.Low,
      i18nkey: 'calculator.ghg.building.heatExchangerEfficiencyLevel.opt1'
    },
    {
      id: HeatExchangerEfficiencyLevel.Medium,
      i18nkey: 'calculator.ghg.building.heatExchangerEfficiencyLevel.opt2'
    },
    {
      id: HeatExchangerEfficiencyLevel.High,
      i18nkey: 'calculator.ghg.building.heatExchangerEfficiencyLevel.opt3'
    }
  ];

  public static readonly LIGHTING_CONTROL_OPTIONS: i18nText[] = [
    {
      id: LightingControl.NoLights,
      i18nkey: 'calculator.ghg.building.lightingControl.opt1'
    },
    {
      id: LightingControl.ManualTimeBased,
      i18nkey: 'calculator.ghg.building.lightingControl.opt2'
    },
    {
      id: LightingControl.AutoWithSensor,
      i18nkey: 'calculator.ghg.building.lightingControl.opt3'
    }
  ];

  public static readonly CROP_AMOUNT_TYPE_OPTIONS: i18nText[] = [
    {
      id: CropAmountType.BeforeDryer,
      i18nkey: 'calculator.ghg.building.cropAmountType.opt1'
    },
    {
      id: CropAmountType.AfterDryer,
      i18nkey: 'calculator.ghg.building.cropAmountType.opt2'
    }
  ];

  public static readonly DELAYED_COOLING_TYPE_OPTIONS: i18nText[] = [
    {
      id: DelayedCoolingType.None,
      i18nkey: 'calculator.ghg.building.DelayedCoolingType.opt1'
    },
    {
      id: DelayedCoolingType.Dryeration,
      i18nkey: 'calculator.ghg.building.DelayedCoolingType.opt2'
    },
    {
      id: DelayedCoolingType.CombinationDrying,
      i18nkey: 'calculator.ghg.building.DelayedCoolingType.opt3'
    }
  ];

  public static readonly PEAK_FLOW_CALCULATION_METHOD_OPTIONS: i18nText[] = [
    {
      id: PeakFlowCalculationMethod.OmafraPublication832Tables,
      i18nkey: 'calculator.age.watershed.pfcm.opt1'
    },
    {
      id: PeakFlowCalculationMethod.ScsMethd,
      i18nkey: 'calculator.age.watershed.pfcm.opt2'
    }
  ];

  public static readonly TIME_OF_CONCENTRATION_METHOD_OPTIONS: i18nText[] = [
    {
      id: TimeOfConcentrationMethod.Kirpich,
      i18nkey: 'calculator.age.watershed.tocm.opt1'
    },
    {
      id: TimeOfConcentrationMethod.Scs,
      i18nkey: 'calculator.age.watershed.tocm.opt2'
    },
    {
      id: TimeOfConcentrationMethod.BransbyWilliamsFormula,
      i18nkey: 'calculator.age.watershed.tocm.opt3'
    }
  ];

  public static readonly IDF_CURVE_SOURCE_OPTIONS: i18nText[] = [
    {
      id: IdfCurveSource.Generic,
      i18nkey: 'calculator.age.watershed.idf.cs.opt1'
    },
    {
      id: IdfCurveSource.LocalStation,
      i18nkey: 'calculator.age.watershed.idf.cs.opt2'
    },
    {
      id: IdfCurveSource.UserDefined,
      i18nkey: 'calculator.age.watershed.idf.cs.opt3'
    }
  ];

  public static readonly AGE_CS_ROCK_CHUTE_CALC_TYPE_OPTIONS: i18nText[] = [
    {
      id: RockChuteCalculationType.PublicationMethod,
      i18nkey: 'calculator.age.cs.rc.rccm.opt.1'
    },
    {
      id: RockChuteCalculationType.NrcsMethod,
      i18nkey: 'calculator.age.cs.rc.rccm.opt.2'
    }
  ];

  public static readonly DEGREE_OF_ANGULARITY_TYPE_OPTIONS: i18nText[] = [
    {
      id: DegreeOfAngularity.AngularRounded,
      i18nkey: 'calculator.age.cs.rc.dra.opt.1'
    },
    {
      id: DegreeOfAngularity.CompletelyRounded,
      i18nkey: 'calculator.age.cs.rc.dra.opt.2'
    }
  ];

  public static readonly GEOTEXTILE_UNDERLAY_TYPE_OPTIONS: i18nText[] = [
    {
      id: GeotextileUnderlayType.NonWoven,
      i18nkey: 'calculator.age.cs.rc.gt.ut.opt1'
    },
    {
      id: GeotextileUnderlayType.Woven,
      i18nkey: 'calculator.age.cs.rc.gt.ut.opt2'
    }
  ];

  public static readonly NUM_OF_FITTING_PARAMS_OPTIONS: i18nText[] = [
    {
      id: FittingParameter.TwoParameters,
      i18nkey: 'calculator.age.watershed.fitting.param.opt1'
    },
    {
      id: FittingParameter.ThreeParameters,
      i18nkey: 'calculator.age.watershed.fitting.param.opt2'
    }
  ];

  public static readonly GRADE_CONTROL_TYPE_OPTIONS: i18nText[] = [
    {
      id: GradeControlType.GabionBasket,
      i18nkey: 'calculator.age.cs.gd.gct.opt1'
    },
    {
      id: GradeControlType.LogType,
      i18nkey: 'calculator.age.cs.gd.gct.opt2'
    }
  ];

  public static readonly AGE_DEVICE_TYPE_SIZE_OPTIONS: i18nText[] = [
    {
      i18nkey: 'calculator.age.cs.rc.dts.opt1'
    },
    {
      i18nkey: 'calculator.age.cs.rc.dts.opt2'
    },
    {
      i18nkey: 'calculator.age.cs.rc.dts.opt3'
    },
    {
      i18nkey: 'calculator.age.cs.rc.dts.opt4'
    },
    {
      i18nkey: 'calculator.age.cs.rc.dts.opt5'
    }
  ];

  public static readonly GHG_FERTILIZER_INCORP_OPTIONS: any[] = [
    {
      incorporationId: 0,
      description: {
        en: 'Incorporated',
        fr: 'Incorporé'
      }
    },
    {
      incorporationId: 6,
      description: {
        en: 'Not Incorporated',
        fr: 'Non incorporé'
      }
    }
  ];

  public static readonly DROP_PIPE_INLET_TYPE_OPTIONS: i18nText[] = [
    {
      id: DropPipeInletType.DropPipeStructure,
      i18nkey: 'calculator.age.cs.dp.dpit.opt1'
    },
    {
      id: DropPipeInletType.SlopedPipeStructure,
      i18nkey: 'calculator.age.cs.dp.dpit.opt2'
    },
    {
      id: DropPipeInletType.SmallCapacityRiserPipe,
      i18nkey: 'calculator.age.cs.dp.dpit.opt3'
    }
  ];

  public static readonly OUTLET_PIPE_JOINT_TYPE_OPTIONS: i18nText[] = [
    {
      id: TypeOfJoint.Butt,
      i18nkey: 'calculator.age.cs.tswi.toj.opt1'
    },
    {
      id: TypeOfJoint.Sleeve,
      i18nkey: 'calculator.age.cs.tswi.toj.opt2'
    }
  ];

  public static readonly OUTLET_PIPE_OUTFALL_TYPE_OPTIONS: i18nText[] = [
    {
      id: OutfallType.Flush,
      i18nkey: 'calculator.age.cs.tswi.oft.opt1'
    },
    {
      id: OutfallType.Overhanging,
      i18nkey: 'calculator.age.cs.tswi.oft.opt2'
    }
  ];

  public static readonly AGE_CS_CONTOUR_CALC_TYPE_OPTIONS: i18nText[] = [
    {
      id: ContourCalculationType.VolumeFactorMethod,
      i18nkey: 'calculator.age.cs.wascob.contour.ct.opt1'
    },
    {
      id: ContourCalculationType.ContourStageStorageMethod,
      i18nkey: 'calculator.age.cs.wascob.contour.ct.opt2'
    }
  ];

  public static readonly AGE_CS_WASCOB_SPILLWAY_TYPE_OPTIONS: i18nText[] = [
    {
      id: SpillwayType.GrassLined,
      i18nkey: 'calculator.age.cs.wascob.spt.opt1'
    },
    {
      id: SpillwayType.RockLined,
      i18nkey: 'calculator.age.cs.wascob.spt.opt2'
    }
  ];

  public static readonly AGE_CS_WASCOB_TILLAGE_PRACTICE_OPTIONS: i18nText[] = [
    {
      id: TillagePractice.UpDownSlope,
      i18nkey: 'calculator.age.cs.wascob.se.tp.opt1'
    },
    {
      id: TillagePractice.CrossSlope,
      i18nkey: 'calculator.age.cs.wascob.se.tp.opt2'
    }
  ];

  public static readonly AGE_CS_WATERWAY_SHAPE_OPTIONS: i18nText[] = [
    {
      id: WaterwayShape.Triangle,
      i18nkey: 'calculator.age.cs.rc.im.ws.opt1'
    },
    {
      id: WaterwayShape.Trapezoid,
      i18nkey: 'calculator.age.cs.rc.im.ws.opt2'
    },
    {
      id: WaterwayShape.Rectangle,
      i18nkey: 'calculator.age.cs.rc.im.ws.opt3'
    },
    {
      id: WaterwayShape.Parabola,
      i18nkey: 'calculator.age.cs.rc.im.ws.opt4'
    }
  ];

  public static readonly AGE_CS_WASCOB_SIDE_SLOPE_OPTIONS = [2, 3, 4, 5, 6, 7, 8, 9, 10];

  public static readonly DELETE_MSG_SIMPLE_TYPES = [
    DeleteKey.Storage,
    DeleteKey.FertilizerApplication,
    DeleteKey.MaterialType,
    DeleteKey.TransferContact,
    DeleteKey.SoilSample,
    DeleteKey.MdsBuildingPermit,
    DeleteKey.MaterialSample
  ];
  public static readonly IMPORTED_MANURE_UNUSED_STORAGE_TYPES = [76, 80];
  public static readonly IMPORTED_MANURE_UNUSED_STORAGE_SUB_TYPES = [141, 158];
  public static readonly NUTRIENTS_NO_CONVERSION_REQUIRED: string[] = [
    'DM',
    'AGI',
    'ECOLI',
    'GIARD',
    'SALM',
    'TCEV',
    'VHO',
    'CRYPT',
    'Ph',
    'pH',
    'PH'
  ];
  public static STORAGE_TARGET_DAYS = [60, 240, 365];

  public static DEFAULT_GRADIENTS = ['#008000', '#FFFF00', '#F06D06', '#FF0000'];
  public static DEFAULT_GRADIENT_STOPS = ['0%', '25%', '50%', '75%'];
  public static GHG_EMISSION_GRADIENT_STOPS = ['30%', '53.33%', '76.66%', '100%'];
  public static TARGET_SIDE_SLOPE_OPTIONS = [4, 5, 6, 7, 8, 9, 10];
  public static PEAK_FLOW_STORM_TYPE_OPTIONS = [10, 25];
}
