import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class MaterialProduced extends BaseModel {
  storageSystemId: string;
  sourceId: string;
  destinationId: string;
  materialForm: number;

  constructor(init?: Partial<MaterialProduced>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      sourceId: new UntypedFormControl(this.sourceId),
      destinationId: new UntypedFormControl(this.destinationId),
      materialForm: new UntypedFormControl(this.materialForm),
      description: new UntypedFormControl(this.description)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new MaterialProduced({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      storageSystemId: v.storageSystemId,
      sourceId: v.sourceId,
      destinationId: v.destinationId,
      materialForm: v.materialForm,
      description: v.description
    });
  }
}
