import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Utils } from "@core/utilities/utils";
import { BaseModel } from "./BaseModel";

export class WorksheetOverviewOperation extends BaseModel {
  worksheetId: string;
  answerTypeId: number;
  answerText: string;
  isNewAnswer: boolean;

  constructor(init?: Partial<WorksheetOverviewOperation>) {
    super();
    this.id = Utils.uuid();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      answerTypeId: new UntypedFormControl(this.answerTypeId),
      answerText: new UntypedFormControl(this.answerText, { validators: [Validators.maxLength(2000)] }),
      isNewAnswer: new UntypedFormControl(this.isNewAnswer) // note: this is UI cache managed in the form for convenience, not to be saved in database
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new WorksheetOverviewOperation({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      answerTypeId: v.answerTypeId,
      answerText: v.answerText,
      isNewAnswer: v.isNewAnswer
    });
  }

}