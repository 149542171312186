import { Constants } from '@shared/constants/constants';
import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { CacheService } from '@shared/services/cache.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CropSubType } from '../../calculators/crop-nutrient/model/crop-sub-type';

@Pipe({
    name: 'yieldPricePipe'
})
export class YieldPricePipe implements PipeTransform {

    constructor(private unitConverterService: UnitConverterService,
        private measurementService: MeasurementService,
        private cache: CacheService) { }

    transform(val: any, cropSubType$: Observable<CropSubType>): any {
        this.unitConverterService.from = MeasurementSystem.Metric;
        return combineLatest([this.measurementService.measurementSystem$, cropSubType$]).
            pipe(map(([to, cropSubType]) => {
                this.unitConverterService.to = to;
                if (cropSubType && (val || val === 0)) {
                    const cornGrain = this.cornGrain(cropSubType);
                    return this.unitConverterService.convertYieldPrice(val, cornGrain.yieldUnits, cornGrain.density);
                }
                return val || "";
            }));
    }

    private cornGrain(cropSubType: CropSubType) {
        if (cropSubType.cropTypeId === Constants.CORN_CROP_TYPE_ID) {
            return this.cache.allCropSubTypes.find(crop => crop.cropTypeId === Constants.GRAIN_CROP_SUB_TYPE_ID &&
                crop.cropId === Constants.CORN_CROP_TYPE_ID);
        }
        return cropSubType;
    }
}