import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FmpMaterialType } from '@fmp/model/fmp-material-type.model';
import { OutgoingTransfer } from '@mstor/model/outgoing-transfer.model';
import { MaterialCategory } from '@shared/models/common/material-category.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { MaterialComponentType } from './material-component-type.enum';
import { MaterialComponentModel } from './material-component.model';
import { NasmMaterialSample } from './nasm-material-sample.model';

export class NasmMaterialType extends FmpMaterialType {

    materialCategory = MaterialCategory.NASM;
    graphAdjustPercent: number;
    isMaterialAppliedInThisPlan = true;
    isMaterialIrrigated: boolean;
    isMaterialMixture: boolean;
    isMaterialStored: boolean;
    isOverrideOdourCategory = false;
    nasmStorageId: string;
    overrideOdourCategory = 1;
    overrideOdourDescription: string;
    storageSystemId: string;
    useLabAnalysisForPathogen = false;
    materialComponents = [];
    outgoingTransfers = [];
    currentMaterialSampleId: string;

    constructor(init?: Partial<any>) {
        super();
        this.configureMaterialComponents();
        this.materialSamples[0] = new NasmMaterialSample();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            worksheetId: new UntypedFormControl(this.worksheetId),
            name: new UntypedFormControl(this.name, [
                Validators.maxLength(30),
                Validators.minLength(3),
                ValidationFunctions.empty
            ]),
            materialForm: new UntypedFormControl(this.materialForm),
            materialType: new UntypedFormControl(this.materialType),
            materialCategory: new UntypedFormControl(this.materialCategory),
            notes: new UntypedFormControl(this.notes, [Validators.maxLength(250)]),
            graphAdjustPercent: new UntypedFormControl(this.graphAdjustPercent),
            isMaterialAppliedInThisPlan: new UntypedFormControl(this.isMaterialAppliedInThisPlan),
            isMaterialIrrigated: new UntypedFormControl(this.isMaterialIrrigated),
            isMaterialMixture: new UntypedFormControl(this.isMaterialMixture),
            isMaterialStored: new UntypedFormControl(this.isMaterialStored),
            isOverrideOdourCategory: new UntypedFormControl(this.isOverrideOdourCategory),
            nasmStorageId: new UntypedFormControl(this.nasmStorageId),
            overrideOdourCategory: new UntypedFormControl(this.overrideOdourCategory),
            overrideOdourDescription: new UntypedFormControl(this.overrideOdourDescription),
            storageSystemId: new UntypedFormControl(this.storageSystemId),
            useLabAnalysisForPathogen: new UntypedFormControl(this.useLabAnalysisForPathogen),
            materialSamples: new UntypedFormArray(this.materialSamples.map(v => new NasmMaterialSample(v).toForm())),
            outgoingTransfers: new UntypedFormArray(this.outgoingTransfers.map(v => new OutgoingTransfer(v).toForm())),
            materialComponents: new UntypedFormArray(this.materialComponents.map(v => new MaterialComponentModel(v).toForm())),
            currentMaterialSampleId: new UntypedFormControl(this.currentMaterialSampleId)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        const materialSamples = form.get('materialSamples') as UntypedFormArray;
        const outgoingTransfers = form.get('outgoingTransfers') as UntypedFormArray;
        const materialComponents = form.get('materialComponents') as UntypedFormArray;
        return new NasmMaterialType({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            worksheetId: v.worksheet,
            name: v.name,
            materialForm: v.materialForm,
            materialType: v.materialType,
            materialCategory: v.materialCategory,
            notes: v.notes,
            graphAdjustPercent: v.graphAdjustPercent,
            isMaterialAppliedInThisPlan: v.isMaterialAppliedInThisPlan,
            isMaterialIrrigated: v.isMaterialIrrigated,
            isMaterialMixture: v.isMaterialMixture,
            isMaterialStored: v.isMaterialStored,
            isOverrideOdourCategory: v.isOverrideOdourCategory,
            nasmStorageId: v.nasmStorageId,
            overrideOdourCategory: v.overrideOdourCategory,
            overrideOdourDescription: v.overrideOdourDescription,
            storageSystemId: v.storageSystemId,
            useLabAnalysisForPathogen: v.useLabAnalysisForPathogen,
            materialSamples: materialSamples.controls.map((w: UntypedFormGroup) => new NasmMaterialSample().toModel(w)),
            outgoingTransfers: outgoingTransfers?.controls?.map((w: UntypedFormGroup) => new OutgoingTransfer().toModel(w)),
            materialComponents: materialComponents?.controls.map((w: UntypedFormGroup) => new MaterialComponentModel().toModel(w)),
            currentMaterialSampleId: v.currentMaterialSampleId
        });
    }

    private configureMaterialComponents() {
        // at least one NASM component presents when creating a new NASM material type
        if (!this.materialComponents || !this.materialComponents.length) {
            this.materialComponents[0] = new MaterialComponentModel({
                componentType: MaterialComponentType.Nasm,
                materialForm: MaterialForm.Liquid
            });
        }
    }
}
