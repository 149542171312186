export enum WorksheetTypeIds {
  CROP_NUTRIENT = '2e84aedc-1238-4e74-9dd8-e9b4b87163f2',
  MATERIAL_APPLICATION = 'a474295e-80d5-41be-8047-82dca15323a4',
  FERTILIZER_APPLICATION = 'a7a6a5f6-2181-4ca1-accc-d46686cd3eec',
  PHOSPHORUS_LOSS = '593dbe97-2d69-42c5-bc73-b34b372b6d59',
  FIELD_MANAGEMENT_PLAN = '653388fd-f43d-41e3-a231-b3edf6d4278c',
  MANURE_STORAGE_SIZING = '7720c60a-2c80-4001-b8de-9681050ea364',
  NUTRIENT_MANAGEMENT_STRATEGY_PLAN = 'cc5cc157-809a-4c4f-9250-1d2183d23ee4',
  NON_AGRICULTURAL_SOURCE_MATERIAL = '504831fe-7e5b-49b4-af06-30b9562d6cd1',
  GREENHOUSE_NUTRIENT_FEEDWATER = '8c081e0d-d8f2-435f-88f7-7a99f6dd35dc',
  GREENHOUSE_GAS = 'bcdcc144-d563-43d3-bf0d-c28d4acf9663',
  MINIMUM_DISTANCE_SEPARATION_1 = '7f28d144-6cd4-49a5-84db-f540c80fecfa',
  MINIMUM_DISTANCE_SEPARATION_2 = 'd9b59e91-7316-4891-b359-56a0b79e4ca4',
  AG_EROSION = '5b591f66-2672-4148-9827-037ca6c4876b'
}

export const AvailableWorksheetTypeIds = [
  WorksheetTypeIds.CROP_NUTRIENT,
  WorksheetTypeIds.MATERIAL_APPLICATION,
  WorksheetTypeIds.FERTILIZER_APPLICATION,
  WorksheetTypeIds.PHOSPHORUS_LOSS,
  WorksheetTypeIds.FIELD_MANAGEMENT_PLAN,
  WorksheetTypeIds.MANURE_STORAGE_SIZING,
  WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN,
  WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER,
  WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL,
  WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1,
  WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2,
  WorksheetTypeIds.GREENHOUSE_GAS,
  WorksheetTypeIds.AG_EROSION
];

export const RequiredSignInWorksheetTypeIds = [
  WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN,
  WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER,
  WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL,
  WorksheetTypeIds.AG_EROSION
];

export const InProgressWorksheetTypeIds = [];

export const NAWorksheetTypeIds = [];

export const ExportableWorksheetTypeIds = [
  WorksheetTypeIds.FIELD_MANAGEMENT_PLAN,
  WorksheetTypeIds.MANURE_STORAGE_SIZING,
  WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN,
  WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL,
  WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER,
  WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1,
  WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2,
  WorksheetTypeIds.GREENHOUSE_GAS,
  WorksheetTypeIds.AG_EROSION
];

export const CopyableWorksheetTypeIds = [...ExportableWorksheetTypeIds];

export const CertificationNumberWorksheetTypeIds = [
  WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN,
  WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL,
  WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER
];
