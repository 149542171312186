import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';
import { MapConstants } from '@shared/constants/map-constants';
import { Utils } from '@core/utilities/utils';
import { Calculation, InputMaterial, MaterialType } from '@nmsp/model/calculation.model';
import { StorageSizingSummary, StorageSizingSummaryRunOffStorage } from '@nmsp/model/nmsp-calculation.model';
import { Constants } from '@shared/constants/constants';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { StorageSizingSummaryType } from '@shared/models/common/storage-sizing-summary-type.enum';
import { Units } from '@shared/models/common/unit';
import { CacheService } from './cache.service';
import { FormService } from './form.service';
import { EMPTY, combineLatest, map } from 'rxjs';
import { DecimalPipe } from '@shared/pipes/decimal.pipe';

@Injectable({ providedIn: 'root' })
export class MstorHelperService {
  constructor(private formService: FormService, private decimalPipe: DecimalPipe, private cache: CacheService) {}

  // material source related
  materialFormName(materialType: UntypedFormGroup) {
    return ArrayConstants.MATERIAL_FORM_OPTIONS.find(v => v.id === materialType?.get('materialForm')?.value);
  }

  materialDescription$(mtCal: MaterialType): any {
    return mtCal?.inputMaterial?.description || MapConstants.EMPTY_TEXT;
  }

  inputMaterialIds(materialType: UntypedFormGroup, systemCalculation: Calculation) {
    const materialTypeId = materialType?.get('id')?.value;
    return systemCalculation?.materialsProduced
      ?.filter(v => v.isActive && !Utils.matchStr(v.sourceId, materialTypeId) && Utils.matchStr(v.destinationId, materialTypeId))
      .map(v => v.id);
  }

  canSeeInputMaterials(materialType: UntypedFormGroup, system: UntypedFormGroup, systemCalculation: Calculation) {
    const inputMaterialIds = this.inputMaterialIds(materialType, systemCalculation);
    const matched = (system?.get('materialsProduced') as UntypedFormArray)?.controls?.filter(
      materialProduced => inputMaterialIds.indexOf(materialProduced.get('id').value) > -1
    );
    return matched;
  }

  materialDescription(
    inputMaterial: UntypedFormGroup,
    systemCalculation: Calculation,
    lang: LanguageType = Constants.DEFAULT_LANGUAGE_TYPE
  ) {
    const inputMaterialId = inputMaterial?.get('id')?.value;
    return systemCalculation?.materialsProduced?.find(v => Utils.matchStr(v.id, inputMaterialId))?.description[lang];
  }

  materialTypeName(calculation: MaterialType) {
    const dominantMaterialTypeId = calculation?.inputMaterial?.dominantMaterialTypeId;
    if (dominantMaterialTypeId < 0) {
      return MapConstants.NOT_AVAILABLE_TEXT;
    }
    return this.cache.allMaterialTypes.find(v => v.materialTypeId === dominantMaterialTypeId)?.name;
  }

  canSeeDryMatterRange(material: InputMaterial) {
    return material.dryMatterLow !== material.dryMatterHigh;
  }

  nutrientValueSourceName(materialType: UntypedFormGroup) {
    return ArrayConstants.NUTRIENT_VALUE_SOURCE_OPTIONS.find(v => v.id === this.nutrientValueSource(materialType)?.value);
  }

  nutrientValueSource(materialType: UntypedFormGroup) {
    const materialSample = (materialType?.get('materialSamples') as UntypedFormArray)?.controls[0] as UntypedFormGroup;
    return materialSample?.controls?.nutrientValueSource;
  }

  nutrientCodes(calculation: MaterialType) {
    return calculation?.materialSamples[0]?.materialTestValues;
  }

  canSeeDatabank(materialType: UntypedFormGroup) {
    return this.nutrientValueSource(materialType)?.value === 1;
  }

  // storage checklist related
  initChecklists(checklistId: number, checklists: any[] = []) {
    const checklist = this.cache.storageChecklists
      .filter(v => v.checklistId === checklistId)
      .sort((a, b) => a.displayOrder - b.displayOrder);

    if (!!checklist && checklist.length > 0) {
      let currParent;
      for (const item of checklist) {
        if (item.isSubItem === false) {
          currParent = item;
          currParent.children = [];
          checklists.push(currParent);
        } else if (!!currParent) {
          currParent.children.push(item);
        }
      }
    }

    return checklists;
  }

  // storage sizing summary related
  summaryStoragesByType(storageSystems: UntypedFormArray, summary: StorageSizingSummary, summaryType: StorageSizingSummaryType) {
    const storages = summary && summary[summaryType];
    if (storages && storages.length) {
      return this.buildSummaryStorages(storageSystems, storages, summaryType);
    }
    return [];
  }

  buildSummaryStorages(storageSystems: UntypedFormArray, summaryStorages: any[], summaryType?: StorageSizingSummaryType) {
    const findSummaryStorage = (storageId: string) => summaryStorages.find(v => v.storageId === storageId);

    return this.allStorageGroups(storageSystems).reduce((storages: any[], group: any) => {
      group?.get('storages')?.controls.forEach((storage: UntypedFormGroup) => {
        let match = findSummaryStorage(storage?.get('id')?.value);
        if (match) {
          match = this._buildSummaryStorage(match, storage, group, summaryType);
          storages.push(match);
        }
      });
      return storages;
    }, []);
  }

  private _buildSummaryStorage(
    match: any,
    storage: UntypedFormGroup,
    storageGroup: UntypedFormGroup,
    summaryType: StorageSizingSummaryType
  ) {
    match.groupName = storageGroup?.get('name')?.value;
    if (summaryType && summaryType === StorageSizingSummaryType.RunoffMgmt) {
      match.description = this.runoffManagement(storage)?.name;
    }
    return match;
  }

  // general
  isLiquid(data: any) {
    return data?.materialForm?.value === MaterialForm.Liquid;
  }

  isSolid(data: any) {
    return data?.materialForm?.value === MaterialForm.Solid;
  }

  allStorageGroups(storageSystems: UntypedFormArray) {
    return storageSystems?.controls.reduce((groups: any[], system: any) => {
      if (system.get('storageGroups') && system.get('storageGroups').controls.length) {
        groups = [...groups, ...system.get('storageGroups').controls];
      }
      return groups;
    }, []);
  }

  runoffManagement(storage: UntypedFormGroup | StorageSizingSummaryRunOffStorage) {
    return this.runoffManagementOptions.find(v => {
      if (storage instanceof UntypedFormGroup) {
        return v.runoffManagementId === storage?.get('runoffManagementTypeId')?.value;
      } else {
        return v.runoffManagementId === storage.runoffManagementTypeId;
      }
    });
  }

  getStorageYears(calculation$, lang$, id, startFormatting = '', endFormatting = '') {
    // tslint:disable-next-line: deprecation
    return combineLatest([calculation$, lang$]).pipe(
      map(([calculation, lang = 'en']) => {
        if (calculation && calculation.storageSizingCalculations) {
          const storageSystem = calculation.storageSizingCalculations?.find(v => Utils.matchStr(v.storageSystemID, id));
          if (storageSystem) {
            const numberOfYears = storageSystem.numberOfYears;
            const languageJson = this.cache[lang];
            const key = numberOfYears === 1 ? 'year' : 'years.abbr';
            return `${startFormatting}${this.decimalPipe.transform(numberOfYears, 1)} ${languageJson[key]}${endFormatting}`;
          }
        }
        return EMPTY;
      })
    );
  }

  getStorageYearsInParentheses(calculation$, lang$, id) {
    return this.getStorageYears(calculation$, lang$, id, '(', ')');
  }

  // general
  get dryMatterUnit() {
    return new Units().getDryMatterText();
  }

  get nutrientUnit() {
    return new Units().getNutrientUnitsText();
  }

  get nutrientUnitPerYear() {
    return new Units().getNutrientUnitsText() + '/' + new Units().getYearText2();
  }

  get grazingUnit() {
    return new Units().getGrazingUnitsText();
  }

  get runoffManagementOptions() {
    return this.cache.runoffManagementOptions.sort((a, b) => a.displayOrder - b.displayOrder);
  }

  get f() {
    return this.formService?.f;
  }

  // material source related
  get nutrientValueBasis() {
    return ArrayConstants.NUTRIENT_VALUE_BASIS_OPTIONS[0];
  }
}
