import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { environment } from '../../../environments/environment';

export class URLs {
  public static readonly WORKSHEET_ENDPOINT = environment.apiEndpointWorksheetV1 + 'worksheets';
  public static readonly WORKSHEET_LABEL_ENDPOINT = environment.apiEndpointWorksheetV1 + 'worksheet-label';
  public static readonly WORKSHEET_LABEL_LINK_ENDPOINT = environment.apiEndpointWorksheetV1 + 'worksheet-label-link';
  public static readonly CROP_NUTRIENT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/crop-nutrient';
  public static readonly ORGANIC_AMENDMENT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/material-application';
  public static readonly FERTILIZER_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/fertilizer';
  public static readonly PLATO_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/phosphorus-loss';
  public static readonly FMP_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/field-management-plan';
  public static readonly STORAGE_SIZING_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/manure-storage-sizing';
  public static readonly MDS1_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/mds';
  public static readonly MDS2_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/mds';
  public static readonly NUTRIENT_MGMT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/nutrient-management';
  public static readonly GNF_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/gnf';
  public static readonly GHG_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/greenhouse-gas';
  public static readonly NASM_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/nasm';
  public static readonly NASM_STORAGE_ENDPOINT = URLs.NASM_ENDPOINT + '/nasm-storages';
  public static readonly MATERIAL_COMPONENT_ENDPOINT = URLs.NASM_ENDPOINT + '/material-components';
  public static readonly AG_EROSION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion';
  public static readonly FERTILIZER_APPLICATION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/fertilizer-applications';
  public static readonly MATERIAL_APPLICATION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/material-applications';
  public static readonly MATERIAL_TYPE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/material-types';
  public static readonly SHARED_FARM_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/farms';
  public static readonly FMP_FARM_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/field-management-plan';
  public static readonly NUTRIENT_MGMT_FROM_FMP_ENDPOINT = URLs.NUTRIENT_MGMT_ENDPOINT + '/from-fmp';
  public static readonly NUTRIENT_MGMT_FROM_MSTOR_ENDPOINT = URLs.NUTRIENT_MGMT_ENDPOINT + '/from-mstor';
  public static readonly GHG_FROM_MSTOR_ENDPOINT = URLs.GHG_ENDPOINT + '/from-mstor';
  public static readonly GHG_FROM_FMP_ENDPOINT = URLs.GHG_ENDPOINT + '/from-fmp';
  public static readonly GHG_FROM_NMSP_ENDPOINT = URLs.GHG_ENDPOINT + '/from-nmsp';
  public static readonly GHG_BUILDING_MONTH_ENDPOINT = URLs.GHG_ENDPOINT + '/building-month';
  public static readonly SHARED_FIELD_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/fields';
  public static readonly SHARED_CROP_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/crops';
  public static readonly SHARED_STORAGE_SYSTEM_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/storage-systems';
  public static readonly SHARED_TRANSFER_CONTACT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/transfer-contacts';
  public static readonly SHARED_SOIL_SAMPLE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/soil-samples';
  public static readonly SHARED_MATERIAL_SAMPLE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/material-samples';
  public static readonly SHARED_LIVESTOCK_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/livestock';
  public static readonly SHARED_LIVESTOCK_WITH_MATERIALS_PRODUCED_ENDPOINT =
    URLs.WORKSHEET_ENDPOINT + '/shared/livestock-with-materials-produced';
  public static readonly SHARED_TREATMENT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/treatments';
  public static readonly SHARED_STORAGE_GROUP_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/storage-groups';
  public static readonly SHARED_STORAGE_GROUP_WITH_MATERIALS_PRODUCED_ENDPOINT =
    URLs.WORKSHEET_ENDPOINT + '/shared/storage-groups-with-side-effect';
  public static readonly SHARED_STORAGE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/storages';
  public static readonly SHARED_STORAGE_WITH_MATERIALS_PRODUCED_ENDPOINT =
    URLs.WORKSHEET_ENDPOINT + '/shared/storages-with-materials-produced';
  public static readonly SHARED_TREATMENT_WITH_MATERIALS_PRODUCED_ENDPOINT =
    URLs.WORKSHEET_ENDPOINT + '/shared/treatment-with-materials-produced';
  public static readonly SHARED_OTHER_SOURCE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/other-sources';
  public static readonly SHARED_UPDATE_MATRIAL_PRODUCED_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/materials-produced';
  public static readonly SHARED_OTHER_SOURCE_WITH_MATERIALS_PRODUCED_ENDPOINT =
    URLs.WORKSHEET_ENDPOINT + '/shared/other-sources-with-materials-produced';
  public static readonly SHARED_WORKSHEET_PREVIOUS_SUBMISSION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/worksheet-previous-submissions';
  public static readonly SHARED_WORKSHEET_OVERVIEW_OPERATION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/worksheet-overview-operations';
  public static readonly SHARED_CONTACT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/contacts';
  public static readonly SHARED_CONTACT_USER_ACCOUNT_ENDPOINT = URLs.SHARED_CONTACT_ENDPOINT + '/user-account-id';
  public static readonly SHARED_FLAG_COMMENT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/flag-comments';
  public static readonly SHARED_FLAG_COMMENT_WORKSHEET_ENDPOINT = URLs.SHARED_FLAG_COMMENT_ENDPOINT + '/worksheet';
  public static readonly MATERIAL_TYPES_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/material-types/worksheet';
  public static readonly OUTGOING_TRANSFER_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/outgoing-transfers';
  public static readonly LOCATION_LOT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/location-lots';
  public static readonly LOCATION_ROLL_NUMBER_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/shared/location-roll-numbers';
  public static readonly WORKSHEET_IMPORT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/upload';
  public static readonly WORKSHEET_EXPORT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/export';
  public static readonly WORKSHEET_IMPORT_FMP_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-fmp';
  public static readonly WORKSHEET_IMPORT_MSTOR_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-mstor';
  public static readonly WORKSHEET_IMPORT_NMSP_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-nmsp';
  public static readonly WORKSHEET_IMPORT_NASM_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-nasm';
  public static readonly WORKSHEET_IMPORT_GNF_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-gnf';
  public static readonly WORKSHEET_IMPORT_MDS_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-mds';
  public static readonly WORKSHEET_IMPORT_GHG_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-ghg';
  public static readonly WORKSHEET_IMPORT_AGE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/import-agerosion';
  public static readonly SHARED_BUILDING_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/greenhouse-gas/buildings';
  public static readonly SHARED_WATERSHED_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion/watershed';
  public static readonly SHARED_CONTOUR_STAGE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion/control-structure-contour-stage';
  public static readonly SHARED_CONTROL_STRUCTURE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion/control-structure';
  public static readonly SHARED_WATERSHED_IDF_CURVE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion/watershed-idf-curve';
  public static readonly SHARED_WATERSHED_RUNOFF_CURVE_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/agerosion/watershed-runoff-curve';
  public static readonly FLOW_VELOCITY_COEFF_ENDPOINT = environment.apiEndpointAGErosionV1 + 'flow-velocity-coefficients';
  public static readonly CLIMATE_STATION_IDF_CURVE_ENDPOINT = environment.apiEndpointAGErosionV1 + 'climate-station-idf-curve';
  public static readonly CLIMATE_STATION_UPPER_TIER_LINK_ENDPOINT = environment.apiEndpointAGErosionV1 + 'climate-station-upper-tier-link';
  public static readonly RUNOFF_CURVE_NUMBER_ENDPOINT = environment.apiEndpointAGErosionV1 + 'runoff-curve-number';
  public static readonly WATERWAY_LINING_ENDPOINT = environment.apiEndpointAGErosionV1 + 'waterway-lining';
  public static readonly CONTROL_STRUCTURE_TYPE_ENDPOINT = environment.apiEndpointAGErosionV1 + 'control-structure-type';
  public static readonly SOIL_EROSION_CROP = environment.apiEndpointAGErosionV1 + 'soil-erosion-crop';
  public static readonly LIVESTOCK_BUILDING_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/greenhouse-gas/building-livestock';
  public static readonly ALL_WORKSHEET_TYPES_ENDPOINT = environment.apiEndpointRoot + 'worksheet-types';
  public static readonly DB_USER_ENDPOINT = environment.apiEndpointWorksheetV1 + 'users';
  public static readonly UPPER_TIER_MUNIS_ENDPOINT = environment.apiEndpointLocationV1 + 'upper-tier-municipalities';
  public static readonly LOWER_TIER_MUNIS_ENDPOINT = environment.apiEndpointLocationV1 + 'lower-tier-municipalities';
  public static readonly GEOTOWNSHIPS_ENDPOINT = environment.apiEndpointLocationV1 + 'geotownships';
  public static readonly LOCATION_LOT_DATA_ENDPOINT = environment.apiEndpointLocationV1 + 'location-lots/geotownship';
  public static readonly ALL_SOIL_TEXTURES_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-textures';
  public static readonly SOIL_TEXTURES_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-textures';
  public static readonly SOIL_SERIES_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-series';
  public static readonly SOIL_TEST_CODE_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-test-codes';
  public static readonly SOIL_SAMPLE_NUTRIENT_CONTENT_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-sample-nutrient-content';
  public static readonly CROP_HEAT_UNITS_ENDPOINT = environment.apiEndpointLocationV1 + 'heat-units';
  public static readonly CROP_TYPES_ENDPOINT = environment.apiEndpointMaterialV1 + 'crop-types';
  public static readonly CROP_SUB_TYPES_ENDPOINT = environment.apiEndpointMaterialV1 + 'crop-sub-types';
  public static readonly CROP_ROTATIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'crop-rotations';
  public static readonly CROP_NITROGEN_CREDITS_ENDPOINT = environment.apiEndpointMaterialV1 + 'nitrogen-credits';
  public static readonly CROP_YIELD_AVERAGE_ENDPOINT = environment.apiEndpointMaterialV1 + 'crop-yield';
  public static readonly CROP_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'crop-calculations';
  public static readonly TILLAGE_METHODS_ENDPOINT = environment.apiEndpointMaterialV1 + 'tillage-methods';
  public static readonly TILLAGE_PRACTICES_ENDPOINT = environment.apiEndpointMaterialV1 + 'tillage-practices';

  public static readonly MATERIAL_TYPE_LOOPUPS_ENDPOINT = environment.apiEndpointMaterialV1 + 'types?active-only=true';
  public static readonly MATERIAL_TYPE_LOOKUP_ENDPOINT = environment.apiEndpointMaterialV1 + 'types';
  public static readonly MATERIAL_DRY_RANGES_ENDPOINT = environment.apiEndpointMaterialV1 + 'dry-range';
  public static readonly MATERIAL_NUTRIENT_CODE_ENDPOINT = environment.apiEndpointMaterialV1 + 'nutrient-codes';
  public static readonly MATERIAL_DATABANK_AVERAGE = environment.apiEndpointMaterialV1 + 'databank-average';
  public static readonly MATERIAL_APPLICATION_METHOD_ENDPOINT = environment.apiEndpointMaterialV1 + 'application-methods';
  public static readonly MATERIAL_UTILITIES_SLOPE_LENGTH_ENDPOINT = environment.apiEndpointMaterialV1 + 'utilities/slope-length';
  public static readonly PLATO_SOIL_EROSION_ENDPOINT = environment.apiEndpointMaterialV1 + 'soil-erosion';
  public static readonly BUILDING_INSULATION_LEVEL_ENDPOINT = environment.apiEndpointMaterialV1 + 'building-insulation-levels';
  public static readonly BUILDING_INFILTRATION_LEVEL_ENDPOINT = environment.apiEndpointMaterialV1 + 'building-infiltration-levels';
  public static readonly BUILDING_HEATING_SYSTEM_ENDPOINT = environment.apiEndpointMaterialV1 + 'building-heating-systems';
  public static readonly BUILDING_FUEL_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'building-fuel-types';

  // tslint:disable-next-line: max-line-length
  public static readonly MATERIAL_APPLICATION_INCORPORATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'application-incorporation';
  public static readonly MATERIAL_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'material-calculation';
  public static readonly AVAILABLE_NITROGEN_ENDPOINT = environment.apiEndpointMaterialV1 + 'available-nitrogen';
  public static readonly FERTILIZER_PRODUCT_OPTION_ENDPOINT = environment.apiEndpointMaterialV1 + 'fertilizer';
  public static readonly FERTILIZER_APPLIED_NUTRIENTS_ENDPOINT = environment.apiEndpointMaterialV1 + 'fertilizer-applied-nutrients';
  public static readonly FERTILIZER_APPLIED_NUTRIENTS_LIST_ENDPOINT =
    environment.apiEndpointMaterialV1 + 'fertilizer-applied-nutrients-list';
  public static readonly FERTILIZER_TOTAL_APPLIED_NUTRIENTS_ENDPOINT =
    environment.apiEndpointMaterialV1 + 'fertilizer-total-applied-nutrients';
  public static readonly FERTILIZER_TOTAL_APPLIED_NUTRIENTS_LIST_ENDPOINT =
    environment.apiEndpointMaterialV1 + 'fertilizer-total-applied-nutrients-list';
  public static readonly FERTILIZER_APPLICATION_RATES_ENDPOINT = environment.apiEndpointMaterialV1 + 'fertilizer-application-rates';
  public static readonly FERTILIZER_APPLICATION_INCORPORATION_ENDPOINT =
    environment.apiEndpointMaterialV1 + 'fertilizer/application-incorporation';
  public static readonly PLATO_TOTAL_PLOSS_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'total-phosphorus-loss';
  public static readonly PLATO_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'plato-calculation';
  public static readonly FMP_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'field-management-plan-calculation';
  public static readonly NMSP_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'nutrient-management-calculation';
  public static readonly GNF_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'gnf-master-calculation';
  public static readonly GHG_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'ghg-master-calculation';
  public static readonly AGE_CALCULATION_ENDPOINT = environment.apiEndpointAGErosionV1 + 'agerosion-master-calculation';
  public static readonly AGE_ISBASH_CURVE_BASE_ENDPOINT = environment.apiEndpointAGErosionV1 + 'isbash-curve-base';
  public static readonly NASM_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'nasm-master-calculation';
  public static readonly STORAGE_SIZING_CALCULATION_ENDPOINT = environment.apiEndpointMaterialV1 + 'manure-storage-sizing-calculation';
  public static readonly LIVESTOCK_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'livestock-types';
  public static readonly LIVESTOCK_COMBINATIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'livestock-combinations';
  public static readonly LIVESTOCK_COMBINATIONS_DETAILS_ENDPOINT = environment.apiEndpointMaterialV1 + 'livestock-combination-details';
  public static readonly TREATMENT_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'treatment-types';
  public static readonly OTHER_SOURCE_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'other-source-types';
  public static readonly TRANSFER_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'transfer-types';
  public static readonly STORAGE_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-types';
  public static readonly STORAGE_DIMENSIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-dimensions';
  public static readonly STORAGE_DIMENSIONS_LAST_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-utilities/storage-dimension-last';
  public static readonly STORAGE_CHARACTERISTICS_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-utilities/storage-characteristics';
  public static readonly STORAGE_RUNOFF_MANAGEMENT_OPTIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'runoff-managements';
  public static readonly STORAGE_DIMENSION_LINKS_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-dimension-links';
  public static readonly STORAGE_CHECKLIST_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-checklists';
  public static readonly STORAGE_TEMPORARIES_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-temporaries';
  public static readonly STORAGE_TEMPORARY_OPTIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'storage-temporary-options';
  public static readonly MDS_CALCULATION_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/mds/mds-calculations';
  public static readonly MDS_MATERIAL_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/mds/mds-materials';
  public static readonly LAND_USE_TYPE_ENDPOINT = environment.apiEndpointMDSV1 + 'land-use-types';
  public static readonly MDS_MATERIAL_TYPE_ENDPOINT = environment.apiEndpointMDSV1 + 'mds-material-types';
  public static readonly MDS_MATERIAL_SUB_TYPE_ENDPOINT = environment.apiEndpointMDSV1 + 'mds-material-sub-types';
  public static readonly MDS_STORAGE_TYPE_ENDPOINT = environment.apiEndpointMDSV1 + 'mds-storage-option';
  public static readonly MDS_BUILDING_PERMIT_ENDPOINT = URLs.WORKSHEET_ENDPOINT + '/mds/mds-building-permits';
  public static readonly MDS_MASTER_CALCULATION_ENDPOINT = environment.apiEndpointMDSV1 + 'mds-master-calculation';
  public static readonly COMMON_AG_MAPS_ENDPOINT = environment.apiEndpointFlagV1 + 'equation-values/agriculture-maps';
  public static readonly COMMON_OWIT_MAPS_ENDPOINT = environment.apiEndpointFlagV1 + 'equation-values?category=5';
  public static readonly COMMON_MTO_MAPS_ENDPOINT = environment.apiEndpointFlagV1 + 'equation-values?category=6';
  public static readonly EQUATION_VALUES_ENDPOINT = environment.apiEndpointFlagV1 + 'equation-values';
  public static readonly HTML_CONTENT_ENDPOINT = environment.apiEndpointFlagV1 + 'html-content';
  public static readonly OVERVIEW_OPERATIONS_ENDPOINT = environment.apiEndpointMaterialV1 + 'overview-operations';
  public static readonly HORTI_CULTURAL_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'horticultural-type';
  public static readonly TREE_SPECIES_ENDPOINT = environment.apiEndpointMaterialV1 + 'tree-species';
  public static readonly LIVESTOCK_FEED_ADDITIVE_ENDPOINT = environment.apiEndpointMaterialV1 + 'livestock_feed_additive';

  public static readonly NASM_TYPES_ENDPOINT = environment.apiEndpointMaterialV1 + 'nasm-types';
  public static readonly DEPTH_OF_UNSATURATED_SOIL_RESTRICTION_ENDPOINT =
    environment.apiEndpointMaterialV1 + 'depth-of-unsaturated-soil-restriction';
  public static readonly LIVESTOCK_TYPE_DIET_COMMON_ENDPOINT = environment.apiEndpointMaterialV1 + 'livestock_type_diet_common';
  public static readonly GREENHOUSE_COVERING_MATERIAL_ENDPOINT = environment.apiEndpointMaterialV1 + 'greenhouse-covering-material';
  public static readonly GREENHOUSE_CONSTRUCTION_LEVEL_ENDPOINT = environment.apiEndpointMaterialV1 + 'greenhouse-construction-level';
  public static readonly GREENHOUSE_THERMAL_CURTAIN_ENDPOINT = environment.apiEndpointMaterialV1 + 'greenhouse-thermal-curtain';
  public static readonly GREENHOUSE_CROP_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'greenhouse-crop-type';
  public static readonly GREENHOUSE_LIGHTING_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'greenhouse-lighting-type';
  public static readonly GRAIN_DRYER_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'grain-dryer-type';
  public static readonly GRAIN_DRYER_CROP_TYPE_ENDPOINT = environment.apiEndpointMaterialV1 + 'grain-dryer-crop-type';

  public static readonly LIST_WORKSHEETS_URL = 'worksheet/worksheets/username';

  public static readonly CROP_NUTRIENT_PATH = 'worksheet/worksheets/crop-nutrient';
  public static readonly ORGANIC_AMENDMENT_PATH = 'worksheet/worksheets/material-application';
  public static readonly FERTILIZER_PATH = 'worksheet/worksheets/fertilizer';
  public static readonly PLATO_PATH = 'worksheet/worksheets/phosphorus-loss';
  public static readonly FMP_PATH = 'worksheet/worksheets/field-management-plan';
  public static readonly STORAGE_SIZING_PATH = 'worksheet/worksheets/manure-storage-sizing';
  public static readonly MDS1_PATH = 'worksheet/worksheets/mds';
  public static readonly MDS2_PATH = 'worksheet/worksheets/mds';

  public static readonly WORKSHEET_PATH_MAP = {
    [URLs.CROP_NUTRIENT_PATH]: WorksheetKeys.CROP_NUTRIENT,
    [URLs.ORGANIC_AMENDMENT_PATH]: WorksheetKeys.ORGANIC_AMENDMENT,
    [URLs.FERTILIZER_PATH]: WorksheetKeys.FERTILIZER,
    [URLs.PLATO_PATH]: WorksheetKeys.PLATO,
    [URLs.FMP_PATH]: WorksheetKeys.FMP,
    [URLs.STORAGE_SIZING_PATH]: WorksheetKeys.STORAGE_SIZING
  };

  public static readonly INTERCEPTABLE_WORKSHEET_PATHS = [
    URLs.CROP_NUTRIENT_PATH,
    URLs.ORGANIC_AMENDMENT_PATH,
    URLs.FERTILIZER_PATH,
    URLs.PLATO_PATH,
    URLs.FMP_PATH,
    URLs.STORAGE_SIZING_PATH,
    URLs.MDS1_PATH,
    URLs.MDS2_PATH
  ];

  public static readonly ENDPOINT_MAP = {
    [WorksheetKeys.CROP_NUTRIENT]: URLs.CROP_NUTRIENT_ENDPOINT,
    [WorksheetKeys.ORGANIC_AMENDMENT]: URLs.ORGANIC_AMENDMENT_ENDPOINT,
    [WorksheetKeys.FERTILIZER]: URLs.FERTILIZER_ENDPOINT,
    [WorksheetKeys.PLATO]: URLs.PLATO_ENDPOINT,
    [WorksheetKeys.FMP]: URLs.FMP_ENDPOINT,
    [WorksheetKeys.STORAGE_SIZING]: URLs.STORAGE_SIZING_ENDPOINT,
    [WorksheetKeys.MDS1]: URLs.MDS1_ENDPOINT,
    [WorksheetKeys.MDS2]: URLs.MDS2_ENDPOINT,
    [WorksheetKeys.NUTRIENT_MGMT]: URLs.NUTRIENT_MGMT_ENDPOINT,
    [WorksheetKeys.NASM]: URLs.NASM_ENDPOINT,
    [WorksheetKeys.GNF]: URLs.GNF_ENDPOINT,
    [WorksheetKeys.GHG]: URLs.GHG_ENDPOINT,
    [WorksheetKeys.AG_EROSION]: URLs.AG_EROSION_ENDPOINT
  };

  public static readonly IMPORT_ENDPOINT_MAP = {
    [WorksheetKeys.FMP]: URLs.WORKSHEET_IMPORT_FMP_ENDPOINT,
    [WorksheetKeys.STORAGE_SIZING]: URLs.WORKSHEET_IMPORT_MSTOR_ENDPOINT,
    [WorksheetKeys.NUTRIENT_MGMT]: URLs.WORKSHEET_IMPORT_NMSP_ENDPOINT,
    [WorksheetKeys.NASM]: URLs.WORKSHEET_IMPORT_NASM_ENDPOINT,
    [WorksheetKeys.GNF]: URLs.WORKSHEET_IMPORT_GNF_ENDPOINT,
    [WorksheetKeys.MDS1]: URLs.WORKSHEET_IMPORT_MDS_ENDPOINT,
    [WorksheetKeys.MDS2]: URLs.WORKSHEET_IMPORT_MDS_ENDPOINT,
    [WorksheetKeys.GHG]: URLs.WORKSHEET_IMPORT_GHG_ENDPOINT,
    [WorksheetKeys.AG_EROSION]: URLs.WORKSHEET_IMPORT_AGE_ENDPOINT
  };

  static getEndPoint(type: string) {
    const endPoint = URLs.ENDPOINT_MAP[type];
    return endPoint || URLs.WORKSHEET_ENDPOINT;
  }

  static getImportEndPoint(type: string) {
    return URLs.IMPORT_ENDPOINT_MAP[type];
  }
}
