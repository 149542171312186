export enum SubmissionType {
    NewSubmission = 0,
    ResponseToRequest = 1,
    Amendment = 2,
    RecordKeeping = 3
}

export const SubmissionIdRequiredTypes = [
    SubmissionType.ResponseToRequest,
    SubmissionType.Amendment
];