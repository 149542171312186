import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'flagIconClassPipe' })
export class FlagIconClassPipe implements PipeTransform {
  constructor() {}

  transform(flagLevelId: number): string {
    switch (flagLevelId) {
      case 1:
        return 'button--yellow-tertiary level-1'; // return 'flag-level-1';
      case 2:
        return 'button--yellow-tertiary level-2'; // return 'flag-level-2';
      case 3:
        return 'button--red-tertiary level-3'; // return 'flag-level-3';
      case 4:
        return 'button--yellow-tertiary level-4'; // return 'flag-level-4';
      case 5:
        return 'button--red-tertiary level-5'; // return 'flag-level-5';
      case 6:
        return 'button--blue-tertiary level-6'; // return 'flag-level-6';
      case 7:
        return 'button--yellow-tertiary level-7'; // return 'flag-level-7'; // this is questionable
      case 8:
        return 'button--red-tertiary level-8'; // return 'flag-level-8';
      default:
        return undefined;
    }
  }
}

@Pipe({ name: 'flagIconClassPipeNoButton' })
export class FlagIconClassNoButtonPipe implements PipeTransform {
  constructor() {}

  transform(flagLevelId: number): string {
    switch (flagLevelId) {
      case 1:
        return 'colour--yellow-primary';
      case 2:
        return 'colour--yellow-primary';
      case 3:
        return 'colour--red-primary';
      case 4:
        return 'colour--yellow-primary';
      case 5:
        return 'colour--red-primary';
      case 6:
        return 'colour--blue-primary';
      case 7:
        return 'colour--yellow-primary';
      case 8:
        return 'colour--red-primary';
      default:
        return undefined;
    }
  }
}

@Pipe({ name: 'flagClassBadgePipe' })
export class FlagIconClassBadgePipe implements PipeTransform {
  constructor() {}

  transform(flagLevelId: number): string {
    switch (flagLevelId) {
      case 2:
        return 'badge--yellow-primary';
      case 3:
        return 'badge--red-primary';
      case 4:
        return 'badge--yellow-primary';
      case 5:
        return 'badge--red-primary';
      case 6:
        return 'badge--blue-primary';
      case 8:
        return 'badge--red-primary';
      default:
        return undefined;
    }
  }
}
