import { Pipe } from '@angular/core';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({
    name: 'sort',
    pure: true
})
export class FormArraySortPipe {
    transform(array: Array<string>, args: string): Array<string> {
        if (array !== undefined) {
            return array.sort((a: any, b: any) => {

                const aValue = a.get(args).value;
                const bValue = b.get(args).value;

                if (aValue < bValue) {
                    return -1;
                } else if (aValue > bValue) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
        return array;
    }
}
