import { Injectable } from '@angular/core';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { FormService } from './form.service';
import { FMPTabs } from '@fmp/model/fmp-tabs.enum';
import { MDS1Tabs } from '@mds1/models/mds1-tabs.enum';
import { Tabs as MstorTabs } from '@mstor/model/tabs.enum';
import { Tabs as NmspTabs } from '@nmsp/model/tabs.enum';
import { Tabs as NasmTabs } from '@nasm/model/tabs.enum';
import { Tabs as GnfTabs } from '@gnf/model/tabs.enum';
import { Tabs as GhgTabs } from '@ghg/model/tabs.enum';
import { Tabs as AgeTabs } from '@ag-erosion/model/tabs.enum';
import { UntypedFormControl } from '@angular/forms';

export enum TabName {
  GeneralInfo = 'GeneralInfo',
  StorageSystems = 'StorageSystems',
  Livestock = 'Livestock',
  Storages = 'Storages',
  Farms = 'Farms',
  Fields = 'Fields',
  FarmFields = 'FarmFields',
  TransferContacts = 'TransferContacts',
  Report = 'Report',
  Resources = 'Resources',
  Calculations = 'Calculations',
  MaterialTypes = 'MaterialTypes',
  Materials = 'Materials',
  Buildings = 'Buildings',
  Watersheds = 'Watersheds',
  ControlStructures = 'ControlStructures'
}

@Injectable({ providedIn: 'root' })
export class TabService {
  constructor(private formService: FormService) {}

  markGeneralInfoAsActive(conditions?: any) {
    return this.markAsActive(TabName.GeneralInfo, conditions);
  }

  markStorageSystemsAsActive(conditions?: any) {
    if (this.formService.isGhgWorksheet) {
      return this.markAsActive(TabName.Livestock, conditions);
    }
    return this.markAsActive(TabName.StorageSystems, conditions);
  }

  markStoragesAsActive(conditions?: any) {
    return this.markAsActive(TabName.Storages, conditions);
  }

  markFarmsAsActive(conditions?: any) {
    if (this.formService.isGhgWorksheet) {
      return this.markAsActive(TabName.Fields, conditions);
    }
    return this.markAsActive(TabName.Farms, conditions);
  }

  markFieldsAsActive(conditions?: any) {
    return this.markAsActive(TabName.Fields, conditions);
  }

  markTransferContactsAsActive(conditions?: any) {
    return this.markAsActive(TabName.TransferContacts, conditions);
  }

  markCalculationsAsActive(conditions?: any) {
    return this.markAsActive(TabName.Calculations, conditions);
  }

  markMaterialTypesAsActive(conditions?: any) {
    return this.markAsActive(TabName.MaterialTypes, conditions);
  }

  markMaterialsAsActive(conditions?: any) {
    return this.markAsActive(TabName.Materials, conditions);
  }

  markBuildingAsActive(conditions?: any) {
    return this.markAsActive(TabName.Buildings, conditions);
  }

  markWatershedAsActive(conditions?: any) {
    return this.markAsActive(TabName.Watersheds, conditions);
  }

  markControlStructureAsActive(conditions?: any) {
    return this.markAsActive(TabName.ControlStructures, conditions);
  }

  private getTabValue(name: TabName): number {
    let targetTabName = name;
    if (this.formService.isFmpWorksheet && [TabName.Farms, TabName.Fields].includes(name)) {
      targetTabName = TabName.FarmFields;
    }
    let targetTab: number = this.tabs[targetTabName];
    return targetTab;
  }

  markAsActive(name: TabName, conditions?: any) {
    const targetTab: number = this.getTabValue(name);
    const skipTabs: number[] = [].concat(conditions?.skipTabNames).reduce((names, tabName) => {
      if (tabName) {
        names.push(this.getTabValue(tabName));
      }
      return names;
    }, []);
    const allSkipTabs: number[] = [targetTab, ...skipTabs];

    if (!allSkipTabs.includes(this.currentTab?.value)) {
      this.currentTab.patchValue(targetTab);
    }
  }

  get currentTab(): UntypedFormControl {
    return this.formService.f?.controls?.currentTab as UntypedFormControl;
  }

  get tabs() {
    switch (this.formService.worksheetTypeId) {
      case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
        return FMPTabs;
      case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1:
      case WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2:
        return MDS1Tabs;
      case WorksheetTypeIds.MANURE_STORAGE_SIZING:
        return MstorTabs;
      case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
        return NmspTabs;
      case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
        return NasmTabs;
      case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
        return GnfTabs;
      case WorksheetTypeIds.GREENHOUSE_GAS:
        return GhgTabs;
      case WorksheetTypeIds.AG_EROSION:
        return AgeTabs;
      default:
        return null;
    }
  }
}
