import { Pipe, PipeTransform } from '@angular/core';
import { FmpFieldInput } from '@fmp/model/fmp-field-input.model';
import { FmpMaterialApplication } from '@fmp/model/fmp-material-application.model';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { LanguageType } from '../models/common/language-type.enum';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { Units } from '../models/common/unit';

@Pipe({ name: 'applicationRateUnitPipe' })
export class ApplicationRateUnitPipe implements PipeTransform {
  constructor(private measurementService: MeasurementService, private languageService: LanguageService) {}

  transform(element: FmpFieldInput): Observable<string> {
    const mt = element?.materialTypes?.find(v => Utils.matchStr(v.id, (element?.app as FmpMaterialApplication)?.materialTypeId));
    if (!mt) {
      return EMPTY;
    }
    // tslint:disable-next-line: deprecation
    return combineLatest([this.measurementService.measurementSystem$, this.languageService.languageType$]).pipe(
      map(([measurementSystem, lang]) => {
        const u = new Units();
        u.Units(measurementSystem, LanguageType[lang]);
        if (mt.materialForm === MaterialForm.Liquid) {
          if (measurementSystem === MeasurementSystem.Metric) {
            return u.appVolume2;
          } else {
            return u.appVolume;
          }
        } else {
          return u.appMassLarge;
        }
      })
    );
  }
}
