import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { URLs } from "@core/conf/urls";
import { WorksheetTypeIds } from "@shared/models/common/worksheet-type-ids.enum";
import { HtmlContent } from "@shared/models/flag/html-content.model";
import { Observable } from "rxjs";
import { CacheService } from "./cache.service";

@Injectable({ providedIn: 'root' })
export class HtmlContentService {

  constructor(private httpClient: HttpClient,
    private cache: CacheService) { }

  get(): Observable<HtmlContent[]> {
    return this.httpClient.get(`${URLs.HTML_CONTENT_ENDPOINT}`) as Observable<HtmlContent[]>;
  }

  getById(worksheetId: string): Observable<HtmlContent> {
    return this.httpClient.get(`${URLs.HTML_CONTENT_ENDPOINT}/${worksheetId}`) as Observable<HtmlContent>;
  }

  getByWorksheetTypeId(worksheetTypeId: WorksheetTypeIds): Observable<HtmlContent> {
    const type = this.cache.worksheetTypes.find(t => t.id.toLowerCase() === worksheetTypeId.toLowerCase());
    return this.httpClient.get(`${URLs.HTML_CONTENT_ENDPOINT}/worksheet-type-name/${type.typeName}`) as Observable<HtmlContent>;
  }
}