import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { ContactType } from '@shared/models/common/contact-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { MaterialComponentType } from './material-component-type.enum';

export class MaterialComponentModel extends BaseModel {

    componentType: MaterialComponentType;
    materialForm: MaterialForm;
    materialType: number;
    materialTypeId: string;
    name: string;
    nasmType = Constants.DEFAULT_NASM_TYPE;
    contacts = [new ContactInformation({ contactType: ContactType.Facility })];

    constructor(init?: Partial<MaterialComponentModel>) {
        super();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            name: new UntypedFormControl(this.name, [Validators.maxLength(50)]),
            materialForm: new UntypedFormControl(this.materialForm),
            materialType: new UntypedFormControl(this.materialType),
            notes: new UntypedFormControl(this.notes, [Validators.maxLength(250)]),
            componentType: new UntypedFormControl(this.componentType),
            materialTypeId: new UntypedFormControl(this.materialTypeId),
            nasmType: new UntypedFormControl(this.nasmType),
            contacts: new UntypedFormArray(this.contacts.map(c => new ContactInformation(c).toForm()))
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        const contacts = form.get('contacts') as UntypedFormArray;
        return new MaterialComponentModel({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            name: v.name,
            materialForm: v.materialForm,
            materialType: v.materialType,
            notes: v.notes,
            componentType: v.componentType,
            materialTypeId: v.materialTypeId,
            nasmType: v.nasmType,
            contacts: contacts.controls.map((c: UntypedFormGroup) => new ContactInformation().toModel(c))
        });
    }
}
