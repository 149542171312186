/* eslint-disable no-extra-boolean-cast */
import { ElementRef, Renderer2 } from '@angular/core';
import { LanguageType } from '../../shared/models/common/language-type.enum';

export class AODA {
  static applyAllFixes(el: ElementRef, renderer: Renderer2, lang: LanguageType = LanguageType.English) {
    AODA.addCheckboxRole(el, renderer);
    AODA.setAriaHiddenFalse(el, renderer);
    AODA.addCheckboxSvgAriaLabel(el, renderer, lang);
    AODA.addDatePickerSvgAriaLabel(el, renderer, lang);
    AODA.removeSelectAriaDisabled(el, renderer);
  }

  static addCheckboxRole(el: ElementRef, renderer: Renderer2) {
    // AODA fix: add role to checkbox inputs
    const checkboxesRef = el.nativeElement.querySelectorAll('.mat-checkbox-input');
    if (!!checkboxesRef) {
      checkboxesRef.forEach(el => renderer.setAttribute(el, 'role', 'checkbox'));
    }
  }

  static addDatePickerSvgAriaLabel(el: ElementRef, renderer: Renderer2, lang: LanguageType) {
    // AODA fix: add date picker svg aria-label
    const datePickerSvgsRef = el.nativeElement.querySelectorAll('.mat-datepicker-toggle-default-icon');
    datePickerSvgsRef && lang === LanguageType.English
      ? datePickerSvgsRef.forEach(el => renderer.setAttribute(el, 'aria-label', 'Open the calendar'))
      : datePickerSvgsRef.forEach(el => renderer.setAttribute(el, 'aria-label', 'Ouvrir calendrier'));
  }

  static addCheckboxSvgAriaLabel(el: ElementRef, renderer: Renderer2, lang: LanguageType) {
    // AODA fix: add checkbox svg aria-label
    const checkboxSvgsRef = el.nativeElement.querySelectorAll('.mat-checkbox-checkmark');
    checkboxSvgsRef && lang === LanguageType.English
      ? checkboxSvgsRef.forEach(el => renderer.setAttribute(el, 'aria-label', 'checkbox'))
      : checkboxSvgsRef.forEach(el => renderer.setAttribute(el, 'aria-label', 'case à cocher'));
  }

  static setAriaHiddenFalse(el: ElementRef, renderer: Renderer2) {
    // AODA fix: aria-hidden elements do not contain focusable elements
    const ref = el.nativeElement.querySelectorAll('div.cdk-visually-hidden.cdk-focus-trap-anchor');
    if (!!ref) {
      ref.forEach(el => renderer.setAttribute(el, 'aria-hidden', 'false'));
    }
  }

  static removeSelectAriaDisabled(el: ElementRef, renderer: Renderer2) {
    // AODA fix: disabled select elements do not need aira-disabled
    const ref = el.nativeElement.querySelectorAll('.mat-select.mat-select-disabled');
    if (!!ref) {
      ref.forEach(el => renderer.removeAttribute(el, 'aria-disabled'));
    }
  }
}
