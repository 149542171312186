import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { WorksheetTypeIds } from '../common/worksheet-type-ids.enum';
import {
  AgePreSelectedFlagLevelTypes,
  DefaultPreSelectedFlagLevelTypesInModel,
  MdsPreSelectedFlagLevelTypes
} from './flag-level-type.enum';

export class FlagSummaryFilter {
  sections = [];
  excludedEntityTypes = ['FIELD', 'MATERIAL_APPLICATION'];
  displayNmsFlagsOnly: boolean;
  worksheetTypeId: string;

  constructor(init?: Partial<FlagSummaryFilter>, worksheetTypeId?: string) {
    this.worksheetTypeId = worksheetTypeId;
    this.sections = this.defaultSections;
    Object.assign(this, init);
  }

  private isMds(): boolean {
    return (
      this.worksheetTypeId &&
      (Utils.matchStr(this.worksheetTypeId, WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1) ||
        Utils.matchStr(this.worksheetTypeId, WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2))
    );
  }

  private isAge(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.AG_EROSION;
  }

  private get defaultSections() {
    if (!this.worksheetTypeId) return [];
    if (this.isMds()) return MdsPreSelectedFlagLevelTypes;
    if (this.isAge()) return AgePreSelectedFlagLevelTypes;
    return DefaultPreSelectedFlagLevelTypesInModel;
    // Constants.FLAG_LEVELS.filter(v => v.checked === true).map((v) => v.flagLevelId);
  }

  toForm() {
    return new UntypedFormGroup({
      sections: new UntypedFormControl(this.sections),
      displayNmsFlagsOnly: new UntypedFormControl(this.displayNmsFlagsOnly)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new FlagSummaryFilter(
      {
        sections: v.sections,
        displayNmsFlagsOnly: v.displayNmsFlagsOnly
      },
      this.worksheetTypeId
    );
  }
}
