import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Livestock } from '@mstor/model/livestock.model';
import { FeedAdditiveLivestockTypes, LivestockType } from '../material/livestock-type.enum';

export class LivestocksFormGroup {
  private _parent: UntypedFormGroup;
  private _livestocks: UntypedFormArray;

  constructor(parent: UntypedFormGroup, livestocks: UntypedFormArray) {
    this._parent = parent;
    this._livestocks = livestocks;
  }

  getById(livestockId: string): LivestockFormGroup {
    const livestock = this._livestocks?.controls?.find(v => Utils.matchStr(v?.get('id')?.value, livestockId)) as UntypedFormGroup;
    return new LivestockFormGroup(this._parent, livestock);
  }

  getByLivestockTypeAndMaterialTypeId(livestockTypeId: number, materialTypeId: number): LivestockFormGroup {
    const livestock = this._livestocks?.controls?.find(
      (v: UntypedFormGroup) => v?.controls?.livestockTypeId?.value === livestockTypeId && v?.controls?.materialTypeId?.value === materialTypeId
    ) as UntypedFormGroup;
    return new LivestockFormGroup(this._parent, livestock);
  }

  getByMaterialType(materialTypes: LivestockType[]): LivestockFormGroup[] {
    return this._livestocks?.controls
      ?.filter((l: UntypedFormGroup) => materialTypes.includes(l?.controls?.materialTypeId?.value))
      ?.map(l => new LivestockFormGroup(this._parent, l as UntypedFormGroup));
  }

  toForm(): UntypedFormArray {
    return this._livestocks;
  }

  toModel(): Livestock[] {
    return this._livestocks?.controls?.map(livestockCtrl => new Livestock().toModel(livestockCtrl as UntypedFormGroup));
  }

  get feedAdditiveStocks(): LivestockFormGroup[] {
    return this.getByMaterialType(FeedAdditiveLivestockTypes);
  }

  get length(): number {
    return this._livestocks.length;
  }
}

export class LivestockFormGroup {
  private _parent: UntypedFormGroup;
  private _livestock: UntypedFormGroup;

  constructor(parent: UntypedFormGroup, livestock: UntypedFormGroup) {
    this._parent = parent;
    this._livestock = livestock;
  }

  toForm(): UntypedFormGroup {
    return this._livestock;
  }

  toModel(): Livestock {
    return new Livestock().toModel(this._livestock);
  }

  get idCtrl(): UntypedFormControl {
    return this._livestock?.get('id') as UntypedFormControl;
  }

  get id(): string {
    return this.idCtrl?.value;
  }

  get nameCtrl(): UntypedFormControl {
    return this._livestock?.get('name') as UntypedFormControl;
  }

  get name(): string {
    return this.nameCtrl?.value;
  }
}
