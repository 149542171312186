import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '../../../core/utilities/utils';
import { ValidationFunctions } from '../../validators/validation-functions';
import { BaseModel } from './BaseModel';
export class Crop extends BaseModel {
    fieldSectionId: string;
    name: string;
    cropTypeId = 7;
    cropSubTypeId = 7;    // heatUnits is on fieldSection
    yieldInMetric = 0;
    croppingYear = Utils.getDefaultCroppingYear();
    plantingDate: string;
    harvestDate: string;
    tillageMethodNotill = false;
    tillageMethod = 0;
    tillagePractice = 0;
    isIrrigated = false;
    isTillageInFall = false;
    nFertilizerTiming = 0;
    coverCrop = false;
    coverCropHarvested = false;
    previousCropNCreditId = 1;
    isNitrogenSuppliedByManure = false;
    soilErosionInMetric = 0;
    soilErosionMethod = 1;
    cropPrice = 0;
    costOfNitrogen = 0;
    coverCropMitigation = 0;
    otherComment: string;
    agronomicNitrogenInMetric = 0;
    agronomicPhosphateInMetric = 0;
    agronomicPotashInMetric = 0;
    removalNitrogenInMetric = 0;
    removalPhosphateInMetric = 0;
    removalPotashInMetric = 0;

    constructor(init?: Partial<Crop>) {
        super();
        this.id = Utils.uuid();
        Object.assign(this, init);
    }

    toForm() {
        const croppingYear = new UntypedFormControl(this.croppingYear ? this.croppingYear : Utils.getDefaultCroppingYear());
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            fieldSectionId: new UntypedFormControl(this.fieldSectionId),
            name: new UntypedFormControl(this.name),
            cropTypeId: new UntypedFormControl(this.cropTypeId),
            cropSubTypeId: new UntypedFormControl(this.cropSubTypeId),    // heatUnits is on fieldSection
            yieldInMetric: new UntypedFormControl(this.yieldInMetric),
            // tslint:disable-next-line: object-literal-shorthand
            croppingYear: croppingYear,
            plantingDate: new UntypedFormControl(Utils.adjustPlantingDateByCroppingYear(this.plantingDate, croppingYear.value),
                { validators: [ValidationFunctions.overTwentyYears] }),
            harvestDate: new UntypedFormControl(Utils.adjustHarvestDateByCroppingYear(this.harvestDate, croppingYear.value)),
            tillageMethodNotill: new UntypedFormControl(this.tillageMethodNotill),
            tillageMethod: new UntypedFormControl(this.tillageMethod),
            tillagePractice: new UntypedFormControl(this.tillagePractice),
            isIrrigated: new UntypedFormControl(this.isIrrigated),
            isTillageInFall: new UntypedFormControl(this.isTillageInFall),
            nFertilizerTiming: new UntypedFormControl(this.nFertilizerTiming),
            coverCrop: new UntypedFormControl(this.coverCrop),
            coverCropHarvested: new UntypedFormControl(this.coverCropHarvested),
            previousCropNCreditId: new UntypedFormControl(this.previousCropNCreditId),
            isNitrogenSuppliedByManure: new UntypedFormControl(this.isNitrogenSuppliedByManure),
            soilErosionInMetric: new UntypedFormControl(this.soilErosionInMetric, { validators: [Validators.min(0)] }),
            soilErosionMethod: new UntypedFormControl(this.soilErosionMethod),
            cropPrice: new UntypedFormControl(this.cropPrice),
            costOfNitrogen: new UntypedFormControl(this.costOfNitrogen),
            coverCropMitigation: new UntypedFormControl(this.coverCropMitigation),
            otherComment: new UntypedFormControl(this.otherComment),
            agronomicNitrogenInMetric: new UntypedFormControl(this.agronomicNitrogenInMetric, { validators: [Validators.min(0)] }),
            agronomicPhosphateInMetric: new UntypedFormControl(this.agronomicPhosphateInMetric, { validators: [Validators.min(0)] }),
            agronomicPotashInMetric: new UntypedFormControl(this.agronomicPotashInMetric, { validators: [Validators.min(0)] }),
            removalNitrogenInMetric: new UntypedFormControl(this.removalNitrogenInMetric, { validators: [Validators.min(0)] }),
            removalPhosphateInMetric: new UntypedFormControl(this.removalPhosphateInMetric, { validators: [Validators.min(0)] }),
            removalPotashInMetric: new UntypedFormControl(this.removalPotashInMetric, { validators: [Validators.min(0)] })
        }, { validators: [ValidationFunctions.minHarvestDate(), ValidationFunctions.maxHarvestDate()] });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new Crop({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            fieldSectionId: v.fieldSectionId,
            name: v.name,
            cropTypeId: v.cropTypeId,
            cropSubTypeId: v.cropSubTypeId,
            yieldInMetric: v.yieldInMetric,
            croppingYear: v.croppingYear,
            plantingDate: v.plantingDate,
            harvestDate: v.harvestDate,
            tillageMethodNotill: v.tillageMethodNotill,
            tillageMethod: v.tillageMethod,
            tillagePractice: v.tillagePractice,
            isIrrigated: v.isIrrigated,
            isTillageInFall: v.isTillageInFall,
            nFertilizerTiming: v.nFertilizerTiming,
            coverCrop: v.coverCrop,
            coverCropHarvested: v.coverCropHarvested,
            previousCropNCreditId: v.previousCropNCreditId,
            isNitrogenSuppliedByManure: v.isNitrogenSuppliedByManure,
            soilErosionInMetric: v.soilErosionInMetric,
            soilErosionMethod: v.soilErosionMethod,
            cropPrice: v.cropPrice,
            costOfNitrogen: v.costOfNitrogen,
            coverCropMitigation: v.coverCropMitigation,
            otherComment: v.otherComment,
            agronomicNitrogenInMetric: v.agronomicNitrogenInMetric,
            agronomicPhosphateInMetric: v.agronomicPhosphateInMetric,
            agronomicPotashInMetric: v.agronomicPotashInMetric,
            removalNitrogenInMetric: v.removalNitrogenInMetric,
            removalPhosphateInMetric: v.removalPhosphateInMetric,
            removalPotashInMetric: v.removalPotashInMetric
        });
    }
}

