import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WorksheetEconomic } from '@shared/models/common/worksheet-economic.model';
import { StorageSystem } from '@mstor/model/storage-system.model';
import { TransferContact } from '@mstor/model/transfer-contact.model';
import { ExemptionReason } from '@nmsp/model/exemption-reason.enum';
import { Constants } from '@shared/constants/constants';
import { DataType } from '@shared/models/common/data-type.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { WorksheetOverviewOperation } from '@shared/models/worksheet/worksheet-overview-operation.model';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { ContactInformation } from '../../../shared/models/common/contact-information.model';
import { WorksheetPreviousSubmission } from '../../../shared/models/worksheet/worksheet-previous-submission.model';
import { GnfFarm } from './gnf-farm.model';
import { GnfMaterialType } from './gnf-material-type.model';

export class GnfWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  startYear: number;
  endYear: number;
  farms: GnfFarm[] = [];
  storageSystems = [];
  transferContacts = [];
  materialTypes = [];
  contacts: ContactInformation[] = [];
  worksheetPreviousSubmissions: WorksheetPreviousSubmission[] = [];
  isLocal = true;
  currentFarmId: string;
  currentFieldId: string;
  currentStorageSystemId: string;
  currentTransferContactId: string;
  currentMaterialTypeId: string;
  worksheetEconomic: WorksheetEconomic;
  submissionId: string;
  registrationId: string;
  hasMunicipalWells = false;
  isNmpRequired = false;
  isOwnerSameAsOperator = false;
  hasPreviousSubmission = false;
  reasonForSubmission = Constants.DEFAULT_WORKSHEET_SUBMISSION_REASON;
  reasonDetails: string;
  isOverviewPrepared = false;
  federalBusinessNumber: string;
  federalBusinessExempt = ExemptionReason.CRA;
  isFederalBusinessExempt = false;
  legalFarmName: string;
  operationType: number;
  operationTypeComment: string;
  noPreviousNasmApplied: false;
  worksheetOverviewOperations: WorksheetOverviewOperation[] = [];
  currentTab = 0;

  constructor(init?: Partial<GnfWorksheet>) {
    super();
    this.name = this.isEn ? 'GNF 1' : 'SNS 1';
    this.farms[0] = init ? new GnfFarm(init.farms ? init.farms[0] : undefined) : new GnfFarm();
    this.storageSystems[0] = init ? new StorageSystem(init.storageSystems ? init.storageSystems[0] : undefined) : new StorageSystem();

    if (init && init.contacts && init.contacts.length > 0) {
      init.contacts.forEach((element, index) => (this.contacts[index] = element[index]));
    } else {
      this.contacts[0] = new ContactInformation();
      this.contacts[1] = new ContactInformation();
      this.contacts[2] = new ContactInformation();
    }

    Object.assign(this, init);

    this.startYear = init && init.startYear ? init.startYear : this.getDefaultStartYear();
    this.endYear = init && init.endYear ? init.endYear : this.startYear;

    this.currentFarmId = this.farms && this.farms[0] ? this.farms[0].id : undefined;
    this.currentFieldId =
      this.farms && this.farms[0] && this.farms[0].fields && this.farms[0].fields[0] ? this.farms[0].fields[0].id : undefined;

    this.currentStorageSystemId = this.storageSystems && this.storageSystems[0] ? this.storageSystems[0].id : undefined;
    this.currentTransferContactId = this.transferContacts && this.transferContacts[0] ? this.transferContacts[0].id : undefined;

    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(e => {
        e.parentType = DataType.WORKSHEET;
        e.parentId = this.id ? this.id : e.parentId;
      });
    }
  }

  getDefaultStartYear() {
    const currentYear = new Date().getUTCFullYear();
    const inNextCroppingYear = () => {
      const currentMonth = new Date().getUTCMonth() + 1;
      return currentMonth > 8;
    };
    return currentYear + (inNextCroppingYear() ? 1 : 0);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      startYear: new UntypedFormControl(this.startYear),
      endYear: new UntypedFormControl(this.endYear),
      farms: new UntypedFormArray(this.farms.map(v => new GnfFarm(v).toForm())),
      storageSystems: new UntypedFormArray(this.storageSystems.map(v => new StorageSystem(v).toForm())),
      transferContacts: new UntypedFormArray(this.transferContacts.map(v => new TransferContact(v).toForm())),
      materialTypes: new UntypedFormArray(this.materialTypes.map(v => new GnfMaterialType(v).toForm())),
      contacts: new UntypedFormArray(
        this.contacts.map(w => new ContactInformation(w).toForm()),
        [ValidationFunctions.nutrientMgmtContactTypes]
      ),
      worksheetPreviousSubmissions: new UntypedFormArray(this.worksheetPreviousSubmissions.map(v => new WorksheetPreviousSubmission(v).toForm())),
      currentFarmId: new UntypedFormControl(this.currentFarmId),
      currentFieldId: new UntypedFormControl(this.currentFieldId),
      currentStorageSystemId: new UntypedFormControl(this.currentStorageSystemId),
      currentTransferContactId: new UntypedFormControl(this.currentTransferContactId),
      currentMaterialTypeId: new UntypedFormControl(this.currentMaterialTypeId),
      worksheetEconomic: new WorksheetEconomic(this.worksheetEconomic).toForm(),
      submissionId: new UntypedFormControl(this.submissionId, { validators: [Validators.maxLength(15)] }),
      registrationId: new UntypedFormControl(this.registrationId, { validators: [Validators.maxLength(15)] }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      hasMunicipalWells: new UntypedFormControl(this.hasMunicipalWells),
      isNmpRequired: new UntypedFormControl(this.isNmpRequired),
      isOwnerSameAsOperator: new UntypedFormControl(this.isOwnerSameAsOperator),
      hasPreviousSubmission: new UntypedFormControl(this.hasPreviousSubmission),
      reasonForSubmission: new UntypedFormControl(this.reasonForSubmission),
      reasonDetails: new UntypedFormControl(this.reasonDetails, { validators: [Validators.maxLength(250)] }),
      isOverviewPrepared: new UntypedFormControl(this.isOverviewPrepared),
      federalBusinessNumber: new UntypedFormControl(this.federalBusinessNumber, {
        validators: [Validators.maxLength(9), Validators.pattern(/[0-9]/)]
      }),
      federalBusinessExempt: new UntypedFormControl(this.federalBusinessExempt),
      isFederalBusinessExempt: new UntypedFormControl(this.isFederalBusinessExempt),
      legalFarmName: new UntypedFormControl(this.legalFarmName, { validators: [Validators.maxLength(50)] }),
      operationType: new UntypedFormControl(this.operationType),
      operationTypeComment: new UntypedFormControl(this.operationTypeComment),
      noPreviousNasmApplied: new UntypedFormControl(this.noPreviousNasmApplied),
      worksheetOverviewOperations: new UntypedFormArray(this.worksheetOverviewOperations.map(v => new WorksheetOverviewOperation(v).toForm())),
      currentTab: new UntypedFormControl(this.currentTab)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const farms = form.get('farms') as UntypedFormArray;
    const worksheetEconomic = form.get('worksheetEconomic') as UntypedFormGroup;
    const storageSystems = form.get('storageSystems') as UntypedFormArray;
    const transferContacts = form.get('transferContacts') as UntypedFormArray;
    const materialTypes = form.get('materialTypes') as UntypedFormArray;
    const contacts = form.get('contacts') as UntypedFormArray;
    const worksheetPreviousSubmissions = form.get('worksheetPreviousSubmissions') as UntypedFormArray;
    const worksheetOverviewOperations = form.get('worksheetOverviewOperations') as UntypedFormArray;
    return new GnfWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      startYear: v.startYear,
      endYear: v.endYear,
      farms: farms.controls.map((w: UntypedFormGroup) => new GnfFarm().toModel(w)),
      storageSystems: storageSystems?.controls.map((w: UntypedFormGroup) => new StorageSystem().toModel(w)),
      transferContacts: transferContacts?.controls.map((w: UntypedFormGroup) => new TransferContact().toModel(w)),
      materialTypes: materialTypes?.controls.map((w: UntypedFormGroup) => new GnfMaterialType().toModel(w)),
      contacts: contacts?.controls.map((w: UntypedFormGroup) => new ContactInformation().toModel(w)),
      worksheetPreviousSubmissions: worksheetPreviousSubmissions?.controls.map((w: UntypedFormGroup) =>
        new WorksheetPreviousSubmission().toModel(w)
      ),
      currentFarmId: v.currentFarmId,
      currentFieldId: v.currentFieldId,
      currentStorageSystemId: v.currentStorageSystemId,
      currentTransferContactId: v.currentTransferContactId,
      currentMaterialTypeId: v.currentMaterialTypeId,
      worksheetEconomic: new WorksheetEconomic().toModel(worksheetEconomic),
      submissionId: v.submissionId,
      registrationId: v.registrationId,
      hasMunicipalWells: v.hasMunicipalWells,
      isNmpRequired: v.isNmpRequired,
      isOwnerSameAsOperator: v.isOwnerSameAsOperator,
      hasPreviousSubmission: v.hasPreviousSubmission,
      notes: v.notes,
      reasonForSubmission: v.reasonForSubmission,
      reasonDetails: v.reasonDetails,
      isOverviewPrepared: v.isOverviewPrepared,
      federalBusinessNumber: v.federalBusinessNumber,
      federalBusinessExempt: v.federalBusinessExempt,
      isFederalBusinessExempt: v.isFederalBusinessExempt,
      legalFarmName: v.legalFarmName,
      operationType: v.operationType,
      operationTypeComment: v.operationTypeComment,
      noPreviousNasmApplied: v.noPreviousNasmApplied,
      worksheetOverviewOperations: worksheetOverviewOperations?.controls.map((w: UntypedFormGroup) => new WorksheetOverviewOperation().toModel(w)),
      currentTab: v.currentTab
    });
  }
}
