import { Pipe, PipeTransform } from '@angular/core';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'yearUnitPipe' })
export class YearUnitPipe implements PipeTransform {
  constructor(private languageService: LanguageService, private cache: CacheService) {}

  transform(numberOfYears: number): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const languageJson: any = this.cache[lang];
        return !this.isSingularYear(numberOfYears) ? languageJson['years.abbr'] : languageJson['year.abbr'];
      })
    );
  }

  isSingularYear(numberOfYears: number): boolean {
    return Math.round((Number(numberOfYears) + Number.EPSILON) * 100) / 100 === 1;
  }
}
