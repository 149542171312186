export enum WorksheetType {
  CROP_NUTRIENT = 'crop',
  FERTILIZER_APPLICATION = 'fertilizer',
  PHOSPHORUS_LOSS = 'plato',
  FIELD_MANAGEMENT_PLAN = 'fmp',
  MANURE_STORAGE_SIZING = 'mstor',
  NUTRIENT_MANAGEMENT_STRATEGY_PLAN = 'nmsp',
  NON_AGRICULTURAL_SOURCE_MATERIAL = 'nasm',
  GREENHOUSE_NUTRIENT_FEEDWATER = 'gnf',
  MINIMUM_DISTANCE_SEPARATION_1 = 'mds1',
  MINIMUM_DISTANCE_SEPARATION_2 = 'mds2'
}