import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { MaterialTypeOption } from '@shared/models/material/material-type-option';
import { FormService } from '@shared/services/form.service';
import { LanguageService } from '@shared/services/language.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FmpMaterialType } from '../model/fmp-material-type.model';
import { FmpWorksheet } from '../model/fmp-worksheet.model';

@Injectable({ providedIn: 'root' })
export class FmpService {

  farmExpanded$ = new BehaviorSubject(undefined);
  fieldExpanded$ = new BehaviorSubject(undefined);
  fieldMoved$ = new BehaviorSubject(undefined);
  materialTypeChanged$ = new BehaviorSubject(undefined);
  addingFarm = false;
  // tslint:disable-next-line: variable-name
  expandedApplication$ = new BehaviorSubject(undefined);

  constructor(private httpClient: HttpClient,
    private formService: FormService,
    private languageService: LanguageService) { }

  expandFarmPanel() {
    this.farmExpanded$.next(Utils.uuid());
  }

  expandFieldPanel() {
    this.fieldExpanded$.next(Utils.uuid());
  }

  get(id: string): Observable<FmpWorksheet> {
    return this.httpClient.get(`${URLs.FMP_ENDPOINT}/${id}`) as Observable<FmpWorksheet>;
  }

  save(worksheet: FmpWorksheet): Observable<any> {
    return this.httpClient.post(URLs.FMP_ENDPOINT, worksheet);
  }

  update(worksheet: FmpWorksheet): Observable<any> {
    const url = `${URLs.FMP_ENDPOINT}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet);
  }

  updateWorksheetFlat(worksheet: FmpWorksheet): Observable<any> {
    const url = `${URLs.FMP_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    params = params.append('ignore', 'child-elements');
    return this.httpClient.put(url, worksheet, { params });
  }

  updateWorksheetEconomics(worksheet: FmpWorksheet): Observable<any> {
    const url = `${URLs.FMP_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    params = params.append('ignore', 'farms, material-types');
    return this.httpClient.put(url, worksheet, { params });
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${URLs.FMP_ENDPOINT}/${id}`);
  }

  dummyMaterialType() {
    return new FmpMaterialType();
  }

  getMaterialTypeOption(id): Observable<MaterialTypeOption> {
    return this.httpClient.get(`${URLs.MATERIAL_TYPE_LOOKUP_ENDPOINT}/${id}`) as Observable<MaterialTypeOption>;
  }

  get f() {
    return this.formService.f;
  }

  get currentFieldId() {
    return this.f.get('currentFieldId');
  }

  get farms() {
    return this.f ? (this.f.get('farms') as UntypedFormArray) : undefined;
  }

  get fields() {
    return this.currentFarm ? (this.currentFarm.get('fields') as UntypedFormArray) : undefined;
  }

  get currentFarm() {
    return this.farms && this.currentField
      ? (this.farms.controls.find((v) => Utils.matchStr(v.get('id').value, this.currentField.get('farmId').value)) as UntypedFormGroup)
      : undefined;
  }

  get currentField() {
    let currentField: UntypedFormGroup;
    if (!this.farms) {
      return undefined;
    }
    this.farms.controls.forEach((farm) => {
      const fields = farm.get('fields') as UntypedFormArray;
      fields.controls.forEach((field: UntypedFormGroup) => {
        if (Utils.matchStr(field.get('id').value, this.currentFieldId.value)) {
          currentField = field;
        }
      });
    });
    return currentField;
  }

}
