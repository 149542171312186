import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'emissionDecimalPipe' })
export class EmissionDecimalPipe implements PipeTransform {
  transform(value: any): number {
    if (!!value && !isNaN(value)) {
      const decimalPlaces = value > 100 ? 0 : 1;
      const result = Number(parseFloat(value).toFixed(decimalPlaces));
      return result;
    } else {
      return value;
    }
  }
}
