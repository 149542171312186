import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { FertilizerProduct } from './fertilizer-product.model';

export class FertilizerApplication extends BaseModel {

  name: string;
  applicationDate: any;
  calculationType: number;
  fieldSectionId: string;
  targetCalciumInMetric = 0;
  targetMagnesiumInMetric = 0;
  targetManganeseInMetric = 0;
  targetNitrogenInMetric = 0;
  targetPhosphateInMetric = 0;
  targetPotashInMetric = 0;
  targetSulfurInMetric = 0;
  targetZincInMetric = 0;
  targetBoronInMetric = 0;
  targetMolybdenumInMetric = 0;
  fertilizerProducts: FertilizerProduct[];

  constructor(init?: Partial<FertilizerApplication>) {
    super();
    this.name = this.isEn ? 'Fertilizer App A' : 'Épan engrais A';
    Object.assign(this, init);
  }
}
