import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import { MatCalendar, MatDatepickerIntl } from "@angular/material/datepicker";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

/** Counter used to generate unique IDs. */
let uniqueId = 0;

@Component({
  selector: 'app-year-picker-header',
  templateUrl: './year-picker-header.component.html',
  styleUrls: ['./year-picker-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearPickerHeaderComponent<D> implements OnDestroy {
  _buttonDescriptionId = `mat-calendar-button-${uniqueId++}`;

  private _destroyed = new Subject<void>();

  constructor(
    private _intl: MatDatepickerIntl,
    private calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    private changeDetectorRef: ChangeDetectorRef) {
    this.calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => this.changeDetectorRef.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  previousEnabled(): boolean {
    if (!this.calendar.minDate) {
      return true;
    }
    return !this.calendar.minDate ||
      !this._isSameView(this.calendar.activeDate, this.calendar.minDate);
  }

  previousClicked(): void {
    this.calendar.activeDate = this._dateAdapter.addCalendarYears(this.calendar.activeDate, -1);
  }

  nextEnabled(): boolean {
    return !this.calendar.maxDate ||
      !this._isSameView(this.calendar.activeDate, this.calendar.maxDate);
  }

  nextClicked(): void {
    this.calendar.activeDate = this._dateAdapter.addCalendarYears(this.calendar.activeDate, 1);
  }

  private _isSameView(date1: D, date2: D): boolean {
    return this._dateAdapter.getYear(date1) == this._dateAdapter.getYear(date2);
  }

  get periodButtonLabel(): string {
    return this._intl.switchToMonthViewLabel;
  }

  get periodButtonText(): string {
    return this._dateAdapter.getYearName(this.calendar.activeDate);
  }

  get prevButtonLabel(): string {
    return this._intl.prevYearLabel;
  }

  get nextButtonLabel(): string {
    return this._intl.nextYearLabel;
  }
}
