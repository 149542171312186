import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class MDSBuildingPermit extends BaseModel {
  name: string;
  buildingPermitNumber: string;
  issueDate: Date;
  calculationId: string;

  constructor(init?: Partial<MDSBuildingPermit>) {
    super();
    Object.assign(this, init);
    this.id = this.id || Utils.uuid();
  }

  toForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      name: new UntypedFormControl(this.name, { validators: [Validators.maxLength(30)] }),
      buildingPermitNumber: new UntypedFormControl(this.buildingPermitNumber, {
        validators: [Validators.maxLength(30), ValidationFunctions.empty]
      }),
      issueDate: new UntypedFormControl(this.issueDate, { validators: [ValidationFunctions.empty] }),
      calculationId: new UntypedFormControl(this.calculationId)
    });
  }

  toModel(form: UntypedFormGroup): MDSBuildingPermit {
    const v = form.getRawValue();
    return new MDSBuildingPermit({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      name: v.name,
      buildingPermitNumber: v.buildingPermitNumber,
      issueDate: v.issueDate,
      calculationId: v.calculationId
    });
  }
}
