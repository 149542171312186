import { Injectable } from "@angular/core";
import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";
import { Utils } from "@core/utilities/utils";
import { MaterialSample, MaterialSampleBeneficialUse, MaterialTypeCalculation, NasmCalculation } from "@nasm/model/nasm-calculation.model";
import { MaterialForm } from "@shared/models/common/material-form.enum";
import { FlagLevelType } from "@shared/models/flag/flag-level-type.enum";
import { FlagVo } from "@shared/models/flag/flag-vo.model";
import { FormService } from "@shared/services/form.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { NasmCalculationService } from "./nasm-calculation.service";

@Injectable({ providedIn: 'root' })
export class MaterialSampleSummaryService {
  constructor(private formService: FormService,
    private calculationService: NasmCalculationService) { }

  heavyMetalContentText(heavyMetalContent, defaultText: string = 'na'): string {
    if ([0, 1, 2].indexOf(heavyMetalContent) > -1) {
      return `calculator.nasm.heavy.metal.content.text.${heavyMetalContent}`;
    }
    return defaultText;
  }

  pathogenContentText(pathogenContent, defaultText: string = 'na'): string {
    if ([0, 1, 2].indexOf(pathogenContent) > -1) {
      return `calculator.nasm.pathogen.content.text.${pathogenContent}`;
    }
    return defaultText;
  }

  beneficialUseText(beneficialUse: MaterialSampleBeneficialUse, defaultText: string = 'na',
    showAdditional: boolean = false): string {
    const hasBeneficialUse = beneficialUse?.hasBeneficialUse;
    const flagBeneficialUse = beneficialUse?.flagBeneficialUse;
    const isAdditionalFlag = flagBeneficialUse?.flagLevelId === FlagLevelType.AdditionalInfo;
    const hasBu = [0, 1].indexOf(~~hasBeneficialUse);
    const showAdditionalText = showAdditional && hasBu === 0 && isAdditionalFlag;
    if (hasBu > -1) {
      if (showAdditionalText) {
        return `calculator.nasm.beneficial.use.text.0.additional`;
      } else {
        return `calculator.nasm.beneficial.use.text.${~~hasBeneficialUse}`;
      }
    }
    return defaultText;
  }

  flagPathogenContent$(materialSampleId: string): Observable<FlagVo> {
    return this.materialSample$(materialSampleId).pipe(map((ms: MaterialSample) => ms?.flagPathogenContent));
  }

  flagHeavyMetalContent$(materialSampleId: string): Observable<FlagVo> {
    return this.materialSample$(materialSampleId).pipe(map((ms: MaterialSample) => ms?.flagHeavyMetalContent));
  }

  beneficialUse$(materialSampleId: string): Observable<MaterialSampleBeneficialUse> {
    return this.materialSample$(materialSampleId).pipe(map((ms: MaterialSample) => ms?.beneficialUse));
  }

  materialSample$(materialSampleId: string): Observable<MaterialSample> {
    return this.materialType$?.pipe(
      map((mt: MaterialTypeCalculation) => mt?.materialSamples?.find((sp: MaterialSample) =>
        Utils.matchStr(sp?.id, materialSampleId))));
  }

  get materialType$(): Observable<MaterialTypeCalculation> {
    return this.calculation$.pipe(
      map((cal: NasmCalculation) => cal?.materialTypeCalculations?.
        find((mt: MaterialTypeCalculation) =>
          Utils.matchStr(mt?.id, this.currentMaterialType?.controls?.id?.value))));
  }

  get materialForm$(): Observable<MaterialForm> {
    return this.materialType$.pipe(map((mt: MaterialTypeCalculation) => mt?.materialForm));
  }

  get calculation$() {
    return this.calculationService.calculation$;
  }

  get form() {
    return this.formService.f?.controls;
  }

  get currentMaterialType(): UntypedFormGroup {
    return (this.form?.materialTypes as UntypedFormArray)?.controls?.find((mt: UntypedFormGroup) =>
      Utils.matchStr(mt?.controls?.id?.value, this.form?.currentMaterialTypeId?.value)) as UntypedFormGroup;
  }
}