import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class MDSActualSetback extends BaseModel {
  setbackType: number;
  setbackBarnInMetric: number;
  setbackStorageInMetric: number;
  setbackAnaerobicDigestorInMetric: number;
  calculationId: string;

  constructor(init?: Partial<MDSActualSetback>) {
    super();
    this.id = Utils.uuid();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      setbackType: new UntypedFormControl(this.setbackType),
      setbackBarnInMetric: new UntypedFormControl(this.setbackBarnInMetric),
      setbackStorageInMetric: new UntypedFormControl(this.setbackStorageInMetric),
      setbackAnaerobicDigestorInMetric: new UntypedFormControl(this.setbackAnaerobicDigestorInMetric),
      calculationId: new UntypedFormControl(this.calculationId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new MDSActualSetback({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      setbackType: v.setbackType,
      setbackBarnInMetric: v.setbackBarnInMetric,
      setbackStorageInMetric: v.setbackStorageInMetric,
      setbackAnaerobicDigestorInMetric: v.setbackAnaerobicDigestorInMetric,
      calculationId: v.calculationId
    });
  }
}

