import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '@shared/constants/array-constants';
import { MaterialTypeCalculation } from '@fmp/model/fmp-calculation.model';
import { FmpFieldInput } from '@fmp/model/fmp-field-input.model';
import { FmpMaterialApplication } from '@fmp/model/fmp-material-application.model';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { LanguageType } from '../models/common/language-type.enum';

@Pipe({ name: 'fmpMaterialFormPipe' })
export class FmpMaterialFormPipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private cache: CacheService) { }

  transform(element: FmpFieldInput, calculations?: MaterialTypeCalculation[]): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const languageJson: any = this.cache[lang];
        const materialTypeId: string = (element?.app as FmpMaterialApplication)?.materialTypeId;
        const searchArr: any[] = calculations ? calculations : element?.materialTypes;
        const mt = searchArr?.find(mt => Utils.matchStr(mt.id, materialTypeId));
        if (mt) {
          const key = ArrayConstants.MATERIAL_FORM_OPTIONS?.find(v => v.id === mt?.materialForm)?.i18nkey;
          return languageJson[key];
        }
        return undefined;
      })
    );
  }
}
