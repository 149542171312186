export class DbUser {
    id: string;
    createdBy: string;
    updatedBy: string;
    createdDateTime: string;
    updatedDateTime: string;
    userAccountName: string;
    langPreference: string;
    description: string;
    notes: string;
    measureSystemName: string;
    worksheetTypeId: string;
    constructor(langPreference: string, measureSystemName: string) {
        this.measureSystemName = measureSystemName;
        this.langPreference = langPreference;
    }
}

/*
{"id":"19EBD9B1-7254-4772-942D-ED9E169FCFE4",
"createdBy":"system",
"updatedBy":"lambertwen@gmail.com",
"createdDateTime":"2019-05-20T00:00:00.000+0000",
"updatedDateTime":"2019-05-20T00:00:00.000+0000",
"userAccountName":"jacklu0918@hotmail.com",
"langPreference":"en",
"description":"test account",
"notes":"test",
"measureSystemName":"Metric"}
*/
