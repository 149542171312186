import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { Label } from '@shared/models/common/label.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { URLs } from '../../core/conf/urls';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class LabelClient {
  onUpdate = new BehaviorSubject(undefined);

  constructor(private httpClient: HttpClient, private cache: CacheService) {}

  next() {
    this.onUpdate.next(Utils.uuid());
  }

  all(): Observable<Label[]> {
    const url = `${URLs.WORKSHEET_LABEL_ENDPOINT}/user-account-id/${this.cache.user?.id}`;
    return this.httpClient.get(url) as Observable<Label[]>;
  }

  get(id: string): Observable<Label> {
    return this.httpClient.get(URLs.WORKSHEET_LABEL_ENDPOINT) as Observable<Label>;
  }

  update(label: Label): Observable<Label> {
    const url = `${URLs.WORKSHEET_LABEL_ENDPOINT}/${label.id}`;
    return this.httpClient.put(url, label) as Observable<Label>;
  }

  save(label: Label): Observable<Label> {
    const url = `${URLs.WORKSHEET_LABEL_ENDPOINT}`;
    return this.httpClient.post(url, label) as Observable<Label>;
  }

  delete(id: string) {
    const url = `${URLs.WORKSHEET_LABEL_ENDPOINT}/${id}`;
    return this.httpClient.delete(url) as Observable<Label>;
  }
}
