import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: 'input:not([type=number]), mat-checkbox'
})
export class FormConfigDirective {
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event?.key === 'Enter') {
      event.preventDefault();
    }
  }
}
