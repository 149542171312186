import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class ContourStage extends BaseModel {
  controlStructureId: string;
  elevationInMetric: number;
  areaInMetric: number;

  constructor(init?: Partial<ContourStage>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      controlStructureId: new UntypedFormControl(this.controlStructureId),
      elevationInMetric: new UntypedFormControl(this.elevationInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      areaInMetric: new UntypedFormControl(this.areaInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      })
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new ContourStage({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      controlStructureId: v.controlStructureId,
      elevationInMetric: v.elevationInMetric,
      areaInMetric: v.areaInMetric
    });
  }
}
