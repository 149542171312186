export enum OperationType {
  SoleProprietorship = 0,
  PartnershipJointSubmission = 1,
  DivisionOfCorporation = 2,
  Corporation = 3
}

export const LegalFarmNameOperationTypes: OperationType[] = [
  OperationType.Corporation,
  OperationType.DivisionOfCorporation
];