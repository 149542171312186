import { AfterContentInit, Directive, ElementRef } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[autoFocusByContent]'
})
export class AutofocusByContentDirective implements AfterContentInit {

  constructor(private el: ElementRef) { }

  ngAfterContentInit() {
    this.el.nativeElement.focus();
  }
}
