<!--This is a common delete confirm dialog taking one argument. 
	Please define type and create respective content each type-->
<article>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "dialog.title.delete" | translate | async }}
    </h1>

    <!-- Delete a farm-->
    <div mat-dialog-content *ngIf="type === key.Farm">
        <p>
            {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
        </p>
        <p>
            {{ "dialog.message.delete.farm" | translate | async }}
        </p>
    </div>

    <!-- Delete a field-->
    <div mat-dialog-content *ngIf="type === key.Field">
        <p>
            {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
        </p>
        <p>
            {{ "dialog.message.delete.field" | translate | async }}
        </p>
    </div>

    <!-- Delete a material application-->
    <div mat-dialog-content *ngIf="type === key.MaterialApplication">
        <p>
            <ng-container *ngIf="arg0">
                {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
            </ng-container>
            <ng-container *ngIf="!arg0 && !arg1"> {{ "calculator.plato.delete.popup.text" | translate | async }}{{ (lang$ | async) === "en" ? "MaterialApp" : "Épan matière" }}? </ng-container>
        </p>
    </div>

    <!-- Delete a fertilizer application / material type / transfer contact -->
    <div mat-dialog-content *ngIf="isSimpleType(type)">
        <p>
            {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
        </p>
    </div>

    <!-- Delete a crop application-->
    <div mat-dialog-content *ngIf="type === key.CropApplication">
        <p>
            {{ "dialog.message.delete.message" | translate | async }}
            {{ cropTypeId | cropNamePipe : "TYPE" | async }}, {{ cropSubTypeId | cropNamePipe : "SUB_TYPE" | async }}?
        </p>
    </div>

    <!-- Delete a storage system-->
    <div mat-dialog-content *ngIf="type === key.StorageSystem">
        <p>
            {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
        </p>
        <p *ngIf="isGhg">
            {{ "dialog.message.delete.livestock.system" | translate | async }}
        </p>
        <p *ngIf="!isGhg">
            {{ "dialog.message.delete.storage.system" | translate | async }}
        </p>
    </div>

    <!-- Delete a MDS calculation -->
    <div mat-dialog-content *ngIf="type === key.MdsCalculation">
        <p>
            {{ "dialog.message.delete.arg0" | translate : [arg0] | async }}
        </p>
        <p>
            {{ "dialog.message.delete.mds.calculation" | translate | async }}
        </p>
    </div>

    <!-- Delete a NASM component -->
    <div mat-dialog-content *ngIf="type === key.NasmComponent">
        <p>{{ "dialog.message.delete.message" | translate | async }} {{ arg$ | async }}</p>
    </div>

    <!-- Delete a NASM component -->
    <div mat-dialog-content *ngIf="type === key.Building">
        <p>{{ "dialog.message.delete.message" | translate | async }} {{ arg0 }} ?</p>
    </div>

    <!-- Delete a watershed -->
    <div mat-dialog-content *ngIf="type === key.Watershed">
        <p>{{ i18nKey | translate | async }} {{ arg0 }} ?</p>
    </div>

    <!-- Delete a runoff curve -->
    <div mat-dialog-content *ngIf="type === key.RunoffCurve">
        <p>{{ i18nKey | translate : [arg0] | async }} ?</p>
    </div>

    <!-- Delete a control structure -->
    <div mat-dialog-content *ngIf="type === key.ControlStructure">
        <p>{{ i18nKey | translate | async }} {{ arg0 }} ?</p>
    </div>

    <!-- Delete a contour stage -->
    <div mat-dialog-content *ngIf="type === key.ContourStage">
        <p>{{ i18nKey | translate | async }} ?</p>
    </div>

    <!-- Material Type undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.MaterialType">
        <p>
            {{ "calculator.fmp.material.type.undeletable" | translate | async }}
        </p>
    </div>

    <!-- Transfer contact undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.TransferContact">
        <p>
            {{ "calculator.ss.contact.undeletable" | translate | async }}
        </p>
    </div>

    <!-- Soil sample undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.SoilSample">
        <p>
            {{ "calculator.fmp.soil.sample.undeletable" | translate | async }}
        </p>
    </div>

    <!-- MDS calculation undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.MdsCalculation">
        <p>
            {{ "calculator.mds.calculation.undeletable" | translate | async }}
        </p>
    </div>

    <!-- NASM material component undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.NasmComponent">
        <p>
            {{ "calculator.nasm.mt.comp.undeletable" | translate | async }}
        </p>
    </div>

    <!-- NASM material sample undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.MaterialSample">
        <p>
            {{ "calculator.nasm.ms.undeletable" | translate | async }}
        </p>
    </div>

    <!-- Storage system undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.StorageSystem">
        <p>
            {{ arg0[lang$ | async] }}
        </p>
    </div>

    <!-- Watershed undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.Watershed">
        <p>
            {{ i18nKey | translate | async }}
        </p>
    </div>

    <!-- Control structure undeletable -->
    <div mat-dialog-content *ngIf="type === undeletableKey.ControlStructure">
        <p>
            {{ i18nKey | translate | async }}
        </p>
    </div>

    <!-- Action buttons -->
    <ng-container *ngIf="showDeleteBtn; else showCloseBtn">
        <div mat-dialog-actions class="flex flex--end-vertical">
            <div class="flex-child flex-child--flex-shrink">
                <button type="button" (click)="delete()" mat-stroked-button class="button button--red-primary">
                    {{ "dialog.action.delete" | translate | async }}
                </button>
            </div>
            <div class="flex-child flex-child--flex-shrink">
                <button type="button" (click)="cancel()" mat-stroked-button class="button button--black-secondary">
                    {{ "dialog.action.cancel" | translate | async }}
                </button>
            </div>
        </div>
    </ng-container>
    <ng-template #showCloseBtn>
        <div mat-dialog-actions class="flex flex--end-vertical">
            <div class="flex-child flex-child--flex-shrink">
                <button type="button" (click)="cancel()" mat-stroked-button class="button button--black-primary">
                    {{ "dialog.action.close" | translate | async }}
                </button>
            </div>
        </div>
    </ng-template>
</article>
