import { WaterwayShape } from '@ag-erosion/enum/waterway-shape.enum';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class IsbashCurve extends BaseModel {
  controlStructureId: string;
  useVelocityCalculator = false;
  velocityInMetric: number;
  waterwayShape = WaterwayShape.Triangle;
  dimensionDepthInMetric: number;
  dimensionTopWidthInMetric: number;
  dimensionBottomWidthInMetric: number;
  dimensionSideSlope: number;
  coefficientOfRoughness: number;
  slope: number;

  constructor(init?: Partial<IsbashCurve>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      controlStructureId: new UntypedFormControl(this.controlStructureId),
      useVelocityCalculator: new UntypedFormControl(this.useVelocityCalculator),
      velocityInMetric: new UntypedFormControl(this.velocityInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      waterwayShape: new UntypedFormControl(this.waterwayShape),
      dimensionDepthInMetric: new UntypedFormControl(this.dimensionDepthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionTopWidthInMetric: new UntypedFormControl(this.dimensionTopWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionBottomWidthInMetric: new UntypedFormControl(this.dimensionBottomWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionSideSlope: new UntypedFormControl(this.dimensionSideSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      coefficientOfRoughness: new UntypedFormControl(this.coefficientOfRoughness, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      slope: new UntypedFormControl(this.slope, {
        validators: [Validators.min(0), Validators.max(100)]
      })
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new IsbashCurve({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      controlStructureId: v.controlStructureId,
      useVelocityCalculator: v.useVelocityCalculator,
      velocityInMetric: v.velocityInMetric,
      waterwayShape: v.waterwayShape,
      dimensionDepthInMetric: v.dimensionDepthInMetric,
      dimensionTopWidthInMetric: v.dimensionTopWidthInMetric,
      dimensionBottomWidthInMetric: v.dimensionBottomWidthInMetric,
      dimensionSideSlope: v.dimensionSideSlope,
      coefficientOfRoughness: v.coefficientOfRoughness,
      slope: v.slope
    });
  }
}
