import { ChangeDetectionStrategy, Component, Injector, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FlagVo } from '@shared/models/flag/flag-vo.model';
import { Observable } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { FlagComment } from '../../models/flag/flag-comment.model';
import { SharedFlagCommentService } from '../../services/shared-flag-comment.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-flag-comment-input',
  templateUrl: './flag-comment-input.component.html',
  styleUrls: ['./flag-comment-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagCommentInputComponent extends BaseComponent implements OnInit {

  @Input() calculatedFlag: FlagVo;
  @Input() events: Observable<void>;
  f: UntypedFormGroup;

  constructor(private injector: Injector,
    private route: ActivatedRoute,
    private sharedFlagCommantService: SharedFlagCommentService) {
    super(injector);
  }

  ngOnInit(): void {
    this.loadComment();
  }

  syncData() {
    if (this.f.valid) {
      return this.sharedFlagCommantService.update(this.f.value).
        pipe(takeWhile(() => this.alive));
    }
  }

  private loadComment() {
    if (this.calculatedFlag && !this.comment) {
      this.route.queryParams.pipe(takeWhile(() => this.alive)).subscribe(params => {
        // obtain worksheet id from page route
        const worksheetId = params.worksheetId;
        const comment = new FlagComment(undefined, this.calculatedFlag, worksheetId);
        this.f = comment.toForm();
        const compositeKey = {
          entityId: comment.entityId,
          flagType: comment.flagType,
          workspaceId: comment.worksheetId
        };
        this.sharedFlagCommantService.getByCompositeKey(compositeKey).
          pipe(takeWhile(() => this.alive))
          .subscribe(res => {
            if (res) {
              this.f = new FlagComment(res).toForm();
              this.changeDetectorRef.markForCheck();
            }
          });
      });
    }
  }

  get comment() {
    return this.f ? this.f.controls.comment : undefined;
  }
}
