import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { HydrologicSoilGroup } from './hydrologic-soil-group.enum';

export class RunoffCurve extends BaseModel {
  hydrologicSoilGroup = HydrologicSoilGroup.D;
  runoffCurveId = Constants.DEFAULT_AGE_RUNOFF_CURVE_LAND_USE_COVER;
  percentageOfWatershed: number;
  watershedId: string;

  constructor(init?: Partial<RunoffCurve>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      hydrologicSoilGroup: new UntypedFormControl(this.hydrologicSoilGroup),
      runoffCurveId: new UntypedFormControl(this.runoffCurveId),
      percentageOfWatershed: new UntypedFormControl(this.percentageOfWatershed, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      watershedId: new UntypedFormControl(this.watershedId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new RunoffCurve({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      hydrologicSoilGroup: v.hydrologicSoilGroup,
      runoffCurveId: v.runoffCurveId,
      percentageOfWatershed: v.percentageOfWatershed,
      watershedId: v.watershedId
    });
  }
}
