export const environment: any = {
  serviceSubscriptionKey: '78387a4c5d9c4a149bcb31e0cb4cf3d7',
  isProdEnv: false,
  production: true,
  flagging: true,
  clientId: '7e05ca88-e198-4aef-969b-c325fd385cfe',
  authDomain: 'agrisuiteist.b2clogin.com',
  susiAuthority: 'https://agrisuiteist.b2clogin.com/agrisuiteist.onmicrosoft.com/B2C_1_susi_v2',
  forgotPasswordAuthority: 'https://agrisuiteist.b2clogin.com/agrisuiteist.onmicrosoft.com/B2C_1_reset_password_v2',
  signUpAuthority: 'https://agrisuiteist.b2clogin.com/agrisuiteist.onmicrosoft.com/B2C_1_signup_v2',
  i18nDirectory: './assets/i18n/',
  apiEndpointRoot: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/',
  apiEndpointLocationV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/location/api/v1/location/',
  apiEndpointWorksheetV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/worksheet/api/v1/worksheet/',
  apiEndpointMaterialV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/material/api/v1/material/',
  apiEndpointAGErosionV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/agerosion/api/v1/agerosion/',
  apiEndpointMDSV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/mds/api/v1/mds/',
  apiEndpointFlagV1: 'https://test.ws.apim.agrisuite.omafra.gov.on.ca/common/api/v1/flag/',
  worksheetPutThrottle: 100
};
