export enum ContactInfoType {
  Transfer = "transfer",
  Applicant = "applicant",
  Preparer = "preparer",
  Operator = "operator",
  Owner = "owner",
  Facility = "facility"
}

export const ContactTypeWithNameFields: ContactInfoType[] = [
  ContactInfoType.Applicant,
  ContactInfoType.Preparer,
  ContactInfoType.Operator,
  ContactInfoType.Owner
];