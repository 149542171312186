import { AgErosionWorksheet } from '@ag-erosion/model/ag-erosion-worksheet.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { ReportType } from '@nasm/model/nasm-report-type.enum';
import { FormService } from '@shared/services/form.service';
import { distinctUntilChanged, EMPTY, of, startWith, switchMap, tap } from 'rxjs';
import { AgErosionService } from './ag-erosion.service';

@Injectable({ providedIn: 'root' })
export class AgErosionCalculationService extends CalculationService {
  constructor(protected formService: FormService, protected httpClient: HttpClient, private agErosionService: AgErosionService) {
    super(httpClient, formService);
  }

  calculate(calcuationType?: ReportType) {
    const model = new AgErosionWorksheet().toModel(this.formService.f);
    this.input$.next(model);
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data => (data ? Utils.errorsomeStream(this.httpClient.post(URLs.AGE_CALCULATION_ENDPOINT, data)) : EMPTY)),
      tap((res: any) => {
        this.calculation$.next(res);
        this.agErosionService.displayReport$ = of(true);
      })
    );
  }
}
