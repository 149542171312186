import { Pipe, PipeTransform } from '@angular/core';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Pipe({ name: 'materialAmountUnitPipe' })
export class MaterialAmountUnitPipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  transform(value: any, materialForm$: Observable<MaterialForm>): Observable<any> {
    const measurementSystem$ = this.measurementService.measurementSystem$;
    this.unitConverterService.from = MeasurementSystem.Metric;
    return measurementSystem$.pipe(
      tap((to: any) => this.unitConverterService.to = to),
      switchMap(() => materialForm$),
      switchMap((materialForm) => {
        switch (materialForm) {
          case MaterialForm.Liquid:
            // tslint:disable-next-line: max-line-length
            return of(this.unitConverterService.unitTypes[ConversionConstants.UNIT_TYPE_LIQUID_VOLUME][MeasurementSystem[this.unitConverterService.to]]);
          case MaterialForm.Solid:
            return of(this.unitConverterService.unitTypes[ConversionConstants.UNIT_TYPE_MASS_LARGE][MeasurementSystem[this.unitConverterService.to]]);
        }
      }));
  }
}

