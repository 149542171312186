import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-trend-arrow',
  templateUrl: './trend-arrow.component.html',
  styleUrls: ['./trend-arrow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TrendArrowComponent extends BaseComponent {
  @Input() changePercent: any;
  @Input() alwaysShow: boolean = false;

  hasPercentChange(): boolean {
    return Utils.isValidValue(this.changePercent) && this.changePercent !== 'NaN' && Number(this.changePercent) !== 0;
  }
}
