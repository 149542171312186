import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MapConstants } from '@shared/constants/map-constants';
import { Observable } from 'rxjs';
import { URLs } from '../../core/conf/urls';
import { Worksheet } from '../models/worksheet/Worksheet';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class WorksheetClient {
  constructor(private httpClient: HttpClient, private cache: CacheService, private router: Router, private msalService: MsalService) {}

  public all(azureUser: string): Observable<Worksheet[]> {
    const url = `${URLs.WORKSHEET_ENDPOINT}/username/${azureUser}`;
    return this.httpClient.get(url) as Observable<Worksheet[]>;
  }

  public get(id: string, typeId: string) {
    const type = this.cache.worksheetTypes.find(t => t.id === typeId);
    const url = `${URLs.getEndPoint(type.typeName)}/${id}`;
    return this.httpClient.get(url);
  }

  public save(worksheet: Worksheet): Observable<Worksheet> {
    const type = this.cache.worksheetTypes.find(t => t.id.toLowerCase() === worksheet.worksheetTypeId.toLowerCase());
    const url = URLs.getEndPoint(type.typeName);
    return this.httpClient.post(url, worksheet) as Observable<Worksheet>;
  }

  public copy(worksheet: Worksheet, srcWorksheetId: string): Observable<Worksheet> {
    const type = this.cache.worksheetTypes.find(t => t.id.toLowerCase() === worksheet.worksheetTypeId.toLowerCase());
    const url = `${URLs.getEndPoint(type.typeName)}/copy/${srcWorksheetId}`;
    let params = new HttpParams();
    params = params.append('name', worksheet.name);
    return this.httpClient.post(url, worksheet, { params }) as Observable<Worksheet>;
  }

  public export(worksheetId: string): Observable<any> {
    return this.httpClient.get(`${URLs.WORKSHEET_EXPORT_ENDPOINT}/json/${worksheetId}`, { responseType: 'blob' });
  }

  public import(worksheet: Worksheet): Observable<Worksheet> {
    const type = this.cache.worksheetTypes.find(t => t.id.toLowerCase() === worksheet.worksheetTypeId.toLowerCase());
    const url = URLs.getImportEndPoint(type.typeName);
    return this.httpClient.post(url, worksheet) as Observable<Worksheet>;
  }

  public update(worksheet: Worksheet): Observable<Worksheet> {
    const type = this.cache.worksheetTypes.find(t => t.id === worksheet.worksheetTypeId);
    const url = `${URLs.getEndPoint(type.typeName)}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet) as Observable<Worksheet>;
  }

  public updateFlat(worksheet: Worksheet): Observable<Worksheet> {
    const url = `${URLs.WORKSHEET_ENDPOINT}/flat/${worksheet.id}`;
    return this.httpClient.put(url, worksheet) as Observable<Worksheet>;
  }

  public delete(id: string): Observable<any> {
    const url = `${URLs.WORKSHEET_ENDPOINT}/${id}`;
    return this.httpClient.delete(url) as Observable<any>;
  }

  public convert(src: Worksheet, dest: Worksheet): Observable<Worksheet> {
    const conversionEndpoint = MapConstants.WORKSHEET_CONVERSION[src.worksheetTypeId + dest.worksheetTypeId];
    const url = `${conversionEndpoint}/${src.id}`;
    return this.httpClient.post(url, dest) as Observable<Worksheet>;
  }
}
