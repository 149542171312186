import { Pipe, PipeTransform } from '@angular/core';
import { NitrogenCredit } from '@crop-nutrient/model/nitrogen-credit';
import { SoilTexture } from '@crop-nutrient/model/soil-texture';
import { FertilizerProductOption } from '@fertilizer/model/fertilizer-product-option.model';
import { MaterialApplicationIncorporation } from '@organic-amendment/model/material-application-incorporation.model';
import { MaterialApplicationMethod } from '@organic-amendment/model/material-application-method.model';
import { LanguageService } from '@shared/services/language.service';
import { EMPTY, Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { LanguageType } from '../models/common/language-type.enum';
import { SoilSerie } from '../models/crop/soil-serie';
import { TillageMethod } from '../models/crop/tillage-method';
import { TillagePractice } from '../models/crop/tillage-practice';
import { FertilizerApplicationIncorporation } from '../models/material/fertilizer-application-incorporation.model';
import { MaterialDryMatterRange } from '../models/material/material-dry-matter-range';
import { MaterialTypeOption } from '../models/material/material-type-option';
import { CacheService } from '../services/cache.service';

@Pipe({ name: 'optionNamePipe' })
// This pipe is not recommended for reactive views as it causes performance issues
export class OptionNamePipe implements PipeTransform {

    constructor(private cache: CacheService, private languageService: LanguageService) { }

    transform(value: number, optionType: string): Observable<any> {
        return this.languageService.languageType$.pipe(concatMap((lang: LanguageType) => {
            if (value || value === 0) {
                switch (optionType) {
                    case 'MaterialType':
                        const mt = this.cache.allMaterialTypes.find((v: MaterialTypeOption) => v.materialTypeId === value);
                        return of(lang === LanguageType.English ? mt.name.en : mt.name.fr);
                    case 'DryMatterRange':
                        const dm = this.cache.allDryRanges.find((v: MaterialDryMatterRange) => v.dryMatterRangeId === value);
                        return of(lang === LanguageType.English ? dm.description.en : dm.description.fr);
                    case 'SoilSeries':
                        const ss = this.cache.soilSeries.find((v: SoilSerie) => v.soilSeriesId === value);
                        return of(ss.name);
                    case 'HidroSoilGroup':
                        const s = this.cache.soilSeries.find((v: SoilSerie) => v.soilSeriesId === value);
                        return of(s.hydrologicSoilGroup);
                    case 'SoilTexture':
                        if (value === -1) {
                            break;
                        }
                        const st = this.cache.allSoilTextures.find((v: SoilTexture) => v.soilTextureId === value);
                        return of(lang === LanguageType.English ? st.name.en : st.name.fr);
                    case 'TillageMethod':
                        const tm = this.cache.allTillageMethods.find((v: TillageMethod) => v.tillageMethodId === value);
                        return of(lang === LanguageType.English ? tm.description.en : tm.description.fr);
                    case 'TillagePractice':
                        const tp = this.cache.allTillagePractices.find((v: TillagePractice) => v.tillagePracticeId === value);
                        return of(lang === LanguageType.English ? tp.description.en : tp.description.fr);
                    case 'NitrogenCredit':
                        const nc = this.cache.allNitrogenCredits.find((v: NitrogenCredit) => v.nitrogenCreditId === value);
                        return of(lang === LanguageType.English ? nc.description.en : nc.description.fr);
                    case 'FertilizerIncorporation':
                        const fi = this.cache.fertilizerIncorporations
                            .find((v: FertilizerApplicationIncorporation) => v.incorporationId === value);
                        return of(lang === LanguageType.English ? fi.description.en : fi.description.fr);
                    case 'MaterialIncorporation':
                        const mi = this.cache.materialIncorporations
                            .find((v: MaterialApplicationIncorporation) => v.incorporationId === value);
                        return of(lang === LanguageType.English ? mi.description.en : mi.description.fr);
                    case 'FertilizerProduct':
                        const fp = this.cache.fertilizerProducts
                            .find((v: FertilizerProductOption) => v.fertilizerProductId === value);
                        return of(lang === LanguageType.English ? fp.name.en : fp.name.fr);
                    case 'ApplicationMethod':
                        const am = this.cache.applicationMethods
                            .find((v: MaterialApplicationMethod) => v.applicationMethodId === value);
                        return of(lang === LanguageType.English ? am.description.en : am.description.fr);
                    default:
                        return EMPTY;
                }
            }
            return EMPTY;
        }));
    }
}
