import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { AgErosionWorksheet } from '../model/ag-erosion-worksheet.model';

@Injectable({ providedIn: 'root' })
export class AgErosionService {
  displayReport$ = of(false);
  systemExpanded$ = new BehaviorSubject(undefined);
  //todo rename this service to shared-service ...
  constructor(private httpClient: HttpClient, private formService: FormService) {}

  get(id: string): Observable<AgErosionWorksheet> {
    return this.httpClient.get(`${URLs.AG_EROSION_ENDPOINT}/${id}`) as Observable<AgErosionWorksheet>;
  }

  save(worksheet: AgErosionWorksheet): Observable<any> {
    return this.httpClient.post(URLs.AG_EROSION_ENDPOINT, worksheet);
  }

  update(worksheet: AgErosionWorksheet): Observable<any> {
    const url = `${URLs.AG_EROSION_ENDPOINT}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet);
  }

  updateWorksheetFlat(worksheet: AgErosionWorksheet): Observable<any> {
    const url = `${URLs.AG_EROSION_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    params = params.append('ignore', 'child-elements');
    return this.httpClient.put(url, worksheet, { params });
  }

  get f() {
    return this.formService.f;
  }
}
