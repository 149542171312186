<article>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "dialog.title.delete.all.field.inputs" | translate | async }}
    </h1>

    <div mat-dialog-content>
        <p>
            {{ "dialog.message.delete.all.field.inputs.p1" | translate | async }}
        </p>
        <p>
            {{ "dialog.message.delete.all.field.inputs.p2" | translate | async }}
        </p>

        <mat-form-field appearance="outline" class="width--100-percent" color="accent">
            <mat-label>
                {{ "dialog.label.delete.all.field.inputs" | translate | async }}
            </mat-label>

            <mat-select [value]="target.value" (selectionChange)="target.patchValue($event.value)">
                <mat-option *ngFor="let year of years" [value]="year">
                    {{ year | croppingYearRangePipe | async }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div mat-dialog-actions class="flex flex--cols-2 flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--red-primary" (click)="delete()">
                {{ "dialog.action.delete" | translate | async }}
            </button>
        </div>

        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--black-secondary" (click)="close()">
                {{ "dialog.action.cancel" | translate | async }}
            </button>
        </div>
    </div>
</article>
