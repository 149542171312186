import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class WorksheetPreviousSubmission extends BaseModel {
  worksheetId: string;
  submissionId: string;

  constructor(init?: Partial<WorksheetPreviousSubmission>) {
    super();
    this.id = Utils.uuid();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      submissionId: new UntypedFormControl(this.submissionId, { validators: [Validators.maxLength(15)] })
    })
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new WorksheetPreviousSubmission({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      submissionId: v.submissionId
    });
  }
}