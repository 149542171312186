
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { OrganicAmendmentFieldSection } from './organic-amendment-field-section.model';

export class OrganicAmendmentField extends BaseModel {
    farmId: string;
    name: string;
    fieldSections: OrganicAmendmentFieldSection[] = [];

    constructor(init?: Partial<OrganicAmendmentField>) {
        super();
        this.name = this.isEn ? 'Field 1' : 'Champ 1';
        this.fieldSections[0] = new OrganicAmendmentFieldSection();
        Object.assign(this, init);
    }
}
