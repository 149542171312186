import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NasmMaterialSampleTestValue } from '@nasm/model/nasm-calculation.model';
import { NutrientValueBasis } from '@shared/models/common/nutrient-value-basis.enum';
import { Observable, of } from 'rxjs';
import { DecimalPipe } from './decimal.pipe';
import { LessThanPipe } from './less-than.pipe';
import { TranslatePipe } from './translate.pipe';

@Pipe({ name: 'msTestValuePipe' })
export class MaterialSampleTestValuePipe implements PipeTransform {

  constructor(private translatePipe: TranslatePipe,
    private decimalPipe: DecimalPipe,
    private lessThanPipe: LessThanPipe) { }

  isWetBasis(ms: UntypedFormGroup) {
    return ms?.controls?.nutrientValueBasis?.value === NutrientValueBasis.Wet;
  }

  testValue(materialSample: UntypedFormGroup, testValueCal: NasmMaterialSampleTestValue) {
    if (testValueCal.isNotDetected) {
      return this.translatePipe.transform('calculator.nasm.tv.not.detected');
    }
    const value = this.isWetBasis(materialSample) ? testValueCal.testValueWetBasis : testValueCal.testValueDryBasis;
    const valWithPrecision = this.decimalPipe.transform(value, testValueCal.precision);
    return of(`${this.lessThanPipe.transform(valWithPrecision, testValueCal.isLessThan)} ${testValueCal.unit}`);
  }

  transform(testValuCal: NasmMaterialSampleTestValue, materialSample: UntypedFormGroup): Observable<string> {
    return this.testValue(materialSample, testValuCal);
  }
}
