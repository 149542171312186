import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Inject,
  Injector,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { Utils } from '@core/utilities/utils';
import { ArrayConstants } from '@shared/constants/array-constants';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import {
  AgePreSelectedFlagLevelTypes,
  DefaultPreSelectedFlagLevelTypesInComponent,
  MdsPreSelectedFlagLevelTypes
} from '@shared/models/flag/flag-level-type.enum';
import { FlagSummaryFilter } from '@shared/models/flag/flag-summary-filter.model';
import { EMPTY, Observable, of } from 'rxjs';
import { startWith, takeWhile } from 'rxjs/operators';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-flag-summary',
  templateUrl: './flag-summary.component.html',
  styleUrls: ['./flag-summary.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlagSummaryComponent extends BaseComponent implements OnInit, AfterViewInit {
  calculatedFlags$: any;
  displayNmsFlagsOnly$: Observable<any>;

  filterForm: UntypedFormGroup;

  constructor(private injector: Injector, public dialogRef: MatDialogRef<FlagSummaryComponent>, @Inject(MAT_DIALOG_DATA) data: any) {
    super(injector);
    this.calculatedFlags$ = data ? data.calculatedFlags$ : EMPTY;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.bindForm();
    this.bindDisplayNmsFlagsOnlyListener();
  }

  ngAfterViewInit() {
    AODADialog.applyAllFixes();
  }

  bindDisplayNmsFlagsOnlyListener() {
    this.displayNmsFlagsOnly.valueChanges
      .pipe(
        takeWhile(() => this.alive),
        startWith(this.displayNmsFlagsOnly.value)
      )
      .subscribe(value => (value ? (this.displayNmsFlagsOnly$ = of(value)) : (this.displayNmsFlagsOnly$ = of(false))));
  }

  close(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.close();
  }

  private bindForm() {
    this.filterForm = new FlagSummaryFilter(undefined, this.worksheetTypeId.value).toForm();
  }

  get sections() {
    return this.filterForm ? this.filterForm.controls.sections : undefined;
  }

  get firstSection() {
    if (this.sections && this.sections.value && this.sections.value.length > 0) {
      const flagLevelId = this.sections.value[0];
      const option = ArrayConstants.FLAG_LEVELS.find(v => v.displayOrder === 1);
      return option ? option.i18nkey : undefined;
    }
    return undefined;
  }

  get preSelctedFlagLevelTypes() {
    if (this.isMds) return MdsPreSelectedFlagLevelTypes;
    if (this.isAge) return AgePreSelectedFlagLevelTypes;
    return DefaultPreSelectedFlagLevelTypesInComponent;
  }

  get filterSections() {
    return ArrayConstants.FLAG_LEVELS.filter(v => v.active === true && this.preSelctedFlagLevelTypes.includes(v.flagLevelId));
  }

  get displayNmsFlagsOnly() {
    return this.filterForm ? this.filterForm.controls.displayNmsFlagsOnly : undefined;
  }

  get isNmspWorksheet() {
    return this.worksheetTypeId && Utils.matchStr(this.worksheetTypeId.value, WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN);
  }

  get isMds() {
    return (
      this.worksheetTypeId &&
      (Utils.matchStr(this.worksheetTypeId.value, WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1) ||
        Utils.matchStr(this.worksheetTypeId.value, WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2))
    );
  }

  get isAge() {
    return this.formService?.isAgErosionWorksheet;
  }
}
