import { Injectable } from '@angular/core';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class I18nService {

  constructor(private cache: CacheService) { }

  translate(key: string, lang: any = 'en'): any {
    const json = this.cache[lang];
    return json[key];
  }
}
