import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { SoilTestValue } from '@shared/models/worksheet/soil-test-value.model';

export class PlatoSoilSample extends BaseModel {
    name: string;
    fieldSectionId: string;
    soilTestValues: SoilTestValue[];

    constructor() {
        super();
        this.name = null;
        this.soilTestValues = [];
        this.soilTestValues[0] = new SoilTestValue();
        this.soilTestValues[0].testCode = 'P';
    }
}
