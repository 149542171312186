import { Pipe, PipeTransform } from '@angular/core';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';

enum NodeTitleName {
  Farms = 'Farms',
  Fields = 'Fields',
  FarmFields = 'Farms & fields',
  MaterialTypes = 'Material Types',
  Storages = 'Storages',
  StorageSystems = 'Storage systems',
  TransferContacts = 'Transfer contacts',
  Calculations = 'Calculations',
  Materials = 'Materials',
  Livestock = 'Livestock',
  Buildings = 'Buildings',
  Watersheds = 'Watersheds',
  ControlStructures = 'Control structures'
}

@Pipe({ name: 'nodeTitleName' })
export class NodeTitleNamePipe implements PipeTransform {
  constructor(private cache: CacheService) {}

  transform(value: any, isTitle: boolean, lang: LanguageType): any {
    const languageJson = this.cache[lang];

    if (!isTitle || (isTitle && this.isEnglish(lang))) {
      return value;
    }

    switch (value) {
      case NodeTitleName.Farms:
        return languageJson['tab.farms'];
      case NodeTitleName.Fields:
        return languageJson['tab.fields'];
      case NodeTitleName.FarmFields:
        return languageJson['tab.farm.field'];
      case NodeTitleName.MaterialTypes:
        return languageJson['tab.material.types'];
      case NodeTitleName.Storages:
      case NodeTitleName.StorageSystems:
        return languageJson['tab.storage.systems'];
      case NodeTitleName.TransferContacts:
        return languageJson['tab.transfer.contacts'];
      case NodeTitleName.Calculations:
        return languageJson['tab.calculations'];
      case NodeTitleName.Materials:
        return languageJson['tab.materials'];
      case NodeTitleName.Livestock:
        return languageJson['tab.livestock'];
      case NodeTitleName.Buildings:
        return languageJson['tab.buildings'];
      case NodeTitleName.Watersheds:
        return languageJson['tab.watersheds'];
      case NodeTitleName.ControlStructures:
        return languageJson['tab.control.structures'];
      default:
        return value;
    }
  }

  isEnglish(lang: LanguageType): boolean {
    return lang === LanguageType.English;
  }
}
