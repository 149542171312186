import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { FertilizerFarm } from './fertilizer-farm.model';

export class FertilizerWorksheet extends BaseModel {
    name: string;
    worksheetTypeId = WorksheetTypeIds.FERTILIZER_APPLICATION;
    measureSystemName = 'Imperial';
    langPreference = 'en';
    userAccountId = '';
    worksheetGroupName: string;
    worksheetSubGroupName: string;
    farms: FertilizerFarm[] = [];
    isLocal = true;

    constructor(init?: Partial<FertilizerWorksheet>) {
        super();
        this.name = this.isEn ? 'Fertilizer 1' : 'Engrais 1';
        this.farms[0] = new FertilizerFarm(init);
        Object.assign(this, init);
    }
}
