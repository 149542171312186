import { UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { AlertInputName, DEFAULT_ALERT_INPUTS } from '../common/alert-input-name.enum';
import { FarmFormGroup } from './farms-form-group.model';

export class FarmFieldsFormGroup {
  private _farm: UntypedFormGroup;
  private _fields: UntypedFormArray;

  constructor(farm: UntypedFormGroup, fields: UntypedFormArray) {
    this._farm = farm;
    this._fields = fields;
  }

  getById(fieldId: string): FarmFieldFormGroup {
    const field = this._fields?.controls?.find(v => Utils.matchStr(v?.get('id')?.value, fieldId)) as UntypedFormGroup;
    return new FarmFieldFormGroup(this._farm, field);
  }

  first(): FarmFieldFormGroup {
    return this._fields.length && this._fields.controls[0]
      ? new FarmFieldFormGroup(this._farm, this._fields.controls[0] as UntypedFormGroup)
      : null;
  }

  get length(): number {
    return this._fields.length;
  }
}

export class FarmFieldFormGroup {
  private _farm: UntypedFormGroup;
  private _field: UntypedFormGroup;

  constructor(farm: UntypedFormGroup, field: UntypedFormGroup) {
    this._farm = farm;
    this._field = field;
  }

  get nameCtrl(): UntypedFormControl {
    return this._field?.get('name') as UntypedFormControl;
  }

  get name(): string {
    return this.nameCtrl?.value;
  }

  get fullName(): string {
    const farm = new FarmFormGroup(this._farm);
    return `${farm?.name}, ${this.name}`;
  }

  get farmId(): UntypedFormControl {
    return this._farm?.get('id') as UntypedFormControl;
  }

  get fieldSection(): UntypedFormGroup {
    return this._field?.get('fieldSections.0') as UntypedFormGroup;
  }

  get upperTierCode(): UntypedFormGroup {
    return this._farm?.get('upperTierCode') as UntypedFormGroup;
  }

  get tillableArea(): UntypedFormGroup {
    return this.fieldSection?.get('areas.fieldAreaInMetric') as UntypedFormGroup;
  }

  get crops(): UntypedFormArray {
    return this.fieldSection?.get('crops') as UntypedFormArray;
  }

  get soilSeriesId(): UntypedFormGroup {
    return this.fieldSection?.get('soilSeriesId') as UntypedFormGroup;
  }

  get soilTextureId(): UntypedFormGroup {
    return this.fieldSection?.get('soilTextureId') as UntypedFormGroup;
  }

  get soilTestValueP(): UntypedFormGroup {
    return this.fieldSection?.get('soilSamples.0.P') as UntypedFormGroup;
  }

  get soilTestValueK(): UntypedFormGroup {
    return this.fieldSection?.get('soilSamples.0.K') as UntypedFormGroup;
  }

  get croppingPracticeYears(): UntypedFormControl {
    return this.fieldSection?.get('croppingPracticeYears') as UntypedFormControl;
  }

  get treePlantingRows(): UntypedFormControl {
    return this.fieldSection?.get('treePlantingRows') as UntypedFormControl;
  }

  isMissing(input: AlertInputName): boolean {
    switch (input) {
      case AlertInputName.UpperTierCode:
        return !this.upperTierCode?.value;
      case AlertInputName.TillableArea:
        return !this.tillableArea?.value;
      case AlertInputName.Crops:
        return !this.crops?.controls?.length;
      case AlertInputName.SoilSeries:
        return this.soilSeriesId?.value === -1;
      case AlertInputName.SoilTexture:
        return this.soilTextureId?.value === -1;
      case AlertInputName.SoilTextValueP:
        return !this.soilTestValueP?.value;
      case AlertInputName.SoilTextValueK:
        return !this.soilTestValueK?.value;
      default:
        return false;
    }
  }

  hasMissingWarning(inputs?: AlertInputName[]): boolean {
    const alertInputs = inputs ? inputs : DEFAULT_ALERT_INPUTS;
    return alertInputs.filter(input => this.isMissing(input))?.length > 0;
  }
}
