import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { CropNutrientField } from './crop-nutrient-field.model';

export class CropNutrientFarm extends BaseModel {
  worksheetId: string;
  name: string;
  upperTierName: string;
  upperTierCode: string;
  geotownshipName: string;
  geotownshipId: string;
  fields: CropNutrientField[] = [];

  constructor(init?: Partial<CropNutrientFarm>) {
    super(init);

    this.fields[0] = new CropNutrientField(init?.fields?.[0]);

    this.worksheetId = init?.worksheetId;
    this.name = init?.name || (this.isEn ? 'Farm A' : 'Ferme A');
    this.upperTierName = init?.upperTierName;
    this.upperTierCode = init?.upperTierCode;
    this.geotownshipName = init?.geotownshipName;
    this.geotownshipId = init?.geotownshipId;
  }
}
