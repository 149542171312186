import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Constants } from '@shared/constants/constants';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { DataType } from '@shared/models/common/data-type.enum';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { LocationLot } from '../../mstor/model/location-lot.model';
import { LocationRollNumber } from '../../mstor/model/location-roll-number.model';
import { MdsCalculation } from './mds-calculation.model';

export class MDSBaseWorksheet extends BaseModel {
  worksheetTypeId: WorksheetTypeIds;
  measureSystemName = MeasurementSystem[MeasurementSystem.Imperial];
  langPreference = LanguageType.English;
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  name: string;
  units: number;
  applicationDate: Date;
  fileNumber: number;
  landUseTypeId = Constants.DEFAULT_LAND_USE_TYPE_ID;
  calculations: MdsCalculation[] = [];
  contacts: ContactInformation[] = [];
  locationLot: LocationLot;
  locationRollNumber: LocationRollNumber;
  currentTab = 0;
  currentCalculationId: string;

  constructor(init?: Partial<MDSBaseWorksheet>) {
    super();
    this.id = Utils.uuid();
    this.calculations[0] = init
      ? new MdsCalculation(init.calculations ? init.calculations[0] : undefined)
      : new MdsCalculation();
    this.calculations[0].worksheetId = this.id; // trying this to pass backend validation
    this.calculations[0].worksheetTypeId = this.worksheetTypeId;
    this.contacts[0] = init
      ? new ContactInformation(init.contacts ? init.contacts[0] : undefined) : new ContactInformation();
    this.contacts[1] = init
      ? new ContactInformation(init.contacts ? init.contacts[1] : undefined) : new ContactInformation();
    this.locationLot = init
      ? new LocationLot(init.locationLot ? init.locationLot : undefined) : new LocationLot();
    this.locationRollNumber = init
      ? new LocationRollNumber(init.locationRollNumber ?
        init.locationRollNumber : undefined) : new LocationRollNumber();
    this.applicationDate = Utils.utcNow();
    Object.assign(this, init);

    this.currentCalculationId = this.calculations && this.calculations[0] ? this.calculations[0].id : undefined;

    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(e => {
        e.parentType = DataType.WORKSHEET;
        e.parentId = this.id ? this.id : e.parentId;
      });
    }

    this.locationLot.parentType = DataType.WORKSHEET;
    this.locationLot.parentId = this.id ? this.id : this.locationLot.parentId;
    this.locationRollNumber.parentType = DataType.WORKSHEET;
    this.locationRollNumber.parentId = this.id ? this.id : this.locationLot.parentId;
  }

  toForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      units: new UntypedFormControl(this.units),
      applicationDate: new UntypedFormControl(Utils.utcDateOf(this.applicationDate)),
      fileNumber: new UntypedFormControl(this.fileNumber, { validators: [Validators.maxLength(30)] }),
      landUseTypeId: new UntypedFormControl(this.landUseTypeId),
      calculations: new UntypedFormArray(this.calculations.map(w => new MdsCalculation(w).toForm())),
      currentCalculationId: new UntypedFormControl(this.currentCalculationId),
      contacts: new UntypedFormArray(
        this.contacts.map(w => new ContactInformation(w).toForm()),
        [ValidationFunctions.arrayLength(2), ValidationFunctions.mds1ContactTypes]
      ),
      locationLot: new LocationLot(this.locationLot).toForm(),
      locationRollNumber: new LocationRollNumber(this.locationRollNumber).toForm(),
      currentTab: new UntypedFormControl(this.currentTab)
    });
  }

  toModel(form: UntypedFormGroup): MDSBaseWorksheet {
    const v = form.getRawValue();
    const calculations = form.get('calculations') as UntypedFormArray;
    const contacts = form.get('contacts') as UntypedFormArray;
    const locationLot = form.get('locationLot') as UntypedFormGroup;
    const locationRollNumber = form.get('locationRollNumber') as UntypedFormGroup;
    return new MDSBaseWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      units: v.units,
      applicationDate: v.applicationDate,
      fileNumber: v.fileNumber,
      landUseTypeId: v.landUseTypeId,
      calculations: calculations?.controls.map((w: UntypedFormGroup) => new MdsCalculation().toModel(w)),
      contacts: contacts?.controls.map((w: UntypedFormGroup) => new ContactInformation().toModel(w)),
      locationLot: new LocationLot().toModel(locationLot),
      locationRollNumber: new LocationRollNumber().toModel(locationRollNumber),
      currentTab: v.currentTab,
      currentCalculationId: v.currentCalculationId
    });
  }
}
