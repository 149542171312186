import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CropSubType } from '../../calculators/crop-nutrient/model/crop-sub-type';

@Pipe({
  name: 'yieldPipe'
})
export class YieldPipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  transform(val: any, cropSubType$: Observable<CropSubType>): any {
    this.unitConverterService.from = MeasurementSystem.Metric;

    return combineLatest([this.measurementService.measurementSystem$, cropSubType$]).
      pipe(map(([to, cropSubType]) => {
        this.unitConverterService.to = to;
        if (cropSubType && (val || val === 0)) {
          const converted = this.unitConverterService.convertYield(
            val,
            cropSubType.yieldUnits,
            cropSubType.density
          );
          return converted;
        }
        return !!val ? val : "";
      }));
  }
}
