import { Pipe, PipeTransform } from '@angular/core';
import { CropSubType } from '@crop-nutrient/model/crop-sub-type';
import { CropType } from '@crop-nutrient/model/crop-type';
import { LanguageService } from '@shared/services/language.service';
import { EMPTY, Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { LanguageType } from '../models/common/language-type.enum';
import { CacheService } from '../services/cache.service';

@Pipe({ name: 'cropNamePipe' })
export class CropNamePipe implements PipeTransform {
  constructor(private cache: CacheService, private languageService: LanguageService) {}

  transform(value: number, type: string): Observable<any> {
    return this.languageService.languageType$.pipe(
      concatMap((lang: LanguageType) => {
        let crop;
        if (Utils.matchStr(type, 'TYPE')) {
          crop = this.cache.allCropTypes.find((v: CropType) => v.cropTypeId === value);
        } else if (Utils.matchStr(type, 'SUB_TYPE')) {
          crop = this.cache.allCropSubTypes.find((v: CropSubType) => v.cropId === value);
        } else {
          return EMPTY;
        }
        if (crop) {
          return of(lang === LanguageType.English ? crop.name.en : crop.name.fr);
        }
        return EMPTY;
      })
    );
  }
}
