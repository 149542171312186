import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
export class Crop extends BaseModel {
  fieldSectionId: string;
  name: string;
  cropTypeId = 7;
  cropSubTypeId = 7; // heatUnits is on fieldSection
  yieldInMetric = -1;
  yieldUnit: number;
  croppingYear = Utils.getDefaultCroppingYear();
  plantingDate: string;
  harvestDate: string;
  tillageMethodNotill = false;
  isTillageInFall = false;
  nFertilizerTiming = 0;
  coverCrop = false;
  coverCropHarvested = false;
  previousCropNCreditId = 1;
  isNitrogenSuppliedByManure = false;
  cropPrice: number;
  costOfNitrogen: number;

  constructor(init?: Partial<Crop>) {
    super(init);

    this.fieldSectionId = init?.fieldSectionId;
    this.name = init?.name;
    this.cropTypeId = init?.cropTypeId || 7;
    this.cropSubTypeId = init?.cropSubTypeId || 7;
    this.yieldInMetric = init?.yieldInMetric || -1;
    this.yieldUnit = init?.yieldUnit;
    this.croppingYear = init?.croppingYear || Utils.getDefaultCroppingYear();
    this.plantingDate = init?.plantingDate;
    this.harvestDate = init?.harvestDate;
    this.tillageMethodNotill = init?.tillageMethodNotill || false;
    this.isTillageInFall = init?.isTillageInFall || false;
    this.nFertilizerTiming = init?.nFertilizerTiming || 0;
    this.coverCrop = init?.coverCrop || false;
    this.coverCropHarvested = init?.coverCropHarvested || false;
    this.previousCropNCreditId = init?.previousCropNCreditId || 1;
    this.isNitrogenSuppliedByManure = init?.isNitrogenSuppliedByManure || false;
    this.cropPrice = init?.cropPrice;
    this.costOfNitrogen = init?.costOfNitrogen;
  }
}

// see story 54
