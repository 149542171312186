import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExpansionPanelService {

    selectedPanel$ = new BehaviorSubject(undefined);

    next(id: string) {
        this.selectedPanel$.next(id);
    }
}
