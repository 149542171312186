import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { filter, fromEvent, Subject, takeUntil } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DialogService implements OnDestroy {
  private unsubscribe$ = new Subject<void>();
  constructor(private dialog: MatDialog) {
    // eslint-disable-next-line no-undef
    fromEvent(document, 'keydown')
      .pipe(
        filter((event: KeyboardEvent) => event.code === 'Escape'),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(() => {
        this.closeAll();
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  instance<T>(T, data?: any, config?: any): MatDialogRef<T, MatDialogConfig> {
    const cfg = new MatDialogConfig();
    cfg.width = '450px';
    cfg.panelClass = 'my-dialog';
    cfg.autoFocus = false;
    if (data) {
      cfg.data = data;
    }
    if (config) {
      Object.assign(cfg, config);
    }
    return this.dialog.open(T, cfg);
  }

  closeAll() {
    this.dialog.closeAll();
  }
}
