
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Observable } from 'rxjs';
import { LocationLotData } from '../model/location-lot-data.model';

@Injectable({ providedIn: 'root' })
export class LocationLotDataService {

    constructor(private httpClient: HttpClient) { }

    get(geotownship: string): Observable<LocationLotData[]> {
        return this.httpClient.get(`${URLs.LOCATION_LOT_DATA_ENDPOINT}/${geotownship}`) as Observable<LocationLotData[]>;
    }
}
