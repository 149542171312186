import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { MDSBaseWorksheet } from '@mds1/models/mds-base-worksheet.model';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MDSService {

  displayReport$ = of(false);

  constructor(private httpClient: HttpClient) { }

  get(id: string): Observable<MDSBaseWorksheet> {
    return this.httpClient.get(`${URLs.MDS1_ENDPOINT}/${id}`) as Observable<MDSBaseWorksheet>;
  }

  save(worksheet: MDSBaseWorksheet): Observable<MDSBaseWorksheet> {
    return this.httpClient.post(URLs.MDS1_ENDPOINT, worksheet) as Observable<MDSBaseWorksheet>;
  }

  update(worksheet: MDSBaseWorksheet): Observable<MDSBaseWorksheet> {
    const url = `${URLs.MDS1_ENDPOINT}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet) as Observable<MDSBaseWorksheet>;
  }

  updateWorksheetFlat(worksheet: MDSBaseWorksheet): Observable<MDSBaseWorksheet> {
    const url = `${URLs.MDS1_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    params = params.append('ignore', 'child-elements');
    return this.httpClient.put(url, worksheet, { params }) as Observable<MDSBaseWorksheet>;
  }
}
