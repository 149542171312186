import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ConstructionDate } from '@nasm/model/construction-date.enum';
import { StorageCategory } from '@nasm/model/storage-category.enum';
import { Constants } from '@shared/constants/constants';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class Storage extends BaseModel {
  name: string;
  notes: string;
  materialForm = MaterialForm.Liquid;
  storageGroupId: string;
  storageTypeId: number;
  constructionMaterial: number = Constants.DEFAULT_CONSTRUCTION_MATERIAL_ID;
  isCovered = false;
  isEarthenStorage = false;
  isNewStorage = true;
  isNewTransferSystem = true;
  holdsRainfall: boolean;
  holdsRainfallPercent: number;
  runoffManagementTypeId = 0; // Constants.DEFAULT_RUNOFF_MGMT_ID;
  runoffManagementDryMatter: number;
  dimensionDepthInMetric: number;
  dimensionDiameterInMetric: number;
  dimensionFreeboardInMetric = 0.3048;
  dimensionLengthInMetric: number;
  dimensionWidthInMetric: number;
  dimensionSlope = 2;
  dimensionPileAngle: number;
  dimensionWallHeightInMetric: number;
  holdsRainfallComment: string;
  runoffManagementComment: string;
  dimensionWallHeightComment: string;
  newTransferSystemComment: string;
  engineerStorageComment: string;
  storageTemporaryChecklist: boolean;
  storageOcaChecklist: boolean;
  runoffManagementChecklist: boolean;
  storageTemporaryCalculation1 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_1_VALUE;
  storageTemporaryCalculation2 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_2_VALUE;
  storageTemporaryCalculation3 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_3_VALUE;
  storageTemporaryCalculation4 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_4_VALUE;
  storageTemporaryCalculation5 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_5_VALUE;
  storageTemporaryCalculation6 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_6_VALUE;
  storageTemporaryCalculation7 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_7_VALUE;
  storageTemporaryCalculation8 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_8_VALUE;
  storageTemporaryCalculation9 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_9_VALUE;
  storageTemporaryCalculation10 = Constants.DEFAULT_STORAGE_TEMPORARY_CALCULATION_10_VALUE;
  constructionPeriod = ConstructionDate.afterJan1;
  farmId: string;
  runoffStorageId: string;
  storageCategory = StorageCategory.nasmStorageFacility;
  storageCategoryDescription: string;
  worksheetId: string;

  constructor(init?: Partial<Storage>) {
    super();
    this.name = this.isEn ? 'New storage' : 'Nouveaux entreposages';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      materialForm: new UntypedFormControl(this.materialForm),
      storageGroupId: new UntypedFormControl(this.storageGroupId),
      storageTypeId: new UntypedFormControl(this.storageTypeId),
      constructionMaterial: new UntypedFormControl(this.constructionMaterial),
      isCovered: new UntypedFormControl(this.isCovered),
      isEarthenStorage: new UntypedFormControl(this.isEarthenStorage),
      isNewStorage: new UntypedFormControl(this.isNewStorage),
      isNewTransferSystem: new UntypedFormControl(this.isNewTransferSystem),
      holdsRainfall: new UntypedFormControl(this.holdsRainfall),
      holdsRainfallPercent: new UntypedFormControl(this.holdsRainfallPercent,
        { validators: [Validators.min(0), Validators.max(100)] }),
      runoffManagementTypeId: new UntypedFormControl(this.runoffManagementTypeId),
      runoffManagementDryMatter: new UntypedFormControl(this.runoffManagementDryMatter),
      dimensionDepthInMetric: new UntypedFormControl(this.dimensionDepthInMetric),
      dimensionDiameterInMetric: new UntypedFormControl(this.dimensionDiameterInMetric),
      dimensionFreeboardInMetric: new UntypedFormControl(this.dimensionFreeboardInMetric),
      dimensionLengthInMetric: new UntypedFormControl(this.dimensionLengthInMetric),
      dimensionWidthInMetric: new UntypedFormControl(this.dimensionWidthInMetric),
      dimensionSlope: new UntypedFormControl(this.dimensionSlope),
      dimensionPileAngle: new UntypedFormControl(this.dimensionPileAngle),
      dimensionWallHeightInMetric: new UntypedFormControl(this.dimensionWallHeightInMetric),
      holdsRainfallComment: new UntypedFormControl(this.holdsRainfallComment),
      runoffManagementComment: new UntypedFormControl(this.runoffManagementComment),
      dimensionWallHeightComment: new UntypedFormControl(this.dimensionWallHeightComment),
      newTransferSystemComment: new UntypedFormControl(this.newTransferSystemComment),
      engineerStorageComment: new UntypedFormControl(this.engineerStorageComment),
      storageTemporaryChecklist: new UntypedFormControl(this.storageTemporaryChecklist),
      storageOcaChecklist: new UntypedFormControl(this.storageOcaChecklist),
      runoffManagementChecklist: new UntypedFormControl(this.runoffManagementChecklist),
      storageTemporaryCalculation1: new UntypedFormControl([this.storageTemporaryCalculation1]),
      storageTemporaryCalculation2: new UntypedFormControl([this.storageTemporaryCalculation2]),
      storageTemporaryCalculation3: new UntypedFormControl([this.storageTemporaryCalculation3]),
      storageTemporaryCalculation4: new UntypedFormControl([this.storageTemporaryCalculation4]),
      storageTemporaryCalculation5: new UntypedFormControl([this.storageTemporaryCalculation5]),
      storageTemporaryCalculation6: new UntypedFormControl([this.storageTemporaryCalculation6]),
      storageTemporaryCalculation7: new UntypedFormControl([this.storageTemporaryCalculation7]),
      storageTemporaryCalculation8: new UntypedFormControl([this.storageTemporaryCalculation8]),
      storageTemporaryCalculation9: new UntypedFormControl([this.storageTemporaryCalculation9]),
      storageTemporaryCalculation10: new UntypedFormControl([this.storageTemporaryCalculation10]),
      constructionPeriod: new UntypedFormControl(this.constructionPeriod),
      farmId: new UntypedFormControl(this.farmId),
      runoffStorageId: new UntypedFormControl(this.runoffStorageId),
      storageCategory: new UntypedFormControl(this.storageCategory),
      storageCategoryDescription: new UntypedFormControl(this.storageCategoryDescription,
        { validators: [Validators.maxLength(30), ValidationFunctions.empty] }),
      worksheetId: new UntypedFormControl(this.worksheetId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new Storage({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      materialForm: v.materialForm,
      storageGroupId: v.storageGroupId,
      storageTypeId: v.storageTypeId,
      constructionMaterial: v.constructionMaterial,
      isCovered: v.isCovered,
      isEarthenStorage: v.isEarthenStorage,
      isNewStorage: v.isNewStorage,
      isNewTransferSystem: v.isNewTransferSystem,
      holdsRainfall: v.holdsRainfall,
      holdsRainfallPercent: v.holdsRainfallPercent,
      runoffManagementTypeId: v.runoffManagementTypeId,
      runoffManagementDryMatter: v.runoffManagementDryMatter,
      dimensionDepthInMetric: v.dimensionDepthInMetric,
      dimensionDiameterInMetric: v.dimensionDiameterInMetric,
      dimensionFreeboardInMetric: v.dimensionFreeboardInMetric,
      dimensionLengthInMetric: v.dimensionLengthInMetric,
      dimensionWidthInMetric: v.dimensionWidthInMetric,
      dimensionSlope: v.dimensionSlope,
      dimensionPileAngle: v.dimensionPileAngle,
      dimensionWallHeightInMetric: v.dimensionWallHeightInMetric,
      holdsRainfallComment: v.holdsRainfallComment,
      runoffManagementComment: v.runoffManagementComment,
      dimensionWallHeightComment: v.dimensionWallHeightComment,
      newTransferSystemComment: v.newTransferSystemComment,
      engineerStorageComment: v.engineerStorageComment,
      storageTemporaryChecklist: v.storageTemporaryChecklist,
      storageOcaChecklist: v.storageOcaChecklist,
      runoffManagementChecklist: v.runoffManagementChecklist,
      storageTemporaryCalculation1: this.selectionValue(v.storageTemporaryCalculation1),
      storageTemporaryCalculation2: this.selectionValue(v.storageTemporaryCalculation2),
      storageTemporaryCalculation3: this.selectionValue(v.storageTemporaryCalculation3),
      storageTemporaryCalculation4: this.selectionValue(v.storageTemporaryCalculation4),
      storageTemporaryCalculation5: this.selectionValue(v.storageTemporaryCalculation5),
      storageTemporaryCalculation6: this.selectionValue(v.storageTemporaryCalculation6),
      storageTemporaryCalculation7: this.selectionValue(v.storageTemporaryCalculation7),
      storageTemporaryCalculation8: this.selectionValue(v.storageTemporaryCalculation8),
      storageTemporaryCalculation9: this.selectionValue(v.storageTemporaryCalculation9),
      storageTemporaryCalculation10: this.selectionValue(v.storageTemporaryCalculation10),
      constructionPeriod: v.constructionPeriod,
      farmId: v.farmId,
      runoffStorageId: v.runoffStorageId,
      storageCategory: v.storageCategory,
      storageCategoryDescription: v.storageCategoryDescription,
      worksheetId: v.worksheetId
    });
  }

  // this is to extract value from mat-selection-list and assign it to model
  selectionValue(val) {
    if (Array.isArray(val)) {
      return val[0];
    }
    return val;
  }
}
