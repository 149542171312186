import { Pipe, PipeTransform } from "@angular/core";
import { LanguageService } from "@shared/services/language.service";
import { MeasurementService } from "@shared/services/measurement.service";
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';

@Pipe({ name: 'flagMessagePipe' })
export class FlagMessagePipe implements PipeTransform {
  constructor(private measurementService: MeasurementService,
    private languageService: LanguageService) { }

  transform(val: any): Observable<any> {
    return combineLatest([this.measurementService.measurementSystem$,
    this.languageService.languageType$]).
      pipe(map(([to, lang]) => {
        switch (to) {
          case MeasurementSystem.Imperial:
            return val.messageUnitTypeImperial[lang];
          case MeasurementSystem.Metric:
            return val.messageUnitTypeMetric[lang];
          case MeasurementSystem.US:
            return val.messageUnitTypeUS[lang];
          default:
            return val.messageUnitTypeImperial[lang];
        }
      }));
  }
}
