<article>
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>

    <div class="form-container">
        <mat-dialog-content [formGroup]="calculatorNameForm">
            <mat-form-field color="accent" style="width: 300px" floatLabel="never" appearance="outline">
                <mat-label>
                    {{ "home.auth.text.planOrCalculatorName" | translate | async }}
                </mat-label>

                <input matInput [errorStateMatcher]="matcher" placeholder="{{ 'calculator.name.placeholder' | translate | async }}" formControlName="calculatorName" maxlength="30" />

                <mat-error *ngIf="calculatorName.errors?.minlength">
                    {{ "validation.message.minLength.3" | translate | async }}
                </mat-error>

                <mat-error *ngIf="calculatorName.errors?.maxlength">
                    {{ "validation.message.maxLength.30" | translate | async }}
                </mat-error>

                <mat-error *ngIf="calculatorName.errors?.empty">
                    {{ "validation.message.empty" | translate | async }}
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--black-secondary" (click)="onNoClick()">
                {{ data.btnCancel | translate | async }}
            </button>
        </div>

        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--green-primary" (click)="apply()">
                {{ "dialog.title.rename" | translate | async }}
            </button>
        </div>
    </div>
</article>
