import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { MaterialApplicationSeasonType } from '@shared/models/common/material-application-season-type.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class FmpMaterialApplication extends BaseModel {
  name: string;
  applicationDate = Utils.getDefaultApplicationDate();
  applicationIncorporation = 0;
  applicationMethod = 0;
  applicationRateInMetric = 0;
  applicationTiming = 0;
  coverCropExists = false;
  fieldSectionId: string;
  materialTypeId: string;
  applicationSeason: MaterialApplicationSeasonType;

  constructor(init?: Partial<any>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'Material App' : 'Épan matière';
    Object.assign(this, init);
  }


  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      // tslint:disable-next-line: max-line-length
      applicationDate: new UntypedFormControl(Utils.utcDateOf(this.applicationDate), { validators: [ValidationFunctions.overTwentyYears] }),
      applicationSeason: new UntypedFormControl(Utils.getApplicationSeasonByDate(Utils.utcDateOf(this.applicationDate))),
      applicationIncorporation: new UntypedFormControl(this.applicationIncorporation),
      applicationMethod: new UntypedFormControl(this.applicationMethod),
      applicationRateInMetric: new UntypedFormControl(this.applicationRateInMetric, { validators: [Validators.max(1000000)] }),
      applicationTiming: new UntypedFormControl(this.applicationTiming),
      coverCropExists: new UntypedFormControl(this.coverCropExists),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      fieldSectionId: new UntypedFormControl(this.fieldSectionId),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new FmpMaterialApplication({
      id: v.id,
      name: v.name,
      applicationDate: v.applicationDate,
      applicationIncorporation: v.applicationIncorporation,
      applicationMethod: v.applicationMethod,
      applicationRateInMetric: v.applicationRateInMetric,
      applicationTiming: v.applicationTiming,
      coverCropExists: v.coverCropExists,
      materialTypeId: v.materialTypeId,
      fieldSectionId: v.fieldSectionId,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
    });
  }
}
