import { NgModule } from '@angular/core';
import { ProductApplicationRateDirective } from '@fertilizer/directive/product-application-rate.directive';
import { ApplicationRateDirective } from '@organic-amendment/directives/application-rate.directive';
import { AnnualPercipitationDirective } from './directives/annual-percipitation.directive';
import { AppMassConverterDirective } from './directives/app-mass-converter.directive';
import { AppMassLargeConverterDirective } from './directives/app-mass-large-converter.directive';
import { Area2ConverterDirective } from './directives/area2-converter.directive';
import { AutofocusByContentDirective } from './directives/autofocus-by-content.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { CardTooltipDirective } from './directives/card-tooltip.directive';
import { ConditionalFocusDirective } from './directives/conditional-focus.directive';
import { ConversionHelperDirective } from './directives/conversion-helper.directive';
import { Density2Directive } from './directives/density2.directive';
import { DisableControlDirective } from './directives/disable-control.directive';
import { FieldAreaDirective } from './directives/field-area.directive';
import { FormConfigDirective } from './directives/form-config.directive';
import { FuelQuantityDirective } from './directives/fuel-quantity.directive';
import { HorticulturalAmountAreaDirective } from './directives/horticultural-amount-area.directive';
import { MenuActionBtnDirective } from './directives/menu-action-btn.directive';
import { LengthMeterFootDirective } from './directives/length-meter-foot.directive';
import { LiquidAmountDirective } from './directives/liquid-amount.directive';
import { MassConverterDirective } from './directives/mass-converter.directive';
import { MassLargeDirective } from './directives/mass-large.directive';
import { NoMinusDirective } from './directives/no-minus.directive';
import { NumericNegativeAllowedDirective } from './directives/numeric-negative-allowed.directive';
import { NumericDirective } from './directives/numeric.directive';
import { NutrientValueDirective } from './directives/nutrient-value.directive';
import { OverrideEnterDirective } from './directives/override-enter.directive';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { PlatoApplicationRateDirective } from './directives/plato-application-rate.directive';
import { PrecisionDirective } from './directives/precision.directive';
import { TemperatureDirective } from './directives/temperature.directive';
import { InputTrimDirective } from './directives/trim.directive';
import { VolumeFlowRateDirective } from './directives/volume-flow-rate.directive';
import { VolumeDirective } from './directives/volume.directive';
import { Volume2Directive } from './directives/volume2.directive';
import { YieldPriceDirective } from './directives/yield-price.directive';
import { YieldDirective } from './directives/yield.directive';
import { ValueConverterDirective } from './directives/value-converter.directive';

const directives = [
  AutofocusDirective,
  AutofocusByContentDirective,
  OverrideEnterDirective,
  NoMinusDirective,
  NumericDirective,
  NumericNegativeAllowedDirective,
  InputTrimDirective,
  FieldAreaDirective,
  AppMassConverterDirective,
  AppMassLargeConverterDirective,
  LengthMeterFootDirective,
  PrecisionDirective,
  DisableControlDirective,
  AnnualPercipitationDirective,
  CardTooltipDirective,
  ConversionHelperDirective,
  ApplicationRateDirective,
  ProductApplicationRateDirective,
  YieldDirective,
  PlatoApplicationRateDirective,
  MassConverterDirective,
  Volume2Directive,
  VolumeDirective,
  MassLargeDirective,
  Area2ConverterDirective,
  Density2Directive,
  LiquidAmountDirective,
  ConditionalFocusDirective,
  YieldPriceDirective,
  NutrientValueDirective,
  PhoneMaskDirective,
  HorticulturalAmountAreaDirective,
  TemperatureDirective,
  FuelQuantityDirective,
  FormConfigDirective,
  MenuActionBtnDirective,
  VolumeFlowRateDirective,
  ValueConverterDirective
];

@NgModule({
  declarations: directives,
  exports: directives
})
export class DirectivesModule {}
