// tslint:disable-next-line: max-line-length
import { ChangeDetectionStrategy, Component, EventEmitter, Injector, Input, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { SessionService } from '@core/services/session.service';
import { Utils } from '@core/utilities/utils';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { EMPTY, firstValueFrom } from 'rxjs';
import { map, takeWhile } from 'rxjs/operators';
import { MeasurementSystem } from '../../models/common/measurement-system.enum';
import { UserService } from '../../services/user.service';
import { InstantErrorStateMatcher } from '../../validators/instant-error-state-matcher';
import { BaseComponent } from '../base.component';
import { FlagSummaryComponent } from '../flag-summary/flag-summary.component';
import { DeleteFieldInputsDialogComponent } from '../delete-field-inputs-dialog/delete-field-inputs-dialog.component';
import { FmpFieldInputService } from '@fmp/service/fmp-field-input.service';
import {TranslateService} from '@shared/services/translate.service';

@Component({
  selector: 'app-calculator-heading',
  templateUrl: './calculator-heading.component.html',
  styleUrls: ['./calculator-heading.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalculatorHeadingComponent extends BaseComponent implements OnInit {
	#renderer: Renderer2;
	#translateService: TranslateService;
  editMode: boolean;
  editModeMobile: boolean;
  matcher = new InstantErrorStateMatcher();
  showWorksheetFunctionsMenuButton: boolean = false;

  @Input() f: UntypedFormGroup;
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSaveWorksheet = new EventEmitter<any>();

  @Output() onCalculate = new EventEmitter<any>();

  constructor(
    private injector: Injector,
    private session: SessionService,
    private userService: UserService,
    private fmpFieldInputService: FmpFieldInputService
  ) {
    super(injector);

	  this.#renderer = this.injector.get(Renderer2);
	  this.#translateService = this.injector.get(TranslateService);
  }

	protected async unitChangerAccessibilityApplications(): Promise<void>
	{
		const overlayContainer: HTMLElement = this.#renderer.selectRootElement('.cdk-overlay-container', true),
			language: string = await firstValueFrom(this.#translateService.translate(this.languageService.languageType));;

		console.info(language["calculator.unit.change.tooltip.text"]);

		overlayContainer.setAttribute("role", "navigation");
		overlayContainer.setAttribute("aria-label", language["calculator.unit.change.tooltip.text"]);
	}

  ngOnInit(): void {
    this.showWorksheetFunctionsMenuButton =
      this.formService.worksheetTypeId === WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL ||
      this.formService.worksheetTypeId === WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN;
  }

  nameFn() {
    if (this.name.valid) {
      this.saveWorksheet();
      this.editMode = false;
      this.editModeMobile = false;
      this.cd();
    }
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  toggleEditModeMobile(): void {
    this.editModeMobile = !this.editModeMobile;
  }

  switchMeasurementSystem(measurement: MeasurementSystem) {
    this.measurementService.measurementSystem = measurement;
    this.f.markAsDirty();
    this.saveWorksheet();
    if (this.isAuthenticated) {
      this.saveUser(measurement);
    }
  }

  home() {
    this.session.home();
  }

  openFlagSummaryDialog() {
    this.dialogService.instance(FlagSummaryComponent, {
      worksheetTypeId: this.worksheetTypeId,
      calculatedFlags$: this.calculatedFlags$
    });
  }

  openDeleteFieldInputsDialog() {
    const years = this.fmpFieldInputService.allCroppingYears();
    if (!years || years.length <= 1) {
      return;
    }

    const deleteYears = years.slice(1);
    this.dialogService
      .instance(DeleteFieldInputsDialogComponent, {
        years: deleteYears
      })
      .afterClosed()
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => {
        this.subscription.add(
          this.fmpFieldInputService.deleteAllFarmsPriorCroppingYear(res as number).subscribe(r => this.onCalculate.emit())
        );
      });
  }

  isDeleteFieldInputsHidden() {
    // has at least one field input -> at least one cropping year
    const years = this.fmpFieldInputService.allCroppingYears();
    return !(years && years.length > 1);
  }

  isMatBadgeHidden(flagSummary: any) {
    return (
      flagSummary?.displayFlagCount === 0 ||
      !(
        flagSummary?.displayFlagLevelId === 2 ||
        flagSummary?.displayFlagLevelId === 3 ||
        flagSummary?.displayFlagLevelId === 4 ||
        flagSummary?.displayFlagLevelId === 5 ||
        flagSummary?.displayFlagLevelId === 6 ||
        flagSummary?.displayFlagLevelId === 8
      )
    );
  }

  private saveWorksheet() {
    this.onSaveWorksheet.emit(this.f.value);
  }

  private saveUser(measurement: MeasurementSystem) {
    const user = this.cache.user;
    user.measureSystemName = MeasurementSystem[measurement];
    this.userService
      .update(user)
      .pipe(takeWhile(() => this.alive))
      .subscribe(res => {
        this.cache.user = res;
      });
  }

  get calculatedFlags$() {
    if (!this.worksheetTypeId || !this.calculationService) {
      return EMPTY;
    }
    return this.calculationService.calculation$.pipe(map((calculation: any) => Utils.pickFlags(calculation)));
  }

  get calculatedFlagSummary$() {
    if (!this.worksheetTypeId || !this.calculationService) {
      return EMPTY;
    }
    return this.calculationService.calculation$.pipe(
      map((calculation: any) => {
        if (!!calculation && !!calculation.flagSummary) {
          return calculation.flagSummary;
        }
        return undefined;
      })
    );
  }

  get name() {
    return this.f.get('name');
  }

  get routerLinkHome() {
    return this.isAuthenticated ? '/secure-home' : '/';
  }

  get measurementSystemName(): string {
    return MeasurementSystem[this.cache.preference.measurementSystem];
  }

  get measurementSystem() {
    return MeasurementSystem;
  }

  get flagSummaryExists() {
    return this.isAuthenticated && !!this.calculationService;
  }
}
