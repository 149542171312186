import { Pipe, PipeTransform } from '@angular/core';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'fmpYieldPipe'
})
export class FmpYieldPipe implements PipeTransform {

  constructor(private unitConverterService: UnitConverterService,
    private cache: CacheService,
    private measurementService: MeasurementService) { }

  transform(val: any, cropSubTypeId: number): any {
    this.unitConverterService.from = MeasurementSystem.Metric;
    const cropSubType = this.cache.allCropSubTypes.find(crop => crop.cropId === cropSubTypeId);

    return this.measurementService.measurementSystem$.
      pipe(
        map((to: MeasurementSystem) => {
          this.unitConverterService.to = to;
          if (cropSubType && (val || val === 0)) {
            const converted = this.unitConverterService.convertYield(val, cropSubType.yieldUnits, cropSubType.density);
            return converted;
          }
          return !!val ? val : '';
        }));
  }
}
