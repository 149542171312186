<div class="mat-calendar-header">
        <div class="mat-calendar-controls">
                <button type="button" mat-button type="button" class="mat-calendar-period-button"
                        [attr.aria-label]="periodButtonLabel" [attr.aria-describedby]="_buttonDescriptionId"
                        cdkAriaLive="polite">
                        <span [attr.id]="_buttonDescriptionId">{{periodButtonText}}</span>
                </button>

                <div class="mat-calendar-spacer"></div>

                <ng-content></ng-content>

                <button type="button" mat-icon-button type="button" class="mat-calendar-previous-button"
                        [disabled]="!previousEnabled()" (click)="previousClicked()" [attr.aria-label]="prevButtonLabel">
                </button>

                <button type="button" mat-icon-button type="button" class="mat-calendar-next-button"
                        [disabled]="!nextEnabled()" (click)="nextClicked()" [attr.aria-label]="nextButtonLabel">
                </button>
        </div>
</div>