import { MDSBaseWorksheet } from '@mds1/models/mds-base-worksheet.model';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';

export class MDS2Worksheet extends MDSBaseWorksheet {
  worksheetTypeId = WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2;

  constructor(init?: Partial<MDS2Worksheet>) {
    super(init);
    if (!this.name) {
      this.name = this.isEn ? 'MDS II' : 'DMS II';
    }
  }
}
