import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { CropNutrientFarm } from './crop-nutrient-farm.model';

export class CropNutrientWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.CROP_NUTRIENT;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  farms: CropNutrientFarm[] = [];
  isLocal = true;

  constructor(init?: Partial<CropNutrientWorksheet>) {
    super(init);

    this.name = init?.name || (this.isEn ? 'Crop Nutrient 1' : 'Culture 1');
    this.worksheetTypeId = init?.worksheetTypeId || WorksheetTypeIds.CROP_NUTRIENT;
    this.measureSystemName = init?.measureSystemName || 'Imperial';
    this.langPreference = init?.langPreference || 'en';
    this.userAccountId = init?.userAccountId || '';
    this.worksheetGroupName = init?.worksheetGroupName;
    this.worksheetSubGroupName = init?.worksheetSubGroupName;
    this.isLocal = init?.isLocal || true;

    this.farms[0] = new CropNutrientFarm(init?.farms?.[0]);
  }
}
