export enum AlertInputName {
  UpperTierCode = 'UpperTierCode',
  TillableArea = 'TillableArea',
  Crops = 'Crops',
  SoilSeries = 'SoilSeries',
  SoilTexture = 'SoilTexture',
  SoilTextValueP = 'SoilTextValueP',
  SoilTextValueK = 'SoilTextValueK',
  Livestocks = 'Livestocks'
}

export const DEFAULT_ALERT_INPUTS = [
  AlertInputName.UpperTierCode,
  AlertInputName.SoilSeries,
  AlertInputName.SoilTexture,
  AlertInputName.SoilTextValueP,
  AlertInputName.SoilTextValueK
];
