import { Component, Inject, Injector, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { Utils } from '@core/utilities/utils';
import { Label } from '@shared/models/common/label.model';
import { WorksheetLabelLink } from '@shared/models/common/worksheet-label-link.model';
import { LabelClient } from '@shared/services/label.service';
import { WorksheetLabelLinkClient } from '@shared/services/worksheet-label-link.client';
import { EMPTY, forkJoin } from 'rxjs';
import { switchMap, takeWhile, tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { ManageLabelDialogComponent } from '../manage-label-dialog/manage-label-dialog.component';

@Component({
  selector: 'app-apply-label-dialog',
  templateUrl: './apply-label-dialog.component.html',
  styleUrls: ['./apply-label-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApplyLabelDialogComponent extends BaseComponent implements OnInit {
  f: UntypedFormGroup;
  labels: Label[];
  labelList = new UntypedFormGroup({});
  worksheetId: string;

  constructor(
    private injector: Injector,
    private dialogRef: MatDialogRef<ApplyLabelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private worksheetLabelLinkClient: WorksheetLabelLinkClient,
    private labelClient: LabelClient
  ) {
    super(injector);
    this.worksheetId = data.worksheetId;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.all();
  }

  close() {
    this.dialogRef.close();
  }

  openManageLabelsDialog() {
    this.close();
    this.dialogService.instance(ManageLabelDialogComponent, {}, { width: '380px' });
  }

  labelName(key) {
    return this.labels?.find(v => Utils.matchStr(key, v.id))?.labelName;
  }

  toggleLabel(event, labelId) {
    const call = event.checked ? this.saveLink(labelId) : this.deleteLink(labelId);
    call
      .pipe(
        takeWhile(() => this.alive),
        tap(() => this.onUpdate.emit())
      )
      .subscribe();
  }

  private all() {
    forkJoin([this.labelClient.all(), this.worksheetLabelLinkClient.all()])
      .pipe(
        tap(([allLabels, links]) => {
          this.labels = allLabels;
          const relaventLinks = links.filter(v => Utils.matchStr(v.worksheetId, this.worksheetId))?.map(v => v.worksheetLabelId);
          this.labelList = new UntypedFormGroup({});
          allLabels?.forEach(l => {
            const selected = relaventLinks.findIndex(k => Utils.matchStr(l.id, k)) > -1;
            this.labelList.addControl(l.id, new UntypedFormControl(selected));
          });
          this.aodaFix();
        })
      )
      .subscribe();
  }

  private aodaFix() {
    setTimeout(() => {
      AODADialog.applyAllFixes();
    }, 100);
  }

  private saveLink(labelId) {
    return this.worksheetLabelLinkClient.save(
      new WorksheetLabelLink({
        worksheetId: this.worksheetId,
        worksheetLabelId: labelId
      })
    );
  }

  private deleteLink(labelId) {
    return this.worksheetLabelLinkClient.byWorksheetId(this.worksheetId).pipe(
      switchMap(links => {
        const link = links?.find(l => Utils.matchStr(l.worksheetLabelId, labelId));
        if (link) {
          return this.worksheetLabelLinkClient.delete(link.id);
        }
        return EMPTY;
      })
    );
  }

  get canSeeLabels() {
    return !!this.labels?.length;
  }

  get labelSelected() {
    return Object.values(this.labelList.value).some(v => v === true);
  }

  get object() {
    return Object;
  }

  get form() {
    return this.f?.controls;
  }
}
