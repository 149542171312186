import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";

export class HtmlContent {
  contentEnglish: string;
  contentFrench: string;
  id: 0;
  worksheetTypeName: string;

  constructor(init?: Partial<HtmlContent>) {
    Object.assign(this, init);
  }

  toForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      contentEnglish: new UntypedFormControl(this.contentEnglish),
      contentFrench: new UntypedFormControl(this.contentFrench),
      id: new UntypedFormControl(this.id),
      worksheetTypeName: new UntypedFormControl(this.worksheetTypeName)
    });
  }

  toModel(form: UntypedFormGroup): HtmlContent {
    const v: any = form.getRawValue();
    return new HtmlContent({
      contentEnglish: v.contentEnglish,
      contentFrench: v.contentFrench,
      id: v.id,
      worksheetTypeName: v.worksheetTypeName
    });
  }
}