import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class IdfCurve extends BaseModel {

  numberOfYears: number;
  factorA: number;
  factorB: number;
  factorC: number;
  watershedId: string;

  constructor(init?: Partial<IdfCurve>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      numberOfYears: new UntypedFormControl(this.numberOfYears),
      factorA: new UntypedFormControl(this.factorA),
      factorB: new UntypedFormControl(this.factorB),
      factorC: new UntypedFormControl(this.factorC),
      watershedId: new UntypedFormControl(this.watershedId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new IdfCurve({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      numberOfYears: v.numberOfYears,
      factorA: v.factorA,
      factorB: v.factorB,
      factorC: v.factorC,
      watershedId: v.watershedId
    });
  }
}
