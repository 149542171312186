import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'flagIconPipe' })
export class FlagIconPipe implements PipeTransform {
  constructor() { }

  transform(flagLevelId: number): string {
    switch (flagLevelId) {
      case 1:
        return 'monetization_on';
      case 2:
        return 'warning';
      case 3:
        return 'warning';
      case 4: //Yellow info/sheild icon
        return 'privacy_tip';
      case 5:
        return 'report';
      case 6: //Missing info
        return 'error_outline';
      case 7:
        return 'check_circle'; // this is questionable
      case 8:
        return 'report';
      default:
        return undefined;
    }
  }
}
