import { Directive, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[overrideEnter]'
})
export class OverrideEnterDirective {

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent) {
    this.disableEnterEvent(event);
  }

  private disableEnterEvent(event) {
    if (event.key && event.key === 'Enter') {
      event.preventDefault();
    }
  }
}
