import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { LocationLot } from './location-lot.model';
import { LocationRollNumber } from './location-roll-number.model';

export class TransferContact extends BaseModel {
  name: string;
  notes: string;
  worksheetId: string;
  transferTypeId = Constants.DEFAULT_TRANSFER_TYPE_ID;
  identificationText: string;
  areaInMetric: number;
  livestockTypeId: number;
  nutrientUnits: number;
  doesLivestockExist: boolean;
  storageCapacityInMetric: number;
  isIncomingTransfer: boolean;
  isOutgoingTransfer: boolean;
  isHandledByCarrier: boolean = true;
  carrierNumber: string;
  hasContactInformation: boolean;
  hasLocationInformation: boolean;
  upperTierCode: number;
  lowerTierCode: number;
  geotownship: string;
  contact: ContactInformation;
  locationRollNumbers = [];
  locationLots: LocationLot[];

  constructor(init?: Partial<TransferContact>) {
    super();
    this.name = this.isEn ? 'New Contact' : 'Nouveau contact';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      worksheetId: new UntypedFormControl(this.worksheetId),
      transferTypeId: new UntypedFormControl(this.transferTypeId),
      isIncomingTransfer: new UntypedFormControl(this.isIncomingTransfer),
      isOutgoingTransfer: new UntypedFormControl(this.isOutgoingTransfer),
      isHandledByCarrier: new UntypedFormControl(this.isHandledByCarrier),
      carrierNumber: new UntypedFormControl(this.carrierNumber, { validators: [Validators.maxLength(50)] }),
      identificationText: new UntypedFormControl(this.identificationText, {
        validators: [Validators.maxLength(50)]
      }),
      areaInMetric: new UntypedFormControl(this.areaInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      livestockTypeId: new UntypedFormControl(this.livestockTypeId),
      nutrientUnits: new UntypedFormControl(this.nutrientUnits, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      doesLivestockExist: new UntypedFormControl(this.doesLivestockExist),
      storageCapacityInMetric: new UntypedFormControl(this.storageCapacityInMetric),
      hasContactInformation: new UntypedFormControl(this.hasContactInformation),
      hasLocationInformation: new UntypedFormControl(this.hasLocationInformation),
      upperTierCode: new UntypedFormControl(this.upperTierCode),
      lowerTierCode: new UntypedFormControl(this.lowerTierCode),
      geotownship: new UntypedFormControl(this.geotownship),
      contact: new ContactInformation(this.contact).toForm(),
      locationRollNumbers: new UntypedFormArray(this.locationRollNumbers.map(v => new LocationRollNumber(v).toForm())),
      locationLots: new UntypedFormArray(this.locationLots.map(v => new LocationLot(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const contact = form.get('contact') as UntypedFormGroup;
    const locationRollNumbers = form.controls.locationRollNumbers as UntypedFormArray;
    const locationLots = form.controls.locationLots as UntypedFormArray;
    return new TransferContact({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      worksheetId: v.worksheetId,
      transferTypeId: v.transferTypeId,
      isIncomingTransfer: v.isIncomingTransfer,
      isOutgoingTransfer: v.isOutgoingTransfer,
      isHandledByCarrier: v.isHandledByCarrier,
      carrierNumber: v.carrierNumber,
      identificationText: v.identificationText,
      areaInMetric: v.areaInMetric,
      livestockTypeId: v.livestockTypeId,
      nutrientUnits: v.nutrientUnits,
      doesLivestockExist: v.doesLivestockExist,
      storageCapacityInMetric: v.storageCapacityInMetric,
      hasContactInformation: v.hasContactInformation,
      hasLocationInformation: v.hasLocationInformation,
      upperTierCode: v.upperTierCode,
      lowerTierCode: v.lowerTierCode,
      geotownship: v.geotownship,
      contact: new ContactInformation().toModel(contact),
      locationRollNumbers: locationRollNumbers?.controls.map((w: UntypedFormGroup) => new LocationRollNumber().toModel(w)),
      locationLots: locationLots?.controls.map((w: UntypedFormGroup) => new LocationLot().toModel(w))
    });
  }
}
