import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { CropSubType } from '@crop-nutrient/model/crop-sub-type';
import { Constants } from '@shared/constants/constants';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { Subscription } from 'rxjs';
import { takeWhile, tap } from 'rxjs/operators';

@Directive({ selector: '[yieldPrice]' })
export class YieldPriceDirective implements OnDestroy {
  // tslint:disable-next-line: variable-name
  _yieldPrice: AbstractControl;
  @Input('yieldPrice') set yieldPrice(v: AbstractControl) {
    this._yieldPrice = v;

    this.subcription.add(
      this._yieldPrice.valueChanges
        .pipe(
          takeWhile(() => this.alive),
          tap(y => {
            const cropSubType = this.cache.allCropSubTypes.find(c => c.cropId === this.cropSubTypeId.value);
            this.unitConverterService.from = this.measurementService.measurementSystem;
            this.unitConverterService.to = MeasurementSystem.Metric;
            const cornGrain = this.cornGrain(cropSubType);
            const result = this.unitConverterService.convertYieldPrice(y, cornGrain.yieldUnits, cornGrain.density);
            this._yieldPrice.patchValue(result, Utils.UPDATE_MODEL_ONLY);
          })
        )
        .subscribe()
    );
  }

  // tslint:disable-next-line: no-input-rename
  @Input('cropSubTypeId') cropSubTypeId: AbstractControl;
  private alive = true;
  private subcription: Subscription = new Subscription();

  constructor(
    private measurementService: MeasurementService,
    private unitConverterService: UnitConverterService,
    private cache: CacheService
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }

  private cornGrain(cropSubType: CropSubType) {
    if (cropSubType.cropTypeId === Constants.CORN_CROP_TYPE_ID) {
      return this.cache.allCropSubTypes.find(
        crop => crop.cropTypeId === Constants.GRAIN_CROP_SUB_TYPE_ID && crop.cropId === Constants.CORN_CROP_TYPE_ID
      );
    }
    return cropSubType;
  }
}
