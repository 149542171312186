<ng-container *ngIf="isInfoView || isDetailsView; else combinedView">
    <!-- contact info -->
    <ng-container *ngIf="isInfoView; else detailsView">
        <p class="flex-child">
            <strong>
                {{ infoLabel | translate | async }}
            </strong>

            <br />
            <span>
                {{ contact.get("nameFirst").value }}
                {{ contact.get("nameLast").value }}
                <ng-container *ngIf="contact.get('certificationNumber').value"> ({{ contact.get("certificationNumber").value }}) </ng-container>
            </span>
            <ng-container *ngIf="contact.get('jobTitle').value">
                <br />
                <span>{{ contact.get("jobTitle").value }}</span>
            </ng-container>
            <ng-container *ngIf="contact.get('jobTitle').value">
                <br />
                <span>{{ contact.get("nameCompany").value }}</span>
            </ng-container>
        </p>
        <div class="flex-child flex-child--flex-grow">
            <app-flag *ngIf="contactFlag" nextTo="text" [data$]="observable(contactFlag)" class="flex-child"></app-flag>
        </div>
    </ng-container>

    <!-- contact details -->
    <ng-template #detailsView>
        <p>
            <strong>
                {{ "calculator.nmsp.report.section.preparerInformation.contact.details" | translate | async }}
            </strong>
            <br />
            <ng-container *ngIf="contact.get('address1')?.value || contact.get('address2')?.value">
                <span>
                    {{ contact.get("address1").value }}
                    {{ contact.get("address2").value }}
                </span>
                <br />
            </ng-container>
            <span>
                {{ contact.get("city").value }}
                <ng-container *ngIf="contact.get('province')?.value">
                    <ng-container *ngIf="contact.get('city')?.value"> &#44;&nbsp; </ng-container>
                    {{ contact.get("province").value }}
                </ng-container>
                <ng-container *ngIf="contact.get('country')?.value">
                    <ng-container *ngIf="contact.get('province')?.value"> &#44;&nbsp; </ng-container>
                    {{ contact.get("country").value }}
                </ng-container>
                <ng-container *ngIf="contact.get('postalCode')?.value"> &nbsp;{{ contact.get("postalCode").value }} </ng-container>
            </span>
            <ng-container *ngIf="contact.get('phone1')?.value">
                <br />
                <span>{{ contact.get("phone1")?.value }}</span>
            </ng-container>
            <ng-container *ngIf="contact.get('email')?.value">
                <br />
                <span>{{ contact.get("email")?.value }}</span>
            </ng-container>
        </p>
    </ng-template>
</ng-container>

<ng-template #combinedView>
    <p class="flex-child">
        <strong>
            {{ infoLabel | translate | async }}
        </strong>

        <ng-container *ngIf="!isTransferContact; else transferContactName">
            <br />
            <span>
                {{ contact.get("nameFirst").value }}
                {{ contact.get("nameLast").value }}
                <ng-container *ngIf="contact.get('certificationNumber').value"> ({{ contact.get("certificationNumber").value }}) </ng-container>
            </span>
        </ng-container>
        <ng-template #transferContactName>
            <ng-container *ngIf="contact.get('nameFirst')?.value || contact.get('nameLast')?.value">
                <br />
                <span>
                    {{ contact.get("nameFirst").value }}
                    {{ contact.get("nameLast").value }}
                </span>
            </ng-container>
        </ng-template>
        <ng-container *ngIf="contact.get('jobTitle').value">
            <br />
            <span>{{ contact.get("jobTitle").value }}</span>
        </ng-container>
        <ng-container *ngIf="contact.get('nameCompany').value">
            <br />
            <span>{{ contact.get("nameCompany").value }}</span>
        </ng-container>
        <ng-container *ngIf="contact.get('address1')?.value || contact.get('address2')?.value">
            <br />
            <span>
                {{ contact.get("address1").value }}
                {{ contact.get("address2").value }}
            </span>
        </ng-container>
        <br />
        <span>
            {{ contact.get("city").value }}
            <ng-container *ngIf="contact.get('province')?.value">
                <ng-container *ngIf="contact.get('city')?.value"> &#44;&nbsp; </ng-container>
                {{ contact.get("province").value }}
            </ng-container>
        </span>
        <br />
        <span>
            <ng-container *ngIf="contact.get('country')?.value">
                {{ contact.get("country").value }}
            </ng-container>
            <ng-container *ngIf="contact.get('postalCode')?.value"> &nbsp;{{ contact.get("postalCode").value }} </ng-container>
        </span>
        <ng-container *ngIf="contact.get('phone1')?.value">
            <br />
            <span>{{ contact.get("phone1")?.value }}</span>
        </ng-container>
        <ng-container *ngIf="contact.get('email')?.value">
            <br />
            <span>{{ contact.get("email")?.value }}</span>
        </ng-container>
    </p>
</ng-template>
