import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MstorWorksheet } from '../model/mstor-worksheet.model';

@Injectable({ providedIn: 'root' })
export class MstorService {

  displayReport$ = of(false);
  systemExpanded$ = new BehaviorSubject(undefined);
  contactExpanded$ = new BehaviorSubject(undefined);
  addingTransferContact = false;

  constructor(private httpClient: HttpClient,
    private formService: FormService) { }

  expandStorageSystemPanel() {
    this.systemExpanded$.next(Utils.uuid());
  }

  expandTransferContactPanel() {
    this.contactExpanded$.next(Utils.uuid());
  }

  get(id: string): Observable<MstorWorksheet> {
    return this.httpClient.get(`${URLs.STORAGE_SIZING_ENDPOINT}/${id}`) as Observable<MstorWorksheet>;
  }

  save(worksheet: MstorWorksheet): Observable<any> {
    return this.httpClient.post(URLs.STORAGE_SIZING_ENDPOINT, worksheet);
  }

  update(worksheet: MstorWorksheet): Observable<any> {
    const url = `${URLs.STORAGE_SIZING_ENDPOINT}/${worksheet.id}`;
    return this.httpClient.put(url, worksheet);
  }

  updateWorksheetFlat(worksheet: MstorWorksheet): Observable<any> {
    const url = `${URLs.STORAGE_SIZING_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    params = params.append('ignore', 'child-elements');
    return this.httpClient.put(url, worksheet, { params });
  }

  get f() {
    return this.formService.f;
  }

  get storageSystems() {
    return this.f ? this.f.get('storageSystems') as UntypedFormArray : undefined;
  }

  get currentStorageSystem(): UntypedFormGroup {
    return this.storageSystems ?
      (this.storageSystems.controls.find(v => Utils.matchStr(v.get('id').value,
        this.f.get('currentStorageSystemId').value)) as UntypedFormGroup) : undefined;
  }

  get transferContacts() {
    return this.f ? this.f.get('transferContacts') as UntypedFormArray : undefined;
  }

  get currentTransferContact(): UntypedFormGroup {
    return this.transferContacts ?
      (this.transferContacts.controls.find(v => Utils.matchStr(v.get('id').value,
        this.f.get('currentTransferContactId').value)) as UntypedFormGroup) : undefined;
  }

  get materialsProduced() {
    return this.currentStorageSystem ? this.currentStorageSystem.get('materialsProduced') as UntypedFormArray : undefined;
  }
}
