/* eslint-disable no-undef */
import { Injectable } from '@angular/core';
import { AppConstants } from '@core/conf/app-constants';
import { Utils } from '@core/utilities/utils';
import { CropSubType } from '@crop-nutrient/model/crop-sub-type';
import { CropType } from '@crop-nutrient/model/crop-type';
import { NitrogenCredit } from '@crop-nutrient/model/nitrogen-credit';
import { SoilTexture } from '@crop-nutrient/model/soil-texture';
import { MaterialType } from '@plato/model/material-type.model';
import { EquationValue } from '@shared/models/common/equation-value.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { SoilTestCode } from '@shared/models/worksheet/soil-test-code.model';
import moment from 'moment';
import { Constants } from '../constants/constants';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { WorksheetTypeIds } from '../models/common/worksheet-type-ids.enum';
import { TillageMethod } from '../models/crop/tillage-method';
import { TillagePractice } from '../models/crop/tillage-practice';
import { FlagType } from '../models/flag/flag-type.model';
import { MaterialNutrientCode } from '../models/material/material-nutrient-code';
import { DbUser } from '../models/user/db-user';
import { Preference } from '../models/user/preference';
import { Worksheet } from '../models/worksheet/Worksheet';
import { WorksheetType } from '../models/worksheet/WorksheetType';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  initPreference() {
    if (!this.preference) {
      this.preference = new Preference(Constants.DEFAULT_LANGUAGE_TYPE, Constants.DEFAULT_MEASUREMENT_SYSTEM);
    }
  }

  setTimestamp() {
    this.ts = new Date().getTime();
  }

  setHash() {
    this.hash = AppConstants.API_CACHE_ENTRIES.map(v => (this[v] ? this[v].length : 0)).reduce((r, c) => (r += c), 0);
  }

  private cacheDurationInHours() {
    const ts = moment(this.ts);
    const now = moment();
    return moment.duration(now.diff(ts)).asHours();
  }

  /**
   * this is the assumption we're making from now, if no timestamp or hash set
   * we assume cache is not loaded yet
   */
  get empty() {
    return !this.ts || !this.hash;
  }

  /**
   * cache expries in 12 hours
   */
  get expired() {
    const hours = this.cacheDurationInHours();
    return hours > AppConstants.CACHE_LIFESPAN_IN_HOURS;
  }

  /**
   * hash is calculated base on the concatenation of
   * all api cache names. If a new cache is available,
   * this hash will change and force a reload
   */
  get adding() {
    return this.hash !== AppConstants.API_CACHE_ENTRIES.map(v => (this[v] ? this[v].length : 0)).reduce((r, c) => (r += c), 0);
  }

  get user() {
    const user = localStorage.getItem('user');
    return user && user !== 'undefined' ? JSON.parse(user.toString()) : undefined;
  }

  set user(user: DbUser) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  // preference cache
  set preference(preference: Preference) {
    localStorage.setItem('user/preference', JSON.stringify(preference));
  }

  get preference() {
    const pref = localStorage.getItem('user/preference');
    return pref ? JSON.parse(pref.toString()) : undefined;
  }

  set worksheetTypes(worksheetTypes: WorksheetType[]) {
    const types = Utils.sortWithReturn(worksheetTypes, 'displayOrder');
    localStorage.setItem('worksheetTypes', JSON.stringify(types));
  }

  get worksheetTypes() {
    return JSON.parse(localStorage.getItem('worksheetTypes'));
  }

  setMaterialTypes(worksheetId: string, materialTypes: MaterialType[]) {
    localStorage.setItem('materialTypes_' + worksheetId, JSON.stringify(materialTypes));
  }

  getMaterialTypes(worksheetId: string) {
    if (!worksheetId || worksheetId === 'undefined') {
      return undefined;
    }
    return JSON.parse(localStorage.getItem('materialTypes_' + worksheetId));
  }

  getMaterialType(id: string) {
    if (!id || id === 'undefined') {
      return undefined;
    }
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).indexOf('materialTypes_') > -1) {
        const key = localStorage.key(i);
        const materialTypes = JSON.parse(localStorage.getItem(key));
        const materialType = materialTypes.find(v => v.id === id);
        if (materialType) {
          return materialType;
        }
      }
    }
    return undefined;
  }

  set equationValues(equationValues: EquationValue[]) {
    localStorage.setItem('equationValues', JSON.stringify(equationValues));
  }

  get equationValues() {
    const equationValues = localStorage.getItem('equationValues');
    return equationValues ? JSON.parse(localStorage.getItem('equationValues')) : undefined;
  }

  set nutrientCodes(nutrientCodes: MaterialNutrientCode[]) {
    localStorage.setItem('nutrientCodes', JSON.stringify(nutrientCodes));
  }

  get nutrientCodes() {
    const nutrientCodes = localStorage.getItem('nutrientCodes');
    return nutrientCodes ? JSON.parse(localStorage.getItem('nutrientCodes')) : undefined;
  }

  set soilTestCodes(soilTestCodes: SoilTestCode[]) {
    localStorage.setItem('soilTestCodes', JSON.stringify(soilTestCodes));
  }

  get soilTestCodes() {
    const soilTestCodes = localStorage.getItem('soilTestCodes');
    return soilTestCodes ? JSON.parse(localStorage.getItem('soilTestCodes')) : undefined;
  }

  get en() {
    const enFile = localStorage.getItem('en.json');
    return enFile ? JSON.parse(enFile.toString()) : undefined;
  }

  set en(en: any) {
    localStorage.setItem('en.json', JSON.stringify(en));
  }

  get fr() {
    const frFile = localStorage.getItem('fr.json');
    return frFile ? JSON.parse(frFile.toString()) : undefined;
  }

  set fr(fr: any) {
    localStorage.setItem('fr.json', JSON.stringify(fr));
  }

  get ts() {
    const ts = localStorage.getItem('ts');
    return ts ? JSON.parse(ts.toString()) : undefined;
  }

  set ts(ts: any) {
    localStorage.setItem('ts', JSON.stringify(ts));
  }

  get hash() {
    const hash = localStorage.getItem('hash');
    return hash ? JSON.parse(hash.toString()) : undefined;
  }

  set hash(hash: any) {
    localStorage.setItem('hash', JSON.stringify(hash));
  }

  get buildVersion() {
    const buildVersion = localStorage.getItem('buildVersion');
    return buildVersion ? JSON.parse(buildVersion.toString()) : undefined;
  }

  set buildVersion(buildVersion: any) {
    localStorage.setItem('buildVersion', JSON.stringify(buildVersion));
  }

  // worksheet cache
  get worksheets() {
    const worksheets: Worksheet[] = [];
    Object.keys(WorksheetTypeIds).forEach(key => {
      const worksheet = localStorage.getItem(key);
      if (worksheet) {
        worksheets.push(JSON.parse(worksheet));
      }
    });
    return worksheets;
  }

  get platoWorksheetExist() {
    return localStorage.getItem('PHOSPHORUS_LOSS') ? true : false;
  }

  get flagTypes(): FlagType[] {
    const flagTypes = localStorage.getItem('flagTypes');
    return flagTypes ? JSON.parse(flagTypes.toString()) : undefined;
  }

  set flagTypes(flagTypes: FlagType[]) {
    localStorage.setItem('flagTypes', JSON.stringify(flagTypes));
  }

  get allCropTypes() {
    const allCropTypes = localStorage.getItem('allCropTypes');
    return allCropTypes ? JSON.parse(allCropTypes.toString()) : undefined;
  }

  set allCropTypes(allCropTypes: CropType[]) {
    localStorage.setItem('allCropTypes', JSON.stringify(allCropTypes));
  }

  get allCropSubTypes() {
    const allCropSubTypes = localStorage.getItem('allCropSubTypes');
    return allCropSubTypes ? JSON.parse(allCropSubTypes.toString()) : undefined;
  }

  set allCropSubTypes(allCropSubTypes: CropSubType[]) {
    localStorage.setItem('allCropSubTypes', JSON.stringify(allCropSubTypes));
  }

  get allNitrogenCredits() {
    const allNitrogenCredits = localStorage.getItem('allNitrogenCredits');
    return allNitrogenCredits ? JSON.parse(allNitrogenCredits.toString()) : undefined;
  }

  set allNitrogenCredits(allNitrogenCredits: NitrogenCredit[]) {
    localStorage.setItem('allNitrogenCredits', JSON.stringify(allNitrogenCredits));
  }

  get allSoilTextures() {
    const allSoilTextures = localStorage.getItem('allSoilTextures');
    return allSoilTextures ? JSON.parse(allSoilTextures.toString()) : undefined;
  }

  set allSoilTextures(allSoilTextures: SoilTexture[]) {
    localStorage.setItem('allSoilTextures', JSON.stringify(allSoilTextures));
  }

  get allTillageMethods() {
    const allTillageMethods = localStorage.getItem('allTillageMethods');
    return allTillageMethods ? JSON.parse(allTillageMethods.toString()) : undefined;
  }

  set allTillageMethods(allTillageMethods: TillageMethod[]) {
    localStorage.setItem('allTillageMethods', JSON.stringify(allTillageMethods));
  }

  get allTillagePractices() {
    const allTillagePractices = localStorage.getItem('allTillagePractices');
    return allTillagePractices ? JSON.parse(allTillagePractices.toString()) : undefined;
  }

  set allTillagePractices(allTillagePractices: TillagePractice[]) {
    localStorage.setItem('allTillagePractices', JSON.stringify(allTillagePractices));
  }

  get soilSeries() {
    const soilSeries = localStorage.getItem('soilSeries');
    return soilSeries ? JSON.parse(soilSeries.toString()) : undefined;
  }

  set soilSeries(soilSeries: any[]) {
    localStorage.setItem('soilSeries', JSON.stringify(soilSeries));
  }

  get upperTierMunicipalities() {
    const upperTierMunicipalities = localStorage.getItem('upperTierMunicipalities');
    return upperTierMunicipalities ? JSON.parse(upperTierMunicipalities.toString()) : undefined;
  }

  set upperTierMunicipalities(upperTierMunicipalities: any[]) {
    localStorage.setItem('upperTierMunicipalities', JSON.stringify(upperTierMunicipalities));
  }

  get lowerTierMunicipalities() {
    const lowerTierMunicipalities = localStorage.getItem('lowerTierMunicipalities');
    return lowerTierMunicipalities ? JSON.parse(lowerTierMunicipalities.toString()) : undefined;
  }

  set lowerTierMunicipalities(lowerTierMunicipalities: any[]) {
    localStorage.setItem('lowerTierMunicipalities', JSON.stringify(lowerTierMunicipalities));
  }

  get geoTownships() {
    const geoTownships = localStorage.getItem('geoTownships');
    return geoTownships ? JSON.parse(geoTownships.toString()) : undefined;
  }

  set geoTownships(geoTownships: any[]) {
    localStorage.setItem('geoTownships', JSON.stringify(geoTownships));
  }

  get allMaterialTypes() {
    const allMaterialTypes = localStorage.getItem('allMaterialTypes');
    return allMaterialTypes ? JSON.parse(allMaterialTypes.toString()) : undefined;
  }

  set allMaterialTypes(allMaterialTypes: any[]) {
    localStorage.setItem('allMaterialTypes', JSON.stringify(allMaterialTypes));
  }

  set fertilizerIncorporations(fertilizerIncorporations: any[]) {
    localStorage.setItem('fertilizerIncorporations', JSON.stringify(fertilizerIncorporations));
  }

  get fertilizerIncorporations() {
    const fertilizerIncorporations = localStorage.getItem('fertilizerIncorporations');
    return fertilizerIncorporations ? JSON.parse(fertilizerIncorporations.toString()) : undefined;
  }

  set materialIncorporations(materialIncorporations: any[]) {
    localStorage.setItem('materialIncorporations', JSON.stringify(materialIncorporations));
  }

  get materialIncorporations() {
    const materialIncorporations = localStorage.getItem('materialIncorporations');
    return materialIncorporations ? JSON.parse(materialIncorporations.toString()) : undefined;
  }

  get cropRotations() {
    const cropRotations = localStorage.getItem('cropRotations');
    return cropRotations ? JSON.parse(cropRotations.toString()) : undefined;
  }

  set cropRotations(cropRotations: any[]) {
    localStorage.setItem('cropRotations', JSON.stringify(cropRotations));
  }

  get allDryRanges() {
    const allDryRanges = localStorage.getItem('allDryRanges');
    return allDryRanges ? JSON.parse(allDryRanges.toString()) : undefined;
  }

  set allDryRanges(allDryRanges: any[]) {
    localStorage.setItem('allDryRanges', JSON.stringify(allDryRanges));
  }

  get fertilizerProducts() {
    const fertilizerProducts = localStorage.getItem('fertilizerProducts');
    return fertilizerProducts ? JSON.parse(fertilizerProducts.toString()) : undefined;
  }

  set fertilizerProducts(fertilizerProducts: any[]) {
    localStorage.setItem('fertilizerProducts', JSON.stringify(fertilizerProducts));
  }

  get applicationMethods() {
    const applicationMethods = localStorage.getItem('applicationMethods');
    return applicationMethods ? JSON.parse(applicationMethods.toString()) : undefined;
  }

  set applicationMethods(applicationMethods: any[]) {
    localStorage.setItem('applicationMethods', JSON.stringify(applicationMethods));
  }

  get livestockTypes() {
    const livestockTypes = localStorage.getItem('livestockTypes');
    return livestockTypes ? JSON.parse(livestockTypes.toString()) : undefined;
  }

  set livestockTypes(livestockTypes: any[]) {
    localStorage.setItem('livestockTypes', JSON.stringify(livestockTypes));
  }

  get livestockTypeDietCommon() {
    const livestockTypeDietCommon = localStorage.getItem('livestockTypeDietCommon');
    return livestockTypeDietCommon ? JSON.parse(livestockTypeDietCommon.toString()) : undefined;
  }

  set livestockTypeDietCommon(livestockTypeDietCommon: any[]) {
    localStorage.setItem('livestockTypeDietCommon', JSON.stringify(livestockTypeDietCommon));
  }

  get treatmentTypes() {
    const treatmentTypes = localStorage.getItem('treatmentTypes');
    return treatmentTypes ? JSON.parse(treatmentTypes.toString()) : undefined;
  }

  set treatmentTypes(treatmentTypes: any[]) {
    localStorage.setItem('treatmentTypes', JSON.stringify(treatmentTypes));
  }

  get otherSourceTypes() {
    const otherSourceTypes = localStorage.getItem('otherSourceTypes');
    return otherSourceTypes ? JSON.parse(otherSourceTypes.toString()) : undefined;
  }

  set otherSourceTypes(otherSourceTypes: any[]) {
    localStorage.setItem('otherSourceTypes', JSON.stringify(otherSourceTypes));
  }

  get transferTypes() {
    const transferTypes = localStorage.getItem('transferTypes');
    return transferTypes ? JSON.parse(transferTypes.toString()) : undefined;
  }

  set transferTypes(transferTypes: any[]) {
    localStorage.setItem('transferTypes', JSON.stringify(transferTypes));
  }

  get livestockCombinations() {
    const livestockCombinations = localStorage.getItem('livestockCombinations');
    return livestockCombinations ? JSON.parse(livestockCombinations.toString()) : undefined;
  }

  set livestockCombinations(livestockCombinations: any[]) {
    localStorage.setItem('livestockCombinations', JSON.stringify(livestockCombinations));
  }

  get livestockCombinationDetails() {
    const livestockCombinationDetails = localStorage.getItem('livestockCombinationDetails');
    return livestockCombinationDetails ? JSON.parse(livestockCombinationDetails.toString()) : undefined;
  }

  set livestockCombinationDetails(livestockCombinationDetails: any[]) {
    localStorage.setItem('livestockCombinationDetails', JSON.stringify(livestockCombinationDetails));
  }

  get storageTypes() {
    const storageTypes = localStorage.getItem('storageTypes');
    return storageTypes ? JSON.parse(storageTypes.toString()) : undefined;
  }

  set storageTypes(storageTypes: any[]) {
    localStorage.setItem('storageTypes', JSON.stringify(storageTypes));
  }

  get storageDimensions() {
    const storageDimensions = localStorage.getItem('storageDimensions');
    return storageDimensions ? JSON.parse(storageDimensions.toString()) : undefined;
  }

  set storageDimensions(storageDimensions: any[]) {
    localStorage.setItem('storageDimensions', JSON.stringify(storageDimensions));
  }

  get storageDimensionLinks() {
    const storageDimensionLinks = localStorage.getItem('storageDimensionLinks');
    return storageDimensionLinks ? JSON.parse(storageDimensionLinks.toString()) : undefined;
  }

  set storageDimensionLinks(storageDimensionLinks: any[]) {
    localStorage.setItem('storageDimensionLinks', JSON.stringify(storageDimensionLinks));
  }

  get storageChecklists() {
    const storageChecklists = localStorage.getItem('storageChecklists');
    return storageChecklists ? JSON.parse(storageChecklists.toString()) : undefined;
  }

  set storageChecklists(storageChecklists: any[]) {
    localStorage.setItem('storageChecklists', JSON.stringify(storageChecklists));
  }

  get storageTemporaries() {
    const storageTemporaries = localStorage.getItem('storageTemporaries');
    return storageTemporaries ? JSON.parse(storageTemporaries.toString()) : undefined;
  }

  set storageTemporaries(storageTemporaries: any[]) {
    localStorage.setItem('storageTemporaries', JSON.stringify(storageTemporaries));
  }

  get storageTemporaryOptions() {
    const storageTemporaryOptions = localStorage.getItem('storageTemporaryOptions');
    return storageTemporaryOptions ? JSON.parse(storageTemporaryOptions.toString()) : undefined;
  }

  set storageTemporaryOptions(storageTemporaryOptions: any[]) {
    localStorage.setItem('storageTemporaryOptions', JSON.stringify(storageTemporaryOptions));
  }

  get runoffManagementOptions() {
    const runoffManagementOptions = localStorage.getItem('runoffManagementOptions');
    return runoffManagementOptions ? JSON.parse(runoffManagementOptions.toString()) : undefined;
  }

  set runoffManagementOptions(runoffManagementOptions: any[]) {
    localStorage.setItem('runoffManagementOptions', JSON.stringify(runoffManagementOptions));
  }

  get landUseTypes() {
    const landUseTypes = localStorage.getItem('landUseTypes');
    return landUseTypes ? JSON.parse(landUseTypes.toString()) : undefined;
  }

  set landUseTypes(landUseTypes: any[]) {
    localStorage.setItem('landUseTypes', JSON.stringify(landUseTypes));
  }

  get horticulturalType() {
    const horticulturalType = localStorage.getItem('horticulturalType');
    return horticulturalType ? JSON.parse(horticulturalType.toString()) : undefined;
  }

  set horticulturalType(horticulturalType: any[]) {
    localStorage.setItem('horticulturalType', JSON.stringify(horticulturalType));
  }

  get mdsMaterialTypes() {
    const mdsMaterialTypes = localStorage.getItem('mdsMaterialTypes');
    return mdsMaterialTypes ? JSON.parse(mdsMaterialTypes.toString()) : undefined;
  }

  set mdsMaterialTypes(mdsMaterialTypes: any[]) {
    localStorage.setItem('mdsMaterialTypes', JSON.stringify(mdsMaterialTypes));
  }

  get mdsMaterialSubTypes() {
    const mdsMaterialSubTypes = localStorage.getItem('mdsMaterialSubTypes');
    return mdsMaterialSubTypes ? JSON.parse(mdsMaterialSubTypes.toString()) : undefined;
  }

  set mdsMaterialSubTypes(mdsMaterialSubTypes: any[]) {
    localStorage.setItem('mdsMaterialSubTypes', JSON.stringify(mdsMaterialSubTypes));
  }

  get mdsStorageTypes() {
    const mdsStorageTypes = localStorage.getItem('mdsStorageTypes');
    return mdsStorageTypes ? JSON.parse(mdsStorageTypes.toString()) : undefined;
  }

  set mdsStorageTypes(mdsStorageTypes: any[]) {
    localStorage.setItem('mdsStorageTypes', JSON.stringify(mdsStorageTypes));
  }

  get overviewOperations() {
    const overviewOperations = localStorage.getItem('overviewOperations');
    return overviewOperations ? JSON.parse(overviewOperations.toString()) : undefined;
  }

  set overviewOperations(overviewOperations: any[]) {
    localStorage.setItem('overviewOperations', JSON.stringify(overviewOperations));
  }

  get nasmTypes() {
    const nasmTypes = localStorage.getItem('nasmTypes');
    return nasmTypes ? JSON.parse(nasmTypes.toString()) : undefined;
  }

  set nasmTypes(nasmTypes: any[]) {
    localStorage.setItem('nasmTypes', JSON.stringify(nasmTypes));
  }

  get buildingInsulationLevels() {
    const buildingInsulationLevels = localStorage.getItem('buildingInsulationLevels');
    return buildingInsulationLevels ? JSON.parse(buildingInsulationLevels.toString()) : undefined;
  }

  set buildingInsulationLevels(buildingInsulationLevels: any[]) {
    localStorage.setItem('buildingInsulationLevels', JSON.stringify(buildingInsulationLevels));
  }

  get buildingInfiltrationLevels() {
    const buildingInfiltrationLevels = localStorage.getItem('buildingInfiltrationLevels');
    return buildingInfiltrationLevels ? JSON.parse(buildingInfiltrationLevels.toString()) : undefined;
  }

  set buildingInfiltrationLevels(buildingInfiltrationLevels: any[]) {
    localStorage.setItem('buildingInfiltrationLevels', JSON.stringify(buildingInfiltrationLevels));
  }

  get heatingTypes() {
    const heatingTypes = localStorage.getItem('heatingTypes');
    return heatingTypes ? JSON.parse(heatingTypes.toString()) : undefined;
  }

  set heatingTypes(heatingTypes: any[]) {
    localStorage.setItem('heatingTypes', JSON.stringify(heatingTypes));
  }

  get fuelTypes() {
    const fuelTypes = localStorage.getItem('fuelTypes');
    return fuelTypes ? JSON.parse(fuelTypes.toString()) : undefined;
  }

  set fuelTypes(fuelTypes: any[]) {
    localStorage.setItem('fuelTypes', JSON.stringify(fuelTypes));
  }

  get depthOfUnsaturatedSoilRestriction() {
    const depthOfUnsaturatedSoilRestriction = localStorage.getItem('depthOfUnsaturatedSoilRestriction');
    return depthOfUnsaturatedSoilRestriction ? JSON.parse(depthOfUnsaturatedSoilRestriction.toString()) : undefined;
  }

  set depthOfUnsaturatedSoilRestriction(depthOfUnsaturatedSoilRestriction: any[]) {
    localStorage.setItem('depthOfUnsaturatedSoilRestriction', JSON.stringify(depthOfUnsaturatedSoilRestriction));
  }

  get treeSpecies() {
    const treeSpecies = localStorage.getItem('treeSpecies');
    return treeSpecies ? JSON.parse(treeSpecies.toString()) : undefined;
  }

  set treeSpecies(treeSpecies: any[]) {
    localStorage.setItem('treeSpecies', JSON.stringify(treeSpecies));
  }

  get feedAdditives() {
    const feedAdditives = localStorage.getItem('feedAdditives');
    return feedAdditives ? JSON.parse(feedAdditives.toString()) : undefined;
  }

  set feedAdditives(feedAdditives: any[]) {
    localStorage.setItem('feedAdditives', JSON.stringify(feedAdditives));
  }

  get greenhouseCoveringMaterial() {
    const greenhouseCoveringMaterial = localStorage.getItem('greenhouseCoveringMaterial');
    return greenhouseCoveringMaterial ? JSON.parse(greenhouseCoveringMaterial.toString()) : undefined;
  }

  set greenhouseCoveringMaterial(greenhouseCoveringMaterial: any[]) {
    localStorage.setItem('greenhouseCoveringMaterial', JSON.stringify(greenhouseCoveringMaterial));
  }

  get greenhouseConstructionLevel() {
    const greenhouseConstructionLevel = localStorage.getItem('greenhouseConstructionLevel');
    return greenhouseConstructionLevel ? JSON.parse(greenhouseConstructionLevel.toString()) : undefined;
  }

  set greenhouseConstructionLevel(greenhouseConstructionLevel: any[]) {
    localStorage.setItem('greenhouseConstructionLevel', JSON.stringify(greenhouseConstructionLevel));
  }

  get greenhouseThermalCurtain() {
    const greenhouseThermalCurtain = localStorage.getItem('greenhouseThermalCurtain');
    return greenhouseThermalCurtain ? JSON.parse(greenhouseThermalCurtain.toString()) : undefined;
  }

  set greenhouseThermalCurtain(greenhouseThermalCurtain: any[]) {
    localStorage.setItem('greenhouseThermalCurtain', JSON.stringify(greenhouseThermalCurtain));
  }

  get greenhouseCropType() {
    const greenhouseCropType = localStorage.getItem('greenhouseCropType');
    return greenhouseCropType ? JSON.parse(greenhouseCropType.toString()) : undefined;
  }

  set greenhouseCropType(greenhouseCropType: any[]) {
    localStorage.setItem('greenhouseCropType', JSON.stringify(greenhouseCropType));
  }

  get greenhouseLightingType() {
    const greenhouseLightingType = localStorage.getItem('greenhouseLightingType');
    return greenhouseLightingType ? JSON.parse(greenhouseLightingType.toString()) : undefined;
  }

  set greenhouseLightingType(greenhouseLightingType: any[]) {
    localStorage.setItem('greenhouseLightingType', JSON.stringify(greenhouseLightingType));
  }

  get grainDryerType() {
    const grainDryerType = localStorage.getItem('grainDryerType');
    return grainDryerType ? JSON.parse(grainDryerType.toString()) : undefined;
  }

  set grainDryerType(grainDryerType: any[]) {
    localStorage.setItem('grainDryerType', JSON.stringify(grainDryerType));
  }

  get grainDryerCropType() {
    const grainDryerCropType = localStorage.getItem('grainDryerCropType');
    return grainDryerCropType ? JSON.parse(grainDryerCropType.toString()) : undefined;
  }

  set grainDryerCropType(grainDryerCropType: any[]) {
    localStorage.setItem('grainDryerCropType', JSON.stringify(grainDryerCropType));
  }

  get climateStationIdfCurve() {
    const climateStationIdfCurve = localStorage.getItem('climateStationIdfCurve');
    return climateStationIdfCurve ? JSON.parse(climateStationIdfCurve.toString()) : undefined;
  }

  set climateStationIdfCurve(climateStationIdfCurve: any[]) {
    localStorage.setItem('climateStationIdfCurve', JSON.stringify(climateStationIdfCurve));
  }

  get controlStructureType() {
    const controlStructureType = localStorage.getItem('controlStructureType');
    return controlStructureType ? JSON.parse(controlStructureType.toString()) : undefined;
  }

  set controlStructureType(controlStructureType: any[]) {
    localStorage.setItem('controlStructureType', JSON.stringify(controlStructureType));
  }

  get climateStationUpperTierLink() {
    const climateStationUpperTierLink = localStorage.getItem('climateStationUpperTierLink');
    return climateStationUpperTierLink ? JSON.parse(climateStationUpperTierLink.toString()) : undefined;
  }

  set climateStationUpperTierLink(climateStationUpperTierLink: any[]) {
    localStorage.setItem('climateStationUpperTierLink', JSON.stringify(climateStationUpperTierLink));
  }

  get runoffCurveNumber() {
    const runoffCurveNumber = localStorage.getItem('runoffCurveNumber');
    return runoffCurveNumber ? JSON.parse(runoffCurveNumber.toString()) : undefined;
  }

  set runoffCurveNumber(runoffCurveNumber: any[]) {
    localStorage.setItem('runoffCurveNumber', JSON.stringify(runoffCurveNumber));
  }

  get waterwayLining() {
    const waterwayLining = localStorage.getItem('waterwayLining');
    return waterwayLining ? JSON.parse(waterwayLining.toString()) : undefined;
  }

  set waterwayLining(waterwayLining: any[]) {
    localStorage.setItem('waterwayLining', JSON.stringify(waterwayLining));
  }

  get flowVelocityCoefficients() {
    const flowVelocityCoefficients = localStorage.getItem('flowVelocityCoefficients');
    return flowVelocityCoefficients ? JSON.parse(flowVelocityCoefficients.toString()) : undefined;
  }

  set flowVelocityCoefficients(flowVelocityCoefficients: any[]) {
    localStorage.setItem('flowVelocityCoefficients', JSON.stringify(flowVelocityCoefficients));
  }

  get soilErosionCrop() {
    const soilErosionCrop = localStorage.getItem('soilErosionCrop');
    return soilErosionCrop ? JSON.parse(soilErosionCrop.toString()) : undefined;
  }

  set soilErosionCrop(soilErosionCrop: any[]) {
    localStorage.setItem('soilErosionCrop', JSON.stringify(soilErosionCrop));
  }

  public deleteWorksheets() {
    Object.keys(WorksheetTypeIds).forEach(key => localStorage.removeItem(key));
  }

  public getWorksheet(key: string) {
    const worksheet = localStorage.getItem(key);
    return worksheet ? JSON.parse(worksheet) : undefined;
  }

  public setWorksheet(worksheet: any, key: string) {
    localStorage.setItem(key, JSON.stringify(worksheet));
  }

  public deleteWorksheet(path: string) {
    const key = Object.keys(WorksheetTypeIds).find(v => v === path);
    localStorage.removeItem(key);

    // delete local cache for phosphate material type
    this.deleteLocalMaterialTypes(path);
  }

  getMeasurementSystemName(): string {
    return MeasurementSystem[this.preference.measurementSystem];
  }

  private deleteLocalMaterialTypes(path: string) {
    if (path && path.indexOf(WorksheetKeys.PLATO) > -1) {
      const arr = []; // Array to hold the keys
      // Iterate over localStorage and that meet the condition into arr
      for (let i = 0; i < localStorage.length; i++) {
        if (localStorage.key(i).substring(0, 14) === 'materialTypes_') {
          arr.push(localStorage.key(i));
        }
      }
      // Iterate over arr and remove the items by key
      arr.forEach(key => localStorage.removeItem(key));
    }
  }
}
