import { Pipe, PipeTransform } from '@angular/core';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitService } from '@shared/services/unit.service';
import { combineLatest, of } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Pipe({ name: 'yieldUnitPipe' })
export class YieldUnitPipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private measurementService: MeasurementService,
    private unitService: UnitService) { }

  transform(val: string, yieldUnit: number): any {
    const measurementSystem = this.measurementService.measurementSystem;
    const languageType = this.languageService.languageType;
    return combineLatest([this.measurementService.measurementSystem$.pipe(startWith(measurementSystem)),
    this.languageService.languageType$.pipe(startWith(languageType))]).
      pipe(switchMap(([measurement, lang]) => {
        this.unitService.init(measurement, LanguageType[lang]);
        const result = yieldUnit === 1 ? this.unitService.getYield() : this.unitService.getYield2();
        return of(result);
      }));
  }
}
