import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Crop } from '@shared/models/worksheet/crop.model';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedCropService {
    constructor(private httpClient: HttpClient) { }

    save(application: Crop): Observable<Crop> {
        if (application && !application.createdDateTime) {
            application.updatedDateTime = new Date();
            application.createdDateTime = new Date();
        }
        return this.httpClient.post(URLs.SHARED_CROP_ENDPOINT, application) as Observable<Crop>;
    }

    update(application: Crop): Observable<Crop> {
        if (application) {
            application.updatedDateTime = new Date();
        }
        return this.httpClient.put(`${URLs.SHARED_CROP_ENDPOINT}/${application.id}`,
            application) as Observable<Crop>;
    }

    delete(id: string): Observable<boolean> {
        return this.httpClient.delete(`${URLs.SHARED_CROP_ENDPOINT}/${id}`) as Observable<boolean>;
    }

    get(id: number): Observable<Crop> {
        return this.httpClient.get(`${URLs.SHARED_CROP_ENDPOINT}/${id}`) as Observable<Crop>;
    }

    list(fieldSectionId: number): Observable<Crop[]> {
        return this.httpClient.get(`${URLs.SHARED_CROP_ENDPOINT}/list/${fieldSectionId}`) as Observable<Crop[]>;
    }
}
