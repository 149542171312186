import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { FmpFarm } from './fmp-farm.model';
import { FmpMaterialType } from './fmp-material-type.model';
import { WorksheetEconomic } from '../../../shared/models/common/worksheet-economic.model';

export class FmpWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.FIELD_MANAGEMENT_PLAN;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  farms: FmpFarm[] = [];
  materialTypes: FmpMaterialType[] = [];
  isLocal = true;
  currentFarmId: string;
  currentFieldId: string;
  currentMaterialTypeId: string;
  worksheetEconomic: WorksheetEconomic;
  currentTab = 0;

  constructor(init?: Partial<FmpWorksheet>) {
    super();
    this.name = this.isEn ? 'Field Plan 1' : 'Plan du champ 1';
    this.farms[0] = init ? new FmpFarm(init.farms ? init.farms[0] : undefined) : new FmpFarm();
    Object.assign(this, init);

    this.currentFarmId = this.farms && this.farms[0] ? this.farms[0].id : undefined;
    this.currentFieldId =
      this.farms && this.farms[0] && this.farms[0].fields && this.farms[0].fields[0] ? this.farms[0].fields[0].id : undefined;
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      farms: new UntypedFormArray(this.farms.map(v => new FmpFarm(v).toForm())),
      materialTypes: new UntypedFormArray(this.materialTypes.map(v => new FmpMaterialType(v).toForm())),
      currentFarmId: new UntypedFormControl(this.currentFarmId),
      currentFieldId: new UntypedFormControl(this.currentFieldId),
      currentMaterialTypeId: new UntypedFormControl(this.currentMaterialTypeId),
      worksheetEconomic: new WorksheetEconomic(this.worksheetEconomic).toForm(),
      currentTab: new UntypedFormControl(this.currentTab)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const farms = form.get('farms') as UntypedFormArray;
    const materialTypes = form.get('materialTypes') as UntypedFormArray;
    const worksheetEconomic = form.get('worksheetEconomic') as UntypedFormGroup;
    return new FmpWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      farms: farms.controls.map((w: UntypedFormGroup) => new FmpFarm().toModel(w)),
      materialTypes: materialTypes.controls.map((w: UntypedFormGroup) => new FmpMaterialType().toModel(w)),
      currentFarmId: v.currentFarmId,
      currentFieldId: v.currentFieldId,
      currentMaterialTypeId: v.currentMaterialTypeId,
      worksheetEconomic: new WorksheetEconomic().toModel(worksheetEconomic),
      currentTab: v.currentTab
    });
  }

  fromOrganicAmendment() {
    this.worksheetTypeId = WorksheetTypeIds.FIELD_MANAGEMENT_PLAN;
    this.currentFieldId = this.farms[0].fields[0].id;
    this.farms[0].id = Utils.uuid();
    this.farms[0].fields[0].id = Utils.uuid();
    this.farms[0].fields[0].farmId = this.farms[0].id;
    this.farms[0].fields[0].fieldSections[0].id = Utils.uuid();
    this.farms[0].fields[0].fieldSections[0].materialApplications[0].id = Utils.uuid();
    this.farms[0].fields[0].fieldSections[0].materialApplications[0].materialTypeId = this.materialTypes[0].id;
  }

  fromCrop() {
    this.worksheetTypeId = WorksheetTypeIds.FIELD_MANAGEMENT_PLAN;
    this.currentFieldId = this.farms[0].fields[0].id;
    this.farms[0].id = Utils.uuid();
    this.farms[0].fields[0].id = Utils.uuid();
    this.farms[0].fields[0].farmId = this.farms[0].id;
    this.farms[0].fields[0].fieldSections[0].id = Utils.uuid();
    this.farms[0].fields[0].fieldSections[0].crops[0].id = Utils.uuid();
    if (this.farms[0].fields[0].fieldSections[0].crops[0].tillageMethodNotill) {
      this.farms[0].fields[0].fieldSections[0].crops[0].tillageMethod = 4; // No-till
    }
    if (this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[0].testValue === -1) {
      this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[0].testValue = 0;
    }
    if (this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[1].testValue === -1) {
      this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[1].testValue = 0;
    }
    if (this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[2].testValue === -1) {
      this.farms[0].fields[0].fieldSections[0].soilSamples[0].soilTestValues[2].testValue = 0;
    }
  }
}
