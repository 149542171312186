import { Observable } from 'rxjs';
import { Worksheet } from '../../models/worksheet/Worksheet';
import { DialogDataType } from './dialog-data-type.enum';

export class DialogData {
  id: number;
  title: string;
  message: string | string[];
  isArray = false;
  msgSuffix: string;
  customMessage$: Observable<any>;
  btnOk: string;
  btnOkStyle: string;
  displayBtnCancel = true;
  displayBtnOk: boolean;
  btnCancel: string;
  worksheet: Worksheet;
  defaultWorksheetTypeId: string;

  constructor() {
    this.displayBtnOk = true;
  }

  static getInstance(dialogType: DialogDataType, messageData: any): DialogData {
    const data = new DialogData();
    data.btnCancel = 'dialog.action.cancel';

    const { customMessage$, message, msgSuffix } = messageData;

    switch (dialogType) {
      case DialogDataType.Delete:
        data.title = 'dialog.title.delete';
        data.message = 'dialog.message.delete.partial';
        data.btnOk = 'dialog.action.delete';
        data.displayBtnOk = true;
        data.btnOkStyle = 'button--red-primary';
        data.btnCancel = 'dialog.action.cancel';
        if (customMessage$) {
          data.customMessage$ = customMessage$;
        }
        if (msgSuffix) {
          data.msgSuffix = msgSuffix;
        }
        break;
      case DialogDataType.Info:
        data.title = 'dialog.title.info';
        data.displayBtnOk = false;
        data.btnCancel = 'dialog.action.ok';
        data.message = message;
        break;
    }

    return data;
  }
}
