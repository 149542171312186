import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { MDSMasterCalculation } from '@mds1/models/mds-master-calculation.model';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { MdsReportType } from '../models/mds-report-type.enum';
import { MDS1Worksheet } from '../models/mds-worksheet.model';
import { MDSService } from './mds.service';

@Injectable({ providedIn: 'root' })
export class MdsMasterCalculationService {

  constructor(private formService: FormService,
    private httpClient: HttpClient,
    private mdsService: MDSService) { }

  calculation$ = new BehaviorSubject<MDSMasterCalculation>(undefined);

  // tslint:disable-next-line: variable-name
  input$ = new BehaviorSubject(undefined);

  calculate(calcuationType?: MdsReportType) {
    const model = new MDS1Worksheet().toModel(this.formService.f);
    if (calcuationType === MdsReportType.currentCalculation
      && this.currentCalculation && this.currentCalculation.value) {
      // Only keep current calculation for calculation
      const calculation = model.calculations.find(v => Utils.matchStr(v.id, this.currentCalculation.get('id').value));
      model.calculations = [calculation];
    }
    this.input$.next(model);
  }

  bind() {
    return this.input$
      .pipe(
        distinctUntilChanged(),
        // tslint:disable-next-line: deprecation
        startWith(undefined),
        switchMap(data => data ?
          Utils.errorsomeStream(this.httpClient.post(URLs.MDS_MASTER_CALCULATION_ENDPOINT, data)) : EMPTY),
        tap((res) => {
          if (res) {
            this.calculation$.next(res);
            this.mdsService.displayReport$ = of(true);
          }
        }));
  }

  get calculations(): UntypedFormArray {
    return this.formService?.f?.get('calculations') as UntypedFormArray;
  }

  get currentCalculationId(): UntypedFormGroup {
    return this.formService?.f?.get('currentCalculationId') as UntypedFormGroup;
  }

  get currentCalculation(): UntypedFormGroup {
    return this.calculations?.controls?.find(v => Utils.matchStr(
      v?.get('id')?.value, this.currentCalculationId?.value)) as UntypedFormGroup;
  }

  get materials(): UntypedFormArray {
    return this.currentCalculation?.get('materials') as UntypedFormArray;
  }
}
