import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { WorksheetType } from '@shared/models/common/worksheet-type.enum';
import { BuildingsFormGroup } from '@shared/models/forms/buildings-form-group.model';
import { FarmsFormGroup } from '@shared/models/forms/farms-form-group.model';
import { StorageSystemsFormGroup } from '@shared/models/forms/storage-systems-form-group.model';

@Injectable({ providedIn: 'root' })
export class FormService {
  private _f: UntypedFormGroup;
  private _farms: FarmsFormGroup;
  private _systems: StorageSystemsFormGroup;
  private _buildings: BuildingsFormGroup;

  bind(f: UntypedFormGroup) {
    this._f = f;
    this._farms = new FarmsFormGroup(f?.get('farms') as UntypedFormArray);
    this._systems = new StorageSystemsFormGroup(f?.get('storageSystems') as UntypedFormArray);
    this._buildings = new BuildingsFormGroup(f?.get('buildings') as UntypedFormArray);
  }

  get f(): UntypedFormGroup {
    return this._f as UntypedFormGroup;
  }

  get currentTab(): UntypedFormControl {
    return this._f?.controls?.currentTab as UntypedFormControl;
  }

  get farms(): FarmsFormGroup {
    return this._farms;
  }

  get storageSystems(): StorageSystemsFormGroup {
    return this._systems;
  }

  get buildings(): BuildingsFormGroup {
    return this._buildings;
  }

  get worksheetTypeId(): string {
    return this.f?.controls?.worksheetTypeId?.value;
  }

  get worksheetTypeName(): WorksheetType {
    switch (this.worksheetTypeId) {
      case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
        return WorksheetType.NUTRIENT_MANAGEMENT_STRATEGY_PLAN;
      case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
        return WorksheetType.NON_AGRICULTURAL_SOURCE_MATERIAL;
      case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
        return WorksheetType.GREENHOUSE_NUTRIENT_FEEDWATER;
      default:
        return null;
    }
  }

  get isFmpWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.FIELD_MANAGEMENT_PLAN;
  }

  get isMds1Worksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1;
  }

  get isMds2Worksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2;
  }

  get isNmspWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN;
  }

  get isNasmWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL;
  }

  get isGnfWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER;
  }

  get isGhgWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.GREENHOUSE_GAS;
  }

  get isAgErosionWorksheet(): boolean {
    return this.worksheetTypeId === WorksheetTypeIds.AG_EROSION;
  }
}
