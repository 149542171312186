import { Injectable } from '@angular/core';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteFertilizerApplicationService {
  constructor(private cache: CacheService, private formService: FormService) {}

  save(application: any): Observable<any> {
    if (application && !application.createdDateTime) {
      application.createdDateTime = new Date();
      application.updatedDateTime = new Date();
    }
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const applications = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications;
        applications.push(application);
        this.cache.setWorksheet(worksheet, this.worksheetKey);
        return of(application);
      }
    }
    return EMPTY;
  }

  update(application: any): Observable<any> {
    if (application) {
      application.updatedDateTime = new Date();
    }
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const applications = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications;
        if (applications && applications.length) {
          const index = applications.findIndex(c => c.id === application.id);
          if (index > -1) {
            applications[index] = application;
            this.cache.setWorksheet(worksheet, this.worksheetKey);
            return of(application);
          }
        }
      }
    }
    return EMPTY;
  }

  delete(id: string): Observable<boolean> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const applications = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications;
        if (applications && applications.length) {
          const index = applications.findIndex(c => c.id === id);
          if (index > -1) {
            applications.splice(index, 1);
            this.cache.setWorksheet(worksheet, this.worksheetKey);
            return of(true);
          }
        }
      }
    }
    return of(false);
  }

  get(id: string): Observable<any> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const applications = worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications;
        if (applications && applications.length) {
          return of(applications.find(c => c.id === id));
        }
      }
    }
    return EMPTY;
  }

  list(fieldSectionId: number): Observable<any[]> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        return of(worksheet.farms[0].fields[0].fieldSections[0].fertilizerApplications);
      }
    }
    return EMPTY;
  }

  get isGhg() {
    return this.formService.isGhgWorksheet;
  }

  get worksheetKey() {
    return this.isGhg ? WorksheetKeys.GHG : WorksheetKeys.FMP;
  }
}
