import { Component, ElementRef, EventEmitter, Injector, Input, OnInit, Output, Renderer2, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AODA } from '@core/utilities/aoda';
import { Utils } from '@core/utilities/utils';
import { LabelClient } from '@shared/services/label.service';
import { LoadingService } from '@shared/services/loading.service';
import { tap } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { ManageLabelDialogComponent } from '../manage-label-dialog/manage-label-dialog.component';

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterMenuComponent extends BaseComponent implements OnInit {
  @Input() trigger: any;
  @Output() onClear = new EventEmitter();
  f: UntypedFormGroup;
  labels = [];
  labelList = new UntypedFormGroup({});

  constructor(
    private injector: Injector,
    private looadingService: LoadingService,
    private el: ElementRef,
    private r2: Renderer2,
    private labelClient: LabelClient
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.all().subscribe();
  }

  syncData() {
    this.looadingService.startLoading();
    this.onUpdate.emit(this.labelList.value);
  }

  all() {
    this.labelList = new UntypedFormGroup({});
    return this.labelClient.all().pipe(
      tap(labels => {
        if (labels && labels.length > 0) {
          this.labels = labels;
          this.labels.forEach(l => this.labelList.addControl(l.id, new UntypedFormControl(false)));
          this.aodaFix();
        }
      })
    );
  }

  openManageLabelsDialog() {
    this.trigger.closeMenu();
    this.dialogService.instance(ManageLabelDialogComponent, {}, { width: '380px' });
  }

  labelName(key) {
    return this.labels?.find(v => Utils.matchStr(key, v.id))?.labelName;
  }

  clearAll() {
    this.looadingService.startLoading();
    this.labelList.reset();
    this.onClear.emit();
  }

  private aodaFix() {
    setTimeout(() => {
      AODA.addCheckboxSvgAriaLabel(this.el, this.r2, this.languageService.languageType);
    }, 0);
  }

  get labelSelected() {
    const selection = this.labelList?.value;
    if (selection) {
      return Object.values(selection).some(v => v === true);
    }
    return false;
  }

  get object() {
    return Object;
  }

  get form() {
    return this.f?.controls;
  }
}
