import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { BaseModel } from "../worksheet/BaseModel";

export class Label extends BaseModel {
    userAccountId: string;
    parentId: string;
    labelName: string;
    editMode = false;

    constructor(init?: Partial<Label>) {
        super();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            userAccountId: new UntypedFormControl(this.userAccountId),
            parentId: new UntypedFormControl(this.parentId),
            labelName: new UntypedFormControl(this.labelName),
            editMode: new UntypedFormControl(this.editMode)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new Label({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            userAccountId: v.userAccountId,
            parentId: v.parentId,
            labelName: v.labelName,
            editMode: v.editMode
        });
    }
}