import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { MaterialApplication } from './material-application.model';

export class OrganicAmendmentFieldSection extends BaseModel {
    id: string;
    fieldId: string;
    name: string;
    soilTextureId: number;
    cropHeatUnits: number;
    fieldAreaInMetric: any = 0;
    materialApplications: MaterialApplication[] = [];

    constructor(init?: Partial<OrganicAmendmentFieldSection>) {
        super();
        this.materialApplications[0] = new MaterialApplication(init);
        Object.assign(this, init);
    }
}
