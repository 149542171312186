import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { MaterialTestValue } from './material-test-value.model';

export class MaterialSample extends BaseModel {
  dryMatterRange: number;
  dryMatterLow: number;
  dryMatterHigh: number;
  nutrientValueSource = 1;
  nutrientValueBasis = 0;
  materialTestValues: MaterialTestValue[] = [];

  constructor() {
    super();
  }
}
