import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { GhgWorksheet } from '@ghg/model/ghg-worksheet.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GhgService {
  displayReport$ = of(false);
  buildingExpanded$ = new BehaviorSubject(undefined);

  constructor(private formService: FormService, private cache: CacheService) {}

  expandBuildingPanel() {
    this.buildingExpanded$.next(Utils.uuid());
  }

  get(id: string): Observable<GhgWorksheet> {
    const worksheet = this.cache.getWorksheet(WorksheetKeys.GHG);
    if (!worksheet) {
      const ws = new GhgWorksheet();
      this.cache.setWorksheet(ws, WorksheetKeys.GHG);
    }
    return of(this.cache.getWorksheet(WorksheetKeys.GHG));
  }

  save(worksheet: GhgWorksheet): Observable<any> {
    this.cache.setWorksheet(worksheet, WorksheetKeys.GHG);
    return of(worksheet);
  }

  update(worksheet: any): Observable<any> {
    const ws = this.cache.getWorksheet(WorksheetKeys.GHG);
    Object.assign(ws, worksheet);
    this.cache.setWorksheet(ws, WorksheetKeys.GHG);
    return of(ws);
  }

  updateWorksheetFlat(worksheet: any): Observable<any> {
    return this.update(worksheet);
  }

  updateWorksheetEconomics(worksheet: GhgWorksheet): Observable<any> {
    const ws = this.cache.getWorksheet(WorksheetKeys.GHG);
    ws.worksheetEconomic = worksheet.worksheetEconomic;
    this.cache.setWorksheet(ws, WorksheetKeys.GHG);
    return of(ws);
  }

  /**
   * Since there is not CRUD service for contact information, refresh the worksheet contact list
   * based on the worksheet update result.
   */

  get contacts(): UntypedFormArray {
    return this.f.get('contacts') as UntypedFormArray;
  }

  get f(): UntypedFormGroup {
    return this.formService?.f;
  }
}
