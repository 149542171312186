
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { FertilizerField } from './fertilizer-field.model';

export class FertilizerFarm extends BaseModel {
  worksheetId: string;
  name: string;
  upperTierName: string;
  upperTierCode: string;
  geotownshipName: string;
  geotownshipId: string;
  fields: FertilizerField[] = [];

  constructor(init?: Partial<FertilizerFarm>) {
    super();
    this.name = this.isEn ? 'Farm A' : 'Ferme A';
    this.fields[0] = new FertilizerField(init);
    Object.assign(this, init);
  }
}

