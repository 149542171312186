import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { SeasonEmptying } from '@shared/models/common/season-emptying.enum';
import { ManureCovering } from '@shared/models/manure-covering.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Livestock } from './livestock.model';
import { MaterialProduced } from './material-produced.model';
import { MstorOtherSource } from './mstor-other-source.model';
import { MstorTreatment } from './mstor-treatment.model';
import { StorageGroup } from './storage-group.model';

export class StorageSystem extends BaseModel {
  name: string;
  notes: string;
  startDate: Date;
  endDate: Date;
  overrideStartDate: boolean;
  overrideEndDate: boolean;
  worksheetId: string;
  livestock: Livestock[];
  materialsProduced: MaterialProduced[];
  storageGroups: StorageGroup[];
  treatments: MstorTreatment[];
  otherSources: MstorOtherSource[];
  currentStorageGroupId: string;
  upperTierCode: number;
  specifyMitigationPractices: boolean;
  ghgDietOptimization: boolean;
  ghgAnaerobicDigesterLiquid: boolean;
  ghgAnaerobicDigesterSolid: boolean;
  ghgSeparationSolidLiquid: boolean;
  ghgCompostSolid: boolean;
  ghgManureAcidification: boolean;
  ghgManureCovering = ManureCovering.None;
  ghgStorageCoveredLiquid: boolean;
  ghgStorageCoveredSolid: boolean;
  ghgStorageEmptySeason = SeasonEmptying.Fall;
  ghgStorageRunoffSolid: boolean;

  constructor(init?: Partial<StorageSystem>) {
    super();
    this.id = this.id || Utils.uuid();
    this.name = this.isEn ? 'New system' : 'Nouvelle système';
    this.setDefaultDates();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      startDate: new UntypedFormControl(this.startDate),
      endDate: new UntypedFormControl(this.endDate),
      overrideStartDate: new UntypedFormControl(this.overrideStartDate),
      overrideEndDate: new UntypedFormControl(this.overrideEndDate),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      // tslint:disable-next-line: max-line-length
      livestock: new UntypedFormArray(this.livestock && this.livestock.length > 0 ? this.livestock.map(v => new Livestock(v).toForm()) : []),
      // tslint:disable-next-line: max-line-length
      otherSources: new UntypedFormArray(
        this.otherSources && this.otherSources.length > 0 ? this.otherSources.map(v => new MstorOtherSource(v).toForm()) : []
      ),
      // tslint:disable-next-line: max-line-length
      treatments: new UntypedFormArray(
        this.treatments && this.treatments.length > 0 ? this.treatments.map(v => new MstorTreatment(v).toForm()) : []
      ),
      // tslint:disable-next-line: max-line-length
      storageGroups: new UntypedFormArray(
        this.storageGroups && this.storageGroups.length > 0 ? this.storageGroups.map(v => new StorageGroup(v).toForm()) : []
      ),
      // tslint:disable-next-line: max-line-length
      materialsProduced: new UntypedFormArray(
        this.materialsProduced && this.materialsProduced.length > 0 ? this.materialsProduced.map(v => new MaterialProduced(v).toForm()) : []
      ),
      currentStorageGroupId: new UntypedFormControl(), // note: this is UI cache managed in the form for convenience, not to be saved in database
      upperTierCode: new UntypedFormControl(this.upperTierCode),
      specifyMitigationPractices: new UntypedFormControl(this.specifyMitigationPractices),
      ghgDietOptimization: new UntypedFormControl(this.ghgDietOptimization),
      ghgAnaerobicDigesterLiquid: new UntypedFormControl(this.ghgAnaerobicDigesterLiquid),
      ghgAnaerobicDigesterSolid: new UntypedFormControl(this.ghgAnaerobicDigesterSolid),
      ghgSeparationSolidLiquid: new UntypedFormControl(this.ghgSeparationSolidLiquid),
      ghgCompostSolid: new UntypedFormControl(this.ghgCompostSolid),
      ghgManureAcidification: new UntypedFormControl(this.ghgManureAcidification),
      ghgManureCovering: new UntypedFormControl(this.ghgManureCovering),
      ghgStorageCoveredLiquid: new UntypedFormControl(this.ghgStorageCoveredLiquid),
      ghgStorageCoveredSolid: new UntypedFormControl(this.ghgStorageCoveredSolid),
      ghgStorageEmptySeason: new UntypedFormControl(this.ghgStorageEmptySeason),
      ghgStorageRunoffSolid: new UntypedFormControl(this.ghgStorageRunoffSolid)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const livestock = form.get('livestock') as UntypedFormArray;
    const treatments = form.get('treatments') as UntypedFormArray;
    const storageGroups = form.get('storageGroups') as UntypedFormArray;
    const otherSources = form.get('otherSources') as UntypedFormArray;
    const materialsProduced = form.get('materialsProduced') as UntypedFormArray;
    return new StorageSystem({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      name: v.name,
      startDate: v.startDate,
      endDate: v.endDate,
      overrideStartDate: v.overrideStartDate,
      overrideEndDate: v.overrideEndDate,
      notes: v.notes,
      livestock: livestock?.controls.map((w: UntypedFormGroup) => new Livestock().toModel(w)),
      otherSources: otherSources?.controls.map((w: UntypedFormGroup) => new MstorOtherSource().toModel(w)),
      treatments: treatments?.controls.map((w: UntypedFormGroup) => new MstorTreatment().toModel(w)),
      storageGroups: storageGroups?.controls.map((w: UntypedFormGroup) => new StorageGroup().toModel(w)),
      // tslint:disable-next-line: max-line-length
      materialsProduced: materialsProduced?.controls.map((w: UntypedFormGroup) => new MaterialProduced().toModel(w)),
      currentStorageGroupId: v.currentStorageGroupId,
      upperTierCode: v.upperTierCode,
      specifyMitigationPractices: v.specifyMitigationPractices,
      ghgDietOptimization: v.ghgDietOptimization,
      ghgAnaerobicDigesterLiquid: v.ghgAnaerobicDigesterLiquid,
      ghgAnaerobicDigesterSolid: v.ghgAnaerobicDigesterSolid,
      ghgSeparationSolidLiquid: v.ghgSeparationSolidLiquid,
      ghgCompostSolid: v.ghgCompostSolid,
      ghgManureAcidification: v.ghgManureAcidification,
      ghgManureCovering: v.ghgManureCovering,
      ghgStorageCoveredLiquid: v.ghgStorageCoveredLiquid,
      ghgStorageCoveredSolid: v.ghgStorageCoveredSolid,
      ghgStorageEmptySeason: v.ghgStorageEmptySeason,
      ghgStorageRunoffSolid: v.ghgStorageRunoffSolid
    });
  }

  private setDefaultDates() {
    const defaultMonthIndex = 8;
    const defaultYear = new Date().getUTCFullYear() - 1;
    this.startDate = Utils.utcDate(defaultYear, defaultMonthIndex, 1);
    this.endDate = Utils.utcDate(defaultYear, defaultMonthIndex + 11, 1);
  }
}
