import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { CropNutrientFieldSection } from './crop-nutrient-field-section.model';

export class CropNutrientField extends BaseModel {
  farmId: string;
  name: string;
  fieldSections: CropNutrientFieldSection[] = [];

  constructor(init?: Partial<CropNutrientField>) {
    super(init);

    this.fieldSections[0] = new CropNutrientFieldSection(init?.fieldSections?.[0]);

    this.farmId = init?.farmId;
    this.name = init?.name || (this.isEn ? 'Field 1' : 'Champ 1');
  }
}
