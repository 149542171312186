import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {

  data: any = {};
  constructor(private http: HttpClient, private handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }

  translate(lang: string): Observable<any> {
    return from(this.use(lang));
  }

  private use(lang: string): Promise<{}> {
    return new Promise<{}>((resolve, reject) => {
      // const langPath = environment.i18nContent;
      const langPath = environment.i18nDirectory + `${lang || 'en'}.json`;

      // resolve all data from i18n content file
      this.http.get<{}>(langPath).subscribe(
        translation => {
          // this.data = translation;
          this.data = Object.assign({}, translation || {});
          resolve(this.data);
        },
        error => {
          console.log(error);
          this.data = {};
          resolve(this.data);
        }
      );
    });
  }
}
