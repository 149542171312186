import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { FmpCalculationService } from '@fmp/service/fmp-calculation.service';
import { GnfCalculationService } from '@gnf/service/gnf-calculation.service';
import { NmspCalculationService } from '@nmsp/services/nmsp-calculation.service';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { MaterialProduced } from '../model/material-produced.model';
import { MstorCalculationService } from './mstor-calculation.service';

@Injectable({ providedIn: 'root' })
export class MaterialProducedService { // todo, talk to daniel to see if this should be called 'InputMaterialService'

  constructor(private injector: Injector,
    private httpClient: HttpClient,
    private formService: FormService,
    private cache: CacheService) { }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem(): UntypedFormGroup {
    return this.storageSystems?.controls.find(v => Utils.matchStr(v.get('id').value,
      this.formService.f?.get('currentStorageSystemId')?.value)) as UntypedFormGroup;
  }

  get materialsProduced() {
    return this.currentStorageSystem?.get('materialsProduced') as UntypedFormArray;
  }

  update(materialProduced: MaterialProduced): Observable<any> {
    const url = `${URLs.SHARED_UPDATE_MATRIAL_PRODUCED_ENDPOINT}/${materialProduced.id}`;
    return this.httpClient.put(url, materialProduced);
  }

  /*
  * this block of code returns freshly calculated materialProduced for
  * the current entity
  * note: by design the latest materialProduced are always obtained from a calculation call
  **/
  materialsProduced$(id: string) {
    return this.calculationService?.calculation$.pipe(
      map(calculation => {
        if (calculation && this.currentStorageSystem) {
          const storageSystem = calculation.storageSizingCalculations?.
            find(v => Utils.matchStr(v.storageSystemID, this.currentStorageSystem.controls.id.value));
          if (storageSystem && storageSystem.materialsProduced) {
            return storageSystem.materialsProduced.
              filter(v => v.isActive && !Utils.matchStr(v.sourceId, id) &&
                (!v.destinationId || Utils.matchStr(v.destinationId, id))).
              map(v => v.id);
          }
        }
      })
    );
  }

  get calculationService() {
    return this._calculationService(this.injector, this.formService.f?.controls.worksheetTypeId.value);
  }

  private _calculationService(injector, worksheetTypeId): CalculationService {
    if (worksheetTypeId) {
      switch (worksheetTypeId) {
        case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
          return injector.get(FmpCalculationService);
        case WorksheetTypeIds.MANURE_STORAGE_SIZING:
          return injector.get(MstorCalculationService);
        case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
          return injector.get(NmspCalculationService);
        case WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER:
          return injector.get(GnfCalculationService);
      }
    }
  }

  materialsProducedDescription$(id: string, lang: string) {
    return this.calculationService?.calculation$.pipe(
      map(calculation => {
        if (calculation && this.currentStorageSystem) {
          const storageSystem = calculation.storageSizingCalculations?.
            find(v => Utils.matchStr(v.storageSystemID, this.currentStorageSystem.controls.id.value));
          if (storageSystem && storageSystem.materialsProduced) {
            const inputMaterial = storageSystem.materialsProduced.find(v => Utils.matchStr(v.id, id));
            if (inputMaterial) {
              return inputMaterial['description'][lang];
            }
          }
        }
      })
    );
  }

  /**
   * This block of code sets defaults input materials for entities like treatment, storage group
   * and save them on the server
   */
  configureMaterialsProduced(app: any, treatmentTypeId?: any) {
    // obtain treatmentType only for treatment entity
    const treatmentType = [undefined, null, ''].indexOf(treatmentTypeId) === -1 ?
      this.cache.treatmentTypes.find(v => v.treatmentTypeId === treatmentTypeId) : undefined;
    const materialsProduced = this.materialsProduced.
      controls.map((v: UntypedFormGroup) => new MaterialProduced().toModel(v)).
      filter(v => !Utils.matchStr(v.sourceId, app.id) &&
        (!v.destinationId || Utils.matchStr(v.destinationId, app.id)));
    // set defaults for the payloads
    this.setDefaults(materialsProduced, treatmentType, app);
    // also take care of the reactive form, this is so tedious
    this.configureForm(materialsProduced, app, treatmentType);
    // save to the server
    return materialsProduced?.length ? forkJoin(materialsProduced.map(v => this.update(v))) : of(undefined);
  }

  // form utilities
  pushMaterialsProduced(models: MaterialProduced[]) {
    models.forEach(model => {
      this.materialsProduced.push(new MaterialProduced(model).toForm());
    });
  }

  deleteMaterialsProducedBySourceId(id: string) {
    const indices = this.materialsProduced.controls.reduce((a, c, index) => {
      if (c && Utils.matchStr(c.get('sourceId').value, id)) {
        a.push(index);
      }
      return a;
    }, []);
    for (let i = indices.length - 1; i >= 0; i--) {
      this.materialsProduced.removeAt(indices[i]);
    }
  }

  nullOutMaterialsProducedDestinationId(id: string) {
    this.materialsProduced.controls.forEach(mat => {
      if (Utils.matchStr(mat.get('destinationId').value, id)) {
        mat.get('destinationId').patchValue(null);
      }
    });
  }

  private setDefaults(materialsProduced: MaterialProduced[], treatmentType: any, app: any) {
    materialsProduced.forEach(v => {
      if (!v.destinationId) {
        // treatment retrieves materialForm from treatmentType lookup wheres other entities use their own materialForm
        const materialForm = treatmentType ? treatmentType.materialForm : app.materialForm;
        if (v.materialForm === materialForm) {
          v.destinationId = app.id;
        }
      } else {
        v.destinationId = undefined;
      }
    });
  }

  /**
   * This block of code is a helper to set default input materials in the reactive form
   * materialsProduced
   * app
   * treatmentType
   */
  private configureForm(materialsProduced: MaterialProduced[], app: any, treatmentType?: any) {
    materialsProduced.forEach(v => {
      const materialProduced = this.materialsProduced.controls.
        find(c => Utils.matchStr(c.get('id').value, v.id)) as UntypedFormGroup;
      if (materialProduced) {
        const materialForm = treatmentType ? treatmentType.materialForm : app.materialForm;
        materialProduced.controls.destinationId.
          patchValue(materialProduced.controls.materialForm.value === materialForm ? app.id : undefined);
      }
    });
  }

}
