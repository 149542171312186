import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Constants } from '@shared/constants/constants';
import { BuildingType } from '@shared/models/common/building-type.enum';
import { CropAmountType } from '@shared/models/common/crop-amount-type.enum';
import { DelayedCoolingType } from '@shared/models/common/delayed-cooling-type.enum';
import { LightingControl } from '@shared/models/common/lighting-control.enum';
import { Month2 } from '@shared/models/common/month2.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { BuildingLivestock } from './building-livestock.model';
import { BuildingMonth } from './building-month.model';
import { DestratificationFan } from './destratification-fan.enum';

export class Building extends BaseModel {
  name: string;
  worksheetId: string;
  upperTierCode: number;
  buildingType = BuildingType.Barn;
  buildingLength: number;
  buildingWidth: number;
  buildingHeight: number;
  numberOfStoreys = Constants.DEFAULT_GHG_NUM_OF_STOREYS;
  hasAttic = false;
  insulationLevelWall = Constants.DEFAULT_GHG_BUILDING_INSU_WALL;
  insulationLevelRoof = Constants.DEFAULT_GHG_BUILDING_INSU_ROOF;
  infiltrationLevel = Constants.DEFAULT_GHG_BUILDING_INFIL;
  heatingType = Constants.DEFAULT_GHG_BUILDING_HEATING_TYPE;
  fuelType = Constants.DEFAULT_GHG_BUILDING_FUEL_TYPE;
  hasUnderBarnStorage = false;
  isFoundationInsulated = false;
  isFloorHeated = false;
  isTemperatureOverride = false;
  temperatureOverride = Constants.DEFAULT_GHG_BUILDING_TEMP_OVERRIDE;
  livestock: BuildingLivestock[];
  specifyMitigationPractices = false;
  isMitigationWallInsulation = false;
  isMitigationRoofInsulation = false;
  isMitigationDestratification = false;
  isMitigationElectricHeating = false;
  isMitigationAlternateHeating = false;
  isMitigationReplaceHeating = false;
  isHeatExchangerUsed = false;
  insulationLevelWallMitigation = Constants.DEFAULT_GHG_BUILDING_INSU_WALL;
  insulationLevelRoofMitigation = Constants.DEFAULT_GHG_BUILDING_INSU_ROOF;
  destratificationFans = DestratificationFan.None;
  heatingTypeElectricMitigation = Constants.DEFAULT_GHG_HEATING_TYPE_ELECTRIC_MITIGATION;
  heatingTypeAlternateMitigation = Constants.DEFAULT_GHG_FUEL_TYPE_ALTERNATE_MITIGATION;
  heatingTypeMitigation = Constants.DEFAULT_GHG_BUILDING_HEATING_TYPE;
  heatExchangerEfficiencyLevel = Constants.DEFAULT_GHG_HEAT_EXCHANGER_EFFICIENCY_LEVEL;
  isHeatExchangerAirflowUsed = false;
  heatExchangerAirflowRate: number;
  roofSlopeDirection = Constants.DEFAULT_GHG_BUILDING_ROOT_SLOPE_DIRECTION;
  isAreaOverride = false;
  areaOverride: number;
  temperatureSetpointDay = Constants.DEFAULT_GHG_BUILDING_TEMP_OVERRIDE;
  temperatureSetpointNight = Constants.DEFAULT_GHG_BUILDING_TEMP_OVERRIDE;
  relativeHumiditySetpoint = Constants.DEFAULT_GHG_BUILDING_HUMIDITY_SETPOINT;
  wallMaterial = Constants.DEFAULT_GHG_COVER_MATERIAL;
  roofMaterial = Constants.DEFAULT_GHG_COVER_MATERIAL;
  constructionLevel = Constants.DEFAULT_GHG_CONSTRUCTION_LEVEL;
  wallCurtain = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  roofCurtainTop = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  roofCurtainBottom = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  isWhitewashed = false;
  greenhouseCropType = Constants.DEFAULT_GHG_CROP_TYPE;
  lightingType = Constants.DEFAULT_GHG_LIGHTING_TYPE;
  lightingControl = LightingControl.NoLights;
  isEmpty = false;
  useMonthlyLightingInputs = false;
  lightingTimeMaximum: number;
  lightingTimeOn: number;
  isMitigationWallMaterial = false;
  isMitigationRoofMaterial = false;
  isMitigationWallCurtain = false;
  isMitigationRoofCurtain = false;
  isMitigationLighting = false;
  wallMaterialMitigation = Constants.DEFAULT_GHG_COVER_MATERIAL;
  roofMaterialMitigation = Constants.DEFAULT_GHG_COVER_MATERIAL;
  wallCurtainMitigation = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  roofCurtainTopMitigation = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  roofCurtainBottomMitigation = Constants.DEFAULT_GHG_THERMAL_CURTAIN;
  lightingTypeMitigation = Constants.DEFAULT_GHG_LIGHTING;
  lightingControlMitigation = LightingControl.NoLights;
  dryerTypeBase = Constants.DEFAULT_DRYER_TYPE;
  isDryerHeatRecoveryBase = false;
  isDryerSuctionCooledBase = false;
  dryerMonth = Month2.January;
  cropType = Constants.DEFAULT_GRAIN_DRYER_CROP_TYPE;
  cropAmount: number;
  cropAmountType = CropAmountType.BeforeDryer;
  cropMoistureWet: number;
  cropMoistureDry: number;
  isDryerEfficiencyCalculated = false;
  dryerEfficiencyAmount: number;
  dryerEfficiencyAmountType = CropAmountType.BeforeDryer;
  dryerEfficiencyMoistureWet: number;
  dryerEfficiencyMoistureDry: number;
  dryerEfficiencyFuelAmount: number;
  isMitigationDryerReplace = false;
  isMitigationDryerAddHeatRecovery = false;
  isMitigationDryerBurnerOptimized = false;
  dryerTypeNew = Constants.DEFAULT_DRYER_TYPE;
  isDryerHeatRecoveryNew = false;
  isDryerSuctionCooledNew = false;
  dryerDelayedCoolingType = DelayedCoolingType.None;
  cropTemperatureLeavingDryer = Constants.DEFAULT_GHG_BUILDING_TEMP_OVERRIDE;
  cropMoistureLeavingDryer: number;
  dryerTypeLowMoisture = Constants.DEFAULT_DRYER_TYPE;
  months = [];
  month = Month2.January;

  constructor(init?: Partial<Building>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'New building' : 'Nouveau bâtiment';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      upperTierCode: new UntypedFormControl(this.upperTierCode),
      buildingType: new UntypedFormControl(this.buildingType),
      buildingLength: new UntypedFormControl(this.buildingLength, {
        validators: [Validators.min(0), Validators.max(10000)]
      }),
      buildingWidth: new UntypedFormControl(this.buildingWidth, {
        validators: [Validators.min(0), Validators.max(10000)]
      }),
      buildingHeight: new UntypedFormControl(this.buildingHeight, {
        validators: [Validators.min(0), Validators.max(10000)]
      }),
      numberOfStoreys: new UntypedFormControl(this.numberOfStoreys, {
        validators: [Validators.min(0), Validators.max(10)]
      }),
      hasAttic: new UntypedFormControl(this.hasAttic),
      insulationLevelWall: new UntypedFormControl(this.insulationLevelWall),
      insulationLevelRoof: new UntypedFormControl(this.insulationLevelRoof),
      infiltrationLevel: new UntypedFormControl(this.infiltrationLevel),
      heatingType: new UntypedFormControl(this.heatingType),
      fuelType: new UntypedFormControl(this.fuelType),
      hasUnderBarnStorage: new UntypedFormControl(this.hasUnderBarnStorage),
      isFoundationInsulated: new UntypedFormControl(this.isFoundationInsulated),
      isFloorHeated: new UntypedFormControl(this.isFloorHeated),
      isTemperatureOverride: new UntypedFormControl(this.isTemperatureOverride),
      temperatureOverride: new UntypedFormControl(this.temperatureOverride, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      livestock: new UntypedFormArray(
        this.livestock && this.livestock.length > 0 ? this.livestock.map(v => new BuildingLivestock(v).toForm()) : []
      ),
      specifyMitigationPractices: new UntypedFormControl(this.specifyMitigationPractices),
      isMitigationWallInsulation: new UntypedFormControl(this.isMitigationWallInsulation),
      isMitigationRoofInsulation: new UntypedFormControl(this.isMitigationRoofInsulation),
      isMitigationDestratification: new UntypedFormControl(this.isMitigationDestratification),
      isMitigationElectricHeating: new UntypedFormControl(this.isMitigationElectricHeating),
      isMitigationAlternateHeating: new UntypedFormControl(this.isMitigationAlternateHeating),
      isMitigationReplaceHeating: new UntypedFormControl(this.isMitigationReplaceHeating),
      isHeatExchangerUsed: new UntypedFormControl(this.isHeatExchangerUsed),
      insulationLevelWallMitigation: new UntypedFormControl(this.insulationLevelWallMitigation),
      insulationLevelRoofMitigation: new UntypedFormControl(this.insulationLevelRoofMitigation),
      destratificationFans: new UntypedFormControl(this.destratificationFans),
      heatingTypeElectricMitigation: new UntypedFormControl(this.heatingTypeElectricMitigation),
      heatingTypeAlternateMitigation: new UntypedFormControl(this.heatingTypeAlternateMitigation),
      heatingTypeMitigation: new UntypedFormControl(this.heatingTypeMitigation),
      heatExchangerEfficiencyLevel: new UntypedFormControl(this.heatExchangerEfficiencyLevel),
      isHeatExchangerAirflowUsed: new UntypedFormControl(this.isHeatExchangerAirflowUsed),
      heatExchangerAirflowRate: new UntypedFormControl(this.heatExchangerAirflowRate, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      roofSlopeDirection: new UntypedFormControl(this.roofSlopeDirection),
      isAreaOverride: new UntypedFormControl(this.isAreaOverride),
      areaOverride: new UntypedFormControl(this.areaOverride, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      temperatureSetpointDay: new UntypedFormControl(this.temperatureSetpointDay, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      temperatureSetpointNight: new UntypedFormControl(this.temperatureSetpointNight, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      relativeHumiditySetpoint: new UntypedFormControl(this.relativeHumiditySetpoint, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      wallMaterial: new UntypedFormControl(this.wallMaterial),
      roofMaterial: new UntypedFormControl(this.roofMaterial),
      constructionLevel: new UntypedFormControl(this.constructionLevel),
      wallCurtain: new UntypedFormControl(this.wallCurtain),
      roofCurtainTop: new UntypedFormControl(this.roofCurtainTop),
      roofCurtainBottom: new UntypedFormControl(this.roofCurtainBottom),
      isWhitewashed: new UntypedFormControl(this.isWhitewashed),
      greenhouseCropType: new UntypedFormControl(this.greenhouseCropType),
      lightingType: new UntypedFormControl(this.lightingType),
      lightingControl: new UntypedFormControl(this.lightingControl),
      isEmpty: new UntypedFormControl(this.isEmpty),
      useMonthlyLightingInputs: new UntypedFormControl(this.useMonthlyLightingInputs),
      lightingTimeMaximum: new UntypedFormControl(this.lightingTimeMaximum, {
        validators: [Validators.min(0), Validators.max(24)]
      }),
      lightingTimeOn: new UntypedFormControl(this.lightingTimeOn, {
        validators: [Validators.min(0), Validators.max(24)]
      }),
      isMitigationWallMaterial: new UntypedFormControl(this.isMitigationWallMaterial),
      isMitigationRoofMaterial: new UntypedFormControl(this.isMitigationRoofMaterial),
      isMitigationWallCurtain: new UntypedFormControl(this.isMitigationWallCurtain),
      isMitigationRoofCurtain: new UntypedFormControl(this.isMitigationRoofCurtain),
      isMitigationLighting: new UntypedFormControl(this.isMitigationLighting),
      wallMaterialMitigation: new UntypedFormControl(this.wallMaterialMitigation),
      roofMaterialMitigation: new UntypedFormControl(this.roofMaterialMitigation),
      wallCurtainMitigation: new UntypedFormControl(this.wallCurtainMitigation),
      roofCurtainTopMitigation: new UntypedFormControl(this.roofCurtainTopMitigation),
      roofCurtainBottomMitigation: new UntypedFormControl(this.roofCurtainBottomMitigation),
      lightingTypeMitigation: new UntypedFormControl(this.lightingTypeMitigation),
      lightingControlMitigation: new UntypedFormControl(this.lightingControlMitigation),
      dryerTypeBase: new UntypedFormControl(this.dryerTypeBase),
      isDryerHeatRecoveryBase: new UntypedFormControl(this.isDryerHeatRecoveryBase),
      isDryerSuctionCooledBase: new UntypedFormControl(this.isDryerSuctionCooledBase),
      dryerMonth: new UntypedFormControl(this.dryerMonth),
      cropType: new UntypedFormControl(this.cropType),
      cropAmount: new UntypedFormControl(this.cropAmount, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      cropAmountType: new UntypedFormControl(this.cropAmountType),
      cropMoistureWet: new UntypedFormControl(this.cropMoistureWet, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      cropMoistureDry: new UntypedFormControl(this.cropMoistureDry, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      isDryerEfficiencyCalculated: new UntypedFormControl(this.isDryerEfficiencyCalculated),
      dryerEfficiencyAmount: new UntypedFormControl(this.dryerEfficiencyAmount, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dryerEfficiencyAmountType: new UntypedFormControl(this.dryerEfficiencyAmountType),
      dryerEfficiencyMoistureWet: new UntypedFormControl(this.dryerEfficiencyMoistureWet, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      dryerEfficiencyMoistureDry: new UntypedFormControl(this.dryerEfficiencyMoistureDry, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      isMitigationDryerReplace: new UntypedFormControl(this.isMitigationDryerReplace),
      isMitigationDryerAddHeatRecovery: new UntypedFormControl(this.isMitigationDryerAddHeatRecovery),
      isMitigationDryerBurnerOptimized: new UntypedFormControl(this.isMitigationDryerBurnerOptimized),
      dryerTypeNew: new UntypedFormControl(this.dryerTypeNew),
      isDryerHeatRecoveryNew: new UntypedFormControl(this.isDryerHeatRecoveryNew),
      isDryerSuctionCooledNew: new UntypedFormControl(this.isDryerSuctionCooledNew),
      dryerDelayedCoolingType: new UntypedFormControl(this.dryerDelayedCoolingType),
      cropTemperatureLeavingDryer: new UntypedFormControl(this.cropTemperatureLeavingDryer, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      cropMoistureLeavingDryer: new UntypedFormControl(this.cropMoistureLeavingDryer, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      dryerTypeLowMoisture: new UntypedFormControl(this.dryerTypeLowMoisture),
      dryerEfficiencyFuelAmount: new UntypedFormControl(this.dryerEfficiencyFuelAmount, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      months: new UntypedFormArray(this.months?.length > 0 ? this.months.map(v => new BuildingMonth(v).toForm()) : []),
      month: new UntypedFormControl(this.month)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const livestock = form.get('livestock') as UntypedFormArray;
    const months = form.get('months') as UntypedFormArray;
    return new Building({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      name: v.name,
      notes: v.notes,
      upperTierCode: v.upperTierCode,
      buildingType: v.buildingType,
      buildingLength: v.buildingLength,
      buildingWidth: v.buildingWidth,
      buildingHeight: v.buildingHeight,
      numberOfStoreys: v.numberOfStoreys,
      hasAttic: v.hasAttic,
      insulationLevelWall: v.insulationLevelWall,
      insulationLevelRoof: v.insulationLevelRoof,
      infiltrationLevel: v.infiltrationLevel,
      heatingType: v.heatingType,
      fuelType: v.fuelType,
      hasUnderBarnStorage: v.hasUnderBarnStorage,
      isFoundationInsulated: v.isFoundationInsulated,
      isFloorHeated: v.isFloorHeated,
      isTemperatureOverride: v.isTemperatureOverride,
      temperatureOverride: v.temperatureOverride,
      livestock: livestock?.controls.map((w: UntypedFormGroup) => new BuildingLivestock().toModel(w)),
      specifyMitigationPractices: v.specifyMitigationPractices,
      isMitigationWallInsulation: v.isMitigationWallInsulation,
      isMitigationRoofInsulation: v.isMitigationRoofInsulation,
      isMitigationDestratification: v.isMitigationDestratification,
      isMitigationElectricHeating: v.isMitigationElectricHeating,
      isMitigationAlternateHeating: v.isMitigationAlternateHeating,
      isMitigationReplaceHeating: v.isMitigationReplaceHeating,
      isHeatExchangerUsed: v.isHeatExchangerUsed,
      insulationLevelWallMitigation: v.insulationLevelWallMitigation,
      insulationLevelRoofMitigation: v.insulationLevelRoofMitigation,
      destratificationFans: v.destratificationFans,
      heatingTypeElectricMitigation: v.heatingTypeElectricMitigation,
      heatingTypeAlternateMitigation: v.heatingTypeAlternateMitigation,
      heatingTypeMitigation: v.heatingTypeMitigation,
      heatExchangerEfficiencyLevel: v.heatExchangerEfficiencyLevel,
      isHeatExchangerAirflowUsed: v.isHeatExchangerAirflowUsed,
      heatExchangerAirflowRate: v.heatExchangerAirflowRate,
      roofSlopeDirection: v.roofSlopeDirection,
      isAreaOverride: v.isAreaOverride,
      areaOverride: v.areaOverride,
      temperatureSetpointDay: v.temperatureSetpointDay,
      temperatureSetpointNight: v.temperatureSetpointNight,
      relativeHumiditySetpoint: v.relativeHumiditySetpoint,
      wallMaterial: v.wallMaterial,
      roofMaterial: v.roofMaterial,
      constructionLevel: v.constructionLevel,
      wallCurtain: v.wallCurtain,
      roofCurtainTop: v.roofCurtainTop,
      roofCurtainBottom: v.roofCurtainBottom,
      isWhitewashed: v.isWhitewashed,
      greenhouseCropType: v.greenhouseCropType,
      lightingType: v.lightingType,
      lightingControl: v.lightingControl,
      isEmpty: v.isEmpty,
      useMonthlyLightingInputs: v.useMonthlyLightingInputs,
      lightingTimeMaximum: v.lightingTimeMaximum,
      lightingTimeOn: v.lightingTimeOn,
      isMitigationWallMaterial: v.isMitigationWallMaterial,
      isMitigationRoofMaterial: v.isMitigationRoofMaterial,
      isMitigationWallCurtain: v.isMitigationWallCurtain,
      isMitigationRoofCurtain: v.isMitigationRoofCurtain,
      isMitigationLighting: v.isMitigationLighting,
      wallMaterialMitigation: v.wallMaterialMitigation,
      roofMaterialMitigation: v.roofMaterialMitigation,
      wallCurtainMitigation: v.wallCurtainMitigation,
      roofCurtainTopMitigation: v.roofCurtainTopMitigation,
      roofCurtainBottomMitigation: v.roofCurtainBottomMitigation,
      lightingTypeMitigation: v.lightingTypeMitigation,
      lightingControlMitigation: v.lightingControlMitigation,
      dryerTypeBase: v.dryerTypeBase,
      isDryerHeatRecoveryBase: v.isDryerHeatRecoveryBase,
      isDryerSuctionCooledBase: v.isDryerSuctionCooledBase,
      dryerMonth: v.dryerMonth,
      cropType: v.cropType,
      cropAmount: v.cropAmount,
      cropAmountType: v.cropAmountType,
      cropMoistureWet: v.cropMoistureWet,
      cropMoistureDry: v.cropMoistureDry,
      isDryerEfficiencyCalculated: v.isDryerEfficiencyCalculated,
      dryerEfficiencyAmount: v.dryerEfficiencyAmount,
      dryerEfficiencyAmountType: v.dryerEfficiencyAmountType,
      dryerEfficiencyMoistureWet: v.dryerEfficiencyMoistureWet,
      dryerEfficiencyMoistureDry: v.dryerEfficiencyMoistureDry,
      isMitigationDryerReplace: v.isMitigationDryerReplace,
      isMitigationDryerAddHeatRecovery: v.isMitigationDryerAddHeatRecovery,
      isMitigationDryerBurnerOptimized: v.isMitigationDryerBurnerOptimized,
      dryerTypeNew: v.dryerTypeNew,
      isDryerHeatRecoveryNew: v.isDryerHeatRecoveryNew,
      isDryerSuctionCooledNew: v.isDryerSuctionCooledNew,
      dryerDelayedCoolingType: v.dryerDelayedCoolingType,
      cropTemperatureLeavingDryer: v.cropTemperatureLeavingDryer,
      cropMoistureLeavingDryer: v.cropMoistureLeavingDryer,
      dryerTypeLowMoisture: v.dryerTypeLowMoisture,
      dryerEfficiencyFuelAmount: v.dryerEfficiencyFuelAmount,
      months: months?.controls.map((w: UntypedFormGroup) => new BuildingMonth().toModel(w)),
      month: v.month
    });
  }
}
