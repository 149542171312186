import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { GnfReport } from '@gnf/model/gnf-report.model';
import { GnfWorksheet } from '@gnf/model/gnf-worksheet.model';
import { NmspCalculation } from '@nmsp/model/nmsp-calculation.model';
import { FormService } from '@shared/services/form.service';
import { SharedFlagCommentService } from '@shared/services/shared-flag-comment.service';
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { GnfService } from './gnf.service';

@Injectable({ providedIn: 'root' })
export class GnfCalculationService extends CalculationService {
  flagComments$ = new BehaviorSubject<any>(undefined);

  constructor(
    protected formService: FormService,
    protected httpClient: HttpClient,
    private calculationService: CalculationService,
    private sharedFlagCommentService: SharedFlagCommentService,
    private gnfService: GnfService
  ) {
    super(httpClient, formService);
  }

  publish(v: NmspCalculation) {
    this.calculation$.next(v);
  }

  calculate(report?: GnfReport) {
    const model = new GnfWorksheet().toModel(this.formService.f);
    this.input$.next(model);
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data =>
        data
          ? Utils.errorsomeStream(
              combineLatest([
                this.httpClient.post(URLs.GNF_CALCULATION_ENDPOINT, data),
                this.sharedFlagCommentService.getByWorksheetId(data.id)
              ])
            )
          : EMPTY
      ),
      tap(res => {
        const [calculation, flagComments] = res;
        this.setDefaultMaterialForm(calculation);
        this.publish(calculation as any);
        this.flagComments$.next(flagComments);
        this.gnfService.displayReport$ = of(true);
      })
    );
  }

  bindFlagCommentData() {
    return this.sharedFlagCommentService.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data =>
        data ? Utils.errorsomeStream(this.sharedFlagCommentService.getByWorksheetId(this.formService.f.get('id').value)) : EMPTY
      ),
      tap(res => this.flagComments$.next(res))
    );
  }

  private setDefaultMaterialForm(res: any) {
    const storageSystemResponse = res?.storageSizingCalculations?.find(s =>
      Utils.matchStr(s.storageSystemID, this.currentStorageSystem.get('id').value)
    );
    this.calculationService.defaultMaterialForm = storageSystemResponse?.defaultMaterialForm;
  }

  get f() {
    return this.formService.f || undefined;
  }

  get farms() {
    return this.formService.f?.get('farms') as UntypedFormArray;
  }

  get currentFarm() {
    return this.farms?.controls.find(v => Utils.matchStr(v.get('id').value, this.formService.f?.get('currentFarmId')?.value));
  }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem() {
    return this.storageSystems?.controls.find(v =>
      Utils.matchStr(v.get('id').value, this.formService.f?.get('currentStorageSystemId')?.value)
    );
  }
}
