import { Injectable } from '@angular/core';
import { LanguageType } from '../models/common/language-type.enum';
import { MeasurementSystem } from '../models/common/measurement-system.enum';

@Injectable({
    providedIn: 'root'
})

export class UnitService {

    // Units and Language
    private _unit = MeasurementSystem.Metric;
    private _language = LanguageType.English;

    // Volume : L, gal
    volume: string;

    // Volume : m³, ft³
    volume2: string;

    // Volume : m³, yd³
    volume3: string;

    // Length : m, ft
    length: string;

    // LengthSmall : cm, in
    lengthSmall: string;

    // LengthSmall2 : mm, in
    lengthSmall2: string;

    // LengthLarge : km, mi
    lengthLarge: string;

    // Mass : kg, lb
    mass: string;

    // MassLarge : tonne, ton
    massLarge: string;

    // Velocity : km/h, mph
    velocity: string;

    // Velocity2 : m/min, ft/min
    velocity2: string;

    // Area : ha, ac
    area: string;

    // Area : hectares, acres
    areaText: string;

    // Area2 : m², ft²
    area2: string;

    // AppMass : kg/ha, lb/ac
    appMass: string;

    // AppMassLarge: tonne/ha, ton/ac
    appMassLarge: string;

    // AppMassLarge: m³/ha, gal/ac, USgal/ac
    appVolumeArea: string;

    // AppVolume: L/ha, gal/ac
    appVolume: string;

    // AppVolume2: m³/ha, ft³/ac
    appVolume2: string;

    // Density: kg/L, lb/gal
    density: string;

    // Density2: kg/m³, lb/ft³
    density2: string;

    // Yield: tonne/ha, bu/ac
    yield: string;

    // Yield: tonne/ha, ton/ac
    yield2: string;

    // Yield Price: $/tonne, $/bu
    yieldPrice: string;

    // Yield Price: $/tonne, $/ton
    yieldPrice2: string;

    // Area Cost: $/ha, $/ac
    areaCost: string;

    // Per Area: /ha, /ac
    private _perArea: string;

    // Per Mass: /kg, /lb
    private _perMass: string;

    // Per MassLarge: /tonne, /ton
    private _perMassLarge: string;

    constructor() {
    }

    // Constructor
    public init(units: MeasurementSystem, language: LanguageType) {
        this.setUnits(units, language);
    }

    // Year Text
    public getYearText() {
        if (this.getLanguage() === LanguageType.English) {
            return '/year';
        } else {
            return '/année';
        }
    }

    public getYearText2() {
        if (this.getLanguage() === LanguageType.English) {
            return 'year';
        } else {
            return 'année';
        }
    }

    public getYearsText2() {
        if (this.getLanguage() === LanguageType.English) {
            return 'years';
        } else {
            return 'années';
        }
    }

    //
    // Day Text
    public getDayText() {
        if (this.getLanguage() === LanguageType.English) {
            return '/day';
        } else {
            return '/jour';
        }
    }

    public getDayText2() {
        if (this.getLanguage() === LanguageType.English) {
            return 'days';
        } else {
            return 'jours';
        }
    }

    //
    // Week Text
    public getWeekText() {
        if (this.getLanguage() === LanguageType.English) {
            return '/week';
        } else {
            return '/semaine';
        }
    }

    //
    // Month Text
    public getMonthText() {
        if (this.getLanguage() === LanguageType.English) {
            return '/month';
        } else {
            return '/mois';
        }
    }

    //
    // Nutrient Units
    public getNutrientUnitsText() {
        return 'NU';
    }

    //
    // Grazing Units
    public getGrazingUnitsText() {
        return 'GU';
    }

    //
    // Dry Matter Text
    public getDryMatterText() {
        if (this.getLanguage() === LanguageType.English) {
            return 'DM';
        } else {
            return 'MS';
        }
    }


    // Setup units
    private setUnits(units: MeasurementSystem, language: LanguageType) {
        // Set unit member variables
        this.setUnit(units);
        this.setLanguage(language);
        if (this.getUnit() === MeasurementSystem.Metric) {
            // Metric
            this.setVolume('L');
            this.setVolume2('m³');
            this.setVolume3('m³');
            this.setLength('m');
            this.setLengthSmall('cm');
            this.setLengthSmall2('mm');
            this.setLengthLarge('km');
            this.setMass('kg');
            this.setMassLarge('tonne');
            this.setVelocity('km/h');
            this.setVelocity2('m/min');
            this.setArea('ha');
            this.setArea2('m²');
            this.setAppMass('kg/ha');
            this.setAppMassLarge('tonne/ha');
            this.setAppVolumeArea('m³/ha')
            this.setAppVolume('L/ha');
            this.setAppVolume2('m³/ha');
            this.setDensity('kg/L');
            this.setDensity2('kg/m³');
            this.setYield('tonne/ha');
            this.setYield2('tonne/ha');
            this.setYieldPrice('$/tonne');
            this.setYieldPrice2('$/tonne');
            this.setAreaCost('$/ha');
            this.perArea = '/ha';
            this.perMass = '/kg';
            this.perMassLarge = '/tonne';

            // Set Text Data, used by screen Reader
            this.setAreaText('hectares');
        } else if (this.getUnit() === MeasurementSystem.Imperial || this.getUnit() === MeasurementSystem.US) {
            // Imperial
            this.setVolume('gal');
            this.setVolume2('ft³');
            this.setVolume3('yd³');
            this.setLength('ft');
            this.setLengthSmall('in');
            this.setLengthSmall2('in');
            this.setLengthLarge('mi');
            this.setMass('lb');
            this.setMassLarge('ton');
            this.setVelocity('mph');
            this.setVelocity2('ft/min');
            this.setArea('ac');
            this.setArea2('ft²');
            this.setAppMass('lb/ac');
            this.setAppMassLarge('ton/ac');
            this.setAppVolumeArea('gal/ca')
            this.setAppVolume('gal/ac');
            this.setAppVolume2('ft³/ac');
            this.setDensity('lb/gal');
            this.setDensity2('lb/ft³');
            this.setYield('bu/ac');
            this.setYield2('ton/ac');
            this.setYieldPrice('$/bu');
            this.setYieldPrice2('$/ton');
            this.setAreaCost('$/ac');
            this.perArea = '/ac';
            this.perMass = '/lb';
            this.perMassLarge = '/ton';
            // Set Text Data, used by screen Reader
            this.setAreaText('acres');
            // US
            if (this.getUnit() === MeasurementSystem.US) {
                this.setVolume('USgal');
                this.setDensity('lb/USgal');
                this.setAppVolume('USgal/ac');
                this.setAppVolumeArea('USgal/ac')
            }

            // French
            if (language === LanguageType.French) {
                this.setVolume2('pi³');
                this.setLength('pi');
                this.setVelocity2('pi/min');
                this.setArea2('pi²');
                this.setDensity2('lb/pi³');
            }

        }

        return true;
    }


    // getters and setters for attributes
    public getUnit() {
        return this.unit;
    }

    public setUnit(unit: MeasurementSystem) {
        this.unit = unit;
    }

    public getLanguage() {
        return this.language;
    }

    public setLanguage(language: LanguageType) {
        this.language = language;
    }

    public getVolume() {
        return this.volume;
    }

    public setVolume(volume: string) {
        this.volume = volume;
    }

    public getVolume2() {
        return this.volume2;
    }

    public setVolume2(volume2: string) {
        this.volume2 = volume2;
    }

    public getVolume3() {
        return this.volume3;
    }

    public setVolume3(volume3: string) {
        this.volume3 = volume3;
    }

    public getLength() {
        return this.length;
    }

    public setLength(length: string) {
        this.length = length;
    }

    public getLengthSmall() {
        return this.lengthSmall;
    }

    public setLengthSmall(lengthSmall: string) {
        this.lengthSmall = lengthSmall;
    }

    public getLengthSmall2() {
        return this.lengthSmall2;
    }

    public setLengthSmall2(lengthSmall2: string) {
        this.lengthSmall2 = lengthSmall2;
    }

    public getLengthLarge() {
        return this.lengthLarge;
    }

    public setLengthLarge(lengthLarge: string) {
        this.lengthLarge = lengthLarge;
    }

    public getMass() {
        return this.mass;
    }

    public setMass(mass: string) {
        this.mass = mass;
    }

    public getMassLarge() {
        return this.massLarge;
    }

    public setMassLarge(massLarge: string) {
        this.massLarge = massLarge;
    }

    public getVelocity() {
        return this.velocity;
    }

    public setVelocity(velocity: string) {
        this.velocity = velocity;
    }

    public getVelocity2() {
        return this.velocity2;
    }

    public setVelocity2(velocity2: string) {
        this.velocity2 = velocity2;
    }

    public getArea() {
        return this.area;
    }

    public setArea(area: string) {
        this.area = area;
    }

    public getAreaText() {
        return this.areaText;
    }

    public setAreaText(areaText: string) {
        this.areaText = areaText;
    }

    public getArea2() {
        return this.area2;
    }

    public setArea2(area2: string) {
        this.area2 = area2;
    }

    public getAppMass() {
        return this.appMass;
    }

    public setAppMass(appMass: string) {
        this.appMass = appMass;
    }

    public getAppMassLarge() {
        return this.appMassLarge;
    }

    public setAppMassLarge(appMassLarge: string) {
        this.appMassLarge = appMassLarge;
    }

    public setAppVolumeArea(appVolumeArea: string) {
        this.appVolumeArea = appVolumeArea;
    }

    public getAppVolume() {
        return this.appVolume;
    }

    public setAppVolume(appVolume: string) {
        this.appVolume = appVolume;
    }

    public getAppVolume2() {
        return this.appVolume2;
    }

    public setAppVolume2(appVolume2: string) {
        this.appVolume2 = appVolume2;
    }

    public getDensity() {
        return this.density;
    }

    public setDensity(density: string) {
        this.density = density;
    }

    public getDensity2() {
        return this.density2;
    }

    public setDensity2(density2: string) {
        this.density2 = density2;
    }

    public getYield() {
        return this.yield;
    }

    public setYield(yield1: string) {
        this.yield = yield1;
    }

    public getYield2() {
        return this.yield2;
    }

    public setYield2(yield2: string) {
        this.yield2 = yield2;
    }

    public getYieldPrice() {
        return this.yieldPrice;
    }

    public setYieldPrice(yieldPrice: string) {
        this.yieldPrice = yieldPrice;
    }

    public getYieldPrice2() {
        return this.yieldPrice2;
    }

    public setYieldPrice2(yieldPrice2: string) {
        this.yieldPrice2 = yieldPrice2;
    }

    public getAreaCost(): string {
        return this.areaCost;
    }
    public setAreaCost(value: string) {
        this.areaCost = value;
    }

    public get perArea(): string {
        return this._perArea;
    }
    public set perArea(value: string) {
        this._perArea = value;
    }

    public get perMass(): string {
        return this._perMass;
    }
    public set perMass(value: string) {
        this._perMass = value;
    }

    public get perMassLarge(): string {
        return this._perMassLarge;
    }
    public set perMassLarge(value: string) {
        this._perMassLarge = value;
    }

    get unit() {
        return this._unit;
    }

    set unit(val: MeasurementSystem) {
        this._unit = val;
    }

    get language() {
        return this._language;
    }

    set language(val: LanguageType) {
        this._language = val;
    }

}
