import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { SoilTestValue } from '@shared/models/worksheet/soil-test-value.model';

export class CropSoilSample extends BaseModel {
  name: string;
  fieldSectionId: string;
  soilTestValues: SoilTestValue[] = [];

  constructor(init?: Partial<CropSoilSample>) {
    super(init);

    this.name = init?.name;
    this.fieldSectionId = init?.fieldSectionId;

    this.soilTestValues[0] = this.createSoilTestValue(init?.soilTestValues, 'P');
    this.soilTestValues[1] = this.createSoilTestValue(init?.soilTestValues, 'K');
    this.soilTestValues[2] = this.createSoilTestValue(init?.soilTestValues, 'PH');
    this.soilTestValues[3] = this.createSoilTestValue(init?.soilTestValues, 'BPH');
  }

  private createSoilTestValue(soilTestValues, key) {
    let stvDefault = new SoilTestValue();
    stvDefault.testCode = key;
    stvDefault.testValue = -1;

    if (!soilTestValues) {
      return stvDefault;
    }

    const initStv = soilTestValues.filter(o => o.testCode === key)[0];
    if (!initStv) {
      return stvDefault;
    }

    let stv = new SoilTestValue(initStv);
    return stv;
  }
}
