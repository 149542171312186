import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class MstorTreatment extends BaseModel {
  name: string;
  notes: string;
  storageSystemId: string;
  materialTypeId: string;
  treatmentTypeId: string;
  startDate: Date;
  endDate: Date;
  overrideStartDate: boolean;
  overrideEndDate: boolean;
  adjustPercent1: number;
  adjustPercent2: number;
  treatmentTypeComment: string;
  anaerobicDigesterComment: string;
  adjustPercent1Comment: string;
  adjustPercent2Comment: string;
  nutrientUnits: number;

  constructor(init?: Partial<MstorTreatment>) {
    super();
    this.name = this.isEn ? 'Treatment 1' : 'Traitement 1';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      treatmentTypeId: new UntypedFormControl(this.treatmentTypeId),
      startDate: new UntypedFormControl({ value: this.startDate, disabled: !this.overrideStartDate }),
      endDate: new UntypedFormControl({ value: this.endDate, disabled: !this.overrideEndDate }),
      overrideStartDate: new UntypedFormControl(this.overrideStartDate),
      overrideEndDate: new UntypedFormControl(this.overrideEndDate),
      adjustPercent1: new UntypedFormControl(this.adjustPercent1, { validators: [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]*$')] }),
      adjustPercent2: new UntypedFormControl(this.adjustPercent2, { validators: [Validators.min(0), Validators.max(100), Validators.pattern('^[0-9]*$')] }),
      treatmentTypeComment: new UntypedFormControl(this.treatmentTypeComment),
      anaerobicDigesterComment: new UntypedFormControl(this.anaerobicDigesterComment),
      adjustPercent1Comment: new UntypedFormControl(this.adjustPercent1Comment),
      adjustPercent2Comment: new UntypedFormControl(this.adjustPercent2Comment),
      nutrientUnits: new UntypedFormControl(this.nutrientUnits, {
        validators: [Validators.min(0), Validators.max(1000000)]
      })
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new MstorTreatment({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      storageSystemId: v.storageSystemId,
      materialTypeId: v.materialTypeId,
      treatmentTypeId: v.treatmentTypeId,
      startDate: v.startDate,
      endDate: v.endDate,
      overrideStartDate: v.overrideStartDate,
      overrideEndDate: v.overrideEndDate,
      adjustPercent1: v.adjustPercent1,
      adjustPercent2: v.adjustPercent2,
      treatmentTypeComment: v.treatmentTypeComment,
      anaerobicDigesterComment: v.anaerobicDigesterComment,
      adjustPercent1Comment: v.adjustPercent1Comment,
      adjustPercent2Comment: v.adjustPercent2Comment,
      nutrientUnits: v.nutrientUnits
    });
  }
}
