import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContactInformation } from '../../../shared/models/common/contact-information.model';
import { GhgWorksheet } from '../model/ghg-worksheet.model';

@Injectable({ providedIn: 'root' })
export class GhgService {
  displayReport$ = of(false);
  buildingExpanded$ = new BehaviorSubject(undefined);

  constructor(private httpClient: HttpClient, private formService: FormService) {}

  expandBuildingPanel() {
    this.buildingExpanded$.next(Utils.uuid());
  }

  get(id: string): Observable<GhgWorksheet> {
    return this.httpClient.get(`${URLs.GHG_ENDPOINT}/${id}`) as Observable<GhgWorksheet>;
  }

  save(worksheet: GhgWorksheet): Observable<any> {
    return this.httpClient.post(URLs.GHG_ENDPOINT, worksheet);
  }

  updateWorksheetEconomics(worksheet: GhgWorksheet): Observable<any> {
    const url = `${URLs.GHG_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    return this.httpClient.put(url, worksheet, { params });
  }

  update(worksheet: any): Observable<any> {
    return this._update(worksheet);
  }

  updateWorksheetFlat(worksheet: any): Observable<any> {
    return this._update(worksheet, true);
  }

  private _update(worksheet: any, ignoreChildren: boolean = false): Observable<any> {
    const url = `${URLs.GHG_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    if (ignoreChildren) {
      params = params.append('ignore', 'child-elements');
    }
    return this.httpClient.put(url, worksheet, { params }).pipe(
      tap((res: any) => {
        this.contacts.clear();
        res?.contacts?.forEach(contact => {
          this.contacts.push(new ContactInformation(contact).toForm());
        });
      })
    );
  }

  /**
   * Since there is not CRUD service for contact information, refresh the worksheet contact list
   * based on the worksheet update result.
   */

  get contacts(): UntypedFormArray {
    return this.f.get('contacts') as UntypedFormArray;
  }

  get f(): UntypedFormGroup {
    return this.formService?.f;
  }
}
