import { DragDropModule } from '@angular/cdk/drag-drop';
import { LayoutModule } from '@angular/cdk/layout';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LimeRecDialogComponent } from '@crop-nutrient/crop-nutrient-summary/lime-rec-dialog/lime-rec-dialog.component';
import { NgChartsModule } from 'ng2-charts';
import { NewCalculatorDialogComponent } from '../../app/home/home/new-calculator-dialog/new-calculator-dialog.component';
import { ApplicationPhosphorusIndexDialogComponent } from '../calculators/plato/plato-phosphorus-loss/application-phosphorus-index-dialog/application-phosphorus-index-dialog.component';
import { ApplyLabelDialogComponent } from './components/apply-label-dialog/apply-label-dialog.component';
import { CalculatorHeadingComponent } from './components/calculator-heading/calculator-heading.component';
import { CalculatorSideNavigationToggleComponent } from './components/calculator-side-navigation-toggle/calculator-side-navigation-toggle.component';
import { CardTooltipComponent } from './components/card-tooltip/card-tooltip.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialog2Component } from './components/confirmation-dialog2/confirmation-dialog2.component';
import { ContactInformationComponent } from './components/contact-information/contact-information.component';
import { ConversionFieldComponent } from './components/conversion-field/conversion-field.component';
import { DeleteConfirmDialogComponent } from './components/delete-confirm-dialog/delete-confirm-dialog.component';
import { DeleteFieldInputsDialogComponent } from './components/delete-field-inputs-dialog/delete-field-inputs-dialog.component';
import { FilterMenuComponent } from './components/filter-menu/filter-menu.component';
import { FlagCommentInputComponent } from './components/flag-comment-input/flag-comment-input.component';
import { FlagDialogComponent } from './components/flag-dialog/flag-dialog.component';
import { FlagSummaryComponent } from './components/flag-summary/flag-summary.component';
import { FlagComponent } from './components/flag/flag.component';
import { FooterComponent } from './components/footer/footer.component';
import { HorizontalGradientBarComponent } from './components/horizontal-gradient-bar/horizontal-gradient-bar.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { LoadingComponent } from './components/loading/loading.component';
import { LocationInformationCompactComponent } from './components/location-information/location-information-compact/location-information-compact.component';
import { LocationInformationComponent } from './components/location-information/location-information.component';
import { ManageLabelDialogComponent } from './components/manage-label-dialog/manage-label-dialog.component';
import { FormFieldWithFlagComponent } from './components/form-field-with-flag/form-field-with-flag.component';
import { MaterialSourceComponent } from './components/material-source/material-source.component';
import { NutrientBalanceSelectorComponent } from './components/nutrient-balance-selector/nutrient-balance-selector.component';
import { PrintHeaderComponent } from './components/print-header/print-header.component';
import { ProgressSpinnerComponent } from './components/progress-spinner/progress-spinner.component';
import { ContactInfoSectionComponent } from './components/report/contact-info-section/contact-info-section.component';
import { EmissionTableComponent } from './components/report/emission-table/emission-table.component';
import { MultiColumnContactInfoComponent } from './components/report/multi-column-contact-info/multi-column-contact-info.component';
import { SingleColumnContactInfoComponent } from './components/report/single-column-contact-info/single-column-contact-info.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { SignInToolbarComponent } from './components/sign-in-toolbar/sign-in-toolbar.component';
import { TooltipDialogComponent } from './components/tooltip-dialog/tooltip-dialog.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { TopNavComponent } from './components/top-nav/top-nav.component';
import { TrendArrowComponent } from './components/trend-arrow/trend-arrow.component';
import { YearMonthPickerComponent } from './components/year-month-picker/year-month-picker.component';
import { YearPickerHeaderComponent } from './components/year-month-picker/year-picker-header/year-picker-header.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { DirectivesModule } from './directives.module';
import { AppMassCostDirective } from './directives/app-mass-cost.directive';
import { MaterialModule } from './material.module';
import { PipesModule } from './pipes.module';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { EmissionPercentChangePipe } from './pipes/emission-percent-change.pipe';
import { EmissionTextPipe } from './pipes/emission-text.pipe';
import { EmissionUnitPipe } from './pipes/emission-unit.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { ValueConverterPipe } from './pipes/value-converter.pipe';

@NgModule({
  declarations: [
    TopNavComponent,
    FooterComponent,
    YearPickerComponent,
    YearMonthPickerComponent,
    ConfirmationDialogComponent,
    ConfirmationDialog2Component,
    DeleteConfirmDialogComponent,
    TooltipDialogComponent,
    CalculatorHeadingComponent,
    SignInToolbarComponent,
    LoadingComponent,
    ProgressSpinnerComponent,
    CardTooltipComponent,
    PrintHeaderComponent,
    NewCalculatorDialogComponent,
    ContactInformationComponent,
    YearPickerHeaderComponent,
    ApplicationPhosphorusIndexDialogComponent,
    FlagDialogComponent,
    FlagComponent,
    LocationInformationComponent,
    LocationInformationCompactComponent,
    FlagCommentInputComponent,
    FlagSummaryComponent,
    TooltipComponent,
    LineChartComponent,
    MaterialSourceComponent,
    ResourcesComponent,
    FilterMenuComponent,
    ManageLabelDialogComponent,
    ApplyLabelDialogComponent,
    LimeRecDialogComponent,
    CalculatorSideNavigationToggleComponent,
    TrendArrowComponent,
    HorizontalGradientBarComponent,
    MultiColumnContactInfoComponent,
    ContactInfoSectionComponent,
    EmissionTableComponent,
    NutrientBalanceSelectorComponent,
    AppMassCostDirective,
    ConversionFieldComponent,
    SingleColumnContactInfoComponent,
    DeleteFieldInputsDialogComponent,
    FormFieldWithFlagComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    LayoutModule,
    PipesModule,
    DirectivesModule,
    NgChartsModule
  ],
  providers: [
    DecimalPipe,
    TranslatePipe,
    UnitPipe,
    ValueConverterPipe,
    AbsoluteValuePipe,
    EmissionUnitPipe,
    EmissionTextPipe,
    EmissionPercentChangePipe
  ],
  exports: [
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    PipesModule,
    DirectivesModule,
    TopNavComponent,
    FooterComponent,
    LoadingComponent,
    ProgressSpinnerComponent,
    YearPickerComponent,
    YearMonthPickerComponent,
    CalculatorHeadingComponent,
    SignInToolbarComponent,
    PrintHeaderComponent,
    ContactInformationComponent,
    ApplicationPhosphorusIndexDialogComponent,
    FlagDialogComponent,
    FlagComponent,
    LocationInformationComponent,
    LocationInformationCompactComponent,
    FlagCommentInputComponent,
    TooltipComponent,
    LineChartComponent,
    MaterialSourceComponent,
    ResourcesComponent,
    FilterMenuComponent,
    ManageLabelDialogComponent,
    ApplyLabelDialogComponent,
    LimeRecDialogComponent,
    CalculatorSideNavigationToggleComponent,
    TrendArrowComponent,
    HorizontalGradientBarComponent,
    MultiColumnContactInfoComponent,
    ContactInfoSectionComponent,
    EmissionTableComponent,
    NutrientBalanceSelectorComponent,
    AppMassCostDirective,
    ConversionFieldComponent,
    SingleColumnContactInfoComponent,
    FormFieldWithFlagComponent
  ]
})
export class SharedModule {}
