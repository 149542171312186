import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Constants } from '@shared/constants/constants';
import { MaterialNutrientCode } from '@shared/models/material/material-nutrient-code';
import { MaterialTestValue } from '@shared/models/worksheet/material-test-value.model';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';

let testCodes = null; // well this is still awkard

export class MaterialSample extends BaseModel {
  dryMatterRange = 0;
  dryMatterLow = 0;
  dryMatterHigh = 18;
  nutrientValueSource = 1;
  nutrientValueBasis = 0;
  materialTypeId: string;
  materialTestValues: MaterialTestValue[] = [];
  selectedNutrients = [];
  focusedNutrient = undefined;

  constructor(init?: Partial<any>) {
    super();
    testCodes = JSON.parse(localStorage.getItem('nutrientCodes'));
    Object.assign(this, init);
  }

  toForm() {
    const f = new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      dryMatterRange: new UntypedFormControl(this.dryMatterRange),
      dryMatterLow: new UntypedFormControl(this.dryMatterLow),
      dryMatterHigh: new UntypedFormControl(this.dryMatterHigh),
      nutrientValueSource: new UntypedFormControl(this.nutrientValueSource),
      nutrientValueBasis: new UntypedFormControl(this.nutrientValueBasis),
      selectedNutrients: new UntypedFormControl(this.selectedNutrients),
      focusedNutrient: new UntypedFormControl(this.focusedNutrient)
    });
    return this.assembleTestValues(f);
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    // tslint:disable-next-line: no-string-literal
    const codes = Constants['nutrientCodes'].filter(c => v[c.nutrientCode] !== undefined).map(c => c.nutrientCode);
    return new MaterialSample({
      id: v.id,
      createdBy: v.createdBy,
      materialTypeId: v.materialTypeId,
      dryMatterRange: v.dryMatterRange,
      dryMatterLow: v.dryMatterLow,
      dryMatterHigh: v.dryMatterHigh,
      nutrientValueSource: v.nutrientValueSource,
      nutrientValueBasis: v.nutrientValueBasis,
      selectedNutrients: v.selectedNutrients,
      focusedNutrient: v.focusedNutrient,
      materialTestValues: codes ? codes.
        map(key => ({
          testCode: key,
          testValue: form.get(key).get('testValue')?.value,
          isLessThan: form.get(key).get('isLessThan')?.value,
          isNotDetected: form.get(key).get('isNotDetected')?.value,
        })) as MaterialTestValue[] : undefined
    });
  }

  private assembleTestValues(f: UntypedFormGroup) {
    if (this.materialTestValues && this.materialTestValues.length) {
      this.materialTestValues.forEach(v => f.addControl(v.testCode,
        new UntypedFormGroup({
          testValue: new UntypedFormControl(v.testValue, { validators: this.nutrientValidators(v.testCode) }),
          isLessThan: new UntypedFormControl(v.isLessThan),
          isNotDetected: new UntypedFormControl(v.isNotDetected),
        })
      ));
    } else {
      this.defaultNutrientCodes.forEach(v => f.addControl(v.nutrientCode,
        new UntypedFormGroup({
          testValue: new UntypedFormControl(0, { validators: this.nutrientValidators(v.nutrientCode) }),
          isLessThan: new UntypedFormControl(false),
          isNotDetected: new UntypedFormControl(false),
        })
      ));
    }
    return f;
  }


  private nutrientValidators(testCode: string): ValidatorFn[] {
    const nutrient = testCodes.find(v => Utils.matchStr(v.nutrientCode, testCode));
    if (Utils.matchStr(nutrient.nutrientCode, 'PH')) {
      return [Validators.min(0), Validators.max(14)];
    }
    return [Validators.min(0), Validators.max(nutrient?.units === '%' ? 100 : 1000000000)];
  }

  get defaultNutrientCodes(): MaterialNutrientCode[] {
    return Constants['nutrientCodes']
      .filter(v => v.defaultAsmFlag === true)
      .sort((a, b) => a.sequenceId - b.sequenceId);
  }
}
