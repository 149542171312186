import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BilingualText } from '@shared/models/common/bilingual-text';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class FmpFertilizerProduct extends BaseModel {
    name: BilingualText;
    applicationCost = 0;
    applicationIncorporation = 0;
    applicationRate = 0;
    displayOrder = 0;
    fertilizerApplicationId: string;
    fertilizerForm = 1;
    // fertilizerProductId = 18; // default to 11-52.0 MAP
    fertilizerProductId = Constants.DEFAULT_FERTILIZER_APPLIED_PRODUCT_ID;

    constructor(init?: Partial<FmpFertilizerProduct>) {
        super();
        this.name = { en: 'Product 1', fr: 'Produit 1' };
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            name: new UntypedFormControl(this.name),
            applicationCost: new UntypedFormControl(this.applicationCost),
            applicationRate: new UntypedFormControl(this.applicationRate, { validators: [Validators.max(1000000)] }),
            displayOrder: new UntypedFormControl(this.displayOrder),
            fertilizerApplicationId: new UntypedFormControl(this.fertilizerApplicationId),
            fertilizerForm: new UntypedFormControl(this.fertilizerForm),
            fertilizerProductId: new UntypedFormControl(this.fertilizerProductId)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new FmpFertilizerProduct({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            name: v.name,
            applicationCost: v.applicationCost,
            applicationRate: v.applicationRate,
            displayOrder: v.displayOrder,
            fertilizerApplicationId: v.fertilizerApplicationId,
            fertilizerForm: v.fertilizerForm,
            fertilizerProductId: v.fertilizerProductId
        })
    }
}
