import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { concatMap, reduce, tap } from 'rxjs/operators';
import { URLs } from '../../../core/conf/urls';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { FertilizerProductOption } from '../model/fertilizer-product-option.model';
import { LanguageService } from '@shared/services/language.service';

@Injectable({ providedIn: 'root' })
export class FertilizerProductOptionsService {
  private fertilizerProductOptionsApi$ = this.httpClient.get(URLs.FERTILIZER_PRODUCT_OPTION_ENDPOINT).pipe(
    tap((res: FertilizerProductOption[]) => {
      this.fertilizerProductOptions = res;
    })
  );
  // flat product option array
  // tslint:disable-next-line: variable-name
  private _fertilizerProductOptions = undefined;
  private subject = new BehaviorSubject(this._fertilizerProductOptions);
  // tslint:disable-next-line: variable-name
  private _fertilizerProductOptions$: Observable<any> = this.subject.asObservable();

  constructor(private httpClient: HttpClient, private languageService: LanguageService) {
    this.fertilizerProductOptionsApi$.subscribe();
  }

  /**
   * This function
   * 1: calls product options API to fetch a list of options
   * 2: transform the list into a keymap by grouping by fertilizerProductGroup
   * 3: transform the data gain into shape below for template to use
   * {
   *    groups: ['N', 'K20', 'P205', ...]
   *    options: {
   *      'N': [option1, option2, ...],
   *      'K20': [option1, option2, ...],
   *      'P205': [option1, option2, ...],
   *      'blend': [option1, option2, ...],
   *      'micro': [option1, option2, ...]
   *    }
   * }
   *
   */
  get(fertilizerForm: MaterialForm, includeCustom: boolean): Observable<any> {
    const params = new HttpParams().set('include-custom', includeCustom && includeCustom === true ? 'true' : 'false');
    return this.httpClient.get(URLs.FERTILIZER_PRODUCT_OPTION_ENDPOINT, { params }).pipe(
      tap((res: FertilizerProductOption[]) => {
        this._fertilizerProductOptions = res;
      }),
      concatMap(res => res),
      reduce((r, c: any) => {
        if (c.fertilizerForm === fertilizerForm) {
          r[c.fertilizerProductGroup] = r[c.fertilizerProductGroup] || [];
          const index = r[c.fertilizerProductGroup].findIndex(v => v.fertilizerProductId === c.fertilizerProductId);
          if (index === -1) {
            r[c.fertilizerProductGroup].push(c);
          }
        }
        return r;
      }, {})
    );
  }

  get fertilizerProductOptions(): any {
    return this._fertilizerProductOptions;
  }

  set fertilizerProductOptions(value: any) {
    this._fertilizerProductOptions = value;

    const fertilizerHash = value.reduce((r, c: any) => {
      let currentOpt = { ...c };
      r[currentOpt.fertilizerProductId] = currentOpt;
      return r;
    }, {});
    this.subject.next({ ...fertilizerHash });
  }

  get fertilizerProductOptions$(): Observable<any> {
    return this._fertilizerProductOptions$;
  }
}
