import { AgErosionWorksheet } from '@ag-erosion/model/ag-erosion-worksheet.model';
import { Watershed } from '@ag-erosion/model/watershed.model';
import { Injectable } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { of, BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SiteAgErosionService {
  displayReport$ = of(false);
  systemExpanded$ = new BehaviorSubject(undefined);
  //todo rename this service to shared-service ...
  constructor(private formService: FormService, private cache: CacheService) {}

  get(id: string): Observable<AgErosionWorksheet> {
    const worksheet = this.cache.getWorksheet(WorksheetKeys.AG_EROSION);
    if (!worksheet) {
      const ws = new AgErosionWorksheet();
      if (!ws.watersheds?.length) {
        const watershed = new Watershed();
        watershed.worksheetId = ws.id || Utils.uuid();
        watershed.id = Utils.uuid();
        watershed.runoffCurves?.forEach(rc => {
          rc.id = Utils.uuid();
        });
        ws.watersheds = [watershed];
      }
      this.cache.setWorksheet(ws, WorksheetKeys.AG_EROSION);
    }
    return of(this.cache.getWorksheet(WorksheetKeys.AG_EROSION));
  }

  save(worksheet: AgErosionWorksheet): Observable<any> {
    this.cache.setWorksheet(worksheet, WorksheetKeys.AG_EROSION);
    return of(worksheet);
  }

  update(worksheet: AgErosionWorksheet): Observable<any> {
    const ws = this.cache.getWorksheet(WorksheetKeys.AG_EROSION);
    Object.assign(ws, worksheet);
    this.cache.setWorksheet(ws, WorksheetKeys.AG_EROSION);
    return of(ws);
  }

  updateWorksheetFlat(worksheet: AgErosionWorksheet): Observable<any> {
    return this.update(worksheet);
  }

  get f() {
    return this.formService?.f;
  }
}
