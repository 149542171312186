import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy } from '@angular/core';
import { takeWhile } from 'rxjs/operators';
import { LoadingService } from '../../services/loading.service';

@Component({
  selector: 'app-loading, .app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements AfterViewInit, OnDestroy {

  private alive = true;

  constructor(
    private loadingService: LoadingService,
    private elmRef: ElementRef,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngAfterViewInit(): void {
    this.elmRef.nativeElement.style.display = 'none';
    this.loadingService.loading$.pipe(takeWhile(() => this.alive)).subscribe((loading: boolean) => {
      this.elmRef.nativeElement.style.display = loading === false ? 'none' : 'block';
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}
