<article class="page-container">
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "dialog.title.lime.rec" | translate | async }}
    </h1>
    <div mat-dialog-content>
        <p>
            {{ "dialog.message.lime.rec.p1" | translate | async }}
            <abbr title="{{ 'link.open.new.window' | translate | async }}">
                <a title="{{ 'dialog.message.lime.rec.p2' | translate | async }}" href="{{ link$ | async }}" target="_blank" rel="noopener">
                    {{ "dialog.message.lime.rec.p2" | translate | async }}
                </a>
            </abbr>
            {{ "dialog.message.lime.rec.p3" | translate | async }}
        </p>
    </div>
    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--black-primary" (click)="close()">
                {{ "dialog.action.close" | translate | async }}
            </button>
        </div>
    </div>
</article>
