import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { SoilSample } from '@shared/models/calculations/field-calculation.model';
import { NasmFieldCalculation } from '@nasm/model/nasm-calculation.model';
import { FlagLevelType } from '@shared/models/flag/flag-level-type.enum';
import { FlagVo } from '@shared/models/flag/flag-vo.model';

@Pipe({ name: 'nasmMostRecentSsPipe' })
export class NasmMostRecentSoilSampleInfoPipe implements PipeTransform {
  constructor() {}

  soilSamples(field: UntypedFormGroup) {
    return (field?.get('fieldSections.0.soilSamples') as UntypedFormArray)?.controls;
  }

  mostRecentSoilSample(field: UntypedFormGroup) {
    const sss = this.soilSamples(field);
    return !Utils.isEmptyArray(sss) ? Utils.sortFormArray(sss, 'sampleDate', true, true)[0]?.controls : undefined;
  }

  mostRecentSsCal(field: UntypedFormGroup, calculation: NasmFieldCalculation): SoilSample {
    const ss = this.mostRecentSoilSample(field);
    return calculation?.soilSamples?.find(v => Utils.matchStr(v?.id, ss?.id?.value));
  }

  flagSoilSample(field: UntypedFormGroup, calculation: NasmFieldCalculation): FlagVo {
    return this.mostRecentSsCal(field, calculation)?.flagSoilSample;
  }

  transform(field: UntypedFormGroup, calculation: NasmFieldCalculation): string {
    const flag = this.flagSoilSample(field, calculation);
    if (flag) {
      if (flag.flagLevelId === FlagLevelType.MissingInfo) {
        return 'calculator.nasm.report.section.application.area.ss.not.specified';
      } else {
        return 'calculator.nasm.report.section.application.area.ss.not.conform';
      }
    } else {
      return 'calculator.nasm.report.section.application.area.ss.conform';
    }
  }
}
