import { Injectable } from '@angular/core';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { Crop } from '@shared/models/worksheet/crop.model';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { EMPTY, Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SiteCropService {
  constructor(private cache: CacheService, private formService: FormService) {}

  save(application: Crop): Observable<Crop> {
    if (application && !application.createdDateTime) {
      application.updatedDateTime = new Date();
      application.createdDateTime = new Date();
    }
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const crops = worksheet.farms[0].fields[0].fieldSections[0].crops;
        crops.push(application);
        this.cache.setWorksheet(worksheet, this.worksheetKey);
        return of(application);
      }
    }
    return EMPTY;
  }

  update(application: Crop): Observable<Crop> {
    if (application) {
      application.updatedDateTime = new Date();
    }
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const crops = worksheet.farms[0].fields[0].fieldSections[0].crops;
        if (crops && crops.length) {
          const index = crops.findIndex(c => c.id === application.id);
          if (index > -1) {
            crops[index] = application;
            this.cache.setWorksheet(worksheet, this.worksheetKey);
            return of(application);
          }
        }
      }
    }
    return EMPTY;
  }

  delete(id: string): Observable<boolean> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const crops = worksheet.farms[0].fields[0].fieldSections[0].crops;
        if (crops && crops.length) {
          const index = crops.findIndex(c => c.id === id);
          if (index > -1) {
            crops.splice(index, 1);
            this.cache.setWorksheet(worksheet, this.worksheetKey);
            return of(true);
          }
        }
      }
    }
    return of(false);
  }

  get(id: string): Observable<Crop> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        const crops = worksheet.farms[0].fields[0].fieldSections[0].crops;
        if (crops && crops.length) {
          return of(crops.find(c => c.id === id));
        }
      }
    }
    return EMPTY;
  }

  list(fieldSectionId: number): Observable<Crop[]> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey);
    if (worksheet) {
      if (worksheet.farms[0] && worksheet.farms[0].fields[0]) {
        return of(worksheet.farms[0].fields[0].fieldSections[0].crops);
      }
    }
    return EMPTY;
  }

  get isGhg() {
    return this.formService.isGhgWorksheet;
  }

  get worksheetKey() {
    return this.isGhg ? WorksheetKeys.GHG : WorksheetKeys.FMP;
  }
}
