import { Pipe, PipeTransform } from '@angular/core';
import { CacheService } from '../services/cache.service';

@Pipe({ name: 'cropTypeIdPipe' })
export class CropTypeIdPipe implements PipeTransform {
  constructor(private cache: CacheService) {}

  transform(cropId: number): number {
    return this.cache.allCropSubTypes.find((v: any) => v.cropId === cropId)?.cropTypeId;
  }
}
