import { Pipe, PipeTransform } from '@angular/core';
import { EMPTY, Observable, of } from 'rxjs';
import { FertilizerProductOption } from '../model/fertilizer-product-option.model';
import { FertilizerProductOptionsService } from '../service/fertilizer-product-options.service';

@Pipe({ name: 'productOptionPipe' })
export class ProductOptionPipe implements PipeTransform {

  constructor(private fertilizerProductOptionsService: FertilizerProductOptionsService) { }

  transform(value: any): Observable<FertilizerProductOption> {
    return this.fertilizerProductOptionsService.fertilizerProductOptions ?
      of(this.fertilizerProductOptionsService.fertilizerProductOptions.find(v => v.fertilizerProductId === value))
      : EMPTY;
  }
}
