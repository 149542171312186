export enum WorksheetKeys {
  CROP_NUTRIENT = 'CROP_NUTRIENT',
  ORGANIC_AMENDMENT = 'MATERIAL_APPLICATION',
  FERTILIZER = 'FERTILIZER_APPLICATION',
  PLATO = 'PHOSPHORUS_LOSS',
  FMP = 'FIELD_MANAGEMENT_PLAN',
  FMP_SITE = 'FIELD_MANAGEMENT_PLAN_SITE',
  STORAGE_SIZING = 'MANURE_STORAGE_SIZING',
  STORAGE_SIZING_SITE = 'MANURE_STORAGE_SIZING_SITE',
  MDS1 = 'MINIMUM_DISTANCE_SEPARATION_1',
  MDS1_SITE = 'MINIMUM_DISTANCE_SEPARATION_1_SITE',
  MDS2 = 'MINIMUM_DISTANCE_SEPARATION_2',
  MDS2_SITE = 'MINIMUM_DISTANCE_SEPARATION_2_SITE',
  NUTRIENT_MGMT = 'NUTRIENT_MANAGEMENT_STRATEGY_PLAN',
  NASM = 'NON_AGRICULTURAL_SOURCE_MATERIAL',
  GNF = 'GREENHOUSE_NUTRIENT_FEEDWATER',
  GHG = 'GREENHOUSE_GAS',
  AG_EROSION = 'AG_EROSION'
}
