import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FmpFieldSection } from '@fmp/model/fmp-field-section.model';
import { Constants } from '@shared/constants/constants';

export class GhgFieldSection extends FmpFieldSection {
  specifyMitigationPractices = false;
  croppingPracticeYears = Constants.DEFAULT_GHG_MITIGATION_CROPPING_PRACTICE_YEARS;
  tillageIntensityMitigation = Constants.DEFAULT_GHG_MITIGATION_TILLAGE_INTENSITY;
  fertilizerReductionMitigation = Constants.DEFAULT_GHG_MITIGATION_FER_REDUCTION;
  useFertilizerInhibitor: boolean;
  fertilizerIncorporationMitigation = Constants.DEFAULT_GHG_FERTILIZER_INCORP_ID;
  materialIncorporationMitigation = Constants.DEFAULT_GHG_MATERIAL_INCORP_ID;
  useFertilizerReductionByCrop: boolean;
  hasTreePlanting = Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTED;
  treePlantingReplacesCropland: boolean;
  treePlantingType = Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING;
  treePlantingSpecies = Constants.DEFAULT_GHG_MITIGATION_TREE_SPECIES_ID;
  treePlantingArea = Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_AREA;
  treePlantingLength = Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_LENGTH;
  treePlantingRows = Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_ROWS;

  constructor(init?: Partial<GhgFieldSection>) {
    super();
    Object.assign(this, init);
  }

  toForm(): UntypedFormGroup {
    const formGroup = super.toForm();

    formGroup.addControl('specifyMitigationPractices', new UntypedFormControl(this.specifyMitigationPractices));
    formGroup.addControl(
      'croppingPracticeYears',
      new UntypedFormControl(this.croppingPracticeYears, { validators: [Validators.min(1), Validators.max(99)] })
    );
    formGroup.addControl('tillageIntensityMitigation', new UntypedFormControl(this.tillageIntensityMitigation));
    formGroup.addControl('fertilizerReductionMitigation', new UntypedFormControl(this.fertilizerReductionMitigation));
    formGroup.addControl('useFertilizerInhibitor', new UntypedFormControl(this.useFertilizerInhibitor));
    formGroup.addControl('fertilizerIncorporationMitigation', new UntypedFormControl(this.fertilizerIncorporationMitigation));
    formGroup.addControl('materialIncorporationMitigation', new UntypedFormControl(this.materialIncorporationMitigation));
    formGroup.addControl('useFertilizerReductionByCrop', new UntypedFormControl(this.useFertilizerReductionByCrop));
    formGroup.addControl('hasTreePlanting', new UntypedFormControl(this.hasTreePlanting));
    formGroup.addControl('treePlantingReplacesCropland', new UntypedFormControl(this.treePlantingReplacesCropland));
    formGroup.addControl('treePlantingType', new UntypedFormControl(this.treePlantingType));
    formGroup.addControl('treePlantingSpecies', new UntypedFormControl(this.treePlantingSpecies));
    formGroup.addControl(
      'treePlantingArea',
      new UntypedFormControl(this.treePlantingArea, {
        validators: [Validators.min(Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_AREA), Validators.max(1000000)]
      })
    );
    formGroup.addControl(
      'treePlantingLength',
      new UntypedFormControl(this.treePlantingLength, {
        validators: [Validators.min(Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_LENGTH), Validators.max(1000000)]
      })
    );
    formGroup.addControl(
      'treePlantingRows',
      new UntypedFormControl(this.treePlantingRows, {
        validators: [Validators.min(Constants.DEFAULT_GHG_MITIGATION_TREE_PLANTING_ROWS), Validators.max(1000000)]
      })
    );

    return formGroup;
  }

  toModel(form: UntypedFormGroup): GhgFieldSection {
    const model = Object.assign(this, super.toModel(form));
    const v = form.getRawValue();

    model.specifyMitigationPractices = v.specifyMitigationPractices;
    model.croppingPracticeYears = v.croppingPracticeYears;
    model.tillageIntensityMitigation = v.tillageIntensityMitigation;
    model.fertilizerReductionMitigation = v.fertilizerReductionMitigation;
    model.useFertilizerInhibitor = v.useFertilizerInhibitor;
    model.fertilizerIncorporationMitigation = v.fertilizerIncorporationMitigation;
    model.materialIncorporationMitigation = v.materialIncorporationMitigation;
    model.useFertilizerReductionByCrop = v.useFertilizerReductionByCrop;
    model.hasTreePlanting = v.hasTreePlanting;
    model.treePlantingReplacesCropland = v.treePlantingReplacesCropland;
    model.treePlantingType = v.treePlantingType;
    model.treePlantingSpecies = v.treePlantingSpecies;
    model.treePlantingArea = v.treePlantingArea;
    model.treePlantingLength = v.treePlantingLength;
    model.treePlantingRows = v.treePlantingRows;

    return model;
  }
}
