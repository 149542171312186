import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SharedFieldService {
  constructor(private httpClient: HttpClient) {}

  save(field: any): Observable<any> {
    return this.httpClient.post(URLs.SHARED_FIELD_ENDPOINT, field);
  }

  update(field: any): Observable<any> {
    return this.httpClient.put(`${URLs.SHARED_FIELD_ENDPOINT}/${field.id}`, field);
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${URLs.SHARED_FIELD_ENDPOINT}/${id}`);
  }
}
