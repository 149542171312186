import { Utils } from '@core/utilities/utils';
import { MaterialType } from '@plato/model/material-type.model';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { PlatoFarm } from './plato-farm.model';

export class PlatoWorksheet extends BaseModel {
    name: string;
    worksheetTypeId = WorksheetTypeIds.PHOSPHORUS_LOSS;
    measureSystemName = 'Imperial';
    langPreference = 'en';
    userAccountId = '';
    worksheetGroupName: string;
    worksheetSubGroupName: string;
    farms: PlatoFarm[] = [];
    materialTypes: MaterialType[] = [];
    isLocal = true;

    constructor(init?: Partial<PlatoWorksheet>) {
        super();
        this.id = this.id || Utils.uuid();
        this.name = this.isEn ? 'Plato 1' : 'Plato 1';
        this.farms[0] = new PlatoFarm(init);
        Object.assign(this, init);
    }
}
