import { Injectable } from '@angular/core';
import { CropYieldUnits } from '@crop-nutrient/model/crop-yield-units.enum';
import { ConversionConstants as Constants } from '../constants/conversion-constants';
import { MeasurementSystem } from '../models/common/measurement-system.enum';

@Injectable({
  providedIn: 'root'
})
export class UnitConverterService {
  // tslint:disable-next-line: variable-name
  private _from: MeasurementSystem;
  // tslint:disable-next-line: variable-name
  private _to: MeasurementSystem;

  public unitTypes = [];

  constructor() {
    const Metric = MeasurementSystem.Metric;
    const Imperial = MeasurementSystem.Imperial;
    const US = MeasurementSystem.US;

    // Build a two dimenstion array/map, dimension one: unit type (mass, area, length etc.); dimension two: concrete unit
    this.unitTypes[Constants.UNIT_TYPE_AREA] = {};
    this.unitTypes[Constants.UNIT_TYPE_AREA][MeasurementSystem[Metric]] = Constants.HA;
    this.unitTypes[Constants.UNIT_TYPE_AREA][MeasurementSystem[Imperial]] = Constants.AC;
    this.unitTypes[Constants.UNIT_TYPE_AREA][MeasurementSystem[US]] = Constants.AC;

    this.unitTypes[Constants.UNIT_TYPE_AREA2] = {};
    this.unitTypes[Constants.UNIT_TYPE_AREA2][MeasurementSystem[Metric]] = Constants.SQ_M;
    this.unitTypes[Constants.UNIT_TYPE_AREA2][MeasurementSystem[Imperial]] = Constants.SQ_FT;
    this.unitTypes[Constants.UNIT_TYPE_AREA2][MeasurementSystem[US]] = Constants.SQ_FT;

    this.unitTypes[Constants.UNIT_TYPE_YIELD] = {};
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[Metric]] = Constants.TONNE_HA;
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[Imperial]] = {};
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[Imperial]][0] = Constants.TON_AC;
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[Imperial]][1] = Constants.BU_AC;
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[US]] = {};
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[US]][0] = Constants.TON_AC;
    this.unitTypes[Constants.UNIT_TYPE_YIELD][MeasurementSystem[US]][1] = Constants.BU_AC;

    this.unitTypes[Constants.UNIT_TYPE_APP_MASS] = {};
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS][MeasurementSystem[Metric]] = Constants.KG_HA;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS][MeasurementSystem[Imperial]] = Constants.LB_AC;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS][MeasurementSystem[US]] = Constants.LB_AC;

    this.unitTypes[Constants.UNIT_TYPE_AREA_COST] = {};
    this.unitTypes[Constants.UNIT_TYPE_AREA_COST][MeasurementSystem[Metric]] = Constants.$_HA;
    this.unitTypes[Constants.UNIT_TYPE_AREA_COST][MeasurementSystem[Imperial]] = Constants.$_AC;
    this.unitTypes[Constants.UNIT_TYPE_AREA_COST][MeasurementSystem[US]] = Constants.$_AC;

    this.unitTypes[Constants.UNIT_TYPE_MASS] = {};
    this.unitTypes[Constants.UNIT_TYPE_MASS][MeasurementSystem[Metric]] = Constants.KG;
    this.unitTypes[Constants.UNIT_TYPE_MASS][MeasurementSystem[Imperial]] = Constants.LB;
    this.unitTypes[Constants.UNIT_TYPE_MASS][MeasurementSystem[US]] = Constants.LB;

    this.unitTypes[Constants.UNIT_TYPE_MASS_LARGE] = {};
    this.unitTypes[Constants.UNIT_TYPE_MASS_LARGE][MeasurementSystem[Metric]] = Constants.TONNE;
    this.unitTypes[Constants.UNIT_TYPE_MASS_LARGE][MeasurementSystem[Imperial]] = Constants.TON;
    this.unitTypes[Constants.UNIT_TYPE_MASS_LARGE][MeasurementSystem[US]] = Constants.TON;

    this.unitTypes[Constants.UNIT_TYPE_LIQUID_VOLUME] = {};
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_VOLUME][MeasurementSystem[Metric]] = Constants.CU_M;
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_VOLUME][MeasurementSystem[Imperial]] = Constants.GAL;
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_VOLUME][MeasurementSystem[US]] = Constants.US_GAL;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME2] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2][MeasurementSystem[Metric]] = Constants.CU_M;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2][MeasurementSystem[Imperial]] = Constants.CU_FT;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2][MeasurementSystem[US]] = Constants.CU_FT;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME2_S] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2_S][MeasurementSystem[Metric]] = Constants.CU_M_S;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2_S][MeasurementSystem[Imperial]] = Constants.CU_FT_S;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME2_S][MeasurementSystem[US]] = Constants.CU_FT_S;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME3] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME3][MeasurementSystem[Metric]] = Constants.CU_M;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME3][MeasurementSystem[Imperial]] = Constants.CU_YD;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME3][MeasurementSystem[US]] = Constants.CU_YD;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME][MeasurementSystem[Metric]] = Constants.L;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME][MeasurementSystem[Imperial]] = Constants.GAL;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME][MeasurementSystem[US]] = Constants.US_GAL;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME_FLOW_RATE] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_FLOW_RATE][MeasurementSystem[Metric]] = Constants.LPS;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_FLOW_RATE][MeasurementSystem[Imperial]] = Constants.CFM;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_FLOW_RATE][MeasurementSystem[US]] = Constants.CFM;

    this.unitTypes[Constants.UNIT_TYPE_LIQUID_AMOUNT] = {};
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_AMOUNT][MeasurementSystem[Metric]] = Constants.CU_M;
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_AMOUNT][MeasurementSystem[Imperial]] = Constants.GAL;
    this.unitTypes[Constants.UNIT_TYPE_LIQUID_AMOUNT][MeasurementSystem[US]] = Constants.US_GAL;

    this.unitTypes[Constants.UNIT_TYPE_DENSITY2] = {};
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[Metric]] = Constants.KB_CU_M;
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[Imperial]] = Constants.LB_CU_FT;
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[US]] = Constants.LB_CU_FT;

    this.unitTypes[Constants.UNIT_TYPE_ANNUAL_PERCIPITATION] = {};
    this.unitTypes[Constants.UNIT_TYPE_ANNUAL_PERCIPITATION][MeasurementSystem[Metric]] = Constants.MM;
    this.unitTypes[Constants.UNIT_TYPE_ANNUAL_PERCIPITATION][MeasurementSystem[Imperial]] = Constants.IN;
    this.unitTypes[Constants.UNIT_TYPE_ANNUAL_PERCIPITATION][MeasurementSystem[US]] = Constants.IN;

    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT] = {};
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT][MeasurementSystem[Metric]] = Constants.M;
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT][MeasurementSystem[Imperial]] = Constants.FT;
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT][MeasurementSystem[US]] = Constants.FT;

    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT_S] = {};
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT_S][MeasurementSystem[Metric]] = Constants.M_S;
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT_S][MeasurementSystem[Imperial]] = Constants.FT_S;
    this.unitTypes[Constants.UNIT_TYPE_LENGTH_M_FT_S][MeasurementSystem[US]] = Constants.FT_S;

    this.unitTypes[Constants.UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA] = {};
    this.unitTypes[Constants.UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA][MeasurementSystem[Metric]] = Constants.L_SQ_M;
    this.unitTypes[Constants.UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA][MeasurementSystem[Imperial]] = Constants.GAL_SQ_FT;
    this.unitTypes[Constants.UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA][MeasurementSystem[US]] = Constants.US_GAL_SQ_FT;

    this.unitTypes[Constants.UNIT_TYPE_VOLUME_AREA] = {};
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_AREA][MeasurementSystem[Metric]] = Constants.CU_M_HA;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_AREA][MeasurementSystem[Imperial]] = Constants.GAL_AC;
    this.unitTypes[Constants.UNIT_TYPE_VOLUME_AREA][MeasurementSystem[US]] = Constants.US_GAL_AC;

    this.unitTypes[Constants.UNIT_TYPE_DENSITY2] = {};
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[Metric]] = Constants.KB_CU_M;
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[Imperial]] = Constants.LB_CU_FT;
    this.unitTypes[Constants.UNIT_TYPE_DENSITY2][MeasurementSystem[US]] = Constants.LB_CU_FT;

    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_LARGE] = {};
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_LARGE][MeasurementSystem[Metric]] = Constants.TONNE_HA;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_LARGE][MeasurementSystem[Imperial]] = Constants.TON_AC;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_LARGE][MeasurementSystem[US]] = Constants.TON_AC;

    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION] = {};
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION][MeasurementSystem[Metric]] = Constants.TONNE_HA;
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION][MeasurementSystem[Imperial]] = Constants.TON_AC;
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION][MeasurementSystem[US]] = Constants.TON_AC;

    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION_Y] = {};
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION_Y][MeasurementSystem[Metric]] = Constants.TONNE_HA_YEAR;
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION_Y][MeasurementSystem[Imperial]] = Constants.TON_AC_YEAR;
    this.unitTypes[Constants.UNIT_TYPE_SOIL_EROSION_Y][MeasurementSystem[US]] = Constants.TON_AC_YEAR;

    this.unitTypes[Constants.UNIT_TYPE_YIELD_PRICE] = {};
    this.unitTypes[Constants.UNIT_TYPE_YIELD_PRICE][MeasurementSystem[Metric]] = Constants.$_TONNE;
    this.unitTypes[Constants.UNIT_TYPE_YIELD_PRICE][MeasurementSystem[Imperial]] = Constants.$_BU;
    this.unitTypes[Constants.UNIT_TYPE_YIELD_PRICE][MeasurementSystem[US]] = Constants.$_BU;

    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_COST] = {};
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_COST][MeasurementSystem[Metric]] = Constants.$_KG;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_COST][MeasurementSystem[Imperial]] = Constants.$_LB;
    this.unitTypes[Constants.UNIT_TYPE_APP_MASS_COST][MeasurementSystem[US]] = Constants.$_LB;

    this.unitTypes[Constants.UNIT_TYPE_TEMP] = {};
    this.unitTypes[Constants.UNIT_TYPE_TEMP][MeasurementSystem[Metric]] = Constants.CELSIUS;
    this.unitTypes[Constants.UNIT_TYPE_TEMP][MeasurementSystem[Imperial]] = Constants.FAHRENHEIT;
    this.unitTypes[Constants.UNIT_TYPE_TEMP][MeasurementSystem[US]] = Constants.FAHRENHEIT;

    this.unitTypes[Constants.UNIT_TYPE_KWH] = {};
    this.unitTypes[Constants.UNIT_TYPE_KWH][MeasurementSystem[Metric]] = Constants.KWH;
    this.unitTypes[Constants.UNIT_TYPE_KWH][MeasurementSystem[Imperial]] = Constants.KWH;
    this.unitTypes[Constants.UNIT_TYPE_KWH][MeasurementSystem[US]] = Constants.KWH;
  }

  public convert(value: number, unitType: string) {
    switch (unitType) {
      case Constants.UNIT_TYPE_AREA:
        return this.convertArea(value);
      case Constants.UNIT_TYPE_AREA2:
        return this.convertArea2(value);
      case Constants.UNIT_TYPE_APP_MASS:
        return this.convertAppMass(value);
      case Constants.UNIT_TYPE_MASS:
        return this.convertMass(value);
      case Constants.UNIT_TYPE_AREA_COST:
        return this.convertAreaCost(value);
      case Constants.UNIT_TYPE_LIQUID_VOLUME:
        return this.convertVolumeM3(value);
      case Constants.UNIT_TYPE_VOLUME:
        return this.convertVolume(value);
      case Constants.UNIT_TYPE_VOLUME2:
        return this.convertVolume2(value);
      case Constants.UNIT_TYPE_VOLUME2_S:
        return this.convertVolume2(value);
      case Constants.UNIT_TYPE_VOLUME3:
        return this.convertVolume3(value);
      case Constants.UNIT_TYPE_APP_VOLUME:
        return this.convertAppVolume(value);
      case Constants.UNIT_TYPE_VOLUME_FLOW_RATE:
        return this.convertVolumeFlowRate(value);
      case Constants.UNIT_TYPE_DENSITY2:
        return this.convertDensity2(value);
      case Constants.UNIT_TYPE_LIQUID_AMOUNT:
        return this.convertAmount(value);
      case Constants.UNIT_TYPE_MASS_LARGE:
        return this.convertMassLarge(value);
      case Constants.UNIT_TYPE_APP_MASS_COST:
        return this.convertMassCost(value);
      case Constants.UNIT_TYPE_APP_MASS_LARGE_COST:
        return this.convertMassLargeCost(value);
      case Constants.UNIT_TYPE_VOLUME_M3_COST:
        return this.convertVolumeM3Cost(value);
      case Constants.UNIT_TYPE_ANNUAL_PERCIPITATION:
        return this.convertLengthSmall2(value);
      case Constants.UNIT_TYPE_LENGTH_M_FT:
        return this.convertLength(value);
      case Constants.UNIT_TYPE_LENGTH_M_FT_S:
        return this.convertLength(value);
      case Constants.UNIT_TYPE_APP_MASS_LARGE:
        return this.convertAppMassLarge(value);
      case Constants.UNIT_TYPE_VOLUME_AREA:
        return this.convertAppVolumeArea(value);
      case Constants.UNIT_TYPE_DENSITY2:
        return this.convertDensity2(value);
      case Constants.UNIT_TYPE_HORTICULTURAL_AMOUNT_AREA:
        return this.convertHorticulturalAmountArea(value);
      case Constants.UNIT_TYPE_SOIL_EROSION:
        return this.convertSoilErosion(value);
      case Constants.UNIT_TYPE_SOIL_EROSION_Y:
        return this.convertSoilErosion(value);
      case Constants.UNIT_TYPE_TEMP:
        return this.convertTemperature(value);
      case Constants.NO_CONVERSION:
        return value;
      default:
        return value;
    }
  }

  private convertMetricToImperial(metric: number) {
    return metric * 3.28;
  }

  private convertMetricToAmerican(metric: number) {
    return metric * 1.09361;
  }
  // the following code is from java
  // Simple conversion method - used by most other conversion methods
  public convertSimple(value: number, conversionFactor: number, multiplyFactor: boolean) {
    // Simple conversion - use conversionFactor parameter
    let result: number;
    // Do not convert if units are the same
    if (this.to !== this.from) {
      // Conversion Factor: UNIT_X_Y
      // Muliply factor: true -> converting from X to Y (multiply)
      // false -> converting from Y to X (divide)
      if (multiplyFactor) {
        result = value * conversionFactor;
      } else {
        result = value / conversionFactor;
      }
    } else {
      result = value;
    }
    return result;
  }

  // Convert Area: ha <-> ac
  public convertArea(area: number) {
    // Convert between ha and ac
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(area, Constants.AREA_HA_AC, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(area, Constants.AREA_HA_AC, true);
    } else {
      result = area;
    }
    return result;
  }

  // Convert AppMass: kg/ha <-> lb/ac
  public convertAppMass(appMass: number) {
    // Convert between kg/ha and lb/ac
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(appMass, Constants.MASS_KG_LB / Constants.AREA_HA_AC, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(appMass, Constants.MASS_KG_LB / Constants.AREA_HA_AC, true);
    } else {
      result = appMass;
    }
    return result;
  }

  // Convert AppVolume: L/ha <-> gal/ac
  public convertAppVolume(appVolume: number) {
    // Convert between L/ha and gal/ac
    let result: number;
    // Convert volume
    result = this.convertVolume(appVolume);
    // Convert area
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(result, 1 / Constants.AREA_HA_AC, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(result, 1 / Constants.AREA_HA_AC, true);
    }
    return result;
  }

  // Convert Volume Flow Rate: L/s <-> cfm
  public convertVolumeFlowRate(value: number) {
    let result: number;
    if (this.to === MeasurementSystem.Metric && this.from !== MeasurementSystem.Metric) {
      result = this.convertSimple(value, Constants.VOLUME_LPS_CFM, false);
    } else if (this.to !== MeasurementSystem.Metric && this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(value, Constants.VOLUME_LPS_CFM, true);
    } else {
      return value;
    }
    return result;
  }

  // Convert Volume:  L <-> gal
  public convertVolume(volume: number) {
    // L to gal
    // L to USgal
    // gal to L
    // gal to USgal
    // USgal to L
    // UUgal to gal
    let result: number;

    if (this.to === MeasurementSystem.Metric && this.from === MeasurementSystem.Imperial) {
      result = this.convertSimple(volume, Constants.VOLUME_L_GAL, false);
    } else if (this.to === MeasurementSystem.Metric && this.from === MeasurementSystem.US) {
      result = this.convertSimple(volume, Constants.VOLUME_L_USGAL, false);
    } else if (this.to === MeasurementSystem.Imperial && this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_L_GAL, true);
    } else if (this.to === MeasurementSystem.Imperial && this.from === MeasurementSystem.US) {
      result = this.convertSimple(volume, Constants.VOLUME_GAL_USGAL, false);
    } else if (this.to === MeasurementSystem.US && this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_L_USGAL, true);
    } else if (this.to === MeasurementSystem.US && this.from === MeasurementSystem.Imperial) {
      result = this.convertSimple(volume, Constants.VOLUME_GAL_USGAL, true);
    } else {
      result = volume;
    }

    return result;
  }

  // Convert Volume: m³ <-> gal
  public convertVolumeM3(volume: number) {
    // m³ to gal
    // m³ to USgal
    // gal to m³
    // gal to USgal
    // USgal to m³
    // UUgal to gal
    let result: number;

    if (this.to === MeasurementSystem.Metric && this.from === MeasurementSystem.Imperial) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_GAL, false);
    } else if (this.to === MeasurementSystem.Metric && this.from === MeasurementSystem.US) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_USGAL, false);
    } else if (this.to === MeasurementSystem.Imperial && this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_GAL, true);
    } else if (this.to === MeasurementSystem.Imperial && this.from === MeasurementSystem.US) {
      result = this.convertSimple(volume, Constants.VOLUME_GAL_USGAL, false);
    } else if (this.to === MeasurementSystem.US && this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_USGAL, true);
    } else if (this.to === MeasurementSystem.US && this.from === MeasurementSystem.Imperial) {
      result = this.convertSimple(volume, Constants.VOLUME_GAL_USGAL, true);
    } else {
      result = volume;
    }

    return result;
  }

  // Convert Material Amount: gal <-> m³ (liquid) or ton <-> tonne (solid)
  public convertAmount(amount: number) {
    // Convert metric from m³ to L
    if (this.from === MeasurementSystem.Metric) {
      amount = amount * 1000;
    }
    // Convert
    amount = this.convertVolume(amount);
    // Convert metric from L to m³
    if (this.to === MeasurementSystem.Metric) {
      amount = amount / 1000;
    }
    return amount;
  }

  public convertTemperature(degree: number) {
    let res = degree;
    // Convert from °C (metric) to °F (imperial, US)
    if (this.from === MeasurementSystem.Metric && this.to !== MeasurementSystem.Metric) {
      res = (degree * 9) / 5 + 32;
    }
    // Convert from °F (imperial, US) to °C (metric)
    if (this.from !== MeasurementSystem.Metric && this.to === MeasurementSystem.Metric) {
      res = ((degree - 32) * 5) / 9;
    }
    return res;
  }

  // Convert Density: kg/L <-> lb/gal
  public convertDensity(density: number) {
    let result: number;
    const volume: number = this.convertVolume(1);
    // Convert kg/L <--> lb/gal
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(density, Constants.MASS_KG_LB * volume, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(density, Constants.MASS_KG_LB / volume, true);
    } else {
      result = density;
    }
    return result;
  }

  //  Convert Mass/Mass: kg/tonne <-> lb/ton
  public convertMassPerMass(mass: number) {
    // Convert between kg/tonne and lb/ton
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(mass, Constants.MASS_KG_LB / Constants.MASS_TONNE_TON, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(mass, Constants.MASS_KG_LB / Constants.MASS_TONNE_TON, true);
    } else {
      result = mass;
    }
    return result;
  }

  // Convert Yield: bu/ac <-> tonne/ha OR ton/ac <-> tonne/ha, in yield pipe
  public convertYield(yield1: number, yieldUnits: CropYieldUnits, cropDensity: number): number {
    // Convert:  bu/ac <-> tonne/ha OR ton/ac <-> tonne/ha
    let result: number;
    // Determine crop density
    if (cropDensity <= 0) {
      // If density is missing, force conversion to be ton/ac
      yieldUnits = CropYieldUnits.TonPerAcre;
    } else {
      // Convert from lb/bu to ton/bu
      cropDensity = cropDensity / 2000;
    }
    // [ton/bu] = [lb/bu] / [lb/ton]
    // Convert bu/ac
    if (yieldUnits === CropYieldUnits.BushelPerAcre) {
      // bu/ac <-> tonne/ha
      let tempYield: number;
      // Converting to metric - convert from bu/ac to ton/ac
      if (this.to === MeasurementSystem.Metric && this.from !== MeasurementSystem.Metric) {
        tempYield = yield1 * cropDensity;
      } else {
        // [ton/ac] = [bu/ac] * [ton/bu]
        tempYield = yield1;
      }
      // Convert ton/ac <-> tonne/ac
      tempYield = this.convertAppMassLarge(tempYield);
      // Converting from metric - convert from ton/ac to bu/ac
      if (this.from === MeasurementSystem.Metric && this.to !== MeasurementSystem.Metric) {
        result = tempYield / cropDensity;
      } else {
        // [bu/ac] = [ton/ac] / [ton/bu]
        result = tempYield;
      }
    } //
    // Convert ton/ac
    else if (yieldUnits === CropYieldUnits.TonPerAcre) {
      // Convert ton/ac <-> tonne/ha
      result = this.convertAppMassLarge(yield1);
    } else {
      result = yield1;
    }
    return result;
  }

  // Convert yield price: $/bu <-> $/tonne OR $/ton <-> $/tonne, in yield pipe
  public convertYieldPrice(yieldPrice: number, yieldUnits: CropYieldUnits, cropDensity: number) {
    // Use ConvertYield method - ConvertArea method is used to negate conversion between ha and ac
    // Eliminates having to deal with messy crop density calculations
    // if (yieldPrice <= 0)
    //    return 0;
    const conv1 = this.convertArea(1);
    const conv2 = this.convertYield(1, yieldUnits, cropDensity);
    const result = yieldPrice / (conv1 * conv2);
    return result;
  }

  // Convert AppMassLarge: tonne/ha <-> ton/ac
  public convertAppMassLarge(appMassLarge: number) {
    let result: number;
    // Convert between tonne/ha and ton/ac
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(appMassLarge, Constants.MASS_TONNE_TON / Constants.AREA_HA_AC, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(appMassLarge, Constants.MASS_TONNE_TON / Constants.AREA_HA_AC, true);
    } else {
      result = appMassLarge;
    }
    return result;
  }

  // Convert AppVolumeArea: m³/ha <-> gal/ac
  public convertAppVolumeArea(appVolumeArea: number) {
    let result: number;
    // Convert between m³/ha and gal/ac
    if (this.to === MeasurementSystem.Metric) {
      result = appVolumeArea;
    } else if (this.to === MeasurementSystem.Imperial) {
      result = this.convertSimple(appVolumeArea, Constants.VOLUME_GAL_AC / Constants.VOLUME_CU_M_HA, true);
    } else if (this.to === MeasurementSystem.US) {
      result = this.convertSimple(appVolumeArea, Constants.VOLUME_USGAL_AC / Constants.VOLUME_CU_M_HA, true);
    }
    return result;
  }

  // Convert AppMassArea: m³/ha <-> gal/ac
  public convertAppMassArea(appMassArea: number) {
    let result: number;
    // Convert between m³/ha and gal/ac
    if (this.to === MeasurementSystem.Metric) {
      result = appMassArea;
    } else if (this.to === MeasurementSystem.Imperial) {
      result = this.convertSimple(appMassArea, Constants.MASS_KG_LB / Constants.AREA_HA_AC, true);
    } else if (this.to === MeasurementSystem.US) {
      result = this.convertSimple(appMassArea, Constants.MASS_KG_LB / Constants.AREA_HA_AC, true);
    }
    return result;
  }

  // Convert Length: m <-> ft
  public convertLength(length: number) {
    let result: number;
    // Convert between m and ft
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(length, Constants.LENGTH_M_FT, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(length, Constants.LENGTH_M_FT, true);
    } else {
      result = length;
    }
    return result;
  }

  // Convert MassLarge: tonne <-> ton
  public convertMassLarge(massLarge: number) {
    let result: number;
    // Convert between tonne and ton
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(massLarge, Constants.MASS_TONNE_TON, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(massLarge, Constants.MASS_TONNE_TON, true);
    } else {
      result = massLarge;
    }
    return result;
  }

  // Convert Mass: kg <-> lb
  public convertMass(dMass: number) {
    let result: number;
    // Convert between kg and lb
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(dMass, Constants.MASS_KG_LB, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(dMass, Constants.MASS_KG_LB, true);
    } else {
      result = dMass;
    }
    return result;
  }

  // Convert LengthLarge: km <-> mi
  public convertLengthLarge(dLength: number) {
    // Convert between km and mi
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_KM_MI, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_KM_MI, true);
    } else {
      result = dLength;
    }
    return result;
  }

  // Convert LengthSmall: cm <-> in
  public convertLengthSmall(dLength: number) {
    // Convert between cm and in
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_CM_IN, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_CM_IN, true);
    } else {
      result = dLength;
    }
    return result;
  }

  // Convert LengthSmall2: mm <-> in
  public convertLengthSmall2(dLength: number) {
    // Convert between mm and in
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_MM_IN, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.LENGTH_MM_IN, true);
    } else {
      result = dLength;
    }
    return result;
  }

  // Convert Area2: m² <-> ft²
  public convertArea2(area: number) {
    // Convert between m² and ft²
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(area, Math.pow(Constants.LENGTH_M_FT, 2), false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(area, Math.pow(Constants.LENGTH_M_FT, 2), true);
    } else {
      result = area;
    }
    return result;
  }

  // Convert Volume2: m³ <-> ft³
  public convertVolume2(volume: number) {
    // Convert between m³ and ft³
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Math.pow(Constants.LENGTH_M_FT, 3), false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Math.pow(Constants.LENGTH_M_FT, 3), true);
    } else {
      result = volume;
    }
    return result;
  }

  // Convert Density2: kg/m³ <-> lb/ft³
  public convertDensity2(density: number) {
    let result: number;
    const volume = this.convertVolume2(1);
    // Convert kg/m³ <--> lb/ft³
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(density, Constants.MASS_KG_LB * volume, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(density, Constants.MASS_KG_LB / volume, true);
    } else {
      result = density;
    }
    return result;
  }

  // Convert Volume3: m³ <-> yd³
  public convertVolume3(volume: number) {
    // Convert between m³ and ft³
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_CU_YD, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(volume, Constants.VOLUME_CU_M_CU_YD, true);
    } else {
      result = volume;
    }
    return result;
  }

  // Convert Pipe Diameter: mm <-> in
  public convertPipeDiameter(dLength: number) {
    // Convert between mm and in
    let result: number;
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.PIPE_DIAMETER, false);
    } else if (this.from === MeasurementSystem.Metric) {
      result = this.convertSimple(dLength, Constants.PIPE_DIAMETER, true);
    } else {
      result = dLength;
    }
    return result;
  }

  // Convert Fertilizer Cost, not in the pipe
  public convertFertilizerCost(cost: number, isDryBlend: boolean) {
    if (isDryBlend) {
      return 1 / this.convertMass(1 / cost);
    } else {
      return 1 / this.convertVolume(1 / cost);
    }
  }

  // Convert volume cost, not in the pipe
  public convertVolumeCost(volumeCost: number) {
    // Convert:  $/gal <-> $/L
    let result: number;
    result = 1 / this.convertVolume(1 / volumeCost);
    return result;
  }

  // Convert volume cost, not in the pipe
  public convertVolumeM3Cost(volumeCost: number) {
    // Convert:  $/gal <-> $/M3
    let result: number;
    result = 1 / this.convertVolumeM3(1 / volumeCost);
    return result;
  }

  // Convert mass cost
  public convertMassCost(massCost: number) {
    // Convert: $/lb <-> $/kg
    let result: number;
    result = 1 / this.convertMass(1 / massCost);
    return result;
  }

  // Convert large mass cost, not in the pipe
  public convertMassLargeCost(massCost: number) {
    // Convert:  $/ton <-> $/tonne
    let result: number;
    result = 1 / this.convertMassLarge(1 / massCost);
    return result;
  }

  // Convert area cost, not in the pipe
  public convertAreaCost(areaCost: number) {
    // Convert:  $/ac <-> $/ha
    let result: number;
    result = 1 / this.convertArea(1 / areaCost);
    return result;
  }

  // Convert horticultural irrigation amount, not in the pipe
  public convertHorticulturalAmountArea(irrigationAmount: number) {
    // Convert between L/m² and gal/ft²
    // Convert L <--> gal
    let result: number = this.convertVolume(irrigationAmount);
    // Convert m² and ft²
    result = 1 / this.convertArea2(1 / result);
    return result;
  }

  // Convert Soil Erosion: tonne/ha <-> ton/ac
  public convertSoilErosion(soilErosion: number) {
    let result = 0;
    // Convert between tonne/ha and ton/ac
    if (this.to === MeasurementSystem.Metric) {
      result = this.convertSimple(soilErosion, Constants.SOILEROSION_TONNEHA_TONAC, false);
    } else if (this.from == MeasurementSystem.Metric) {
      result = this.convertSimple(soilErosion, Constants.SOILEROSION_TONNEHA_TONAC, true);
    } else {
      result = soilErosion;
    }
    return result;
  }

  get from() {
    return this._from;
  }

  set from(val: MeasurementSystem) {
    this._from = val;
  }

  get to() {
    return this._to;
  }

  set to(val: MeasurementSystem) {
    this._to = val;
  }
}
