import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';

@Pipe({ name: 'nasmMcTypePipe' })
export class NasmMcTypePipe implements PipeTransform {

  constructor() { }

  transform(element: UntypedFormGroup): string {
    const componentType = element?.controls?.componentType?.value;
    const componentTypeOption = ArrayConstants.NASM_COMPONENT_TYPES.find(v => v.id === componentType);
    return componentTypeOption.i18nkey;
  }
}
