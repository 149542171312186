import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { ContactType } from '@shared/models/common/contact-type.enum';
import { DataType } from '@shared/models/common/data-type.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { ControlStructure } from './control-structure.model';
import { Watershed } from './watershed.model';

export class AgErosionWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.AG_EROSION;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  startYear: number;
  endYear: number;
  isLocal = true;
  currentTab = 0;
  applicationDate: Date;
  fileNumber: string;
  landUseTypeId: number;
  submissionId: string;
  hasPreviousSubmission: boolean;
  reasonForSubmission: number;
  reasonDetails: string;
  isOverviewPrepared: boolean;
  hasMunicipalWells: boolean;
  isNmpRequired: boolean;
  federalBusinessNumber: string;
  federalBusinessExempt: number;
  isFederalBusinessExempt: boolean;
  legalFarmName: string;
  operationType: number;
  operationTypeComment: string;
  isOwnerSameAsOperator: boolean;
  noPreviousNasmApplied: boolean;
  registrationId: string;
  nasm: boolean;
  nmsp: boolean;
  gnf: boolean;
  watersheds: Watershed[];
  currentWatershedId: string;
  controlStructures: ControlStructure[];
  currentControlStructureId: string;
  contacts: ContactInformation[] = [];

  constructor(init?: Partial<AgErosionWorksheet>) {
    super();
    this.name = this.isEn ? 'AgErosion 1' : 'AgErosion 1';
    this.setContacts(init);
    Object.assign(this, init);

    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(e => {
        e.parentType = DataType.WORKSHEET;
        e.parentId = this.id ? this.id : e.parentId;
      });
    }
  }

  private setContacts(init: Partial<AgErosionWorksheet>) {
    this.contacts[0] = init
      ? new ContactInformation(init.contacts ? init.contacts[0] : undefined)
      : new ContactInformation({ contactType: ContactType.Preparer });
    this.contacts[1] = init
      ? new ContactInformation(init.contacts ? init.contacts[1] : undefined)
      : new ContactInformation({ contactType: ContactType.MdsApplicant });
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      startYear: new UntypedFormControl(this.startYear),
      endYear: new UntypedFormControl(this.endYear),
      currentTab: new UntypedFormControl(this.currentTab),
      applicationDate: new UntypedFormControl(this.applicationDate),
      fileNumber: new UntypedFormControl(this.fileNumber),
      landUseTypeId: new UntypedFormControl(this.landUseTypeId),
      submissionId: new UntypedFormControl(this.submissionId),
      hasPreviousSubmission: new UntypedFormControl(this.hasPreviousSubmission),
      reasonForSubmission: new UntypedFormControl(this.reasonForSubmission),
      reasonDetails: new UntypedFormControl(this.reasonDetails),
      isOverviewPrepared: new UntypedFormControl(this.isOverviewPrepared),
      hasMunicipalWells: new UntypedFormControl(this.hasMunicipalWells),
      isNmpRequired: new UntypedFormControl(this.isNmpRequired),
      federalBusinessNumber: new UntypedFormControl(this.federalBusinessNumber),
      federalBusinessExempt: new UntypedFormControl(this.federalBusinessExempt),
      isFederalBusinessExempt: new UntypedFormControl(this.isFederalBusinessExempt),
      legalFarmName: new UntypedFormControl(this.legalFarmName),
      operationType: new UntypedFormControl(this.operationType),
      operationTypeComment: new UntypedFormControl(this.operationTypeComment),
      isOwnerSameAsOperator: new UntypedFormControl(this.isOwnerSameAsOperator),
      noPreviousNasmApplied: new UntypedFormControl(this.noPreviousNasmApplied),
      registrationId: new UntypedFormControl(this.registrationId),
      nasm: new UntypedFormControl(this.nasm),
      nmsp: new UntypedFormControl(this.nmsp),
      gnf: new UntypedFormControl(this.gnf),
      watersheds: new UntypedFormArray(this.watersheds?.length ? this.watersheds?.map((w: Watershed) => new Watershed(w).toForm()) : []),
      currentWatershedId: new UntypedFormControl(this.currentWatershedId),
      controlStructures: new UntypedFormArray(
        this.controlStructures?.length ? this.controlStructures?.map((w: ControlStructure) => new ControlStructure(w).toForm()) : []
      ),
      currentControlStructureId: new UntypedFormControl(this.currentControlStructureId),
      contacts: new UntypedFormArray(
        this.contacts.map(w => new ContactInformation(w).toForm()),
        [ValidationFunctions.arrayLength(2), ValidationFunctions.mds1ContactTypes]
      )
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const watersheds = form.get('watersheds') as UntypedFormArray;
    const controlStructures = form.get('controlStructures') as UntypedFormArray;
    const contacts = form.get('contacts') as UntypedFormArray;
    return new AgErosionWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      startYear: v.startYear,
      endYear: v.endYear,
      currentTab: v.currentTab,
      applicationDate: v.applicationDate,
      fileNumber: v.fileNumber,
      landUseTypeId: v.landUseTypeId,
      submissionId: v.submissionId,
      hasPreviousSubmission: v.hasPreviousSubmission,
      reasonForSubmission: v.reasonForSubmission,
      reasonDetails: v.reasonDetails,
      isOverviewPrepared: v.isOverviewPrepared,
      hasMunicipalWells: v.hasMunicipalWells,
      isNmpRequired: v.isNmpRequired,
      federalBusinessNumber: v.federalBusinessNumber,
      federalBusinessExempt: v.federalBusinessExempt,
      isFederalBusinessExempt: v.isFederalBusinessExempt,
      legalFarmName: v.legalFarmName,
      operationType: v.operationType,
      operationTypeComment: v.operationTypeComment,
      isOwnerSameAsOperator: v.isOwnerSameAsOperator,
      noPreviousNasmApplied: v.noPreviousNasmApplied,
      registrationId: v.registrationId,
      nasm: v.nasm,
      nmsp: v.nmsp,
      gnf: v.gnf,
      watersheds: watersheds.controls.map((w: UntypedFormGroup) => new Watershed().toModel(w)),
      currentWatershedId: v.currentWatershedId,
      controlStructures: controlStructures.controls.map((w: UntypedFormGroup) => new ControlStructure().toModel(w)),
      currentControlStructureId: v.currentControlStructureId,
      contacts: contacts?.controls.map((w: UntypedFormGroup) => new ContactInformation().toModel(w))
    });
  }
}
