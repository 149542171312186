import { Component, HostListener, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MsalService } from '@azure/msal-angular';
import { DialogData } from '@shared/components/confirmation-dialog/DialogData';
import { WorksheetClient } from '@shared/services/worksheet.client';
import { InstantErrorStateMatcher } from '@shared/validators/instant-error-state-matcher';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-rename-worksheet',
  templateUrl: './rename-worksheet.component.html',
  styleUrls: ['./rename-worksheet.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RenameWorksheetComponent implements OnInit, OnDestroy {
  data: DialogData;
  // define a name form for worksheet name
  calculatorNameForm: UntypedFormGroup;
  matcher = new InstantErrorStateMatcher();

  private alive = true;

  constructor(
    private dialogRef: MatDialogRef<RenameWorksheetComponent>,
    private msalService: MsalService,
    private formBuilder: UntypedFormBuilder,
    private worksheetClient: WorksheetClient,
    @Inject(MAT_DIALOG_DATA) data: DialogData
  ) {
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.buildForm();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  @HostListener('window: keyup.esc') onKeyUp() {
    this.onNoClick();
  }

  apply() {
    // if input values are in invalid state, don't submit to server side
    if (this.calculatorNameForm.invalid) {
      return false;
    }
    // get form control by name
    let newName = this.calculatorNameForm.get('calculatorName').value;
    if (newName) {
      newName = newName.trim();
    }
    // valid name change, update database and auth home list
    if (newName !== '' && newName !== this.data.worksheet.name) {
      this.data.worksheet.name = newName;
      this.data.worksheet.updatedBy = this.azureUser;
      this.worksheetClient
        .updateFlat(this.data.worksheet)
        .pipe(takeWhile(() => this.alive))
        .subscribe(data => {
          if (data) {
            // return updated worksheet to caller
            this.dialogRef.close(data);
          }
        });
    }
  }

  private buildForm() {
    this.calculatorNameForm = this.formBuilder.group({
      calculatorName: [
        this.data.worksheet.name,
        [Validators.required, Validators.minLength(3), Validators.maxLength(30), ValidationFunctions.empty]
      ]
    });
  }

  get calculatorName() {
    return this.calculatorNameForm.get('calculatorName');
  }

  private get azureUser() {
    const all = this.msalService.instance.getAllAccounts();
    const first = all[0];
    return first.username;
  }
}
