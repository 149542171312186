import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class LocationLot extends BaseModel {
    concessionName: string;
    geotownship: string;
    isGeneratedAsm = false;
    isGeneratedGnf = false;
    isStoredGnf = false;
    lotId = 0;
    lotName: string;
    overrideConcession = false;
    overrideLot = false;
    parentId: string;
    parentType: string;
    upperTierCode = 0;
    lowerTierCode: string;

    constructor(init?: Partial<LocationLot>) {
        super();
        Object.assign(this, init);
        this.id = this.id || Utils.uuid();
    }

    toForm() {
        return new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            updatedBy: new UntypedFormControl(this.updatedBy),
            createdDateTime: new UntypedFormControl(this.createdDateTime),
            updatedDateTime: new UntypedFormControl(this.updatedDateTime),
            concessionName: new UntypedFormControl(this.concessionName, { validators: [Validators.maxLength(50)] }),
            geotownship: new UntypedFormControl(this.geotownship),
            isGeneratedAsm: new UntypedFormControl(this.isGeneratedAsm),
            isGeneratedGnf: new UntypedFormControl(this.isGeneratedGnf),
            isStoredGnf: new UntypedFormControl(this.isStoredGnf),
            lotId: new UntypedFormControl(this.lotId),
            lotName: new UntypedFormControl(this.lotName, { validators: [Validators.maxLength(50)] }),
            overrideConcession: new UntypedFormControl(this.overrideConcession),
            overrideLot: new UntypedFormControl(this.overrideLot),
            parentId: new UntypedFormControl(this.parentId),
            parentType: new UntypedFormControl(this.parentType),
            upperTierCode: new UntypedFormControl(this.upperTierCode),
            lowerTierCode: new UntypedFormControl(this.lowerTierCode)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new LocationLot({
            id: v.id,
            createdBy: v.createdBy,
            updatedBy: v.updatedBy,
            createdDateTime: v.createdDateTime,
            updatedDateTime: v.updatedDateTime,
            concessionName: v.concessionName,
            geotownship: v.geotownship,
            isGeneratedAsm: v.isGeneratedAsm,
            isGeneratedGnf: v.isGeneratedGnf,
            isStoredGnf: v.isStoredGnf,
            lotId: v.lotId,
            lotName: v.lotName,
            overrideConcession: v.overrideConcession,
            overrideLot: v.overrideLot,
            parentId: v.parentId,
            parentType: v.parentType,
            upperTierCode: v.upperTierCode,
            lowerTierCode: v.lowerTierCode
        });
    }
}
