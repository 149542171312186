import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { FormService } from '@shared/services/form.service';
import { FmpCalculation } from '../model/fmp-calculation.model';
import { FmpFarm } from '../model/fmp-farm.model';
import { FmpField } from '../model/fmp-field.model';
import { FmpReportType } from '../model/fmp-report-type.model';
import { FmpWorksheet } from '../model/fmp-worksheet.model';

@Injectable({ providedIn: 'root' })
export class FmpCalculationService extends CalculationService {
  constructor(protected httpClient: HttpClient, protected formService: FormService) {
    super(httpClient, formService);
  }

  publish(v: FmpCalculation) {
    this.calculation$.next(v);
  }

  calculate(f: UntypedFormGroup, reportType?: FmpReportType) {
    const model = new FmpWorksheet().toModel(f || this.formService.f);
    if (reportType === FmpReportType.currentFieldOnly && this.currentFarmId && this.currentFarmId.value) {
      // Only keep current field and farm for single field calculation
      model.farms = model.farms.filter((farm: FmpFarm) => Utils.matchStr(farm.id, this.currentFarmId.value));
      model.farms[0].fields = model.farms[0].fields.filter((field: FmpField) => Utils.matchStr(field.id, this.currentFieldId.value));
    }
    this.lastCalc = model;
    this.input$.next(model);
  }

  update() {
    const model = new FmpWorksheet().toModel(this.formService.f);
    if (this.lastCalc) {
      const merged = { ...this.lastCalc, ...model };
      this.input$.next(merged);
    }
  }

  get currentFieldId() {
    return this.f.get('currentFieldId');
  }

  _currentField: UntypedFormGroup;

  get currentField() {
    let currentField: UntypedFormGroup;
    this.farms.controls.forEach(farm => {
      const fields = farm.get('fields') as UntypedFormArray;
      fields.controls.forEach((field: UntypedFormGroup) => {
        if (Utils.matchStr(field.get('id').value, this.currentFieldId.value)) {
          currentField = field;
        }
      });
    });
    return currentField;
  }

  set currentField(v: any) {
    this._currentField = v;
  }

  _currentFarm: UntypedFormGroup;

  get currentFarm() {
    return this.farms && this.currentField && this.currentField.get('farmId').value
      ? (this.farms.controls.find(v => Utils.matchStr(v.get('id').value, this.currentField.get('farmId').value)) as UntypedFormGroup)
      : undefined;
  }

  set currentFarm(v: any) {
    this._currentFarm = v;
  }

  get currentFieldSection() {
    return this.currentField ? (this.currentField.get('fieldSections.0') as UntypedFormGroup) : undefined;
  }

  get currentFarmId() {
    return this.currentFarm ? this.currentFarm.get('id') : undefined;
  }

  get farms() {
    return this.f ? (this.f.get('farms') as UntypedFormArray) : undefined;
  }

  get fields() {
    return this.currentFarm ? (this.currentFarm.get('fields') as UntypedFormArray) : undefined;
  }

  get f() {
    return this.formService.f || undefined;
  }
}
