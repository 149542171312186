import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { FarmFieldFormGroup, FarmFieldsFormGroup } from './farm-fields-form-group-model';

export class FarmsFormGroup {
  private _farms: UntypedFormArray;

  constructor(farms: UntypedFormArray) {
    this._farms = farms;
  }

  getById(farmId: string): FarmFormGroup {
    return new FarmFormGroup(this._farms?.controls?.find(v => Utils.matchStr(v?.get('id')?.value, farmId)) as UntypedFormGroup);
  }

  getFieldById(fieldId: string): FarmFieldFormGroup {
    const fields = this._farms?.controls?.reduce((allFields, farm) => {
      allFields = allFields.concat((farm?.get('fields') as UntypedFormArray)?.controls);
      return allFields;
    }, []);
    const field = fields?.find(v => Utils.matchStr(v?.get('id')?.value, fieldId));
    const farm = this._farms?.controls.find(v => Utils.matchStr(v?.get('id')?.value, field?.get('farmId')?.value)) as UntypedFormGroup;
    return new FarmFieldFormGroup(farm, field);
  }

  first(): FarmFormGroup {
    return this._farms.length && this._farms.controls[0] ? new FarmFormGroup(this._farms.controls[0] as UntypedFormGroup) : null;
  }

  get length(): number {
    return this._farms.length;
  }
}

export class FarmFormGroup {
  private _farm: UntypedFormGroup;

  constructor(farm: UntypedFormGroup) {
    this._farm = farm;
  }

  fieldSection(): UntypedFormGroup {
    return this.fields.first().fieldSection;
  }

  get nameCtrl(): UntypedFormControl {
    return this._farm?.get('name') as UntypedFormControl;
  }

  get name(): string {
    return this.nameCtrl?.value;
  }

  get fields(): FarmFieldsFormGroup {
    return new FarmFieldsFormGroup(this._farm, this._farm?.get('fields') as UntypedFormArray);
  }
}
