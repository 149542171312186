import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
  selector: '[form-field-with-flag]',
  templateUrl: './form-field-with-flag.component.html',
  styleUrls: ['./form-field-with-flag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormFieldWithFlagComponent {
  @Input() flag$: Observable<any>;
  @Input() nextTo: string;
}
