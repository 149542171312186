import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from './../../../shared/validators/validation-functions';
import { AmountType } from './amount-type.enum';
import { MilkingLivestockType } from './milking-livestock-type.enum';
import { MoistureLevel } from './moisture-level.enum';
import { OtherSourceProductionPeriod } from './other-source-production-period.model';
import { SiloCovered } from './silo-covered.enum';
import { SiloFillDate } from './silo-fill-date.enum';
import { SiloType } from './silo-type.enum';

export class MstorOtherSource extends BaseModel {
  name: string;
  notes: string;
  storageSystemId: string;
  transferContactId: string;
  sourceTypeId: string;
  livestockTypeId: string;
  startDate: Date;
  endDate: Date;
  overrideStartDate: boolean;
  overrideEndDate: boolean;
  isNotStored = false;
  isNotStoredComment: string;
  amountUnitType: string;
  amountType = AmountType.doesNotChangeThroughoutYear;
  materialForm: MaterialForm;
  dryMatter: number;
  amountVolumeInMetric: number;
  amountMassInMetric: number;
  areaInMetric: number;
  milkingDairyWash1 = -1; // had to set these -1s because this maps directly to 'Not Specified'
  milkingDairyWash2 = -1;
  milkingDairyWash3 = -1;
  milkingDairyWash4 = -1;
  milkingGoatWash1 = -1;
  milkingGoatWash2 = -1;
  milkingGoatWash3 = -1;
  milkingLivestockComment: string;
  milkingLivestockType = MilkingLivestockType.DairyCows;
  milkingNotManaged = false;
  milkingNumberOfLivestock: number;
  milkingSystemType: number;
  siloAreaApronInMetric: number;
  siloCapturePeriod = Constants.DEFAULT_SILO_PERIOD;
  siloCaptureStartMonth = SiloFillDate.September;
  siloCovered = SiloCovered.NotCovered;
  siloDensityInMetric = Constants.DEFAULT_SILO_AVG_ENSILAGE_DENSITY;
  siloDimensionDiameterInMetric: number;
  siloDimensionHeightInMetric: number;
  siloDimensionLengthInMetric: number;
  siloDimensionWidthInMetric: number;
  siloMoistureLevel = MoistureLevel.LessThan70;
  siloNotManaged = false;
  siloType = SiloType.Horizontal;
  otherSourceProductionPeriods = [];
  horticultureTypeId = Constants.DEFAULT_HORTICULTURAL_TYPE_ID;
  horticultureAmountIrrigationInMetric: number;
  horticultureGreenhouseAreaInMetric: number;
  horticultureStorageCapacityInMetric: number;
  horticultureFeedwaterInPercent: number;
  horticultureFeedwaterNotRecycledInPercent: number;
  horticultureStartMonth = 2;
  horticultureEndMonth = 11;
  horticultureMaterialProducedAllYear = true;
  horticultureOverrideMonthlyAmount = false;
  horticultureTwentyFourMonthGenerator = false;

  constructor(init?: Partial<MstorOtherSource>) {
    super();
    this.name = this.isEn ? 'Material 1' : 'Matière 1';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      transferContactId: new UntypedFormControl(this.transferContactId),
      sourceTypeId: new UntypedFormControl(this.sourceTypeId),
      livestockTypeId: new UntypedFormControl(this.livestockTypeId),
      startDate: new UntypedFormControl({ value: this.startDate, disabled: !this.overrideStartDate }),
      endDate: new UntypedFormControl({ value: this.endDate, disabled: !this.overrideEndDate }),
      overrideStartDate: new UntypedFormControl(this.overrideStartDate),
      overrideEndDate: new UntypedFormControl(this.overrideEndDate),
      isNotStored: new UntypedFormControl(this.isNotStored),
      isNotStoredComment: new UntypedFormControl(this.isNotStoredComment),
      amountUnitType: new UntypedFormControl(this.amountUnitType),
      amountType: new UntypedFormControl(this.amountType),
      materialForm: new UntypedFormControl(this.materialForm),
      dryMatter: new UntypedFormControl(this.dryMatter,
        { validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      amountVolumeInMetric: new UntypedFormControl(this.amountVolumeInMetric,
        { validators: [Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      amountMassInMetric: new UntypedFormControl(this.amountMassInMetric,
        { validators: [Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      areaInMetric: new UntypedFormControl(this.areaInMetric,
        { validators: [Validators.min(0), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      milking: new UntypedFormGroup({
        milkingDairyWash1: new UntypedFormControl(this.milkingDairyWash1),
        milkingDairyWash2: new UntypedFormControl(this.milkingDairyWash2),
        milkingDairyWash3: new UntypedFormControl(this.milkingDairyWash3),
        milkingDairyWash4: new UntypedFormControl(this.milkingDairyWash4),
        milkingGoatWash1: new UntypedFormControl(this.milkingGoatWash1),
        milkingGoatWash2: new UntypedFormControl(this.milkingGoatWash2),
        milkingGoatWash3: new UntypedFormControl(this.milkingGoatWash3),
        milkingLivestockComment: new UntypedFormControl(this.milkingLivestockComment),
        milkingLivestockType: new UntypedFormControl(this.milkingLivestockType),
        milkingNotManaged: new UntypedFormControl(this.milkingNotManaged),
        milkingNumberOfLivestock: new UntypedFormControl(this.milkingNumberOfLivestock,
          { validators: [Validators.min(0), Validators.max(1000000)] }),
        milkingSystemType: new UntypedFormControl(this.milkingSystemType)
      }),
      silo: new UntypedFormGroup({
        siloAreaApronInMetric: new UntypedFormControl(this.siloAreaApronInMetric),
        siloCapturePeriod: new UntypedFormControl(this.siloCapturePeriod),
        siloCaptureStartMonth: new UntypedFormControl(this.siloCaptureStartMonth),
        siloCovered: new UntypedFormControl(this.siloCovered),
        siloDensityInMetric: new UntypedFormControl(this.siloDensityInMetric),
        siloDimensionDiameterInMetric: new UntypedFormControl(this.siloDimensionDiameterInMetric),
        siloDimensionHeightInMetric: new UntypedFormControl(this.siloDimensionHeightInMetric),
        siloDimensionLengthInMetric: new UntypedFormControl(this.siloDimensionLengthInMetric),
        siloDimensionWidthInMetric: new UntypedFormControl(this.siloDimensionWidthInMetric),
        siloMoistureLevel: new UntypedFormControl(this.siloMoistureLevel),
        siloNotManaged: new UntypedFormControl(this.siloNotManaged),
        siloType: new UntypedFormControl(this.siloType)
      }),
      otherSourceProductionPeriods: new UntypedFormArray(this.otherSourceProductionPeriods && this.otherSourceProductionPeriods.length ?
        this.otherSourceProductionPeriods.map(v => new OtherSourceProductionPeriod(v).toForm()) : []),
      horticulture: new UntypedFormGroup({
        horticultureTwentyFourMonthGenerator: new UntypedFormControl(this.horticultureTwentyFourMonthGenerator),
        horticultureTypeId: new UntypedFormControl(this.horticultureTypeId),
        horticultureAmountIrrigationInMetric: new UntypedFormControl(this.horticultureAmountIrrigationInMetric, { validators: [Validators.min(0)] }),
        horticultureGreenhouseAreaInMetric: new UntypedFormControl(this.horticultureGreenhouseAreaInMetric, { validators: [Validators.min(0)] }),
        horticultureStorageCapacityInMetric: new UntypedFormControl(this.horticultureStorageCapacityInMetric, { validators: [Validators.min(0)] }),
        horticultureFeedwaterInPercent: new UntypedFormControl(this.horticultureFeedwaterInPercent, { validators: [Validators.min(0), Validators.max(100)] }),
        horticultureFeedwaterNotRecycledInPercent: new UntypedFormControl(this.horticultureFeedwaterNotRecycledInPercent, { validators: [Validators.min(0), Validators.max(100)] }),
        horticultureStartMonth: new UntypedFormControl(this.horticultureStartMonth),
        horticultureEndMonth: new UntypedFormControl(this.horticultureEndMonth),
        horticultureMaterialProducedAllYear: new UntypedFormControl(this.horticultureMaterialProducedAllYear),
        horticultureOverrideMonthlyAmount: new UntypedFormControl(this.horticultureOverrideMonthlyAmount)
      }),
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const silo = v.silo;
    const milking = v.milking;
    const horticulture = v.horticulture;
    const periods = form.get('otherSourceProductionPeriods') as UntypedFormArray;
    return new MstorOtherSource({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      storageSystemId: v.storageSystemId,
      transferContactId: v.transferContactId,
      sourceTypeId: v.sourceTypeId,
      livestockTypeId: v.livestockTypeId,
      startDate: v.startDate,
      endDate: v.endDate,
      overrideStartDate: v.overrideStartDate,
      overrideEndDate: v.overrideEndDate,
      isNotStored: v.isNotStored,
      isNotStoredComment: v.isNotStoredComment,
      amountUnitType: v.amountUnitType,
      amountType: v.amountType,
      materialForm: v.materialForm,
      dryMatter: v.dryMatter,
      amountVolumeInMetric: v.amountVolumeInMetric,
      amountMassInMetric: v.amountMassInMetric,
      areaInMetric: v.areaInMetric,
      milkingDairyWash1: milking.milkingDairyWash1,
      milkingDairyWash2: milking.milkingDairyWash2,
      milkingDairyWash3: milking.milkingDairyWash3,
      milkingDairyWash4: milking.milkingDairyWash4,
      milkingGoatWash1: milking.milkingGoatWash1,
      milkingGoatWash2: milking.milkingGoatWash2,
      milkingGoatWash3: milking.milkingGoatWash3,
      milkingLivestockComment: milking.milkingLivestockComment,
      milkingLivestockType: milking.milkingLivestockType,
      milkingNotManaged: milking.milkingNotManaged,
      milkingNumberOfLivestock: milking.milkingNumberOfLivestock,
      milkingSystemType: milking.milkingSystemType,
      siloAreaApronInMetric: silo.siloAreaApronInMetric,
      siloCapturePeriod: silo.siloCapturePeriod,
      siloCaptureStartMonth: silo.siloCaptureStartMonth,
      siloCovered: silo.siloCovered,
      siloDensityInMetric: silo.siloDensityInMetric,
      siloDimensionDiameterInMetric: silo.siloDimensionDiameterInMetric,
      siloDimensionHeightInMetric: silo.siloDimensionHeightInMetric,
      siloDimensionLengthInMetric: silo.siloDimensionLengthInMetric,
      siloDimensionWidthInMetric: silo.siloDimensionWidthInMetric,
      siloMoistureLevel: silo.siloMoistureLevel,
      siloNotManaged: silo.siloNotManaged,
      siloType: silo.siloType,
      otherSourceProductionPeriods: periods?.controls.map((w: UntypedFormGroup) => new OtherSourceProductionPeriod().toModel(w)),
      horticultureTwentyFourMonthGenerator: horticulture.horticultureTwentyFourMonthGenerator,
      horticultureTypeId: horticulture.horticultureTypeId,
      horticultureAmountIrrigationInMetric: horticulture.horticultureAmountIrrigationInMetric,
      horticultureGreenhouseAreaInMetric: horticulture.horticultureGreenhouseAreaInMetric,
      horticultureStorageCapacityInMetric: horticulture.horticultureStorageCapacityInMetric,
      horticultureFeedwaterInPercent: horticulture.horticultureFeedwaterInPercent,
      horticultureFeedwaterNotRecycledInPercent: horticulture.horticultureFeedwaterNotRecycledInPercent,
      horticultureStartMonth: horticulture.horticultureStartMonth,
      horticultureEndMonth: horticulture.horticultureEndMonth,
      horticultureMaterialProducedAllYear: horticulture.horticultureMaterialProducedAllYear,
      horticultureOverrideMonthlyAmount: horticulture.horticultureOverrideMonthlyAmount
    });
  }
}
