import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class OtherSourceProductionPeriod extends BaseModel {
    amountMassInMetric: number = null;
    amountVolumeInMetric: number = null;
    areaInMetric: number = null;
    monthId: number;
    otherSourceId: string;

    constructor(init?: Partial<OtherSourceProductionPeriod>) {
        super();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            amountMassInMetric: new UntypedFormControl(this.amountMassInMetric),
            amountVolumeInMetric: new UntypedFormControl(this.amountVolumeInMetric),
            areaInMetric: new UntypedFormControl(this.areaInMetric),
            monthId: new UntypedFormControl(this.monthId),
            otherSourceId: new UntypedFormControl(this.otherSourceId)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new OtherSourceProductionPeriod({
            amountMassInMetric: v.amountMassInMetric,
            amountVolumeInMetric: v.amountVolumeInMetric,
            areaInMetric: v.areaInMetric,
            monthId: v.monthId,
            otherSourceId: v.otherSourceId
        });
    }
}
