
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { LocationLot } from '@mstor/model/location-lot.model';
import { LocationRollNumber } from '@mstor/model/location-roll-number.model';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { DataType } from '@shared/models/common/data-type.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { FarmStatus } from './fmp-farm-status.enum';
import { FmpField } from './fmp-field.model';

export class FmpFarm extends BaseModel {
  worksheetId: string;
  name: string;
  upperTierName: string;
  upperTierCode: string;
  lowerTierCode: string;
  geotownshipName: string;
  geotownshipId: string;
  status = FarmStatus.Owned;
  address: string;
  fields: FmpField[] = [];
  isAsmGenerated = true;
  isAsmReceived = true;
  isFertilizerReceived = true;
  isGnfGenerated: boolean;
  isGnfReceived: boolean;
  isGnfStored: boolean;
  isNasmReceived: boolean;
  isTillableAreaCalculated = true;
  statusComment: string;
  tillableAreaComment: string;
  tillableAreaInMetric: number;
  contacts: ContactInformation[] = [];
  locationRollNumbers = [];
  locationLots = [];

  constructor(init?: Partial<FmpFarm>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'New farm' : 'Nouvelle ferme';
    this.fields[0] = init ? new FmpField(init.fields[0]) : new FmpField();
    this.fields[0].farmId = this.id;
    Object.assign(this, init);

    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(e => {
        e.parentType = DataType.FARM;
        e.parentId = this.id;
      });
    }

    if (this.locationLots && this.locationLots.length > 0) {
      this.locationLots.forEach(e => {
        e.parentType = DataType.FARM;
        e.parentId = this.id;
      });
    }

    if (this.locationRollNumbers && this.locationRollNumbers.length > 0) {
      this.locationRollNumbers.forEach(e => {
        e.parentType = DataType.FARM;
        e.parentId = this.id;
      });
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      upperTierName: new UntypedFormControl(this.upperTierName),
      upperTierCode: new UntypedFormControl(this.upperTierCode),
      lowerTierCode: new UntypedFormControl(this.lowerTierCode),
      geotownshipName: new UntypedFormControl(this.geotownshipName),
      geotownshipId: new UntypedFormControl(this.geotownshipId),
      status: new UntypedFormControl(this.status),
      address: new UntypedFormControl(this.address, { validators: [Validators.maxLength(100)] }),
      fields: new UntypedFormArray(this.fields.map(v => new FmpField(v).toForm())),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      isAsmGenerated: new UntypedFormControl(this.isAsmGenerated),
      isAsmReceived: new UntypedFormControl(this.isAsmReceived),
      isFertilizerReceived: new UntypedFormControl(this.isFertilizerReceived),
      isGnfGenerated: new UntypedFormControl(this.isGnfGenerated),
      isGnfReceived: new UntypedFormControl(this.isGnfReceived),
      isGnfStored: new UntypedFormControl(this.isGnfStored),
      isNasmReceived: new UntypedFormControl(this.isNasmReceived),
      isTillableAreaCalculated: new UntypedFormControl(this.isTillableAreaCalculated),
      statusComment: new UntypedFormControl(this.statusComment),
      tillableAreaComment: new UntypedFormControl(this.tillableAreaComment),
      tillableAreaInMetric: new UntypedFormControl(this.tillableAreaInMetric, { validators: [Validators.max(1000000)] }),
      contacts: new UntypedFormArray(this.contacts.map(w => new ContactInformation(w).toForm())),
      locationRollNumbers: new UntypedFormArray(this.locationRollNumbers.map(v => new LocationRollNumber(v).toForm())),
      locationLots: new UntypedFormArray(this.locationLots.map(v => new LocationLot(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const fields = form.get('fields') as UntypedFormArray;
    const contacts = form.get('contacts') as UntypedFormArray;
    const locationRollNumbers = form.controls.locationRollNumbers as UntypedFormArray;
    const locationLots = form.controls.locationLots as UntypedFormArray;
    return new FmpFarm({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      name: v.name,
      upperTierName: v.upperTierName,
      upperTierCode: v.upperTierCode,
      lowerTierCode: v.lowerTierCode,
      geotownshipName: v.geotownshipName,
      geotownshipId: v.geotownshipId,
      status: v.status,
      address: v.address,
      fields: fields.controls.map((w: UntypedFormGroup) => new FmpField().toModel(w)),
      notes: v.notes,
      isAsmGenerated: v.isAsmGenerated,
      isAsmReceived: v.isAsmReceived,
      isFertilizerReceived: v.isFertilizerReceived,
      isGnfGenerated: v.isGnfGenerated,
      isGnfReceived: v.isGnfReceived,
      isGnfStored: v.isGnfStored,
      isNasmReceived: v.isNasmReceived,
      isTillableAreaCalculated: v.isTillableAreaCalculated,
      statusComment: v.statusComment,
      tillableAreaComment: v.tillableAreaComment,
      tillableAreaInMetric: v.tillableAreaInMetric,
      contacts: contacts?.controls.map((w: UntypedFormGroup) => new ContactInformation().toModel(w)),
      locationRollNumbers: locationRollNumbers?.controls.map((w: UntypedFormGroup) => new LocationRollNumber().toModel(w)),
      locationLots: locationLots?.controls.map((w: UntypedFormGroup) => new LocationLot().toModel(w))
    });
  }
}
