export enum DataType {
  CONTACT = 'CONTACT',
  CROP = 'CROP',
  FARM = 'FARM',
  FERTILIZER_APPLICATION = 'FERTILIZER_APPLICATION',
  FERTILIZER_PRODUCT = 'FERTILIZER_PRODUCT',
  FIELD = 'FIELD',
  FIELD_SECTION = 'FIELD_SECTION',
  FLAG_COMMENT = 'FLAG_COMMENT',
  LIVESTOCK = 'LIVESTOCK',
  LIVESTOCK_PRODUCTION_PERIOD = 'LIVESTOCK_PRODUCTION_PERIOD',
  BUILDING = 'BUILDING',
  LOCATION_LOT = 'LOCATION_LOT',
  LOCATION_ROLL_NUMBER = 'LOCATION_ROLL_NUMBER',
  MATERIAL_APPLICATION = 'MATERIAL_APPLICATION',
  MATERIAL_PRODUCED = 'MATERIAL_PRODUCED',
  MATERIAL_SAMPLE = 'MATERIAL_SAMPLE',
  MATERIAL_TEST_VALUE = 'MATERIAL_TEST_VALUE',
  MATERIAL_TYPE = 'MATERIAL_TYPE',
  MDS_ACTUAL_SETBACK = 'MDS_ACTUAL_SETBACK',
  MDS_BUILDING_PERMIT = 'MDS_BUILDING_PERMIT',
  MDS_CALCULATION = 'MDS_CALCULATION',
  MDS_MATERIAL = 'MDS_MATERIAL',
  MDS_MATERIAL_CAPACITY = 'MDS_MATERIAL_CAPACITY',
  MEASUREMENT_SYSTEM = 'MEASUREMENT_SYSTEM',
  OTHER_SOURCE = 'OTHER_SOURCE',
  OTHER_SOURCE_PRODUCTION_PERIOD = 'OTHER_SOURCE_PRODUCTION_PERIOD',
  OUTGOING_TRANSFER = 'OUTGOING_TRANSFER',
  SOIL_SAMPLE = 'SOIL_SAMPLE',
  SOIL_TEST_VALUE = 'SOIL_TEST_VALUE',
  STORAGE = 'STORAGE',
  STORAGE_GROUP = 'STORAGE_GROUP',
  STORAGE_SYSTEM = 'STORAGE_SYSTEM',
  TRANSFER_CONTACT = 'TRANSFER_CONTACT',
  TREATMENT = 'TREATMENT',
  USER_ACCOUNT = 'USER_ACCOUNT',
  WORKSHEET = 'WORKSHEET',
  WORKSHEET_ECONOMIC = 'WORKSHEET_ECONOMIC',
  WORKSHEET_LABEL = 'WORKSHEET_LABEL',
  WORKSHEET_OVERVIEW_OPERATION = 'WORKSHEET_OVERVIEW_OPERATION',
  WORKSHEET_PERMISSION_TYPE = 'WORKSHEET_PERMISSION_TYPE',
  WORKSHEET_PREVIOUS_SUBMISSION = 'WORKSHEET_PREVIOUS_SUBMISSION',
  WORKSHEET_SHARED_ASSOCIATION = 'WORKSHEET_SHARED_ASSOCIATION',
  WORKSHEET_TYPE = 'WORKSHEET_TYPE'
}
