<article id="applicationPhosphorusIndexDialogComponent">
	<h1 mat-dialog-title class="h1 h1--component-title">
		{{ 'dialog.title.application.ploss.recommendations' | translate | async }}
	</h1>

	<div mat-dialog-content>
		<ul>
			<li *ngIf="data?.applicationIncorporationFlag">
				<p>
					{{ 'calculator.plato.recommendations.banding' | translate | async }}
				</p>
			</li>

			<li *ngIf="data?.applicationDateFlag">
				<p>
					{{ 'calculator.plato.recommendations.only.apply.p' | translate | async }}
				</p>
			</li>

			<li>
				<p>
					{{ 'calculator.plato.recommendations.low.p.rate' | translate | async }}
				</p>
			</li>

			<li>
				<p>
					{{ 'calculator.plato.recommendations.less.frequent.p' | translate | async }}
				</p>
			</li>

			<li *ngIf="data?.applicationDateFlag">
				<p>
					{{ 'calculator.plato.recommendations.using.over-wintering.cover.crops' | translate | async }}
				</p>
			</li>
		</ul>
	</div>

	<div mat-dialog-actions class="flex flex--end-vertical">
		<div class="flex-child flex-child--flex-shrink">
			<button type="button" mat-stroked-button class="button button--black-primary" (click)="cancel()">
				{{ 'dialog.action.close' | translate | async }}
			</button>
		</div>
	</div>
</article>