import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { NutrientValueBasis } from '@shared/models/common/nutrient-value-basis.enum';
import { MaterialNutrientCode } from '@shared/models/material/material-nutrient-code';
import { MaterialTestValue } from '@shared/models/worksheet/material-test-value.model';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';

export class FmpMaterialSample extends BaseModel {
  dryMatterRange = 0;
  dryMatterLow = 0;
  dryMatterHigh = 18;
  nutrientValueSource = 1;
  nutrientValueBasis = NutrientValueBasis.Wet;
  materialTypeId: string;
  materialTestValues: MaterialTestValue[] = [];
  selectedNutrients = [];
  focusedNutrient = undefined;

  constructor(init?: Partial<any>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    const f = new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      dryMatterRange: new UntypedFormControl(this.dryMatterRange),
      dryMatterLow: new UntypedFormControl(this.dryMatterLow),
      dryMatterHigh: new UntypedFormControl(this.dryMatterHigh),
      nutrientValueSource: new UntypedFormControl(this.nutrientValueSource),
      nutrientValueBasis: new UntypedFormControl(this.nutrientValueBasis),
      selectedNutrients: new UntypedFormControl(this.selectedNutrients),
      focusedNutrient: new UntypedFormControl(this.focusedNutrient)
    });
    if (this.materialTestValues && this.materialTestValues.length) {
      this.materialTestValues.forEach(v => f.addControl(v.testCode,
        new UntypedFormControl(v.testValue)
      ));
    } else {
      this.defaultNutrientCodes.forEach(v => f.addControl(v.nutrientCode,
        new UntypedFormControl(0)
      ));
    }
    return f;
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    // tslint:disable-next-line: no-string-literal
    const nutrientCodes = Constants['nutrientCodes'] as MaterialNutrientCode[];
    const codes = nutrientCodes.filter(c => v[c.nutrientCode] !== undefined).map(c => c.nutrientCode);
    return new FmpMaterialSample({
      id: v.id,
      createdBy: v.createdBy,
      materialTypeId: v.materialTypeId,
      dryMatterRange: v.dryMatterRange,
      dryMatterLow: v.dryMatterLow,
      dryMatterHigh: v.dryMatterHigh,
      nutrientValueSource: v.nutrientValueSource,
      nutrientValueBasis: v.nutrientValueBasis,
      selectedNutrients: v.selectedNutrients,
      focusedNutrient: v.focusedNutrient,
      materialTestValues: codes ? codes.
        map(key => ({
          testCode: key,
          testValue: form.get(key).value
        })) as MaterialTestValue[] : undefined
    });
  }

  get defaultNutrientCodes(): MaterialNutrientCode[] {
    return Constants['nutrientCodes']
      .filter(v => v.defaultAsmFlag === true)
      .sort((a, b) => a.sequenceId - b.sequenceId);
  }
}
