import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Livestock } from '@mstor/model/livestock.model';
import { AlertInputName, DEFAULT_ALERT_INPUTS } from '../common/alert-input-name.enum';
import { LivestockFormGroup, LivestocksFormGroup } from './livestocks-form-group.model';

export class StorageSystemsFormGroup {
  private _systems: UntypedFormArray;

  constructor(systems: UntypedFormArray) {
    this._systems = systems;
  }

  getFormCtrlById(systemId: string): UntypedFormGroup {
    return this._systems?.controls?.find(v => Utils.matchStr(v?.get('id')?.value, systemId)) as UntypedFormGroup;
  }

  getById(systemId: string): StorageSystemFormGroup {
    return new StorageSystemFormGroup(this.getFormCtrlById(systemId));
  }

  getLivestockById(livestockId: string): LivestockFormGroup {
    const livestocks = this._systems?.controls?.reduce((allLivestocks, system) => {
      allLivestocks = allLivestocks.concat((system?.get('livestock') as UntypedFormArray)?.controls);
      return allLivestocks;
    }, []);
    const livestock = livestocks?.find(v => Utils.matchStr(v?.get('id')?.value, livestockId));
    const system = this.getFormCtrlById(livestock?.get('storageSystemId')?.value);
    return new LivestockFormGroup(system, livestock);
  }
}

export class StorageSystemFormGroup {
  private _system: UntypedFormGroup;

  constructor(system: UntypedFormGroup) {
    this._system = system;
  }

  isMissing(input: AlertInputName): boolean {
    switch (input) {
      case AlertInputName.UpperTierCode:
        return !this.upperTierCode?.value;
      case AlertInputName.Livestocks:
        return !this.livestocks?.length;
      default:
        return false;
    }
  }

  hasMissingWarning(inputs?: AlertInputName[]): boolean {
    const alertInputs = inputs ? inputs : DEFAULT_ALERT_INPUTS;
    return alertInputs.filter(input => this.isMissing(input))?.length > 0;
  }

  toForm(): UntypedFormGroup {
    return this._system;
  }

  toModel(): Livestock {
    return new Livestock().toModel(this._system);
  }

  get livestocks(): LivestocksFormGroup {
    return new LivestocksFormGroup(this._system, this._system?.get('livestock') as UntypedFormArray);
  }

  get nameCtrl(): UntypedFormControl {
    return this._system?.get('name') as UntypedFormControl;
  }

  get name(): string {
    return this.nameCtrl?.value;
  }

  get upperTierCode(): UntypedFormGroup {
    return this._system?.get('upperTierCode') as UntypedFormGroup;
  }
}
