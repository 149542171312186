export enum ReportType {
  Registration = 0,
  Document = 1,
  Record = 2,
  Strategy = 3,
  PlanSubmission = 4,
  LandApplication = 5,
  LandApplicationBMP = 6,
  LandApplicationRecord = 7,
  TransferDocument = 8
}

export const SubmissionReportTypes = [ReportType.PlanSubmission];

export const SubmissionReasonReportTypes = [
  ReportType.Registration, ReportType.Strategy
];

export const GnfApplicationReportTypes = [
  ReportType.LandApplication,
  ReportType.LandApplicationBMP,
  ReportType.LandApplicationRecord
];

export const RegistrationIdReportTypes = [
  ReportType.Registration,
  ReportType.Document,
  ReportType.Record,
  ReportType.Strategy,
  ReportType.TransferDocument
];