import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '@shared/constants/array-constants';

@Pipe({
    name: 'nutrientValuePipe'
})
export class NutrientValuePipe implements PipeTransform {

    transform(val: any, nutrientValueBasis: number, nutrientCode: string, dryMatterValue: number): any {
        if (nutrientValueBasis === 1) {
            return this.toDryBasis(nutrientCode, val, dryMatterValue);
        }
        return val;
    }

    // Convert Wet to Dry Basis
    private toDryBasis(nutrientCode: string, wetValue: number, dryMatterValue: number) {
        // Do not convert some test codes
        if (ArrayConstants.NUTRIENTS_NO_CONVERSION_REQUIRED.indexOf(nutrientCode) > -1) {
            return wetValue;
        }
        // If dry matter is not available, assume 100% (no conversion occurs)
        if (dryMatterValue <= 0 || dryMatterValue > 100) {
            dryMatterValue = 100;
        }
        // Convert
        return wetValue * 100 / dryMatterValue;
    }
}