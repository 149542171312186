export enum ReportType {
  GhgSummary = 0,
  EntireFarm = 1,
  BuildingEnergy = 2
}

export enum StorageSystemReportType {
  currentStorageSystem = 0,
  allStorageSystems = 1
}

export enum FieldReportType {
  currentFieldOnly = 0,
  allFarmsAndFields = 1
}
