import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'zeroToText' })
export class ZeroToTextPipe implements PipeTransform {
  transform(value: any, text: any): any {
    if (value === 0) {
      return text;
    }
    return value;
  }
}
