import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { GnfReport } from '@gnf/model/gnf-report.model';
import { NmspCalculation } from '@nmsp/model/nmsp-calculation.model';
import { FormService } from '@shared/services/form.service';
import { SharedFlagCommentService } from '@shared/services/shared-flag-comment.service';
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { GhgWorksheet } from '../model/ghg-worksheet.model';
import { GhgService } from './ghg.service';

@Injectable({ providedIn: 'root' })
export class GhgCalculationService extends CalculationService {
  flagComments$ = new BehaviorSubject<any>(undefined);

  constructor(
    protected formService: FormService,
    private msalService: MsalService,
    protected httpClient: HttpClient,
    private calculationService: CalculationService,
    private sharedFlagCommentService: SharedFlagCommentService,
    private ghgService: GhgService
  ) {
    super(httpClient, formService);
  }

  publish(v: NmspCalculation) {
    // for GHG, this is questionable
    this.calculation$.next(v);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  calculate(report?: GnfReport) {
    const model = new GhgWorksheet().toModel(this.formService.f);
    this.input$.next(model);
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data => {
        if (this.isAuthenticated) {
          if (data) {
            return Utils.errorsomeStream(
              combineLatest([
                this.httpClient.post(URLs.GHG_CALCULATION_ENDPOINT, data),
                this.sharedFlagCommentService.getByWorksheetId(data.id)
              ])
            );
          }
          return EMPTY;
        }
        return Utils.errorsomeStream(this.httpClient.post(URLs.GHG_CALCULATION_ENDPOINT, data));
      }),
      tap(res => {
        if (this.isAuthenticated) {
          const [calculation, flagComments] = res;
          this.setDefaultMaterialForm(calculation);
          this.publish(calculation as any);
          if (flagComments) {
            this.flagComments$.next(flagComments);
          }
          this.ghgService.displayReport$ = of(true);
        } else {
          const calculation = res;
          this.setDefaultMaterialForm(calculation);
          this.publish(calculation as any);
          this.ghgService.displayReport$ = of(true);
        }
      })
    );
  }

  bindFlagCommentData() {
    return this.sharedFlagCommentService.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data =>
        data ? Utils.errorsomeStream(this.sharedFlagCommentService.getByWorksheetId(this.formService.f.get('id').value)) : EMPTY
      ),
      tap(res => {
        this.flagComments$.next(res);
      })
    );
  }

  private setDefaultMaterialForm(res: any) {
    const storageSystemResponse = res?.storageSizingCalculations?.find(s =>
      Utils.matchStr(s.storageSystemID, this.currentStorageSystem.get('id').value)
    );
    this.calculationService.defaultMaterialForm = storageSystemResponse?.defaultMaterialForm;
  }

  get f() {
    return this.formService.f || undefined;
  }

  get farms() {
    return this.formService.f?.get('farms') as UntypedFormArray;
  }

  get currentFarm() {
    return this.farms?.controls.find(v => Utils.matchStr(v.get('id').value, this.formService.f?.get('currentFarmId')?.value));
  }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem() {
    return this.storageSystems?.controls.find(v =>
      Utils.matchStr(v.get('id').value, this.formService.f?.get('currentStorageSystemId')?.value)
    );
  }

  get isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }
}
