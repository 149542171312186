import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';


export class LocationRollNumber extends BaseModel {
  parentId: string;
  parentType: string;
  rollNumber: string;
  numOfDigits: number;
  isFocused = false;

  constructor(init?: Partial<LocationRollNumber>) {
    super();
    Object.assign(this, init);
    this.id = this.id || Utils.uuid();
    this.numOfDigits = this.rollNumber ? String(this.rollNumber).length : 0;
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      parentId: new UntypedFormControl(this.parentId),
      parentType: new UntypedFormControl(this.parentType),
      rollNumber: new UntypedFormControl(this.rollNumber, {
        validators: [Validators.maxLength(19), Validators.pattern(/[0-9]/)]
      }),
      numOfDigits: new UntypedFormControl(this.numOfDigits),
      isFocused: new UntypedFormControl(this.isFocused)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new LocationRollNumber({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      parentId: v.parentId,
      parentType: v.parentType,
      rollNumber: v.rollNumber,
      numOfDigits: v.numOfDigits,
      isFocused: v.isFocused
    });
  }
}
