import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { Month } from '@shared/models/common/month.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { LivestockProductionPeriod } from './livestock-production-period.model';

export class Livestock extends BaseModel {
  name: string;
  notes: string;
  numberOfLivestock = Constants.DEFAULT_NUM_OF_LIVESTOCK;
  storageSystemId: string;
  materialTypeId = Constants.DEFAULT_MATERIAL_TYPE_ID;
  livestockTypeId = Constants.DEFAULT_LIVESTOCK_SUBTYPE_ID;
  isSandBedding = false;
  isAllInOut: boolean;
  startDate: Date;
  endDate: Date;
  overrideStartDate = false;
  overrideEndDate = false;
  useAverageWeight: boolean;
  weightComment: string;
  weightAverageInMetric = Constants.DEFAULT_LIVESTOCK_WEIGHT_AVERAGE;
  weightInInMetric: number;
  weightOutInMetric: number;
  hasMultipleNumberPeriods: boolean;
  hasMultipleProductionPeriods: boolean;
  produceLiquid: boolean;
  produceLiquidPercent = Constants.DEFAULT_LIVESTOCK_PRODUCE_LIQUID_PERCENT;
  produceSolid: boolean;
  produceSolidPercent = Constants.DEFAULT_LIVESTOCK_PRODUCE_SOLID_PERCENT;
  producePasture: boolean;
  producePasturePercent = Constants.DEFAULT_LIVESTOCK_PRODUCE_PASTURE_PERCENT;
  overrideDryMatterLiquid: boolean;
  overrideDryMatterSolid: boolean;
  dryMatterLiquidPercent: number;
  dryMatterSolidPercent: number;
  overrideAmountLiquid: boolean;
  overrideAmountSolid: boolean;
  amountLiquidPercent = Constants.DEFAULT_LIVESTOCK_AMOUNT_LIQUID_PERCENT;
  amountSolidPercent = Constants.DEFAULT_LIVESTOCK_AMOUNT_SOLID_PERCENT;
  overrideBeddingLiquid: boolean;
  overrideBeddingSolid: boolean;
  beddingLiquidPercent = Constants.DEFAULT_LIVESTOCK_BEDDING_LIQUID_PERCENT;
  beddingSolidPercent = Constants.DEFAULT_LIVESTOCK_BEDDING_SOLID_PERCENT;
  livestockProductionPeriods: LivestockProductionPeriod[];
  month = Month.January;
  milkProduction: number;
  livestockRatio = Constants.DEFAULT_GHG_LIVESTOCK_RATIO;
  feedLength: number;
  feedDiet: number;
  feedAdditive = Constants.DEFAULT_GHG_FEED_ADDICTIVE;

  constructor(init?: Partial<Livestock>) {
    super();
    this.name = this.isEn ? 'Livestock 1' : 'Bétail 1';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      numberOfLivestock: new UntypedFormControl(this.numberOfLivestock, { validators: [Validators.min(0), Validators.max(1000000)] }),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      livestockTypeId: new UntypedFormControl(this.livestockTypeId),
      isSandBedding: new UntypedFormControl(this.isSandBedding),
      isAllInOut: new UntypedFormControl(this.isAllInOut),
      startDate: new UntypedFormControl({ value: this.startDate, disabled: !this.overrideStartDate }),
      endDate: new UntypedFormControl({ value: this.endDate, disabled: !this.overrideEndDate }),
      overrideStartDate: new UntypedFormControl(this.overrideStartDate),
      overrideEndDate: new UntypedFormControl(this.overrideEndDate),
      useAverageWeight: new UntypedFormControl(this.useAverageWeight),
      weightComment: new UntypedFormControl(this.weightComment),
      weightAverageInMetric: new UntypedFormControl(this.weightAverageInMetric),
      weightInOut: new UntypedFormGroup(
        {
          weightInInMetric: new UntypedFormControl(this.weightInInMetric),
          weightOutInMetric: new UntypedFormControl(this.weightOutInMetric)
        },
        [ValidationFunctions.livestockWeightInLessThanWeightOut()],
        []
      ),
      hasMultipleNumberPeriods: new UntypedFormControl(this.hasMultipleNumberPeriods),
      hasMultipleProductionPeriods: new UntypedFormControl(this.hasMultipleProductionPeriods),
      produceLiquid: new UntypedFormControl(this.produceLiquid),
      produceLiquidPercent: new UntypedFormControl(this.produceLiquidPercent, {
        validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      produceSolid: new UntypedFormControl(this.produceSolid),
      produceSolidPercent: new UntypedFormControl(this.produceSolidPercent, {
        validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      producePasture: new UntypedFormControl(this.producePasture),
      producePasturePercent: new UntypedFormControl(this.producePasturePercent, {
        validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      overrideDryMatterLiquid: new UntypedFormControl(this.overrideDryMatterLiquid),
      overrideDryMatterSolid: new UntypedFormControl(this.overrideDryMatterSolid),
      dryMatterLiquidPercent: new UntypedFormControl(this.dryMatterLiquidPercent, {
        validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      dryMatterSolidPercent: new UntypedFormControl(this.dryMatterSolidPercent, {
        validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      overrideAmountLiquid: new UntypedFormControl(this.overrideAmountLiquid),
      overrideAmountSolid: new UntypedFormControl(this.overrideAmountSolid),
      amountLiquidPercent: new UntypedFormControl(this.amountLiquidPercent, {
        validators: [Validators.min(-50), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      amountSolidPercent: new UntypedFormControl(this.amountSolidPercent, {
        validators: [Validators.min(-50), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      overrideBeddingLiquid: new UntypedFormControl(this.overrideBeddingLiquid),
      overrideBeddingSolid: new UntypedFormControl(this.overrideBeddingSolid),
      beddingLiquidPercent: new UntypedFormControl(this.beddingLiquidPercent, {
        validators: [Validators.min(-50), Validators.max(1000), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      beddingSolidPercent: new UntypedFormControl(this.beddingSolidPercent, {
        validators: [Validators.min(-50), Validators.max(1000), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      livestockProductionPeriods: new UntypedFormArray(
        this.livestockProductionPeriods && this.livestockProductionPeriods.length
          ? this.livestockProductionPeriods.map(v => new LivestockProductionPeriod(v).toForm())
          : []
      ),
      month: new UntypedFormControl(this.month),
      milkProduction: new UntypedFormControl(this.milkProduction, [Validators.min(0), Validators.max(200)]),
      livestockRatio: new UntypedFormControl(this.livestockRatio, [Validators.min(0), Validators.max(10)]),
      feedLength: new UntypedFormControl(this.feedLength, [Validators.min(0), Validators.max(1000)]),
      feedDiet: new UntypedFormControl(this.feedDiet),
      feedAdditive: new UntypedFormControl(this.feedAdditive)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const periods = form.get('livestockProductionPeriods') as UntypedFormArray;
    return new Livestock({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      numberOfLivestock: v.numberOfLivestock,
      storageSystemId: v.storageSystemId,
      materialTypeId: v.materialTypeId,
      livestockTypeId: v.livestockTypeId,
      isSandBedding: v.isSandBedding,
      isAllInOut: v.isAllInOut,
      startDate: v.startDate,
      endDate: v.endDate,
      overrideStartDate: v.overrideStartDate,
      overrideEndDate: v.overrideEndDate,
      useAverageWeight: v.useAverageWeight,
      weightComment: v.weightComment,
      weightAverageInMetric: v.weightAverageInMetric,
      weightInInMetric: v.weightInOut.weightInInMetric,
      weightOutInMetric: v.weightInOut.weightOutInMetric,
      hasMultipleNumberPeriods: v.hasMultipleNumberPeriods,
      hasMultipleProductionPeriods: v.hasMultipleProductionPeriods,
      produceLiquid: v.produceLiquid,
      produceLiquidPercent: v.produceLiquidPercent,
      produceSolid: v.produceSolid,
      produceSolidPercent: v.produceSolidPercent,
      producePasture: v.producePasture,
      producePasturePercent: v.producePasturePercent,
      overrideDryMatterLiquid: v.overrideDryMatterLiquid,
      overrideDryMatterSolid: v.overrideDryMatterSolid,
      dryMatterLiquidPercent: v.dryMatterLiquidPercent,
      dryMatterSolidPercent: v.dryMatterSolidPercent,
      overrideAmountLiquid: v.overrideAmountLiquid,
      overrideAmountSolid: v.overrideAmountSolid,
      amountLiquidPercent: v.amountLiquidPercent,
      amountSolidPercent: v.amountSolidPercent,
      overrideBeddingLiquid: v.overrideBeddingLiquid,
      overrideBeddingSolid: v.overrideBeddingSolid,
      beddingLiquidPercent: v.beddingLiquidPercent,
      beddingSolidPercent: v.beddingSolidPercent,
      livestockProductionPeriods: periods?.controls.map((w: UntypedFormGroup) => new LivestockProductionPeriod().toModel(w)),
      month: v.month,
      milkProduction: v.milkProduction,
      livestockRatio: v.livestockRatio,
      feedLength: v.feedLength,
      feedDiet: v.feedDiet,
      feedAdditive: v.feedAdditive
    });
  }
}
