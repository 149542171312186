import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'negativeToBlank' })
export class NegativeToBlankPipe implements PipeTransform {
  transform(value: any, formPristine: boolean): any {
    if (value < 0 && formPristine) {
      return '';
    }
    return value;
  }
}
