import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { MaterialType } from './material-type.model';
import { StorageSystem } from './storage-system.model';
import { TransferContact } from './transfer-contact.model';

export class MstorWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.MANURE_STORAGE_SIZING;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  startYear: number;
  endYear: number;
  storageSystems = [];
  transferContacts = [];
  materialTypes = [];
  isLocal = true;
  currentStorageSystemId: string;
  currentTransferContactId: string;
  currentMaterialTypeId: string;
  currentTab = 0;

  constructor(init?: Partial<MstorWorksheet>) {
    super();
    this.name = this.isEn ? 'Storage Sizing 1' : 'Calcule de l’entreposage 1';
    this.storageSystems[0] = init
      ? new StorageSystem(init.storageSystems ? init.storageSystems[0] : undefined)
      : new StorageSystem();
    Object.assign(this, init);

    this.currentStorageSystemId = this.storageSystems && this.storageSystems[0] ? this.storageSystems[0].id : undefined;
    this.currentTransferContactId = this.transferContacts && this.transferContacts[0] ? this.transferContacts[0].id : undefined;
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      startYear: new UntypedFormControl(this.startYear),
      endYear: new UntypedFormControl(this.endYear),
      storageSystems: new UntypedFormArray(this.storageSystems.map(v => new StorageSystem(v).toForm())),
      transferContacts: new UntypedFormArray(this.transferContacts.map(v => new TransferContact(v).toForm())),
      materialTypes: new UntypedFormArray(this.materialTypes.map(v => new MaterialType(v).toForm())),
      currentStorageSystemId: new UntypedFormControl(this.currentStorageSystemId),
      currentTransferContactId: new UntypedFormControl(this.currentTransferContactId),
      currentMaterialTypeId: new UntypedFormControl(this.currentMaterialTypeId),
      currentTab: new UntypedFormControl(this.currentTab)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const storageSystems = form.get('storageSystems') as UntypedFormArray;
    const transferContacts = form.get('transferContacts') as UntypedFormArray;
    const materialTypes = form.get('materialTypes') as UntypedFormArray;
    return new MstorWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      startYear: v.startYear,
      endYear: v.endYear,
      storageSystems: storageSystems?.controls.map((w: UntypedFormGroup) => new StorageSystem().toModel(w)),
      transferContacts: transferContacts?.controls.map((w: UntypedFormGroup) => new TransferContact().toModel(w)),
      materialTypes: materialTypes?.controls.map((w: UntypedFormGroup) => new MaterialType().toModel(w)),
      currentStorageSystemId: v.currentStorageSystemId,
      currentTransferContactId: v.currentTransferContactId,
      currentMaterialTypeId: v.currentMaterialTypeId,
      currentTab: v.currentTab
    });
  }
}
