import { Utils } from '@core/utilities/utils';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { MaterialSample } from './material-sample.model';
import { OrganicAmendmentWorksheet } from './organic-amendment-worksheet.model';
export class MaterialType extends BaseModel {
  name: string;
  materialForm = 0;
  materialType = 0;
  graphAdjustPercent = 0;
  materialSamples: MaterialSample[] = [];

  constructor(init?: Partial<OrganicAmendmentWorksheet>) {
    super();
    this.id = this.id || Utils.uuid();
    this.name = this.isEn ? 'Material A' : 'Matière A';
    this.materialSamples[0] = new MaterialSample();
    Object.assign(this, init);
  }
}
