import { BilingualText } from '../common/bilingual-text';
import { LanguageType } from '../common/language-type.enum';

export class BaseModel {
  id: string;
  description?: BilingualText;
  notes?: string;
  createdBy = 'frontend';
  createdDateTime?: Date;
  updatedBy?: string;
  updatedDateTime?: Date;

  constructor(init?: Partial<BaseModel>) {
    this.id = init?.id;
    this.description = init?.description;
    this.notes = init?.notes;
    this.createdBy = init?.createdBy || 'frontend';
    this.createdDateTime = init?.createdDateTime;
    this.updatedBy = init?.updatedBy;
    this.updatedDateTime = init?.updatedDateTime;
  }

  get lang() {
    // eslint-disable-next-line no-undef
    return JSON.parse(localStorage.getItem('user/preference'))?.languageType;
  }

  get isEn() {
    return !this.lang || this.lang === LanguageType.English;
  }
}
