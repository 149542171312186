import { Utils } from '@core/utilities/utils';
import { LanguageType } from '../common/language-type.enum';
import { MeasurementSystem } from '../common/measurement-system.enum';

export class Preference {
    languageType: LanguageType;
    measurementSystem: MeasurementSystem;
    date: Date;

    constructor(languageType: LanguageType, measurementSystem: MeasurementSystem) {
        this.languageType = languageType;
        this.measurementSystem = measurementSystem;
        this.date = Utils.utcNow();
    }
}

/*
{
    "createdBy": "string",
    "createdDateTime": "2019-05-24T14:51:40.945Z",
    "description": "string",
    "id": "string",
    "langPreference": "string",
    "measureSystemName": "string",
    "notes": "string",
    "updatedBy": "string",
    "updatedDateTime": "2019-05-24T14:51:40.945Z",
    "userAccountName": "string"
  }
*/
