import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WorksheetLabelLink } from '@shared/models/common/worksheet-label-link.model';
import { Observable } from 'rxjs';
import { URLs } from '../../core/conf/urls';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class WorksheetLabelLinkClient {
  constructor(private httpClient: HttpClient, private cache: CacheService) {}

  all(): Observable<WorksheetLabelLink[]> {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}/user-account/${this.cache.user?.id}`;
    return this.httpClient.get(url) as Observable<WorksheetLabelLink[]>;
  }

  get(id: string): Observable<WorksheetLabelLink> {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}/${id}`;
    return this.httpClient.get(url) as Observable<WorksheetLabelLink>;
  }

  byWorksheetId(worksheetId: string): Observable<WorksheetLabelLink[]> {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}/worksheet/${worksheetId}`;
    return this.httpClient.get(url) as Observable<WorksheetLabelLink[]>;
  }

  update(link: WorksheetLabelLink): Observable<WorksheetLabelLink> {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}/${link.id}`;
    return this.httpClient.put(url, link) as Observable<WorksheetLabelLink>;
  }

  save(link: WorksheetLabelLink): Observable<WorksheetLabelLink> {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}`;
    return this.httpClient.post(url, link) as Observable<WorksheetLabelLink>;
  }

  delete(id: string) {
    const url = `${URLs.WORKSHEET_LABEL_LINK_ENDPOINT}/${id}`;
    return this.httpClient.delete(url) as Observable<WorksheetLabelLink>;
  }
}
