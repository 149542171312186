import { Injectable } from '@angular/core';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { CacheService } from '@shared/services/cache.service';
import { FormService } from '@shared/services/form.service';
import { EMPTY, Observable, of } from 'rxjs';
import { FmpWorksheet } from '../../fmp/model/fmp-worksheet.model';

@Injectable({ providedIn: 'root' })
export class SiteFieldService {
  constructor(private cache: CacheService, private formService: FormService) {}

  save(field: any): Observable<any> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey) as FmpWorksheet;
    if (worksheet && worksheet.farms.length) {
      worksheet.farms[0].fields[0] = field;
      this.cache.setWorksheet(worksheet, this.worksheetKey);
      return of(field);
    }
    return EMPTY;
  }

  update(field: any): Observable<any> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey) as FmpWorksheet;
    if (worksheet && worksheet.farms.length) {
      worksheet.farms[0].fields[0] = field;
      this.cache.setWorksheet(worksheet, this.worksheetKey);
      return of(field);
    }
    return EMPTY;
  }

  delete(id: string): Observable<any> {
    const worksheet = this.cache.getWorksheet(this.worksheetKey) as FmpWorksheet;
    if (worksheet && worksheet.farms.length) {
      if (worksheet.farms[0].fields.length) {
        const field = worksheet.farms[0].fields.splice(0, 1);
        this.cache.setWorksheet(worksheet, this.worksheetKey);
        return of(field);
      }
    }
    return EMPTY;
  }

  get isGhg() {
    return this.formService.isGhgWorksheet;
  }

  get worksheetKey() {
    return this.isGhg ? WorksheetKeys.GHG : WorksheetKeys.FMP;
  }
}
