import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Constants } from '@shared/constants/constants';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { DataType } from '@shared/models/common/data-type.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { LocationLot } from '../../mstor/model/location-lot.model';
import { LocationRollNumber } from '../../mstor/model/location-roll-number.model';
import { MDSActualSetback } from './mds-actual-setback.model';
import { MDSBuildingPermit } from './mds-building-permit.model';
import { MDSMaterial } from './mds-material.model';

export class MdsCalculation extends BaseModel {
  name: string;
  calculationType: number;
  isStorageOnly = Constants.DEFAULT_LIVESTOCK_MANURE_STORAGE_ANSWER_VALUE;
  isAnaerobicDigestor = Constants.DEFAULT_LIVESTOCK_MANURE_INFO_ANSWER_VALUE;
  isLivestockConfirmed = Constants.DEFAULT_LIVESTOCK_MANURE_INFO_ANSWER_VALUE;
  isBuildingPermitIssued = Constants.DEFAULT_BUILDING_PERMIT_ISSUED_ANSWER_VALUE;
  totalLotSizeInMetric: number;
  worksheetId: string;
  worksheetTypeId: WorksheetTypeIds;
  buildingPermits: MDSBuildingPermit[] = [];
  actualSetbacks: MDSActualSetback[] = [];
  materials: MDSMaterial[] = [];
  contact: ContactInformation;
  locationLot: LocationLot;
  locationRollNumber: LocationRollNumber;

  constructor(init?: Partial<MdsCalculation>) {
    super();
    this.id = this.id || Utils.uuid();
    this.name = this.isEn ? 'New farm' : 'Nouvelle ferme';
    this.contact = init ? new ContactInformation(init.contact) : new ContactInformation();
    this.defaultLocationLot(init);
    this.defaultRollNumber(init);
    Object.assign(this, init);
    this.defaultActualSetbacks(init);
    this.locationLot.parentType = DataType.MDS_CALCULATION;
    this.locationLot.parentId = this.id ? this.id : this.locationLot.parentId;
    this.locationRollNumber.parentType = DataType.MDS_CALCULATION;
    this.locationRollNumber.parentId = this.id ? this.id : this.locationLot.parentId;
    this.contact.parentType = DataType.MDS_CALCULATION;
    this.contact.parentId = this.id ? this.id : this.locationLot.parentId;
  }

  private defaultRollNumber(init: Partial<MdsCalculation>): void {
    this.locationRollNumber = init
      ? new LocationRollNumber(init.locationRollNumber ?
        init.locationRollNumber : undefined) : new LocationRollNumber();
  }

  private defaultLocationLot(init: Partial<MdsCalculation>): void {
    this.locationLot = init
      ? new LocationLot(init.locationLot ? init.locationLot : undefined) : new LocationLot();
  }

  private defaultActualSetbacks(init: Partial<MdsCalculation>): void {
    const getInstance = (data?: Partial<MDSActualSetback>): MDSActualSetback => {
      const setback = new MDSActualSetback(data);
      setback.calculationId = this.id;
      return setback;
    };

    this.actualSetbacks = init?.actualSetbacks ?
      init.actualSetbacks.map((s: Partial<MDSActualSetback>) => getInstance(s)) :
      [getInstance()];

    const test = this.actualSetbacks;
  }

  toForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      worksheetId: new UntypedFormControl(this.worksheetId),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      calculationType: new UntypedFormControl(this.calculationType),
      isStorageOnly: new UntypedFormControl(this.isStorageOnly),
      isAnaerobicDigestor: new UntypedFormControl(this.isAnaerobicDigestor),
      isLivestockConfirmed: new UntypedFormControl(this.isLivestockConfirmed),
      isBuildingPermitIssued: new UntypedFormControl(this.isBuildingPermitIssued),
      totalLotSizeInMetric: new UntypedFormControl(this.totalLotSizeInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      buildingPermits: new UntypedFormArray(this.buildingPermits.map(w => new MDSBuildingPermit(w).toForm())),
      actualSetbacks: new UntypedFormArray(this.actualSetbacks.map(w => new MDSActualSetback(w).toForm())),
      materials: new UntypedFormArray(this.materials.map(w => new MDSMaterial(w).toForm())),
      contact: new ContactInformation(this.contact).toForm(),
      locationLot: new LocationLot(this.locationLot).toForm(),
      locationRollNumber: new LocationRollNumber(this.locationRollNumber).toForm()
    });
  }

  toModel(form: UntypedFormGroup): MdsCalculation {
    const v = form.getRawValue();
    const buildingPermits = form.get('buildingPermits') as UntypedFormArray;
    const actualSetbacks = form.get('actualSetbacks') as UntypedFormArray;
    const materials = form.get('materials') as UntypedFormArray;
    const contact = form.get('contact') as UntypedFormGroup;
    const locationLot = form.get('locationLot') as UntypedFormGroup;
    const locationRollNumber = form.get('locationRollNumber') as UntypedFormGroup;
    return new MdsCalculation({
      id: v.id,
      worksheetId: v.worksheetId,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      name: v.name,
      calculationType: v.calculationType,
      isStorageOnly: v.isStorageOnly,
      isAnaerobicDigestor: v.isAnaerobicDigestor,
      isLivestockConfirmed: v.isLivestockConfirmed,
      isBuildingPermitIssued: v.isBuildingPermitIssued,
      totalLotSizeInMetric: v.totalLotSizeInMetric,
      buildingPermits: buildingPermits?.controls.map((w: UntypedFormGroup) => new MDSBuildingPermit().toModel(w)),
      actualSetbacks: actualSetbacks?.controls.map((w: UntypedFormGroup) => new MDSActualSetback().toModel(w)),
      materials: materials?.controls.map((w: UntypedFormGroup) => new MDSMaterial().toModel(w)),
      contact: new ContactInformation().toModel(contact),
      locationLot: new LocationLot().toModel(locationLot),
      locationRollNumber: new LocationRollNumber().toModel(locationRollNumber)
    });
  }
}
