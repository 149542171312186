import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { MeasurementService } from '@shared/services/measurement.service';
import { Observable, of } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { UnitConverterService } from '../services/unit-converter.service';

@Injectable({ providedIn: 'root' })
@Pipe({ name: 'valueConverterPipe' })
export class ValueConverterPipe implements PipeTransform {
  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  transform(value: any, unitType: string, to$?: Observable<MeasurementSystem>): Observable<any> {
    if (isNaN(value)) {
      return of(value);
    }
    const measurementSystem$ = to$ || this.measurementService.measurementSystem$;
    this.unitConverterService.from = MeasurementSystem.Metric;
    return measurementSystem$.pipe(
      concatMap((to: any) => {
        this.unitConverterService.to = to;
        return of(this.unitConverterService.convert(value, unitType));
      })
    );
  }
}
