import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { CropNutrientWorksheet } from '@crop-nutrient/model/crop-nutrient-worksheet.model';
import { FertilizerWorksheet } from '@fertilizer/model/fertilizer-worksheet.model';
import { FmpWorksheet } from '@fmp/model/fmp-worksheet.model';
import { GhgWorksheet } from '@ghg/model/ghg-worksheet.model';
import { GnfWorksheet } from '@gnf/model/gnf-worksheet.model';
import { MDSBaseWorksheet } from '@mds1/models/mds-base-worksheet.model';
import { MdsCalculation } from '@mds1/models/mds-calculation.model';
import { MDS1Worksheet } from '@mds1/models/mds-worksheet.model';
import { MDS2Worksheet } from '@mds2/models/mds-worksheet.model';
import { LocationLot } from '@mstor/model/location-lot.model';
import { LocationRollNumber } from '@mstor/model/location-roll-number.model';
import { MstorWorksheet } from '@mstor/model/mstor-worksheet.model';
import { NasmWorksheet } from '@nasm/model/nasm-worksheet.model';
import { NmspWorksheet } from '@nmsp/model/nmsp-worksheet.model';
import { OrganicAmendmentWorksheet } from '@organic-amendment/model/organic-amendment-worksheet.model';
import { PlatoWorksheet } from '@plato/model/plato-worksheet.model';
import { AgErosionWorksheet } from '@ag-erosion/model/ag-erosion-worksheet.model';
import { ContactInformation } from '@shared/models/common/contact-information.model';
import { ContactType } from '@shared/models/common/contact-type.enum';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { CacheService } from '@shared/services/cache.service';

@Injectable({
  providedIn: 'root'
})
export class WorksheetFactory {
  private get isAuthenticated() {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  private get contacts() {
    const preparerContact = new ContactInformation({ contactType: ContactType.Preparer });
    const operatorContact = new ContactInformation({ contactType: ContactType.Operator });
    const ownerContact = new ContactInformation({ contactType: ContactType.Owner });
    const partner1 = new ContactInformation({ contactType: ContactType.Partner });
    const partner2 = new ContactInformation({ contactType: ContactType.Partner });
    return [preparerContact, operatorContact, ownerContact, partner1, partner2];
  }

  private get accountAndContacts() {
    return { userAccountId: this.cache.user.id, contacts: this.contacts };
  }

  constructor(private msalService: MsalService, private cache: CacheService) {}

  public instance(key: WorksheetKeys): any {
    const userAccountId = this.isAuthenticated ? this.cache.user.id : undefined;
    switch (key) {
      case WorksheetKeys.CROP_NUTRIENT:
        return new CropNutrientWorksheet({ userAccountId });
      case WorksheetKeys.FERTILIZER:
        return new FertilizerWorksheet({ userAccountId });
      case WorksheetKeys.ORGANIC_AMENDMENT:
        return new OrganicAmendmentWorksheet({ userAccountId });
      case WorksheetKeys.PLATO:
        return new PlatoWorksheet({ userAccountId });
      case WorksheetKeys.FMP:
        return new FmpWorksheet({ userAccountId });
      case WorksheetKeys.STORAGE_SIZING:
        return new MstorWorksheet({ userAccountId });
      case WorksheetKeys.MDS1:
        return this.getDefaultMdsWorksheet(WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1);
      case WorksheetKeys.MDS2:
        return this.getDefaultMdsWorksheet(WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2);
      case WorksheetKeys.NUTRIENT_MGMT:
        return this.getDefaultNutrientMgmtWorksheet();
      case WorksheetKeys.NASM:
        return this.getDefaultNASMWorksheet();
      case WorksheetKeys.GNF:
        return this.getDefaultGNFWorksheet();
      case WorksheetKeys.GHG:
        return this.getDefaultGHGWorksheet();
      case WorksheetKeys.AG_EROSION:
        return this.getDefaultAgErosionWorksheet();
      default:
        return undefined;
    }
  }

  private getDefaultMdsCalculation(type: WorksheetTypeIds): MdsCalculation {
    const calculation: any = {
      contact: new ContactInformation({ contactType: ContactType.MdsApplicant }),
      locationLot: new LocationLot(),
      locationRollNumber: new LocationRollNumber()
    };
    if (type === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2) {
      calculation.actualSetbacks = [...Array(4).keys()].map(key => ({ setbackType: key + 1 }));
    }
    return new MdsCalculation(calculation);
  }

  private _getMdsContactsAndLocation(): any {
    const preparer = new ContactInformation({ contactType: ContactType.Preparer });
    const applicant = new ContactInformation({ contactType: ContactType.MdsApplicant });
    return {
      userAccountId: this.cache.user?.id,
      contacts: [preparer, applicant],
      locationLot: new LocationLot(),
      locationRollNumber: new LocationRollNumber()
    };
  }

  private getDefaultMdsWorksheet(type: WorksheetTypeIds): MDSBaseWorksheet {
    const mdsClass = type === WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2 ? MDS2Worksheet : MDS1Worksheet;
    const mdsCalculation = this.getDefaultMdsCalculation(type);

    return new mdsClass({
      ...this._getMdsContactsAndLocation(),
      calculations: [mdsCalculation]
    });
  }

  private getDefaultNutrientMgmtWorksheet() {
    return new NmspWorksheet(this.accountAndContacts);
  }

  private getDefaultNASMWorksheet() {
    return new NasmWorksheet(this.accountAndContacts);
  }

  private getDefaultGNFWorksheet() {
    return new GnfWorksheet(this.accountAndContacts);
  }

  private getDefaultGHGWorksheet() {
    return new GhgWorksheet(this.accountAndContacts);
  }

  private getDefaultAgErosionWorksheet() {
    return new AgErosionWorksheet({
      userAccountId: this.cache.user?.id,
      contacts: [
        new ContactInformation({ contactType: ContactType.Preparer }),
        new ContactInformation({ contactType: ContactType.MdsApplicant })
      ]
    });
  }
}
