import { UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { FmpMaterialSample } from '@fmp/model/fmp-material-sample.model';
import { Constants } from '@shared/constants/constants';
import { NutrientValueBasis } from '@shared/models/common/nutrient-value-basis.enum';
import { NutrientValueSource } from '@shared/models/common/nutrient-value-source.enum';
import { MaterialNutrientCode } from '@shared/models/material/material-nutrient-code';
import { NasmMaterialTestValue } from './nasm-material-test-value.model';

let testCodes = null; // well this is still awkard

export class NasmMaterialSample extends FmpMaterialSample {

    sampleDate = Utils.utcNow();
    materialTestValues: NasmMaterialTestValue[];
    nutrientValueSource = NutrientValueSource.LabAnalysis;
    nutrientValueBasis = NutrientValueBasis.Dry;

    constructor(init?: Partial<any>) {
        super();
        testCodes = JSON.parse(localStorage.getItem('nutrientCodes'));
        Object.assign(this, init);
    }

    toForm() {
        const f = new UntypedFormGroup({
            id: new UntypedFormControl(this.id),
            createdBy: new UntypedFormControl(this.createdBy),
            materialTypeId: new UntypedFormControl(this.materialTypeId),
            dryMatterRange: new UntypedFormControl(this.dryMatterRange),
            dryMatterLow: new UntypedFormControl(this.dryMatterLow),
            dryMatterHigh: new UntypedFormControl(this.dryMatterHigh),
            nutrientValueSource: new UntypedFormControl(this.nutrientValueSource),
            nutrientValueBasis: new UntypedFormControl(this.nutrientValueBasis),
            selectedNutrients: new UntypedFormControl(this.selectedNutrients),
            focusedNutrient: new UntypedFormControl(this.focusedNutrient),
            sampleDate: new UntypedFormControl(this.sampleDate)
        });
        return this.assembleTestValues(f);
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        // tslint:disable-next-line: no-string-literal
        const nutrientCodes = Constants['nutrientCodes'] as MaterialNutrientCode[];
        const codes = nutrientCodes.filter(c => v[c.nutrientCode] !== undefined).map(c => c.nutrientCode);
        return new NasmMaterialSample({
            id: v.id,
            createdBy: v.createdBy,
            materialTypeId: v.materialTypeId,
            dryMatterRange: v.dryMatterRange,
            dryMatterLow: v.dryMatterLow,
            dryMatterHigh: v.dryMatterHigh,
            nutrientValueSource: v.nutrientValueSource,
            nutrientValueBasis: v.nutrientValueBasis,
            selectedNutrients: v.selectedNutrients,
            focusedNutrient: v.focusedNutrient,
            sampleDate: v.sampleDate,
            materialTestValues: codes ? codes.
                map(key => ({
                    testCode: key,
                    testValue: form.get(key).get('testValue').value,
                    isLessThan: form.get(key).get('isLessThan').value,
                    isNotDetected: form.get(key).get('isNotDetected').value,
                })) as NasmMaterialTestValue[] : undefined
        });
    }

    private assembleTestValues(f: UntypedFormGroup) {
        if (this.materialTestValues && this.materialTestValues.length) {
            this.materialTestValues.forEach(v => f.addControl(v.testCode,
                new UntypedFormGroup({
                    testValue: new UntypedFormControl(v.testValue, { validators: this.nutrientValidators(v.testCode) }),
                    isLessThan: new UntypedFormControl(v.isLessThan),
                    isNotDetected: new UntypedFormControl(v.isNotDetected),
                })
            ));
        }
        return f;
    }

    private nutrientValidators(testCode: string): ValidatorFn[] {
        const nutrient = testCodes.find(v => Utils.matchStr(v.nutrientCode, testCode));
        if (Utils.matchStr(nutrient.nutrientCode, 'PH')) {
            return [Validators.min(0), Validators.max(14)];
        }
        return [Validators.min(0), Validators.max(nutrient?.units === '%' ? 100 : 1000000000)];
    }
}
