import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { FmpFertilizerProduct } from '@fmp/model/fmp-fertilizer-product.model';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';

export class GhgFertilizerApplication extends BaseModel {
  name: string;
  applicationDate = Utils.getDefaultApplicationDate();
  applicationIncorporation = Constants.DEFAULT_GHG_FERTILIZER_INCORP_ID;
  calculationType = 0;
  fieldSectionId: string;
  targetCalciumInMetric = 0;
  targetMagnesiumInMetric = 0;
  targetManganeseInMetric = 0;
  targetNitrogenInMetric = 0;
  targetPhosphateInMetric = 0;
  targetPotashInMetric = 0;
  targetSulfurInMetric = 0;
  targetZincInMetric = 0;
  targetBoronInMetric = 0;
  targetMolybdenumInMetric = 0;
  fertilizerProducts: FmpFertilizerProduct[] = [];
  applicationRate = 0;

  constructor(init?: Partial<GhgFertilizerApplication>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'Fertilizer App' : 'Épan engrais';
    Object.assign(this, init);
    if (this.fertilizerProducts && this.fertilizerProducts.length > 0) {
      this.fertilizerProducts.map(v => (v.fertilizerApplicationId = this.id));
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      targetCalciumInMetric: new UntypedFormControl(this.targetCalciumInMetric, { validators: [Validators.min(0)] }),
      targetMagnesiumInMetric: new UntypedFormControl(this.targetMagnesiumInMetric, { validators: [Validators.min(0)] }),
      targetManganeseInMetric: new UntypedFormControl(this.targetManganeseInMetric, { validators: [Validators.min(0)] }),
      targetNitrogenInMetric: new UntypedFormControl(this.targetNitrogenInMetric, { validators: [Validators.min(0)] }),
      targetPhosphateInMetric: new UntypedFormControl(this.targetPhosphateInMetric, { validators: [Validators.min(0)] }),
      targetPotashInMetric: new UntypedFormControl(this.targetPotashInMetric, { validators: [Validators.min(0)] }),
      targetSulfurInMetric: new UntypedFormControl(this.targetSulfurInMetric, { validators: [Validators.min(0)] }),
      targetZincInMetric: new UntypedFormControl(this.targetZincInMetric, { validators: [Validators.min(0)] }),
      targetBoronInMetric: new UntypedFormControl(this.targetBoronInMetric, { validators: [Validators.min(0)] }),
      targetMolybdenumInMetric: new UntypedFormControl(this.targetMolybdenumInMetric, { validators: [Validators.min(0)] }),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      applicationDate: new UntypedFormControl(this.applicationDate, { validators: [ValidationFunctions.overTwentyYears] }),
      applicationIncorporation: new UntypedFormControl(this.applicationIncorporation),
      calculationType: new UntypedFormControl(this.calculationType),
      fieldSectionId: new UntypedFormControl(this.fieldSectionId),
      fertilizerProducts: new UntypedFormArray(this.fertilizerProducts.map(v => new FmpFertilizerProduct(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const fertilizerProducts = form.get('fertilizerProducts') as UntypedFormArray;
    return new GhgFertilizerApplication({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      targetCalciumInMetric: v.targetCalciumInMetric,
      targetMagnesiumInMetric: v.targetMagnesiumInMetric,
      targetManganeseInMetric: v.targetManganeseInMetric,
      targetNitrogenInMetric: v.targetNitrogenInMetric,
      targetPhosphateInMetric: v.targetPhosphateInMetric,
      targetPotashInMetric: v.targetPotashInMetric,
      targetSulfurInMetric: v.targetSulfurInMetric,
      targetZincInMetric: v.targetZincInMetric,
      targetBoronInMetric: v.targetBoronInMetric,
      targetMolybdenumInMetric: v.targetMolybdenumInMetric,
      name: v.name,
      applicationDate: v.applicationDate,
      applicationIncorporation: v.applicationIncorporation,
      calculationType: v.calculationType,
      fieldSectionId: v.fieldSectionId,
      fertilizerProducts: fertilizerProducts.controls.map((w: UntypedFormGroup) => new FmpFertilizerProduct().toModel(w))
    });
  }
}
