<footer id="footerComponent" class="print--hide" [attr.aria-label]="'footer.landmark.label' | translate | async">
    <div id="toolbar-bottom" class="flex flex--cols-2 flex--center-vertical">
        <p class="flex-child text-align--right">
            {{ "footer.feedback.plain.text" | translate | async }}
        </p>
        <div class="flex-child">
            <button type="button" mat-stroked-button class="button button--black-primary" (click)="feedback()">
                {{ "footer.feedback.button.text" | translate | async }}
            </button>
        </div>
    </div>
    <div id="bottom-footer-navigation" class="flex flex--cols-2" role="navigation" [attr.aria-label]="'footer.landmark.menu' | translate | async">
        <div class="flex-child flex-child--flex-grow flex flex--cols-none" role="menu">
            <a [href]="'footer.aboutOntario.link.target' | translate | async" class="flex-child" role="menuitem">
                {{ "footer.aboutOntario.link.text" | translate | async }}
            </a>
            <a [href]="'footer.accessibility.link.target' | translate | async" class="flex-child" role="menuitem">
                {{ "footer.accessibility.link.text" | translate | async }}
            </a>

            <a [href]="'footer.news.link.target' | translate | async" class="flex-child" role="menuitem">
                {{ "footer.news.link.text" | translate | async }}
            </a>

            <a [href]="'footer.privacy.link.target' | translate | async" class="flex-child" role="menuitem">
                {{ "footer.privacy.link.text" | translate | async }}
            </a>

            <a [href]="'footer.terms.link.target' | translate | async" class="flex-child" role="menuitem">
                {{ "footer.terms.link.text" | translate | async }}
            </a>
        </div>
        <div class="flex-child flex-child--flex-shrink flex flex--cols-none" role="menu">
            <a id="copyright-mobile-link" class="flex-child copyright-right" href="{{ 'footer.copyright.link.target' | translate | async }}" role="menuitem">
                {{ "footer.copyright.link.text" | translate | async }}{{ year }}
            </a>
        </div>
    </div>
    <div id="footerLogoContainer" class="flex flex--cols-3 flex--justify-content-space-evenly flex--center-vertical">
        <div class="cap-image flex-child text-align--center">
            <img
                src="../assets/images/CAP-EN-colour.png"
                alt="{{ 'footer.agricultural.logo.alttext' | translate | async }}"
                title="{{ 'footer.agricultural.logo.alttext' | translate | async }}"
                *ngIf="(lang$ | async) === 'en'" />
            <img
                src="../assets/images/CAP-FR-colour.png"
                alt="{{ 'footer.agricultural.logo.alttext' | translate | async }}"
                title="{{ 'footer.agricultural.logo.alttext' | translate | async }}"
                *ngIf="(lang$ | async) === 'fr'" />
        </div>
        <div class="ontario-image flex-child text-align--center">
            <img
                src="../assets/images/ontario@2x_w_en.png"
                height="48px"
                alt="{{ 'footer.logo.ontario.alttext' | translate | async }}"
                title="{{ 'footer.logo.ontario.alttext' | translate | async }}" />
        </div>
        <div class="canada-image flex-child text-align--center">
            <img src="../assets/images/canada_2c.png" height="28px" alt="{{ 'footer.logo.canada.alttext' | translate | async }}" title="{{ 'footer.logo.canada.title' | translate | async }}" />
        </div>
    </div>
</footer>
<footer aria-hidden="true" class="print--show">
    <p aria-hidden="true" class="print--show">{{ "footer.copyright.link.text" | translate | async }}{{ year }}</p>
</footer>
