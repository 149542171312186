import { Pipe, PipeTransform } from "@angular/core";
import { LanguageType } from "@shared/models/common/language-type.enum";
import { CacheService } from "@shared/services/cache.service";
import { LanguageService } from "@shared/services/language.service";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Pipe({ name: 'heavyMetalContentPipe' })
export class HeavyMetalContentPipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private cache: CacheService) { }

  private getMetalText(metalValue: number, defaultText: string): string {
    switch (metalValue) {
      case 0:
        return 'calculator.nasm.heavy.metal.content.text.0';
      case 1:
        return 'calculator.nasm.heavy.metal.content.text.1';
      case 2:
        return 'calculator.nasm.heavy.metal.content.text.2';
      default:
        return defaultText;
    }
  }

  transform(metalValue: number, defaultText: string = 'na'): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const key = this.getMetalText(metalValue, defaultText);
        return `${this.cache[lang][key]}`;
      })
    );
  }
}