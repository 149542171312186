import { Directive, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Utils } from '../../core/utilities/utils';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disableControl]'
})
export class DisableControlDirective {

  @Input() set disableControl(condition: boolean) {
    const action = condition ? 'disable' : 'enable';
    // Use setTimeout as a workaround for an issue with angular 9
    // Injected ngControl doesn't contain control property in Angular 9 #35330
    // https://github.com/angular/angular/issues/35330
    setTimeout(() => {
      this.ngControl.control[action](Utils.SILENT);
    }, 0);
  }

  constructor(private ngControl: NgControl) {
  }
}