import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MaterialFormService {

  private _materialForm = undefined;
  private subject = new BehaviorSubject(this._materialForm);
  private _materialForm$: Observable<any> = this.subject.asObservable();

  constructor() { }

  get materialForm(): any {
    return this._materialForm;
  }

  set materialForm(value: any) {
    this._materialForm = value;
    this.subject.next(this._materialForm);
  }

  get materialForm$(): Observable<any> {
    return this._materialForm$;
  }

}
