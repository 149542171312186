
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { FertilizerFieldSection } from './fertilizer-field-section.model';

export class FertilizerField extends BaseModel {
    farmId: string;
    name: string;
    fieldSections: FertilizerFieldSection[] = [];

    constructor(init?: Partial<FertilizerField>) {
        super();
        this.name = this.isEn ? 'Field 1' : 'Champ 1';
        this.fieldSections[0] = new FertilizerFieldSection(init);
        Object.assign(this, init);
    }
}
