import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BilingualText } from '@shared/models/common/bilingual-text';
import { CropSubType } from '../../crop-nutrient/model/crop-sub-type';

export const range = [1, 2, 3, 4, 5, 6, 7];
export class CropRotation {
    cropRotationId = 1;
    description: BilingualText;
    displayOrder: number;
    numberOfYears: number;
    croppingYear = Utils.getDefaultCroppingYear();
    cropYear1: number;
    cropYear2: number;
    cropYear3: number;
    cropYear4: number;
    cropYear5: number;
    cropYear6: number;
    cropYear7: number;

    constructor(init?: Partial<CropRotation>, cropSubTypes?: CropSubType[]) {
        Object.assign(this, init);
        const defaultCrop = cropSubTypes.find(crop => crop.cropId === 7);

        range.map(i => {
            // Define attributes dynamically
            const crop = this[`cropYear${i}`] ? cropSubTypes.find(v => v.cropId === this[`cropYear${i}`]) : defaultCrop;
            Object.defineProperty(this, `cropSubType${i}`, { value: crop });
            Object.defineProperty(this, `cropTypeId${i}`, { value: crop.cropTypeId });
        });
    }

    toForm() {
        const formGroup = new UntypedFormGroup({
            cropRotationId: new UntypedFormControl(this.cropRotationId),
            description: new UntypedFormControl(this.description),
            displayOrder: new UntypedFormControl(this.cropRotationId),
            numberOfYears: new UntypedFormControl(this.numberOfYears),
            croppingYear: new UntypedFormControl(this.croppingYear)
        });

        range.map(i => {
            formGroup.addControl(`cropSubType${i}`, new UntypedFormControl(this[`cropSubType${i}`]));
            formGroup.addControl(`cropTypeId${i}`, new UntypedFormControl(this[`cropSubType${i}`].cropTypeId));
        });
        return formGroup;
    }
}

//   {
//     "cropRotationId": 1,
//     "description": {
//       "en": "Corn - Soybeans - Wheat",
//       "fr": "maïs - soya - blé"
//     },
//     "displayOrder": 1,
//     "numberOfYears": 3,
//     "cropYear1": 7,
//     "cropYear2": 15,
//     "cropYear3": 31,
//     "cropYear4": 7,
//     "cropYear5": 7,
//     "cropYear6": 7,
//     "cropYear7": 7
//   }

// for (let i = 1; i <= this.numberOfYears; i++) {
// }

