<div class="flex contact-info">
    <div class="flex-child">
        {{ nameFirst.value }} {{ nameLast.value }}<ng-container *ngIf="canSeeJobTitle">&#44; {{ jobTitle.value }} </ng-container>
    </div>

    <div class="flex-child">
        {{ nameCompany.value }}
    </div>

    <div class="flex-child">
        {{ address1.value }}
    </div>

    <div class="flex-child">
        {{ address2.value }}
    </div>

    <div class="flex-child" *ngIf="city.value || province.value">{{ city.value }}<span *ngIf="city.value">, </span>{{ province.value }}</div>

    <div class="flex-child">
        {{ postalCode.value }}
    </div>

    <div class="flex-child">
        {{ phone1.value }}
    </div>

    <div class="flex-child">
        {{ email.value }}
    </div>
</div>
