<section id="signInToolbar">
    <mat-toolbar *ngIf="!isAuthenticated && toolbarVisible" color="accent" class="flex flex--cols-2 flex--baseline-vertical">
        <div class="flex-child flex-child--flex-grow flex flex--baseline-vertical">
            <p>
                {{ "home.content.right.text.getmost" | translate | async }}
            </p>
            <button type="button" class="button button--black-primary" mat-stroked-button (click)="login()">
                {{ "home.content.right.signin.button.caption" | translate | async }}
            </button>
        </div>
        <div class="flex-child flex-child--flex-shrink">
            <button
                type="button"
                class="button button--white-tertiary"
                attr.aria-label="{{ 'home.content.right.text.getmost.close' | translate | async }}"
                matTooltip="{{ 'home.content.right.text.getmost.close' | translate | async }}"
                matTooltipPosition="left"
                (click)="toggleToolbar()">
                <mat-icon> close </mat-icon>
            </button>
        </div>
    </mat-toolbar>
</section>
