import { Pipe, PipeTransform } from '@angular/core';
import { MaterialSampleSummaryService } from '@nasm/service/material-sample-summary.service';

@Pipe({ name: 'msPathogenContentPipe' })
export class MaterialSamplePathogenContentPipe implements PipeTransform {

  constructor(private summaryService: MaterialSampleSummaryService) { }

  transform(value: number, defaultText: string): string {
    return this.summaryService.pathogenContentText(value, defaultText);
  }
}
