import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({
  selector: '[appAnnualPercipitation]'
})
export class AnnualPercipitationDirective implements OnDestroy {

  private subscription = new Subscription();
  // tslint:disable-next-line: variable-name
  private _appAnnualPercipitation: AbstractControl;

  @Input() set appAnnualPercipitation(v: any) {
    this._appAnnualPercipitation = v;
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.subscription = this._appAnnualPercipitation.valueChanges.subscribe((val) => {
      this.unitConverterService.from = this.measurementService.measurementSystem;
      this.unitConverterService.to = MeasurementSystem.Metric;
      const converted = this.unitConverterService.convertLengthSmall2(val);
      this._appAnnualPercipitation.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
    });
  }

  constructor(private unitConverterService: UnitConverterService,
    private measurementService: MeasurementService) { }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
