import { AbstractControl, UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ContactType } from '@shared/models/common/contact-type.enum';
import { Utils } from '../../core/utilities/utils';
import validator from "validator";

export class ValidationFunctions {

    static empty(control: AbstractControl): ValidationErrors | null {
        if (!control.value && control.dirty && control.value.trim() === '') {
            return { empty: true };
        }
        return null;
    }

    static greaterThanZero(control: AbstractControl): ValidationErrors | null {
        if (!control.value && control.dirty && control.value <= 0) {
            return { greaterThanZero: true };
        }
        return null;
    }

    static minEndDate() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const startDateCtrl = group.get('startDate');
            const endDateCtrl = group.get('endDate');
            if (!startDateCtrl.value || !endDateCtrl.value || endDateCtrl.disabled) {
                return null;
            }
            const startDate = new Date(startDateCtrl.value).getTime();
            const endDate = new Date(endDateCtrl.value).getTime();
            if (endDate < startDate) {
                return { minEndDate: true };
            }
        };
    }

    static maxStartDate() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const startDateCtrl = group.get('startDate');
            const endDateCtrl = group.get('endDate');
            if (!startDateCtrl.value || !endDateCtrl.value || endDateCtrl.disabled) {
                return null;
            }
            const startDate = new Date(startDateCtrl.value).getTime();
            const endDate = new Date(endDateCtrl.value).getTime();
            if (startDate > endDate) {
                return { maxStartDate: true };
            }
        };
    }

    static overTwentyYears(control: AbstractControl): ValidationErrors | null {
        if (control
            && control.dirty
            && control.value
            && Utils.isDateWithinTwentyYears(control.value) === false) {
            return { overTwentyYears: true };
        }
        return undefined;
    }

    static afterYearEnd(control: AbstractControl, year: number): ValidationErrors | null {
        if (control.dirty && year) {
            const firstDate = new Date(control.value);
            const secondDate = new Date();
            secondDate.setUTCFullYear(year);
            secondDate.setUTCMonth(11);
            secondDate.setUTCDate(31);
            if (firstDate.getTime() > secondDate.getTime()) {
                control.setErrors({ afterYearEnd: true });
                return { afterYearEnd: true };
            }
        }
        return null;
    }

    static beforeDate(control1: AbstractControl, control2: AbstractControl): ValidationErrors | null {
        if (control1.dirty) {
            const firstDate = new Date(control1.value);
            const secondDate = new Date(control2.value);
            if (firstDate.getTime() < secondDate.getTime()) {
                control1.setErrors({ beforeDate: true });
                return { beforeDate: true };
            }
        }
        return null;
    }

    static minHarvestDate() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const plantingDate = group.get('plantingDate');
            const harvestDate = group.get('harvestDate');

            // The user cannot select a harvest date before the planting date.
            return ValidationFunctions.beforeDate(harvestDate, plantingDate);
        };
    }

    static maxHarvestDate() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const harvestDate = group.get('harvestDate');
            const croppingYear = group.get('croppingYear');

            // The user cannot select a harvest date after Dec 31st of the cropping year
            // (i.e. if the cropping year is 2020, the harvest date cannot be after Dec 31, 2020.
            return ValidationFunctions.afterYearEnd(harvestDate, croppingYear.value);
        };
    }

    //  The Area for material should not be larger than the Tillable area
    static fieldAreaMaterial() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const fieldAreaInMetric = group.get('fieldAreaInMetric').value;
            const fieldAreaMaterialInMetric = group.get('fieldAreaMaterialInMetric').value;
            if (fieldAreaInMetric && fieldAreaMaterialInMetric) {
                const invalid = +fieldAreaMaterialInMetric > +fieldAreaInMetric;
                return invalid ? { fieldAreaMaterialExceed: true } : null;
            }
            return null;
        };
    }

    //  The weight in should not be larger than weight out
    static livestockWeightInLessThanWeightOut() {
        return (group: UntypedFormGroup): { [key: string]: any } => {
            const weightInInMetric = group.get('weightInInMetric').value;
            const weightOutInMetric = group.get('weightOutInMetric').value;
            if (weightInInMetric && weightOutInMetric) {
                const invalid = +weightInInMetric > +weightOutInMetric;
                return invalid ? { weightInExceeded: true } : null;
            }
            return null;
        };
    }

    static arrayLength(len: number): ValidatorFn | any {
        return (control: AbstractControl[]): { [key: string]: any } => {
            if (!(control instanceof UntypedFormArray)) return;
            return control.length !== len ? { arrayLength: true } : null;
        };
    }

    static mds1ContactTypes(): ValidatorFn | any {
        const allowedContactTypes = [ContactType.MdsApplicant, ContactType.Preparer];
        return (control: AbstractControl[]): { [key: string]: any } => {
            if (!(control instanceof UntypedFormArray)) return;
            for (let contact of control.value) {
                const contactType = contact.get('contactType').value;
                const invalid = allowedContactTypes.indexOf(contactType) === -1;
                if (invalid) {
                    return { allowedContactTypes: true };
                }
            }
            return null;
        };
    }

    static nutrientMgmtContactTypes(): ValidatorFn | any {
        const allowedContactTypes = [ContactType.Preparer, ContactType.Operator, ContactType.Owner, ContactType.Partner];
        return (control: AbstractControl[]): { [key: string]: any } => {
            if (!(control instanceof UntypedFormArray)) return;
            for (let contact of control.value) {
                const contactType = contact.get('contactType').value;
                const invalid = allowedContactTypes.indexOf(contactType) === -1;
                if (invalid) {
                    return { allowedContactTypes: true };
                }
            }
            return null;
        };
    }

    static isEmailValid(email: string): boolean {
		return email ? validator.isEmail(email) : false;
    }

    static isPhoneValid(phone: string): boolean {
        if (!phone) {
            return true;
        }
        const regexp = new RegExp(/^\d{3}[-]\d{3}[-]\d{4}(\s?(x)(\s?\d+))?$/);
        return regexp.test(phone);
    }

    static isPostalCodeValid(postalCode: string): boolean {
        if (!postalCode) {
            return true;
        }
        const regexp = new RegExp(/^([A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d|\d{5})$/);
        return regexp.test(postalCode);
    }
}
