import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ArrayConstants } from '@shared/constants/array-constants';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { CacheService } from '@shared/services/cache.service';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({ name: 'materialFormTypePipe' })
export class MaterialFormTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService,
    private cache: CacheService) { }

  transform(element: UntypedFormGroup): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const languageJson: any = this.cache[lang];
        const mf = element?.controls?.materialForm?.value;
        const mfKey = ArrayConstants.MATERIAL_FORM_OPTIONS.find(v => v?.id === mf)?.i18nkey;
        const mt = element?.controls?.materialType?.value;
        const mtName = this.cache.allMaterialTypes.find(v => v?.materialTypeId === mt)?.name[lang];
        if (languageJson[mfKey]) {
          return languageJson[mfKey] + (mtName ? `, ${mtName}` : '');
        }
        return undefined;
      }));
  }
}
