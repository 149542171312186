import { WaterwayShape } from '@ag-erosion/enum/waterway-shape.enum';
import { URLs } from '@core/conf/urls';
import { ContactInfoType } from '@shared/components/contact-information/contact-info-type.enum';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';

/**
 * This file is meant for storing map like business constants,
 * every variable here is a map
 */
export class MapConstants {
  public static readonly PINDEX_CATEGORY_COLOR_MAP = {
    0: 'colour--green-primary',
    1: 'colour--green-primary',
    2: 'color--yellow-primary',
    3: 'colour--red-primary'
  };

  public static readonly PINDEX_CATEGORY_BUTTON_ICON_ONLY_COLOUR_MAP = {
    0: 'button--green-tertiary',
    1: 'button--green-tertiary',
    2: 'button--yellow-tertiary',
    3: 'button--red-tertiary'
  };

  public static STORAGE_DIMENSION_MAP = {
    0: 'dimensionDepthInMetric',
    1: 'dimensionFreeboardInMetric',
    2: 'dimensionDiameterInMetric',
    3: 'dimensionLengthInMetric',
    4: 'dimensionWidthInMetric',
    5: 'dimensionSlope',
    6: 'dimensionPileAngle',
    7: 'dimensionWallHeightInMetric'
  };

  public static readonly NOT_AVAILABLE_TEXT = { en: 'N/A', fr: 'N/A' };
  public static readonly EMPTY_TEXT = { en: '', fr: '' };

  public static readonly CONTACT_INFO_REQUIRED_FIELD_LABELS = {
    nameFirst: 'calculator.ss.contact.information.first.name',
    nameLast: 'calculator.ss.contact.information.last.name',
    nameCompany: 'calculator.ss.contact.information.organization',
    address1: 'calculator.ss.contact.information.address1',
    city: 'calculator.ss.contact.information.city',
    postalCode: 'calculator.ss.contact.information.postal.code',
    phone1: 'calculator.ss.contact.information.phone',
    email: 'calculator.ss.contact.information.email',
    certificationNumber: 'calculator.ss.contact.information.certification.number'
  };

  /** 3D Map for deciding if show missing info message based on worksheet type, contact type and control name */
  public static readonly CONTACT_INFO_REQUIRED_FIELDS = new Map([
    [
      WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast']],
        [ContactInfoType.Applicant, ['nameFirst', 'nameLast']]
      ])
    ],
    [
      WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_2,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast']],
        [ContactInfoType.Applicant, ['nameFirst', 'nameLast']]
      ])
    ],
    [
      WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast', 'email', 'certificationNumber']],
        [ContactInfoType.Operator, ['nameFirst', 'nameLast']],
        [ContactInfoType.Owner, ['nameFirst', 'nameLast']],
        [ContactInfoType.Transfer, ['nameCompany', 'address1', 'city', 'postalCode', 'phone1']]
      ])
    ],
    [
      WorksheetTypeIds.MANURE_STORAGE_SIZING,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast', 'email', 'certificationNumber']],
        [ContactInfoType.Operator, ['nameFirst', 'nameLast', 'email']],
        [ContactInfoType.Owner, ['nameFirst', 'nameLast', 'email']],
        [ContactInfoType.Transfer, ['nameCompany', 'address1', 'city', 'postalCode', 'phone1']]
      ])
    ],
    [
      WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast', 'email', 'certificationNumber']],
        [ContactInfoType.Operator, ['nameFirst', 'nameLast']],
        [ContactInfoType.Owner, ['nameFirst', 'nameLast']],
        [ContactInfoType.Facility, ['nameCompany', 'address1', 'city', 'postalCode', 'phone1']]
      ])
    ],
    [
      WorksheetTypeIds.GREENHOUSE_NUTRIENT_FEEDWATER,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast', 'email', 'certificationNumber']],
        [ContactInfoType.Operator, ['nameFirst', 'nameLast']],
        [ContactInfoType.Owner, ['nameFirst', 'nameLast']],
        [ContactInfoType.Transfer, ['nameCompany', 'address1', 'city', 'postalCode', 'phone1']]
      ])
    ],
    [
      WorksheetTypeIds.GREENHOUSE_GAS,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast', 'email', 'certificationNumber']],
        [ContactInfoType.Operator, ['nameFirst', 'nameLast']],
        [ContactInfoType.Owner, ['nameFirst', 'nameLast']],
        [ContactInfoType.Transfer, ['nameCompany', 'address1', 'city', 'postalCode', 'phone1']]
      ])
    ],
    [
      WorksheetTypeIds.AG_EROSION,
      new Map([
        [ContactInfoType.Preparer, ['nameFirst', 'nameLast']],
        [ContactInfoType.Applicant, ['nameFirst', 'nameLast']]
      ])
    ]
  ]);

  public static readonly WORKSHEET_CONVERSION = {
    [WorksheetTypeIds.FIELD_MANAGEMENT_PLAN + WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN]: URLs.NUTRIENT_MGMT_FROM_FMP_ENDPOINT,
    [WorksheetTypeIds.MANURE_STORAGE_SIZING + WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN]: URLs.NUTRIENT_MGMT_FROM_MSTOR_ENDPOINT,
    [WorksheetTypeIds.FIELD_MANAGEMENT_PLAN + WorksheetTypeIds.GREENHOUSE_GAS]: URLs.GHG_FROM_FMP_ENDPOINT,
    [WorksheetTypeIds.MANURE_STORAGE_SIZING + WorksheetTypeIds.GREENHOUSE_GAS]: URLs.GHG_FROM_MSTOR_ENDPOINT,
    [WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN + WorksheetTypeIds.GREENHOUSE_GAS]: URLs.GHG_FROM_NMSP_ENDPOINT
  };

  public static readonly WORKSHEET_CONVERSION_TITLE = {
    [WorksheetKeys.NUTRIENT_MGMT]: 'calculator.create.nmsp',
    [WorksheetKeys.GHG]: 'calculator.create.ghg'
  };

  public static readonly AGE_DEVICE_KEY_MAP = {
    'calculator.age.cs.rc.dts.opt1': {
      en: 'Grassed waterway',
      fr: "Voie d'eau engazonnée"
    },
    'calculator.age.cs.rc.dts.opt2': {
      en: 'Municipal drain',
      fr: 'Drain Municipale'
    },
    'calculator.age.cs.rc.dts.opt3': {
      en: 'Natural watercourse',
      fr: "Voie d'eau naturelle"
    },
    'calculator.age.cs.rc.dts.opt4': {
      en: 'WASCoB',
      fr: 'BCS (Basin de Captage et de Sédimentation)'
    },
    'calculator.age.cs.rc.dts.opt5': {
      en: 'Scraped ditch',
      fr: 'Fossé raclé'
    }
  };

  public static readonly AGE_DEVICE_VALUE_MAP = {
    'Grassed waterway': 'calculator.age.cs.rc.dts.opt1',
    "Voie d'eau engazonnée": 'calculator.age.cs.rc.dts.opt1',
    'Municipal drain': 'calculator.age.cs.rc.dts.opt2',
    'Drain Municipale': 'calculator.age.cs.rc.dts.opt2',
    'Natural watercourse': 'calculator.age.cs.rc.dts.opt3',
    "Voie d'eau naturelle": 'calculator.age.cs.rc.dts.opt3',
    WASCoB: 'calculator.age.cs.rc.dts.opt4',
    'BCS (Basin de Captage et de Sédimentation)': 'calculator.age.cs.rc.dts.opt4',
    'Scraped ditch': 'calculator.age.cs.rc.dts.opt5',
    'Fossé raclé': 'calculator.age.cs.rc.dts.opt5'
  };

  public static readonly AGE_WATERWAY_SHAPE_MAP = {
    [WaterwayShape.Triangle]: 'Triangle',
    [WaterwayShape.Trapezoid]: 'Trapezoid',
    [WaterwayShape.Rectangle]: 'Rectangle',
    [WaterwayShape.Parabola]: 'Parabola'
  };
}
