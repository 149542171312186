import { Pipe, PipeTransform } from '@angular/core';
import { MaterialTypeCalculation } from '@fmp/model/fmp-calculation.model';
import { FmpFieldInput } from '@fmp/model/fmp-field-input.model';
import { FmpMaterialApplication } from '@fmp/model/fmp-material-application.model';
import { LanguageService } from '@shared/services/language.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { LanguageType } from '../models/common/language-type.enum';

@Pipe({ name: 'fmpMaterialTypePipe' })
export class FmpMaterialTypePipe implements PipeTransform {

  constructor(private languageService: LanguageService) { }

  transform(element: FmpFieldInput, allMaterialTypeOptions: any[], calculations?: MaterialTypeCalculation[]): Observable<string> {
    return this.languageService.languageType$.pipe(
      map((lang: LanguageType) => {
        const materialTypeId: string = (element?.app as FmpMaterialApplication)?.materialTypeId;
        const searchArr: any[] = calculations ? calculations : element?.materialTypes;
        const mt: any = searchArr?.find(mt => Utils.matchStr(mt.id, materialTypeId));
        if (mt && allMaterialTypeOptions?.length) {
          return allMaterialTypeOptions.find(v => v.materialTypeId === mt?.materialType)?.name[lang];
        }
        return undefined;
      })
    );
  }
}
