import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { MDSBaseWorksheet } from './mds-base-worksheet.model';

export class MDS1Worksheet extends MDSBaseWorksheet {
  worksheetTypeId = WorksheetTypeIds.MINIMUM_DISTANCE_SEPARATION_1;

  constructor(init?: Partial<MDS1Worksheet>) {
    super(init);
    if (!this.name) {
      this.name = this.isEn ? 'MDS I' : 'DMS I';
    }
  }
}
