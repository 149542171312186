import { LanguageType } from '../../shared/models/common/language-type.enum';

export class AODADialog {
  static applyAllFixes(lang: LanguageType = LanguageType.English) {
    AODADialog.setAriaHiddenFalse();
    AODADialog.addCheckboxSvgAriaLabel(lang);
  }

  static addCheckboxSvgAriaLabel(lang: LanguageType) {
    // AODA fix: add checkbox svg aria-label
    const checkboxSvgsRef = document.querySelectorAll('.mat-checkbox-checkmark');
    checkboxSvgsRef && lang === LanguageType.English ?
      checkboxSvgsRef.forEach(el => el.setAttribute('aria-label', 'checkbox')) :
      checkboxSvgsRef.forEach(el => el.setAttribute('aria-label', 'case à cocher'));
  }

  static setAriaHiddenFalse() {
    // AODA fix: aria-hidden elements do not contain focusable elements
    const ref = document.querySelectorAll('div.cdk-visually-hidden.cdk-focus-trap-anchor');
    if (!!ref && ref.length > 0) {
      ref.forEach(el => el.setAttribute('aria-hidden', 'false'));
    }
  }
}
