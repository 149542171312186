import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { CacheService } from '@shared/services/cache.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Directive({ selector: '[yield]' })
export class YieldDirective implements OnDestroy {
  // tslint:disable-next-line: variable-name
  _yield: AbstractControl;
  @Input('yield') set yield(v: AbstractControl) {
    this._yield = v;

    this.subcription.add(
      this._yield.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(y => {
        const cropSubType = this.cache.allCropSubTypes.find(c => c.cropId === this.cropSubTypeId.value);
        this.unitConverterService.from = this.measurementService.measurementSystem;
        this.unitConverterService.to = MeasurementSystem.Metric;
        const result = this.unitConverterService.convertYield(y, cropSubType.yieldUnits, cropSubType.density);
        this._yield.patchValue(result, Utils.UPDATE_MODEL_ONLY);
      })
    );
  }

  // tslint:disable-next-line: no-input-rename
  @Input('cropSubTypeId') cropSubTypeId: AbstractControl;
  private alive = true;
  private subcription: Subscription = new Subscription();

  constructor(
    private measurementService: MeasurementService,
    private unitConverterService: UnitConverterService,
    private cache: CacheService
  ) {}

  ngOnDestroy(): void {
    this.alive = false;
    if (this.subcription) {
      this.subcription.unsubscribe();
    }
  }
}
