import { AfterViewInit, Component, Inject, Injector, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AODADialog } from '@core/utilities/aoda-dialog';
import { BaseComponent } from '@shared/components/base.component';
import { DialogData } from '@shared/components/confirmation-dialog/DialogData';
import { ValidationFunctions } from '@shared/validators/validation-functions';

@Component({
  selector: 'app-copy-worksheet-dialog',
  templateUrl: './copy-worksheet-dialog.component.html',
  styleUrls: ['./copy-worksheet-dialog.component.scss']
})
export class CopyWorksheetDialogComponent extends BaseComponent implements OnInit, AfterViewInit {
  data: any;
  f: UntypedFormGroup;
  srcWorksheetName: string;

  constructor(
    private injector: Injector,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) data: DialogData,
    private dialogRef: MatDialogRef<CopyWorksheetDialogComponent>
  ) {
    super(injector);
    this.data = data;
    this.dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.srcWorksheetName = this.data?.worksheet?.name;
    this.buildForm();
  }

  ngAfterViewInit(): void {
    AODADialog.applyAllFixes();
  }

  cancel(): void {
    this.dialogRef.close();
  }

  copyWorksheet(): void {
    if (this.f.invalid) {
      return;
    }

    let worksheetName = this.worksheetName?.value?.trim();
    if (!worksheetName) {
      return;
    }

    this.data.worksheet.name = worksheetName;
    this.dialogRef.close(this.data.worksheet);
  }

  private buildForm(): void {
    this.f = this.formBuilder.group({
      worksheetName: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(30), ValidationFunctions.empty]]
    });
  }

  get worksheetName(): UntypedFormGroup {
    return this.f.get('worksheetName') as UntypedFormGroup;
  }
}
