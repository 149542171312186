import { NgModule } from '@angular/core';
import { ProductApplicationRatePipe } from '@fertilizer/pipes/product-application-rate.pipe';
import { ProductOptionPipe } from '@fertilizer/pipes/product-option.pipe';
import { ApplicationRatePipe } from '@organic-amendment/pipes/application-rate.pipe';
import { AbsoluteValuePipe } from './pipes/absolute-value.pipe';
import { ApplicationRateUnitPipe } from './pipes/application-rate-unit.pipe';
import { AvailableNutrientPipe } from './pipes/available-nutrient.pipe';
import { CropNamePipe } from './pipes/crop-name.pipe';
import { CropTypeIdPipe } from './pipes/crop-type-id.pipe';
import { CroppingYearRangePipe } from './pipes/cropping-year-range.pipe';
import { DecimalPipe } from './pipes/decimal.pipe';
import { EmissionDecimalPipe } from './pipes/emission-decimal.pipe';
import { EmissionFullTextPipe } from './pipes/emission-full-text.pipe';
import { EmissionPercentChangePipe } from './pipes/emission-percent-change.pipe';
import { EmissionTextPipe } from './pipes/emission-text.pipe';
import { EmissionUnitPipe } from './pipes/emission-unit.pipe';
import { EnumArrayPipe } from './pipes/enum-array.pipe';
import { FlagIconClassBadgePipe, FlagIconClassNoButtonPipe, FlagIconClassPipe } from './pipes/flag-icon-class.pipe';
import { FlagIconPipe } from './pipes/flag-icon.pipe';
import { FlagLevelNamePipe } from './pipes/flag-level-name.pipe';
import { FlagMessagePipe } from './pipes/flag-message.pipe';
import { FmpMaterialFormPipe } from './pipes/fmp-material-form.pipe';
import { FmpMaterialTypeNamePipe } from './pipes/fmp-material-type-name.pipe';
import { FmpMaterialTypePipe } from './pipes/fmp-material-type.pipe';
import { FmpYieldUnitPipe } from './pipes/fmp-yield-unit.pipe';
import { FmpYieldPipe } from './pipes/fmp-yield.pipe';
import { FormArraySortPipe } from './pipes/form-array-sort.pipe';
import { FormArraySortPipe2 } from './pipes/form-array-sort2.pipe';
import { FuelTypeNamePipe } from './pipes/fuel-type-name.pipe';
import { HeavyMetalContentPipe } from './pipes/heavy-metal-content.pipe';
import { LessThanPipe } from './pipes/less-than.pipe';
import { LivestockTypeNamePipe } from './pipes/livestock-type-name.pipe';
import { MaterialAmountUnitPipe } from './pipes/material-amount-unit.pipe';
import { MaterialAmountValuePipe } from './pipes/material-amount-value.pipe';
import { MaterialFormTypePipe } from './pipes/material-form-type.pipe';
import { MaterialSampleBeneficialUsePipe } from './pipes/material-sample-beneficial-use.pipe';
import { MaterialSampleHeavyMetalContentPipe } from './pipes/material-sample-heavy-metal-content.pipe';
import { MaterialSampleNutrientValueBasisPipe } from './pipes/material-sample-nutrient-value-basis.pipe';
import { MaterialSamplePathogenContentPipe } from './pipes/material-sample-pathogen-content.pipe';
import { MaterialSampleTestValuePipe } from './pipes/material-sample-test-value.pipe';
import { MeasurementSystemPipe } from './pipes/measurement-system.pipe';
import { MonthPipe } from './pipes/month.pipe';
import { NasmMcTypePipe } from './pipes/nasm-material-component-type.pipe';
import { NasmMostRecentSoilSampleInfoPipe } from './pipes/nasm-most-recent-soil-sample-info.pipe';
import { NasmTypeOptionPipe } from './pipes/nasm-type-option.pipe';
import { NegativeToBlankPipe } from './pipes/negative-to-blank.pipe';
import { NodeTitleNamePipe } from './pipes/node-title-name.pipe';
import { NutrientValuePipe } from './pipes/nutrient-value.pipe';
import { OdourCategoryPipe } from './pipes/odour-category.pipe';
import { OptionNamePipe } from './pipes/option-name.pipe';
import { OrderPipe } from './pipes/order.pipe';
import { PathogenContentPipe } from './pipes/pathogen-content.pipe';
import { PindexLevelPipe } from './pipes/pindex-level.pipe';
import { SoilSampleTestValuePipe } from './pipes/soil-sample-test-value.pipe';
import { SubScriptPipe } from './pipes/subscript.pipe';
import { TranslatePipe } from './pipes/translate.pipe';
import { UnitConverterPipe } from './pipes/unit-converter.pipe';
import { UnitTitlePipe } from './pipes/unit-title.pipe';
import { UnitPipe } from './pipes/unit.pipe';
import { ValidRangePipe } from './pipes/valid-range.pipe';
import { ValueConverterPipe } from './pipes/value-converter.pipe';
import { WorksheetTypeNameAbbrPipe } from './pipes/worksheet-type-name-abbr.pipe';
import { YearUnitPipe } from './pipes/year-hint.pipe';
import { YieldPricePipe } from './pipes/yield-price.pipe';
import { YieldUnitPipe } from './pipes/yield-unit.pipe';
import { YieldPipe } from './pipes/yield.pipe';
import { ZeroToBlankPipe } from './pipes/zero-to-blank.pipe';
import { ZeroToTextPipe } from './pipes/zero-to-text.pipe';
import { RoundDecimalPipe } from './pipes/round-decimal.pipe';
import { TranslateAsyncPipe } from './pipes/translate-async.pipe';
import { FertilizerAppDetailedNamePipe } from './pipes/fertilizer-app-detailed-name.pipe';

const pipes = [
  TranslatePipe,
  UnitConverterPipe,
  ZeroToBlankPipe,
  ZeroToTextPipe,
  NodeTitleNamePipe,
  ValueConverterPipe,
  MaterialAmountValuePipe,
  MaterialAmountUnitPipe,
  UnitPipe,
  UnitTitlePipe,
  CropNamePipe,
  CropTypeIdPipe,
  OptionNamePipe,
  MeasurementSystemPipe,
  DecimalPipe,
  AbsoluteValuePipe,
  OrderPipe,
  FormArraySortPipe,
  FormArraySortPipe2,
  EnumArrayPipe,
  PindexLevelPipe,
  ApplicationRatePipe,
  ProductApplicationRatePipe,
  ProductOptionPipe,
  WorksheetTypeNameAbbrPipe,
  FmpMaterialFormPipe,
  FmpMaterialTypePipe,
  FmpMaterialTypeNamePipe,
  ApplicationRateUnitPipe,
  FlagMessagePipe,
  FlagLevelNamePipe,
  YieldUnitPipe,
  YearUnitPipe,
  AvailableNutrientPipe,
  YieldPipe,
  FlagIconClassPipe,
  FlagIconClassBadgePipe,
  FlagIconPipe,
  FmpYieldPipe,
  FmpYieldUnitPipe,
  MonthPipe,
  FlagIconClassNoButtonPipe,
  NegativeToBlankPipe,
  SubScriptPipe,
  YieldPricePipe,
  NutrientValuePipe,
  LessThanPipe,
  PathogenContentPipe,
  OdourCategoryPipe,
  HeavyMetalContentPipe,
  NasmMcTypePipe,
  MaterialFormTypePipe,
  NasmTypeOptionPipe,
  NasmMostRecentSoilSampleInfoPipe,
  MaterialSampleHeavyMetalContentPipe,
  MaterialSamplePathogenContentPipe,
  MaterialSampleBeneficialUsePipe,
  MaterialSampleTestValuePipe,
  MaterialSampleNutrientValueBasisPipe,
  SoilSampleTestValuePipe,
  CroppingYearRangePipe,
  EmissionUnitPipe,
  EmissionTextPipe,
  EmissionPercentChangePipe,
  EmissionFullTextPipe,
  LivestockTypeNamePipe,
  FuelTypeNamePipe,
  EmissionDecimalPipe,
  ValidRangePipe,
  RoundDecimalPipe,
  TranslateAsyncPipe,
  FertilizerAppDetailedNamePipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class PipesModule {}
