// Use negative numbers to prevent conflicts with DeleteKey
export enum UndeletableKey {
  SoilSample = -1,
  MaterialType = -2,
  MdsCalculation = -3,
  TransferContact = -4,
  NasmComponent = -5,
  MaterialSample = -6,
  StorageSystem = -7,
  Watershed = -8,
  ControlStructure = -9
}
