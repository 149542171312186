import { Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { FmpField } from '@fmp/model/fmp-field.model';
import { ArrayConstants } from '@shared/constants/array-constants';
import { NutrientBalanceType } from '@shared/models/common/nutrient-balance-type.enum';
import { tap, takeWhile, EMPTY } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { SharedFieldService } from '@shared/services/shared-field.service';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../base.component';
import { FmpReportType } from '@fmp/model/fmp-report-type.model';
import { SiteFieldService } from '@fmp-site/service/site-field.service';

@Component({
  selector: 'app-nutrient-balance-selector',
  templateUrl: './nutrient-balance-selector.component.html',
  styleUrls: ['./nutrient-balance-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NutrientBalanceSelectorComponent extends BaseComponent {
  @Input() fieldSection: UntypedFormGroup;
  @Input() field: UntypedFormGroup;
  @Input() f: UntypedFormGroup;

  sharedFieldService: SharedFieldService | SiteFieldService;

  constructor(private injector: Injector) {
    super(injector);

    this.sharedFieldService = this.isAuthenticated ? injector.get(SharedFieldService) : injector.get(SiteFieldService);
  }

  private calculate(reportType?: FmpReportType) {
    // should be emitted?
    this.calculationService.update();
  }

  get displayNutrientBalanceType() {
    return this.fieldSection.get('displayNutrientBalanceType');
  }

  get nutrientBalanceEnumType() {
    return NutrientBalanceType;
  }

  get nutrientBalanceTypes() {
    return ArrayConstants.NUTRIENT_BALANCE;
  }

  syncData() {
    if (!this.f.pristine) {
      return this.sharedFieldService.update(new FmpField().toModel(this.field)).pipe(
        tap(() => this.calculate()),
        takeWhile(() => this.alive)
      );
    }
    return EMPTY;
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  onNutrientBalanceTypeChange(event) {
    this.syncData().subscribe();
  }

  // todo, refactoring candidate
  openHybridInfo() {
    // display dialog
    this.dialogService.instance(
      ConfirmationDialogComponent,
      {
        title: 'dialog.title.hybrid.nutrient.balance',
        message: 'dialog.message.hybrid.nutrient.balance',
        btnCancel: 'dialog.action.close',
        btnOk: 'dialog.action.ok',
        btnOkStyle: 'button--green-primary',
        displayBtnOk: false
      },
      { disableClose: true }
    );
  }
}
