import { PlatoFertilizerApplication } from '@shared/models/common/plato-fertilizer-application.model';
import { PlatoMaterialApplication } from '@shared/models/common/plato-material-application.model';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { Crop } from '@shared/models/worksheet/crop.model';
import { PlatoSoilSample } from './plato-soil-sample.model';

export class PlatoFieldSection extends BaseModel {
    id: string;
    fieldId: string;
    name: string;
    soilTextureId = -1;
    soilSeriesId = -1;
    cropHeatUnits: number;
    fieldAreaInMetric = 0;
    bufferZone = 2;
    tileDrainageSystem = 2;
    tileDrainageSpacingInMetric = 10;
    precipitationInMetric: number;
    slopeMaximumInPercent = 7;
    crops: Crop[] = [];
    soilSamples: PlatoSoilSample[] = [];
    fertilizerApplications: PlatoFertilizerApplication[] = [];
    materialApplications: PlatoMaterialApplication[] = [];

    constructor(init?: Partial<PlatoFieldSection>) {
        super();
        this.soilSamples[0] = new PlatoSoilSample();
        this.crops[0] = new Crop();
        Object.assign(this, init);
    }
}
