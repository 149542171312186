import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class WorksheetEconomic extends BaseModel {
  costOfLiquidApplicationInMetric = 0;
  costOfSolidApplicationInMetric = 0;
  overrideEconomicValues = false;
  valueOfNitrogenInMetric = 0;
  valueOfPhosphateInMetric = 0;
  valueOfPotashInMetric = 0;
  worksheetId: string;

  constructor(init?: Partial<WorksheetEconomic>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      costOfLiquidApplicationInMetric: new UntypedFormControl(this.costOfLiquidApplicationInMetric),
      costOfSolidApplicationInMetric: new UntypedFormControl(this.costOfSolidApplicationInMetric),
      overrideEconomicValues: new UntypedFormControl(this.overrideEconomicValues),
      valueOfNitrogenInMetric: new UntypedFormControl(this.valueOfNitrogenInMetric),
      valueOfPhosphateInMetric: new UntypedFormControl(this.valueOfPhosphateInMetric),
      valueOfPotashInMetric: new UntypedFormControl(this.valueOfPotashInMetric),
      worksheetId: new UntypedFormControl(this.worksheetId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new WorksheetEconomic({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      costOfLiquidApplicationInMetric: v.costOfLiquidApplicationInMetric,
      costOfSolidApplicationInMetric: v.costOfSolidApplicationInMetric,
      overrideEconomicValues: v.overrideEconomicValues,
      valueOfNitrogenInMetric: v.valueOfNitrogenInMetric,
      valueOfPhosphateInMetric: v.valueOfPhosphateInMetric,
      valueOfPotashInMetric: v.valueOfPotashInMetric,
      worksheetId: v.worksheetId
    });
  }
}
