import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { LocationRollNumber } from '@mstor/model/location-roll-number.model';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { Observable, of } from 'rxjs';
import { CacheService } from './cache.service';

@Injectable({ providedIn: 'root' })
export class SharedLocationRollNumberService {

  private WORKSHEET_KEYS = [
    WorksheetKeys.MDS1,
    WorksheetKeys.MDS2
  ];
  private cache: CacheService;
  httpClient: HttpClient;

  constructor(injector: Injector) {
    this.httpClient = injector.get(HttpClient);
    this.cache = injector.get(CacheService);
  }

  get(id: string): Observable<LocationRollNumber> {
    return this.httpClient.get(`${URLs.LOCATION_ROLL_NUMBER_ENDPOINT}/${id}`) as Observable<LocationRollNumber>;
  }

  save(locationRollNumber: LocationRollNumber): Observable<LocationRollNumber> {
    return this.httpClient.post(URLs.LOCATION_ROLL_NUMBER_ENDPOINT, locationRollNumber) as Observable<LocationRollNumber>;
  }

  delete(id: string): Observable<boolean> {
    return this.httpClient.delete(`${URLs.LOCATION_ROLL_NUMBER_ENDPOINT}/${id}`) as Observable<boolean>;
  }

  update(locationRollNumber: LocationRollNumber, isAuthenticated?: boolean): Observable<LocationRollNumber> {
    if (isAuthenticated !== undefined && isAuthenticated === false) {
      for (let key of this.WORKSHEET_KEYS) {
        const worksheet = this.cache.getWorksheet(key);
        if (!worksheet) { continue; }
        const found = Utils.findById(worksheet, locationRollNumber.id);
        if (found) {
          Utils.mergeObject(found, locationRollNumber);
          this.cache.setWorksheet(worksheet, key);
          return of(found);
        }
      }
      return of(undefined);
    } else {
      const url = `${URLs.LOCATION_ROLL_NUMBER_ENDPOINT}/${locationRollNumber.id}`;
      return this.httpClient.put(url, locationRollNumber) as Observable<LocationRollNumber>;
    }
  }
}
