import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { URLs } from '../../core/conf/urls';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { DbUser } from '../models/user/db-user';
import { Preference } from '../models/user/preference';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private cache: CacheService, private httpClient: HttpClient) {}

  public byEmail(azureUser: string): Observable<DbUser> {
    if (this.cache.user) {
      return of(this.cache.user);
    }
    const url = `${URLs.DB_USER_ENDPOINT}/name/${azureUser}`;
    return this.httpClient.get(url) as Observable<DbUser>;
  }

  //todo this does not work yet
  public byId(id: string): Observable<DbUser> {
    const url = `${URLs.DB_USER_ENDPOINT}/id/${id}`;
    return this.httpClient.get(url) as Observable<DbUser>;
  }

  public save(azureUser: string): Observable<DbUser> {
    const preference: Preference = this.cache.preference;
    const user: DbUser = new DbUser(preference.languageType, MeasurementSystem[preference.measurementSystem]);
    user.userAccountName = azureUser;
    user.createdBy = 'frontend';
    return this.httpClient.post(URLs.DB_USER_ENDPOINT, user) as Observable<DbUser>;
  }

  public update(user: DbUser): Observable<DbUser> {
    const url = `${URLs.DB_USER_ENDPOINT}/${user.id}`;
    return this.httpClient.put(url, user) as Observable<DbUser>;
  }
}
