import { Injectable } from '@angular/core';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { DialogService } from '@shared/services/dialog.service';

@Injectable({
  providedIn: 'root'
})
export class LinkService {
  constructor(private dialogService: DialogService) {}

  feedback() {
    this.dialogService.instance(
      ConfirmationDialogComponent,
      {
        title: 'dialog.title.feedback',
        message: 'dialog.message.feedback',
        btnCancel: 'dialog.action.close',
        btnOk: 'dialog.action.ok',
        btnOkStyle: 'button--green-primary',
        displayBtnOk: false
      },
      { disableClose: true }
    );
  }

  contactUs() {
    this.dialogService.instance(
      ConfirmationDialogComponent,
      {
        title: 'dialog.title.contactus',
        message: 'dialog.message.contactinfo',
        btnCancel: 'dialog.action.close',
        btnOk: 'dialog.action.ok',
        btnOkStyle: 'button--green-primary',
        displayBtnOk: false
      },
      { disableClose: true }
    );
  }
}
