export enum ReportType {
  PlanApprovals = 0,
  PlanRegistration = 1,
  LandApplication = 2,
  PostApplication = 3
}

export const SubmissionReportTypes = [
  ReportType.PlanApprovals,
  ReportType.PlanRegistration
];

export const NasmApplicationReportTypes = [
  ReportType.LandApplication,
  ReportType.PostApplication
];