<article>
	<h1 mat-dialog-title class="h1 h1--component-title">
		{{"dialog.title.confirmation" | translate | async}}
	</h1>

	<p mat-dialog-content>
		{{"dialog.message.continueWorking" | translate | async}}
	</p>

	<div mat-dialog-actions class="flex flex--end-vertical">
		<div class="flex-child flex-child--flex-shrink">
			<button type="button" class="button button--black-secondary" mat-stroked-button [mat-dialog-close]="false">
				{{"dialog.action.close" | translate | async}}
			</button>
		</div>

		<div class="flex-child flex-child--flex-shrink ">
			<button type="button" class="button button--green-secondary" mat-stroked-button [mat-dialog-close]="true">
				{{"dialog.action.startNew" | translate | async}}
			</button>
		</div>

		<div class="flex-child flex-child--flex-shrink ">
			<button type="button" class="button button--green-primary" mat-stroked-button (click)="closeAndContinue()">
				{{"dialog.action.continueWorking" | translate | async}}
			</button>
		</div>
	</div>
</article>