export enum Province {
    AB = 'AB',
    BC = 'BC',
    MB = 'MB',
    NB = 'NB',
    NF = 'NF',
    NS = 'NS',
    ON = 'ON',
    PE = 'PE',
    PQ = 'PQ',
    SK = 'SK'
}
