import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Storage } from './storage.model';

export class StorageGroup extends BaseModel {
  name: string;
  notes: string;
  materialForm = MaterialForm.Liquid;
  storageSystemId: string;
  targetDaysOfStorage = Constants.DEFAULT_STORAGE_TARGET_DAYS;
  overrideTargetDaysOfStorage = false;
  targetDaysOfStorageComment: string;
  storages: Storage[];

  constructor(init?: Partial<StorageGroup>) {
    super();
    this.name = this.isEn ? 'Storage group 1' : 'Groupe d’entreposage 1';
    this.storages = this.storages && this.storages.length ? this.storages : [new Storage()];
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [
          Validators.maxLength(30),
          Validators.minLength(3),
          ValidationFunctions.empty
        ]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      materialForm: new UntypedFormControl({ value: this.materialForm, disabled: this.storages.length > 1 }),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      targetDaysOfStorage: new UntypedFormControl(this.targetDaysOfStorage, { validators: [Validators.max(1000)] }),
      overrideTargetDaysOfStorage: new UntypedFormControl(this.overrideTargetDaysOfStorage),
      targetDaysOfStorageComment: new UntypedFormControl(this.targetDaysOfStorageComment),
      storages: new UntypedFormArray(this.storages.map(v => new Storage(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const storages = form.get('storages') as UntypedFormArray;
    return new StorageGroup({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      materialForm: v.materialForm,
      storageSystemId: v.storageSystemId,
      targetDaysOfStorage: v.targetDaysOfStorage,
      overrideTargetDaysOfStorage: v.overrideTargetDaysOfStorage,
      targetDaysOfStorageComment: v.targetDaysOfStorageComment,
      storages: storages.controls.map((w: UntypedFormGroup) => new Storage().toModel(w))
    });
  }

}
