import { HttpError } from '@shared/models/common/http-error.enum';

/**
 * This constant file is meant for app level constants that has nothing to do with business logic
 */
export class AppConstants {
  public static readonly DEBOUNCE_TIME_FOR_POST_API_WORKSHEET = 800;
  // caching constants
  public static readonly CACHE_MAX_AGE = 3600000; // 1 hour
  public static readonly CACHEABLE_HTTP_METHODS = ['GET'];
  public static readonly CACHEABLE_URLS = [
    // 'location/geotownships?unique-names=false',
    'material/types?active-only=true',
    'material/dry-range',
    'material/fertilizer?include-custom=false'
  ];

  public static readonly DEFAULT_DECIMALS = 2;

  // HTTP errors
  public static readonly HTTP_ERRORS = [
    // {
    //   id: HttpError.HTTP_BAD_REQUEST,
    //   i18nkey: 'http.error.400'
    // },
    // {
    //   id: HttpError.HTTP_UNAUTHORIZED,
    //   i18nkey: 'http.error.401'
    // },
    {
      id: HttpError.HTTP_FORBIDDEN,
      i18nkey: 'http.error.403'
    },
    {
      id: HttpError.HTTP_REQUEST_TIMEOUT,
      i18nkey: 'http.error.408'
    },
    {
      id: HttpError.HTTP_BAD_GATEWAY,
      i18nkey: 'http.error.502'
    },
    {
      id: HttpError.HTTP_GATEWAY_TIMEOUT,
      i18nkey: 'http.error.504'
    }
  ];

  public static readonly API_CACHE_ENTRIES = [
    'livestockCombinationDetails',
    'nutrientCodes',
    'fertilizerProducts',
    'lowerTierMunicipalities',
    'otherSourceTypes',
    'greenhouseThermalCurtain',
    'upperTierMunicipalities',
    'geoTownships',
    'buildingInsulationLevels',
    'buildingInfiltrationLevels',
    'soilSeries',
    'livestockTypes',
    'runoffManagementOptions',
    'storageDimensions',
    'grainDryerType',
    'allNitrogenCredits',
    'cropRotations',
    'mdsStorageTypes',
    'storageTemporaryOptions',
    'allSoilTextures',
    'nasmTypes',
    'livestockCombinations',
    'greenhouseCoveringMaterial',
    'transferTypes',
    'equationValues',
    'depthOfUnsaturatedSoilRestriction',
    'soilTestCodes',
    'flagTypes',
    'greenhouseCropType',
    'allTillageMethods',
    'fuelTypes',
    'materialIncorporations',
    'allTillagePractices',
    'applicationMethods',
    'mdsMaterialSubTypes',
    'allCropSubTypes',
    'storageDimensionLinks',
    'allDryRanges',
    'treatmentTypes',
    'allMaterialTypes',
    'feedAdditives',
    'landUseTypes',
    'storageTypes',
    'livestockTypeDietCommon',
    'worksheetTypes',
    'greenhouseConstructionLevel',
    'treeSpecies',
    'storageChecklists',
    'allCropTypes',
    'buildVersion',
    'mdsMaterialTypes',
    'horticulturalType',
    'heatingTypes',
    'overviewOperations',
    'fertilizerIncorporations',
    'storageTemporaries',
    'greenhouseLightingType',
    'grainDryerCropType'
    //TODO add ag-erosion cache here ...
  ];

  public static readonly CACHE_LIFESPAN_IN_HOURS = 12;
  public static readonly CROP_TYPE_OTHER = 1000;
}
