import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '@shared/services/language.service';
import { map, Observable } from 'rxjs';
import { CacheService } from '../services/cache.service';

@Pipe({ name: 'fuelTypeNamePipe' })
export class FuelTypeNamePipe implements PipeTransform {
  constructor(private cache: CacheService, private lang: LanguageService) {}

  //todo default fuelTypeId to 2 for testing only, real fuelTypeId will be returned from the master calc
  transform(fuelTypeId: number = 2): Observable<string> {
    const fuelType = this.cache.fuelTypes.find(v => v.fuelTypeId === fuelTypeId);
    return this.lang.languageType$.pipe(map(l => fuelType.description[l]));
  }
}
