import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class LivestockProductionPeriod extends BaseModel {
    produceSolidPercent = null;
    produceLiquidPercent = null;
    producePasturePercent = null;
    monthId: number;
    livestockId: string;

    constructor(init?: Partial<LivestockProductionPeriod>) {
        super();
        Object.assign(this, init);
    }

    toForm() {
        return new UntypedFormGroup({
            produceSolidPercent: new UntypedFormControl(this.produceSolidPercent, {
                validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
            }),
            produceLiquidPercent: new UntypedFormControl(this.produceLiquidPercent, {
                validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
            }),
            producePasturePercent: new UntypedFormControl(this.producePasturePercent, {
                validators: [Validators.min(0), Validators.max(100), Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
            }),
            monthId: new UntypedFormControl(this.monthId),
            livestockId: new UntypedFormControl(this.livestockId)
        });
    }

    toModel(form: UntypedFormGroup) {
        const v = form.getRawValue();
        return new LivestockProductionPeriod({
            produceSolidPercent: v.produceSolidPercent,
            produceLiquidPercent: v.produceLiquidPercent,
            producePasturePercent: v.producePasturePercent,
            monthId: v.monthId,
            livestockId: v.livestockId
        });
    }
}
