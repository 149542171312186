import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { FormService } from '@shared/services/form.service';
import { SharedFlagCommentService } from '@shared/services/shared-flag-comment.service';
import { BehaviorSubject, combineLatest, EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { NasmWorksheet } from '../model/nasm-worksheet.model';
import { NasmService } from './nasm.service';

@Injectable({ providedIn: 'root' })
export class NasmCalculationService extends CalculationService {
  flagComments$ = new BehaviorSubject<any>(undefined);

  constructor(
    protected formService: FormService,
    protected httpClient: HttpClient,
    private calculationService: CalculationService,
    private sharedFlagCommentService: SharedFlagCommentService,
    private nasmService: NasmService
  ) {
    super(httpClient, formService);
  }

  publish(v: any) {
    this.calculation$.next(v);
  }

  calculate() {
    const model = new NasmWorksheet().toModel(this.formService.f);
    Utils.errorsomeStream(
      combineLatest([this.httpClient.post(URLs.NASM_CALCULATION_ENDPOINT, model), this.sharedFlagCommentService.getByWorksheetId(model.id)])
    ).subscribe(calcResult => this.input$.next(calcResult));
  }

  update() {
    const model = new NasmWorksheet().toModel(this.formService.f);
    if (this.lastCalc) {
      const merged = { ...this.lastCalc, ...model };
      this.input$.next([merged, null]);
    }
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      tap(res => {
        if (res) {
          const [calculation, flagComments] = res;
          this.setDefaultMaterialForm(calculation);
          this.publish(calculation as any);
          this.lastCalc = calculation;
          if (flagComments) {
            this.flagComments$.next(flagComments);
          }
          this.nasmService.displayReport$ = of(true);
        }
      })
    );
  }

  bindFlagCommentData() {
    return this.sharedFlagCommentService.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data =>
        data ? Utils.errorsomeStream(this.sharedFlagCommentService.getByWorksheetId(this.formService.f.get('id').value)) : EMPTY
      ),
      tap(res => this.flagComments$.next(res))
    );
  }

  private setDefaultMaterialForm(res: any) {
    const storageSystemResponse = res?.storageSizingCalculations?.find(s =>
      Utils.matchStr(s.storageSystemID, this.currentStorageSystem.get('id').value)
    );
    this.calculationService.defaultMaterialForm = storageSystemResponse?.defaultMaterialForm;
  }

  get f() {
    return this.formService.f || undefined;
  }

  get farms() {
    return this.formService.f?.get('farms') as UntypedFormArray;
  }

  get currentFarm() {
    return this.farms?.controls.find(v => Utils.matchStr(v.get('id').value, this.formService.f?.get('currentFarmId')?.value));
  }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem() {
    return this.storageSystems?.controls.find(v =>
      Utils.matchStr(v.get('id').value, this.formService.f?.get('currentStorageSystemId')?.value)
    );
  }
}
