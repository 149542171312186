import { Directive, Input, OnDestroy } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Utils } from '../../core/utilities/utils';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { MeasurementService } from '../services/measurement.service';
import { UnitConverterService } from '../services/unit-converter.service';

@Directive({ selector: '[valueConverter]' })
export class ValueConverterDirective implements OnDestroy {
  @Input('conversionType') conversionType: string;
  private sub: Subscription;
  // tslint:disable-next-line: variable-name
  _control: AbstractControl;
  @Input() set valueConverter(v: any) {
    this._control = v;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.sub = this._control.valueChanges.pipe(takeWhile(() => this.alive)).subscribe(val => {
      this.unitConverterService.from = this.measurementService.measurementSystem;
      this.unitConverterService.to = MeasurementSystem.Metric;
      const converted = this.unitConverterService.convert(val, this.conversionType);
      this._control.patchValue(converted, Utils.UPDATE_MODEL_ONLY);
    });
  }

  private alive = true;

  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  ngOnDestroy(): void {
    this.alive = false;
  }
}
