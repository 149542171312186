import { DropPipeInletType } from '@ag-erosion/enum/drop-pipe-inlet-type.enum';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class DropPipe extends BaseModel {
  controlStructureId: string;
  dropPipeInletType = DropPipeInletType.DropPipeStructure;
  depthOfWaterInMetric: number;
  horizontalPipeLengthInMetric: number;
  horizontalPipeFallInMetric: number;
  horizontalPipeSlope: number;
  horizontalPipeSizeMethod: number;
  verticalPipeDiameterInMetric: number;
  bermHeightInMetric: number;
  orificePlateDiameterInMetric: number;
  customRiserInletHeightInMetric: number;
  customRiserOrificeDiameterInMetric: number;
  customRiserNumberOfHoles: number;
  customRiserNumberOfRows: number;
  isCustomRiserDesignUsed = false;
  isOrificePlateRequired = false;
  todo: any;

  constructor(init?: Partial<DropPipe>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      controlStructureId: new UntypedFormControl(this.controlStructureId),
      dropPipeInletType: new UntypedFormControl(this.dropPipeInletType),
      depthOfWaterInMetric: new UntypedFormControl(this.depthOfWaterInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      horizontalPipeLengthInMetric: new UntypedFormControl(this.horizontalPipeLengthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      horizontalPipeFallInMetric: new UntypedFormControl(this.horizontalPipeFallInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      horizontalPipeSlope: new UntypedFormControl(this.horizontalPipeSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      horizontalPipeSizeMethod: new UntypedFormControl(this.horizontalPipeSizeMethod),
      verticalPipeDiameterInMetric: new UntypedFormControl(this.verticalPipeDiameterInMetric),
      bermHeightInMetric: new UntypedFormControl(this.bermHeightInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      orificePlateDiameterInMetric: new UntypedFormControl(this.orificePlateDiameterInMetric),
      customRiserInletHeightInMetric: new UntypedFormControl(this.customRiserInletHeightInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      customRiserOrificeDiameterInMetric: new UntypedFormControl(this.customRiserOrificeDiameterInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      customRiserNumberOfHoles: new UntypedFormControl(this.customRiserNumberOfHoles, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      customRiserNumberOfRows: new UntypedFormControl(this.customRiserNumberOfRows, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      isCustomRiserDesignUsed: new UntypedFormControl({
        value: this.isCustomRiserDesignUsed,
        disabled: this.isOrificePlateRequired
      }),
      isOrificePlateRequired: new UntypedFormControl({
        value: this.isOrificePlateRequired,
        disabled: this.isCustomRiserDesignUsed
      }),
      todo: new UntypedFormControl(this.todo)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new DropPipe({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      controlStructureId: v.controlStructureId,
      dropPipeInletType: v.dropPipeInletType,
      depthOfWaterInMetric: v.depthOfWaterInMetric,
      horizontalPipeLengthInMetric: v.horizontalPipeLengthInMetric,
      horizontalPipeFallInMetric: v.horizontalPipeFallInMetric,
      horizontalPipeSlope: v.horizontalPipeSlope,
      horizontalPipeSizeMethod: v.horizontalPipeSizeMethod,
      verticalPipeDiameterInMetric: v.verticalPipeDiameterInMetric,
      bermHeightInMetric: v.bermHeightInMetric,
      orificePlateDiameterInMetric: v.orificePlateDiameterInMetric,
      customRiserInletHeightInMetric: v.customRiserInletHeightInMetric,
      customRiserOrificeDiameterInMetric: v.customRiserOrificeDiameterInMetric,
      customRiserNumberOfHoles: v.customRiserNumberOfHoles,
      customRiserNumberOfRows: v.customRiserNumberOfRows,
      isCustomRiserDesignUsed: v.isCustomRiserDesignUsed,
      isOrificePlateRequired: v.isOrificePlateRequired,
      todo: v.todo
    });
  }
}
