
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { PlatoField } from './plato-field.model';

export class PlatoFarm extends BaseModel {
  worksheetId: string;
  name: string;
  upperTierName: string;
  upperTierCode: string;
  geotownshipName: string;
  geotownshipId: string;
  fields: PlatoField[] = [];

  constructor(init?: Partial<PlatoFarm>) {
    super();
    this.name = this.isEn ? 'Farm A' : 'Ferme A';
    this.fields[0] = new PlatoField(init);
    Object.assign(this, init);
  }
}

