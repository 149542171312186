import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { CalculationService } from '@core/services/calculation.service';
import { Utils } from '@core/utilities/utils';
import { FormService } from '@shared/services/form.service';
import { EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';
import { MstorWorksheet } from '../model/mstor-worksheet.model';
import { ReportType } from '../model/report-type.enum';
import { MstorService } from './mstor.service';

@Injectable({ providedIn: 'root' })
export class MstorCalculationService extends CalculationService {
  constructor(
    protected formService: FormService,
    protected httpClient: HttpClient,
    private calculationService: CalculationService,
    private storageSizingService: MstorService
  ) {
    super(httpClient, formService);
  }

  get storageSystems() {
    return this.formService.f?.get('storageSystems') as UntypedFormArray;
  }

  get currentStorageSystem() {
    return this.storageSystems?.controls.find(v =>
      Utils.matchStr(v.get('id').value, this.formService.f?.get('currentStorageSystemId')?.value)
    );
  }

  calculate(calcuationType?: ReportType) {
    const model = new MstorWorksheet().toModel(this.formService.f);
    if (calcuationType === ReportType.currentStorageSystem && this.currentStorageSystem && this.currentStorageSystem.value) {
      // Only keep current storage system for calculation
      const storageSystem = model.storageSystems.find(v => Utils.matchStr(v.id, this.currentStorageSystem.get('id').value));
      model.storageSystems = [storageSystem];
    }
    this.input$.next(model);
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data => (data ? Utils.errorsomeStream(this.httpClient.post(URLs.STORAGE_SIZING_CALCULATION_ENDPOINT, data)) : EMPTY)),
      tap(res => {
        // #24106 set default material form for storage group and direct utilizations
        this.setDefaultMaterialForm(res);
        this.calculation$.next(res);
        this.storageSizingService.displayReport$ = of(true);
      })
    );
  }

  private setDefaultMaterialForm(res: any) {
    const storageSystemResponse = res.storageSizingCalculations?.find(s =>
      Utils.matchStr(s.storageSystemID, this.currentStorageSystem.get('id').value)
    );
    this.calculationService.defaultMaterialForm = storageSystemResponse?.defaultMaterialForm;
  }
}
