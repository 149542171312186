import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseComponent } from '../base.component';
import { FlagDialogComponent } from '../flag-dialog/flag-dialog.component';

@Component({
  selector: 'app-flag',
  templateUrl: './flag.component.html',
  styleUrls: ['./flag.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagComponent extends BaseComponent {

  // NOTE set this flag to true if flag is placed
  // in a form next to a form field, this will find
  // and resize the previous sibling mat-form-field to make
  // room for itself
  @Input() nextTo: string;
  @Input() data$: Observable<any>;

  ngOnInit() {
    super.ngOnInit();
    this.nextToVariableCheck();
  }

  openFlagDialog() {
    this.dialogService.instance(FlagDialogComponent, this.data$, { width: '500px' });
  }

  get flagLevelI18n$() {
    return this.data$.pipe(map(data => `flag.level.${data?.flagLevelId}.text`));
  }

  // this came from the CSS refactoring work, needs to go in the future
  private nextToVariableCheck(): void {
    if (this.nextTo !== 'form-field' && this.nextTo !== 'text' && this.nextTo !== 'form-field-with-error') {
      this.nextTo = 'not-set';
    }
  }
}
