import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Subscription } from 'rxjs';
import { MeasurementSystem } from '../../../shared/models/common/measurement-system.enum';
import { MeasurementService } from '../../../shared/services/measurement.service';
import { UnitConverterService } from '../../../shared/services/unit-converter.service';
import { MaterialForm } from '@shared/models/common/material-form.enum';

// tslint:disable-next-line: directive-selector
@Directive({ selector: '[productApplicationRate]' })
export class ProductApplicationRateDirective implements OnInit, OnDestroy {
  @Input() productApplicationRate: AbstractControl;
  @Input() fertilizerForm: MaterialForm;
  private subscription = new Subscription();

  constructor(private unitConverterService: UnitConverterService, private measurementService: MeasurementService) {}

  ngOnInit() {
    this.subscription.add(
      this.productApplicationRate.valueChanges.subscribe(v => {
        this.unitConverterService.from = this.measurementService.measurementSystem;
        this.unitConverterService.to = MeasurementSystem.Metric;
        const rate = this.productApplicationRate.disabled ? v : this.getApplicationRate(v, this.fertilizerForm);
        this.productApplicationRate.patchValue(rate, Utils.UPDATE_MODEL_ONLY);
        this.validateApplicationRate();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getApplicationRate(applicationRate, fertilizerForm) {
    return fertilizerForm === MaterialForm.Liquid
      ? this.unitConverterService.convertAppVolume(applicationRate)
      : this.unitConverterService.convertAppMass(applicationRate);
  }

  private validateApplicationRate() {
    if (this.productApplicationRate.value > 1000000) {
      this.productApplicationRate.setErrors({ max: true });
    }
  }
}
