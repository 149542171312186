<section>
    <h2 class="h2 h2--underlined-heading">
        {{ title | translate | async }}
    </h2>

    <div class="flex flex--cols-3">
        <div class="flex flex--cols-2 flex-child flex--nowrap reportContactInfo" [view]="contactViewTypeEnum.InfoOnly" [contact]="contact" [contactFlag]="contactFlag"></div>

        <div class="flex flex--cols-2 flex-child flex--nowrap reportContactInfo" [view]="contactViewTypeEnum.DetailsOnly" [contact]="contact"></div>
    </div>
</section>
