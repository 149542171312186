export enum FarmStatus {
    Owned = 0,
    Rented = 1,
    Leased = 2
}

export const LandOwnerFarmStatuses: FarmStatus[] = [
    FarmStatus.Rented,
    FarmStatus.Leased
];

export const GnfLandOwnerFarmStatuses: FarmStatus[] = [
    FarmStatus.Rented,
    FarmStatus.Leased,
    FarmStatus.Owned
];