import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { BaseModel } from '../worksheet/BaseModel';
import { FlagVo } from './flag-vo.model';

export class FlagComment extends BaseModel {
  flagType: number; // flag type id stored in worksheet.flag_comment.flag_type
  entityId: string;
  worksheetId: string;
  comment: string;

  constructor(init?: Partial<FlagComment>, calculatedFlag?: Partial<FlagVo>, worksheetId?: string) {
    super();
    Object.assign(this, init);
    if (calculatedFlag) {
      this.flagType = calculatedFlag.flagTypeId;
      this.entityId = calculatedFlag.entityId;
    }
    if (worksheetId) {
      this.worksheetId = worksheetId;
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      flagType: new UntypedFormControl(this.flagType),
      entityId: new UntypedFormControl(this.entityId),
      worksheetId: new UntypedFormControl(this.worksheetId),
      comment: new UntypedFormControl(this.comment)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new FlagComment({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      flagType: v.flagType,
      entityId: v.entityId,
      worksheetId: v.worksheetId,
    });
  }
}
