import { FieldInputType } from '@shared/models/common/field-input-type.enum';
import { Crop } from '@shared/models/worksheet/crop.model';
import { FmpFertilizerApplication } from './fmp-fertilizer-application.model';
import { FmpMaterialApplication } from './fmp-material-application.model';
import { FmpMaterialType } from './fmp-material-type.model';

export class FmpFieldInput {
    constructor(
        public index: number,
        public id: string,
        public name: string,
        public type: FieldInputType,
        public applicationDate: any,
        public createdDateTime: any,
        public N?: number,
        public P?: number,
        public K?: number,
        public app?: Crop | FmpFertilizerApplication | FmpMaterialApplication | any,
        public materialTypes?: FmpMaterialType[]) { }
}
