import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Utils } from '../../../core/utilities/utils';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { MaterialSample } from './material-sample.model';
import { OutgoingTransfer } from './outgoing-transfer.model';

export class MaterialType extends BaseModel {
  name: string;
  materialForm = 0;
  materialType = 0;
  graphAdjustPercent = 0;
  worksheetId: string;
  materialSamples = [];
  storageSystemId: string;
  outgoingTransfers = [];

  constructor(init?: Partial<any>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'New material' : 'Nouvelle matière';
    this.materialSamples[0] = new MaterialSample();
    Object.assign(this, init);
    if (this.materialSamples && this.materialSamples.length > 0) {
      this.materialSamples.map(v => (v.materialTypeId = this.id));
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      materialForm: new UntypedFormControl(this.materialForm),
      materialType: new UntypedFormControl(this.materialType),
      graphAdjustPercent: new UntypedFormControl(this.graphAdjustPercent, { validators: [Validators.min(0), Validators.max(100)] }),
      materialSamples: new UntypedFormArray(this.materialSamples.map(v => new MaterialSample(v).toForm())),
      storageSystemId: new UntypedFormControl(this.storageSystemId),
      outgoingTransfers: new UntypedFormArray(this.outgoingTransfers.map(v => new OutgoingTransfer(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const materialSamples = form.get('materialSamples') as UntypedFormArray;
    const outgoingTransfers = form.get('outgoingTransfers') as UntypedFormArray;
    return new MaterialType({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheetId,
      name: v.name,
      materialForm: v.materialForm,
      materialType: v.materialType,
      graphAdjustPercent: v.graphAdjustPercent,
      notes: v.notes,
      materialSamples: materialSamples.controls.map((w: UntypedFormGroup) => new MaterialSample().toModel(w)),
      storageSystemId: v.storageSystemId,
      outgoingTransfers: outgoingTransfers?.controls?.map((w: UntypedFormGroup) => new OutgoingTransfer().toModel(w))
    });
  }
}
