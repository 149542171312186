import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Livestock } from '@mstor/model/livestock.model';
import { AlertInputName, DEFAULT_ALERT_INPUTS } from '../common/alert-input-name.enum';
import { BuildingType } from '../common/building-type.enum';
import { LivestocksFormGroup } from './livestocks-form-group.model';

export class BuildingsFormGroup {
  private _buildings: UntypedFormArray;

  constructor(systems: UntypedFormArray) {
    this._buildings = systems;
  }

  getFormCtrlById(buildingId: string): UntypedFormGroup {
    return this._buildings?.controls?.find(v => Utils.matchStr(v?.get('id')?.value, buildingId)) as UntypedFormGroup;
  }

  getById(buildingId: string): BuildingFormGroup {
    return new BuildingFormGroup(this.getFormCtrlById(buildingId));
  }
}

export class BuildingFormGroup {
  private _building: UntypedFormGroup;

  constructor(building: UntypedFormGroup) {
    this._building = building;
  }

  isMissing(input: AlertInputName): boolean {
    switch (input) {
      case AlertInputName.UpperTierCode:
        return !this.upperTierCode?.value;
      case AlertInputName.Livestocks:
        return this.buildingType === BuildingType.Barn && !this.livestocks?.length;
      default:
        return false;
    }
  }

  hasMissingWarning(inputs?: AlertInputName[]): boolean {
    const alertInputs = inputs ? inputs : DEFAULT_ALERT_INPUTS;
    return alertInputs.filter(input => this.isMissing(input))?.length > 0;
  }

  toForm(): UntypedFormGroup {
    return this._building;
  }

  toModel(): Livestock {
    return new Livestock().toModel(this._building);
  }

  get livestocks(): LivestocksFormGroup {
    return new LivestocksFormGroup(this._building, this._building?.get('livestock') as UntypedFormArray);
  }

  get nameCtrl(): UntypedFormControl {
    return this._building?.get('name') as UntypedFormControl;
  }

  get name(): string {
    return this.nameCtrl?.value;
  }

  get buildingTypeCtrl(): UntypedFormControl {
    return this._building?.get('buildingType') as UntypedFormControl;
  }

  get buildingType(): number {
    return this.buildingTypeCtrl?.value;
  }

  get upperTierCode(): UntypedFormGroup {
    return this._building?.get('upperTierCode') as UntypedFormGroup;
  }
}
