import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MaterialCategory } from '@shared/models/common/material-category.enum';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { Utils } from '../../../core/utilities/utils';
import { BaseModel } from '../../../shared/models/worksheet/BaseModel';
import { FmpMaterialSample } from './fmp-material-sample.model';

export class FmpMaterialType extends BaseModel {
  name: string;
  materialForm = 0;
  materialType = 0;
  materialCategory = MaterialCategory.ASM;
  worksheetId: string;
  materialSamples: FmpMaterialSample[] = [];

  constructor(init?: Partial<any>) {
    super();
    this.id = this.id || Utils.uuid();
    this.name = this.isEn ? 'New material' : 'Nouvelle matière';
    this.materialSamples[0] = new FmpMaterialSample();
    Object.assign(this, init);

    if (this.materialSamples && this.materialSamples.length > 0) {
      this.materialSamples.map(v => (v.materialTypeId = this.id));
    }
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      worksheetId: new UntypedFormControl(this.worksheetId),
      name: new UntypedFormControl(this.name, [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]),
      materialForm: new UntypedFormControl(this.materialForm),
      materialType: new UntypedFormControl(this.materialType),
      materialCategory: new UntypedFormControl(this.materialCategory),
      notes: new UntypedFormControl(this.notes, [Validators.maxLength(250)]),
      materialSamples: new UntypedFormArray(this.materialSamples.map(v => new FmpMaterialSample(v).toForm()))
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const materialSamples = form.get('materialSamples') as UntypedFormArray;
    return new FmpMaterialType({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      worksheetId: v.worksheet,
      name: v.name,
      materialForm: v.materialForm,
      materialType: v.materialType,
      materialCategory: v.materialCategory,
      notes: v.notes,
      materialSamples: materialSamples.controls.map((w: UntypedFormGroup) => new FmpMaterialSample().toModel(w))
    });
  }
}
