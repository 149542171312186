import { ReportType as NasmReportType } from '@nasm/model/nasm-report-type.enum';
import { ExemptionReason } from '@nmsp/model/exemption-reason.enum';
import { ReportType as NmspReportType } from '@nmsp/model/nmsp-report-type.enum';
import { SubmissionReason } from '@nmsp/model/submission-reason.enum';
import { SubmissionType } from '@nmsp/model/submission-type.enum';
import { ConstructionMaterial } from '@shared/models/common/construction-material.enum';
import { DirectionRoofSlope } from '@shared/models/common/direction-root-slope.enum';
import { Country } from '../models/common/country.enum';
import { LanguageType } from '../models/common/language-type.enum';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { OperationType } from '../models/common/operation-type.enum';
import { Province } from '../models/common/province.enum';
import { WorksheetSubmissionReason } from '../models/common/worksheet-submission-reason.enum';

/**
 * This file is meant for storing flat business constants,
 * every variable here is a flat variable
 */
export class Constants {
  public static readonly DEFAULT_LANGUAGE_TYPE = LanguageType.English;
  public static readonly DEFAULT_MEASUREMENT_SYSTEM = MeasurementSystem.Imperial;
  public static readonly DEFAULT_PROVINCE_ID = Province.ON;
  public static readonly DEFAULT_COUNTRY_ID = Country.Canada;
  public static readonly DEFAULT_FERTILIZER_APPLIED_PRODUCT_ID = 24;
  public static readonly DEFAULT_PLATO_FERTILIZER_PRODUCT_ID = 18; // 11-52-0 MAP
  public static readonly DEFAULT_SILO_AVG_ENSILAGE_DENSITY = 865;
  public static readonly DEFAULT_SILO_PERIOD = 12;
  public static readonly DEFAULT_GNF_STORAGE_TARGET_DAYS = 60;
  public static readonly DEFAULT_STORAGE_TARGET_DAYS = 240;
  public static readonly DEFAULT_CROP_SUB_TYPE_ID = 7;
  public static readonly DEFAULT_APPLICATION_TIMING = 'Preplant';
  public static readonly DEFAULT_MATERIAL_TYPE_ID = 1;
  public static readonly DEFAULT_LIVESTOCK_SUBTYPE_ID = 5;
  public static readonly DEFAULT_LIVESTOCK_WEIGHT_AVERAGE = 700;
  public static readonly DEFAULT_NUM_OF_LIVESTOCK = 0;
  public static readonly DEFAULT_LIVESTOCK_PRODUCE_LIQUID_PERCENT = 0;
  public static readonly DEFAULT_LIVESTOCK_PRODUCE_SOLID_PERCENT = 100;
  public static readonly DEFAULT_LIVESTOCK_PRODUCE_PASTURE_PERCENT = 0;
  public static readonly DEFAULT_LIVESTOCK_AMOUNT_LIQUID_PERCENT = 0;
  public static readonly DEFAULT_LIVESTOCK_AMOUNT_SOLID_PERCENT = 0;
  public static readonly DEFAULT_LIVESTOCK_BEDDING_LIQUID_PERCENT = 0;
  public static readonly DEFAULT_LIVESTOCK_BEDDING_SOLID_PERCENT = 0;
  public static readonly DEFAULT_TRANSFER_TYPE_ID = 0;
  public static readonly DEFAULT_GNF_TRANSFER_TYPE_ID = 8;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_1_VALUE = 2;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_2_VALUE = 2;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_3_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_4_VALUE = 0;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_5_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_6_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_7_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_8_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_9_VALUE = 1;
  public static readonly DEFAULT_STORAGE_TEMPORARY_CALCULATION_10_VALUE = 1;
  public static readonly DEFAULT_SOLID_STORAGE_TYPE_ID = 1;
  public static readonly DEFAULT_CONSTRUCTION_MATERIAL_ID = 0;
  public static readonly DEFAULT_LAND_USE_TYPE_ID = 0;
  public static readonly DEFAULT_BUILDING_PERMIT_ISSUED_ANSWER_VALUE = -1;
  public static readonly DEFAULT_LIVESTOCK_MANURE_STORAGE_ANSWER_VALUE = -1;
  public static readonly DEFAULT_LIVESTOCK_MANURE_INFO_ANSWER_VALUE = -1;
  public static readonly DEFAULT_MDS_MATERIAL_TYPE_ID = -1;
  public static readonly DEFAULT_MDS_MATERIAL_SUB_TYPE_ID = -1;
  public static readonly DEFAULT_MDS_1_MATERIAL_CAPACITY_TYPE_VALUE = 1;
  public static readonly DEFAULT_MDS_STORAGE_TYPE_ID = 0;
  public static readonly DEFAULT_WORKSHEET_SUBMISSION_REASON = WorksheetSubmissionReason.ConstructBuildingOrStorage;
  public static readonly DEFAULT_FEDERAL_BUSINESS_NUMBER_EXEMPTION_REASON = ExemptionReason.CRA;
  public static readonly DEFAULT_OPERATION_TYPE = OperationType.SoleProprietorship;
  public static readonly DEFAULT_NMSP_REPORT_TYPE = NmspReportType.NutrientMgmtStrategy;
  public static readonly DEFAULT_RNM_SUBMISSION_TYPE = SubmissionType.NewSubmission;
  public static readonly DEFAULT_GNF_SUBMISSION_REASON = SubmissionReason.NewSubmission;
  public static readonly DEFAULT_NASM_REPORT_TYPE = NasmReportType.PlanApprovals;
  public static readonly DEFAULT_NASM_TYPE = 100;
  public static readonly DEFAULT_HORTICULTURAL_TYPE_ID = 0;
  public static readonly DEFAULT_CROP_TYPE_NAME_ENGLISH = 'Corn';
  public static readonly DEFAULT_CROP_SUB_TYPE_NAME_ENGLISH = 'grain';
  public static readonly DEFAULT_GHG_FERTILIZER_INCORP_ID = 6;
  public static readonly DEFAULT_GHG_MATERIAL_INCORP_ID = 6;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_SPECIES_ID = 0;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_PLANTING_AREA = 0;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_PLANTING_LENGTH = 0;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_PLANTING_ROWS = 1;
  public static readonly DEFAULT_GHG_MITIGATION_CROPPING_PRACTICE_YEARS = 20;
  public static readonly DEFAULT_GHG_MITIGATION_TILLAGE_INTENSITY = 0;
  public static readonly DEFAULT_GHG_MITIGATION_FER_REDUCTION = 0;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_PLANTING = 0;
  public static readonly DEFAULT_GHG_MITIGATION_TREE_PLANTED = false;
  public static readonly DEFAULT_GHG_LIVESTOCK_RATIO = 1.5;
  public static readonly DEFAULT_GHG_NUM_OF_STOREYS = 1;
  public static readonly DEFAULT_GHG_FEED_ADDICTIVE = 0;

  public static readonly DEFAULT_GHG_BUILDING_INSU_WALL = 0;
  public static readonly DEFAULT_GHG_BUILDING_INSU_ROOF = 0;
  public static readonly DEFAULT_GHG_BUILDING_INFIL = 0;
  public static readonly DEFAULT_GHG_BUILDING_HEATING_TYPE = 0;
  public static readonly DEFAULT_GHG_BUILDING_FUEL_TYPE = 1;
  public static readonly DEFAULT_GHG_BUILDING_TEMP_OVERRIDE = 0;
  public static readonly DEFAULT_GHG_BUILDING_HUMIDITY_SETPOINT = 0;
  public static readonly DEFAULT_GHG_HEATING_TYPE_ELECTRIC_MITIGATION = 7;
  public static readonly DEFAULT_GHG_FUEL_TYPE_ALTERNATE_MITIGATION = 6;
  public static readonly DEFAULT_GHG_HEAT_EXCHANGER_EFFICIENCY_LEVEL = 0;
  public static readonly DEFAULT_GHG_BUILDING_ROOT_SLOPE_DIRECTION = DirectionRoofSlope.NorthSouth;
  public static readonly DEFAULT_GHG_THERMAL_CURTAIN = 0;
  public static readonly DEFAULT_GHG_COVER_MATERIAL = 0;
  public static readonly DEFAULT_GHG_CONSTRUCTION_LEVEL = 0;
  public static readonly DEFAULT_GHG_CROP_TYPE = 0;
  public static readonly DEFAULT_GHG_LIGHTING = 0;
  public static readonly DEFAULT_GHG_LIGHTING_TYPE = 0;
  public static readonly DEFAULT_DRYER_TYPE = 0;
  public static readonly DEFAULT_GRAIN_DRYER_CROP_TYPE = 7;
  public static readonly DEFAULT_CONTROL_STRUCTURE_TYPE = 1;

  public static readonly DEFAULT_AGE_RUNOFF_CURVE_LAND_USE_COVER = 2;
  public static readonly DEFAULT_AGE_RUNOFF_CURVE_FIRST_PERCENTAGE = 100;
  public static readonly DEFAULT_AGE_TARGET_SIDE_SLOPE = 10;
  public static readonly DEFAULT_AGE_FREEBOARD = 0.1;
  public static readonly DEFAULT_AGE_CS_WASCOB_SIDE_SLOPE = 3;
  public static readonly DEFAULT_SOIL_TEXTURE_ID = 0;
  public static readonly DEFAULT_WATERWAY_LINING_ID = 0;
  public static readonly DEFAULT_SOIL_ERO_CROP_ID = 0;
  public static readonly DEFAULT_AGE_CS_WASCOB_MAX_FLOODING_TIME = 24;
  public static readonly DEFAULT_AGE_CS_WASCOB_BERM_TOP_WIDTH = 1.2;

  public static readonly DEFAULT_PEAK_FLOW_STORM_TYPE = 10;

  public static readonly CORN = 7;
  public static readonly NITROGEN = 1;
  public static readonly NUTRIENT_VALUE_SOURCE_DATABANK = 1;
  public static readonly MINIMUM_PROCESSABLE_RATE = 0.01;
  public static readonly COVER_CROP_TYPE_NAME_ENGLISH = 'Cover Crop';
  public static readonly COVER_CROP_TYPE_ID = 75;
  public static readonly CORN_CROP_TYPE_ID = 7;
  public static readonly GRAIN_CROP_SUB_TYPE_ID = 7;
  public static readonly YIELD_DECIMALS = 1;
  public static readonly SUMMARY_DECIMALS = 0;
  public static readonly ZERO_DECIMAL_PLACES = 0;
  public static readonly NMSP_DEFAULT_FLAG_SUMMARY_KEY = 'flagLevels';
  public static readonly NMSP_STRATEGY_FLAG_SUMMARY_KEY = 'flagLevelsNmsOnly';
  public static readonly GNF_SOURCE_MATERIAL_GEN = 19;
  public static readonly GNF_SOURCE_MATERIAL_TRANS = 21;
  public static readonly CROP_NUTRIENT_PAGE_FROM_CREATE_PLAN = 'create-plan';
  public static readonly PAGE_FROM_HOME = 'home';
  public static readonly CROP_NUTRIENT_PAGE_FROM_EXISTING_PAN = 'existing-plan';
  public static readonly PHOSPHORUS_NUTRIENT_CODE = 'P205';
  public static readonly OUTGOING_TRANSFER_DEFAULT_FREQUENCY_PERIOD = 1;
  public static readonly YARD_ROOF_RUNOFF_TYPE = 1;
  public static readonly WALL_HEIGHT_STORAGE_DIMENSION_ID = 7;
  public static readonly SILO_SEEPAGE_SOURCE_TYPE_ID = 17;
  public static readonly MILKING_CENTRE_WASHWATER_SOURCE_TYPE_ID = 0;
  public static readonly ANAEROBIC_DIGESTOR_TREATMENT_TYPE_ID = 10;
  public static readonly TEMPORARY_STORAGE_DAYS_MIN = 0;
  public static readonly TEMPORARY_STORAGE_DAYS_MAX = 300;
  public static readonly DEFAULT_CS_NUM_OF_UNITS = 1;
}
