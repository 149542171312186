import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Utils } from '@core/utilities/utils';
import { ArrayConstants } from '@shared/constants/array-constants';
import { BaseComponent } from '../base.component';
import { BarIndicator } from './bar-indicator.model';
import { Subscription } from 'rxjs';

const MIN_POSITION = 0;
const MAX_DIAL_POSITION = 99.75;
const SLIDER_PIXEL_DIFF_DELTA = -1;

@Component({
  selector: 'app-horizontal-gradient-bar',
  templateUrl: './horizontal-gradient-bar.component.html',
  styleUrls: ['./horizontal-gradient-bar.component.scss']
})
export class HorizontalGradientBarComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() leftLabel: string;
  @Input() rightLabel: string;
  @Input() topIndicator: BarIndicator;
  @Input() bottomIndicator: BarIndicator;
  @Input() gradients: string[] = ArrayConstants.DEFAULT_GRADIENTS;
  @Input() gradientStops: string[] = ArrayConstants.DEFAULT_GRADIENT_STOPS;

  langSub: Subscription;
  lang: string = 'en';

  ngOnInit(): void {
    super.ngOnInit();
    this.langSub = this.lang$.subscribe(l => {
      this.lang = l;
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.langSub.unsubscribe();
  }

  get gradientBackground(): string {
    const gradientText = Array.from({ length: this.gradients.length }, (_, i) => {
      return this.gradients[i] + (this.gradientStops[i] ? ` ${this.gradientStops[i]}` : '');
    });
    return `background-image: linear-gradient(to right, ${gradientText.join(',')})`;
  }

  labelClass(value: number | null): string {
    const val = Number(Utils.isValidNumber(value) ? value : 0)
      .toString()
      .replace('.', '');
    return `label--${val.length}-digits`;
  }

  dialPosition(value: number | null): string {
    const pos = Math.min(Utils.isValidNumber(value) ? value : MIN_POSITION, MAX_DIAL_POSITION);
    if (pos >= 99) {
      return '99.75%';
    }
    return `${pos}%`;
  }

  sliderPosition(value: number | null): string {
    const pos = Utils.isValidNumber(value) ? Math.max(value + SLIDER_PIXEL_DIFF_DELTA, MIN_POSITION) : MIN_POSITION;
    return `${pos}%`;
  }
}
