import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { Constants } from '../../constants/constants';
import { ContactType } from './contact-type.enum';

export class ContactInformation extends BaseModel {
  address1: string;
  address2: string;
  certificationNumber: string;
  city: string;
  contactType: ContactType;
  country = Constants.DEFAULT_COUNTRY_ID;
  doesNotUseEmail: true;
  email: string;
  jobTitle: string;
  nameCompany: string;
  nameFirst: string;
  nameLast: string;
  parentId: string;
  parentType: string;
  phone1: string;
  phone2: string;
  postalCode: string;
  province = Constants.DEFAULT_PROVINCE_ID;

  constructor(init?: Partial<ContactInformation>) {
    super();
    Object.assign(this, init);
    this.id = this.id || Utils.uuid();
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      address1: new UntypedFormControl(this.address1, { validators: [Validators.maxLength(50)] }),
      address2: new UntypedFormControl(this.address2, { validators: [Validators.maxLength(50)] }),
      certificationNumber: new UntypedFormControl(this.certificationNumber, { validators: [Validators.maxLength(20)] }),
      city: new UntypedFormControl(this.city, { validators: [Validators.maxLength(50)] }),
      contactType: new UntypedFormControl(this.contactType),
      country: new UntypedFormControl(this.country, { validators: [Validators.maxLength(50)] }),
      doesNotUseEmail: new UntypedFormControl(this.doesNotUseEmail),
      email: new UntypedFormControl(this.email, {
        validators: [
          Validators.maxLength(50)
          // , Validators.pattern(/[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/)
        ]
      }),
      jobTitle: new UntypedFormControl(this.jobTitle, { validators: [Validators.maxLength(50)] }),
      nameCompany: new UntypedFormControl(this.nameCompany, { validators: [Validators.maxLength(60)] }),
      nameFirst: new UntypedFormControl(this.nameFirst, { validators: [Validators.maxLength(50)] }),
      nameLast: new UntypedFormControl(this.nameLast, { validators: [Validators.maxLength(50)] }),
      parentId: new UntypedFormControl(this.parentId),
      parentType: new UntypedFormControl(this.parentType),
      phone1: new UntypedFormControl(this.phone1, {
        validators: [
          Validators.maxLength(20)
          // , Validators.pattern(/^\d{3}[-]\d{3}[-]\d{4}(\s?(x)(\s?\d+))?$/)
        ]
      }),
      phone2: new UntypedFormControl(this.phone2),
      postalCode: new UntypedFormControl(this.postalCode, {
        validators: [
          Validators.maxLength(7)
          // , Validators.pattern(/^([A-Za-z]\d[A-Za-z][ ]?\d[A-Za-z]\d|\d{5})$/)
        ]
      }),
      province: new UntypedFormControl(this.province)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new ContactInformation({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      address1: v.address1,
      address2: v.address2,
      certificationNumber: v.certificationNumber,
      city: v.city,
      contactType: v.contactType,
      country: v.country,
      doesNotUseEmail: v.doesNotUseEmail,
      email: v.email,
      jobTitle: v.jobTitle,
      nameCompany: v.nameCompany,
      nameFirst: v.nameFirst,
      nameLast: v.nameLast,
      parentId: v.parentId,
      parentType: v.parentType,
      phone1: v.phone1,
      phone2: v.phone2,
      postalCode: v.postalCode,
      province: v.province
    });
  }
}
