import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Utils } from '@core/utilities/utils';
import { ConvertWorksheetComponent } from '@home/secure-home/convert-worksheet/convert-worksheet.component';
import { CopyWorksheetDialogComponent } from '@home/secure-home/copy-worksheet-dialog/copy-worksheet-dialog.component';
import { CreateNewDialogComponent } from '@home/secure-home/create-new-dialog.component';
import { RenameWorksheetComponent } from '@home/secure-home/rename-worksheet.component';
import { ConfirmationDialogComponent } from '@shared/components/confirmation-dialog/confirmation-dialog.component';
import { MapConstants } from '@shared/constants/map-constants';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { WorksheetKeys } from '@shared/models/common/worksheet-keys.enum';
import { DbUser } from '@shared/models/user/db-user';
import { Preference } from '@shared/models/user/preference';
import { Worksheet } from '@shared/models/worksheet/Worksheet';
import { CacheService } from '@shared/services/cache.service';
import { DialogService } from '@shared/services/dialog.service';
import { LanguageService } from '@shared/services/language.service';
import { MeasurementService } from '@shared/services/measurement.service';
import { UserService } from '@shared/services/user.service';
import { WorksheetClient } from '@shared/services/worksheet.client';
import { EMPTY, forkJoin, Observable, of, switchMap, tap } from 'rxjs';
import { WorksheetFactory } from './worksheet.factory';

@Injectable({
  providedIn: 'root'
})
export class WorksheetService {
  constructor(
    private router: Router,
    private dialogService: DialogService,
    private cache: CacheService,
    private worksheetClient: WorksheetClient,
    private worksheetFactory: WorksheetFactory,
    private userService: UserService,
    private languageService: LanguageService,
    private measurementService: MeasurementService
  ) {}

  public navigateTo(worksheet: Worksheet) {
    const route = this.cache.worksheetTypes.find(t => Utils.matchStr(t.id, worksheet.worksheetTypeId)).typeName;
    this.router.navigate(['/' + route], { queryParams: { worksheetId: worksheet.id } });
  }

  create() {
    const config = {
      defaultWorksheetTypeId: this.cache.user.worksheetTypeId,
      title: 'dialog.title.createnew',
      btnCancel: 'dialog.action.cancel',
      btnOk: 'dialog.action.ok',
      displayBtnOk: false
    };
    return this.dialogService
      .instance(CreateNewDialogComponent, config, { width: '500px', height: '500px', disableClose: true })
      .afterClosed()
      .pipe(
        switchMap(type => (type ? forkJoin(this.createCalls(type as any)) : of([undefined, undefined]))),
        tap(([worksheet, _]) => {
          if (worksheet) {
            this.navigateTo(worksheet);
          }
        })
      );
  }

  delete(worksheetId: string) {
    return this.dialogService
      .instance(
        ConfirmationDialogComponent,
        {
          title: 'dialog.title.delete',
          message: 'dialog.message.delete',
          btnCancel: 'dialog.action.cancel',
          btnOk: 'dialog.action.delete',
          btnOkStyle: 'button--red-primary',
          displayBtnOk: true
        },
        { disableClose: true }
      )
      .afterClosed()
      .pipe(switchMap(ok => (ok ? this.worksheetClient.delete(worksheetId) : EMPTY)));
  }

  copy(src: Worksheet) {
    const toWorksheetType = (worksheetTypeId: string): string =>
      this.cache.worksheetTypes.find(t => t.id.toLowerCase() === worksheetTypeId?.toLowerCase())?.typeName;
    const key = toWorksheetType(src.worksheetTypeId);
    const worksheet = this.worksheetFactory.instance(key as WorksheetKeys);
    worksheet.name = src.name;

    const config = {
      worksheet: worksheet,
      title: 'home.auth.text.copy',
      btnCancel: 'dialog.action.cancel',
      btnOk: 'dialog.action.copy'
    };
    return this.dialogService
      .instance(CopyWorksheetDialogComponent, config, { with: '500px', height: '500px', disableClose: true })
      .afterClosed()
      .pipe(
        switchMap((worksheet: Worksheet) => (worksheet ? forkJoin(this.copyCalls(worksheet, src)) : of([undefined, undefined]))),
        tap(([worksheet, _]) => {
          if (worksheet) {
            this.navigateTo(worksheet);
          }
        })
      );
  }

  convert(src: Worksheet, key: WorksheetKeys) {
    const newWorksheet = { ...this.worksheetFactory.instance(key), name: src.name };
    const title = this.conversionTitle(key);
    const config = {
      worksheet: newWorksheet,
      title,
      btnCancel: 'dialog.action.cancel',
      btnOk: 'dialog.action.ok',
      displayBtnOk: false
    };
    return this.dialogService
      .instance(ConvertWorksheetComponent, config, { disableClose: true })
      .afterClosed()
      .pipe(
        switchMap(dest => (dest ? forkJoin(this.conversionCalls(src, dest as Worksheet)) : of([undefined, undefined]))),
        tap(([worksheet, _]) => {
          if (worksheet) {
            this.navigateTo(worksheet);
          }
        })
      );
  }

  rename(worksheet: Worksheet) {
    return this.dialogService
      .instance(
        RenameWorksheetComponent,
        {
          worksheet,
          title: 'dialog.title.rename',
          btnCancel: 'dialog.action.cancel',
          btnOk: 'dialog.action.apply',
          displayBtnOk: true
        },
        { disableClose: true }
      )
      .afterClosed();
  }

  export(worksheet: Worksheet) {
    return this.worksheetClient
      .export(worksheet.id)
      .pipe(tap(res => this.download(res, 'application/json', this.getExportXmlFileName(worksheet.name))));
  }

  saveLocal(azureUser: string): Observable<any> {
    const worksheets: Worksheet[] = this.cache.worksheets;
    return this.userService.byEmail(azureUser).pipe(
      switchMap(user => {
        if (!user) {
          return this.userService.save(azureUser);
        }
        return of(user);
      }),
      tap(user => {
        this.setLocalUser(user);
        this.setLocalPreference(user);
        this.publishPreference(user);
      }),
      switchMap((user: DbUser) => {
        return worksheets.length > 0
          ? (forkJoin(
              worksheets.map(worksheet => {
                worksheet.userAccountId = user ? user.id : '';
                return Utils.errorsomeStream(this.worksheetClient.save(worksheet));
              })
            ) as Observable<any>)
          : of({});
      })
    );
  }

  private publishPreference(user: DbUser) {
    this.languageService.languageType = user.langPreference === 'en' ? LanguageType.English : LanguageType.French;
    this.measurementService.measurementSystem = MeasurementSystem[user.measureSystemName];
  }

  private setLocalPreference(user: DbUser) {
    const preference = new Preference(
      user.langPreference === 'en' ? LanguageType.English : LanguageType.French,
      MeasurementSystem[user.measureSystemName]
    );
    this.cache.preference = preference;
  }

  private setLocalUser(user: DbUser) {
    this.cache.user = user;
  }

  private getExportXmlFileName(worksheetName: string) {
    const defaultFileName = 'worksheet.json';
    let fileName = worksheetName ? worksheetName.replace(/[^0-9a-z( )\-_]/gi, '') + '.json' : defaultFileName;
    return fileName ? fileName : defaultFileName;
  }

  private download(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  private conversionTitle(key: WorksheetKeys) {
    return MapConstants.WORKSHEET_CONVERSION_TITLE[key];
  }

  private createCalls(key: WorksheetKeys) {
    const worksheet = this.worksheetFactory.instance(key);
    const user = this.cache.user;
    user.worksheetTypeId = worksheet.worksheetTypeId;
    this.cache.user = user;
    return [this.worksheetClient.save(worksheet), this.userService.update(user)];
  }

  private conversionCalls(src: Worksheet, dest: Worksheet) {
    const user = this.cache.user;
    user.worksheetTypeId = dest.worksheetTypeId;
    this.cache.user = user;
    return [this.worksheetClient.convert(src, dest), this.userService.update(user)];
  }

  private copyCalls(worksheet: Worksheet, srcWorksheet: Worksheet): [Observable<Worksheet>, Observable<DbUser>] {
    const user = this.cache.user;
    user.worksheetTypeId = worksheet.worksheetTypeId;
    this.cache.user = user;
    return [this.clone(worksheet, srcWorksheet.id, srcWorksheet.worksheetTypeId), this.userService.update(user)];
  }

  private clone(worksheet: Worksheet, srcWorksheetId: string, srcWorksheetType: string): Observable<Worksheet> {
    if (!worksheet || !srcWorksheetId || !srcWorksheetType) {
      return of(undefined);
    }
    return this.worksheetClient.copy(worksheet, srcWorksheetId);
  }
}
