import { ContourCalculationType } from '@ag-erosion/enum/contour-calculation-type.enum';
import { GeotextileUnderlayType } from '@ag-erosion/enum/geotextile-underlay-type.enum';
import { GradeControlType } from '@ag-erosion/enum/grade-control-type.enum';
import { OutfallType } from '@ag-erosion/enum/outfall-type.enum';
import { SpillwayType } from '@ag-erosion/enum/spillway-type.enum';
import { TillagePractice } from '@ag-erosion/enum/tillage-practice.enum';
import { TypeOfJoint } from '@ag-erosion/enum/type-of-joint.enum';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { ContourStage } from './contour-stage.model';
import { DropPipe } from './drop-pipe.model';
import { IsbashCurve } from './is-bash-curve.model';

export class ControlStructure extends BaseModel {
  name: string;
  notes: string;
  structureType: number;
  peakFlowStormType = Constants.DEFAULT_PEAK_FLOW_STORM_TYPE;
  isMultipleStructure = false;
  isUppermostStructure = true;
  inputDescription: string;
  inputTopWidthInMetric: number;
  inputDepthInMetric: number;
  outputDescription: string;
  outputTopWidthInMetric: number;
  outputDepthInMetric: number;
  soilTexture = Constants.DEFAULT_SOIL_TEXTURE_ID;
  waterwayLining = Constants.DEFAULT_WATERWAY_LINING_ID;
  useSideSlopeTarget = false;
  sideSlopeTarget = Constants.DEFAULT_AGE_TARGET_SIDE_SLOPE;
  dimensionLengthInMetric: number;
  dimensionElevationInMetric: number;
  dimensionFreeboardInMetric = Constants.DEFAULT_AGE_FREEBOARD;
  dimensionTopWidthInMetric: number;
  dimensionBottomWidthInMetric: number;
  dimensionDepthInMetric: number;
  dimensionChuteSlope: number;
  dimensionSideSlope: number;
  apronEntranceInMetric: number;
  apronExitInMetric: number;
  weirWidthInMetric: number;
  weirHeightInMetric: number;
  rockRiprapDepthInMetric: number;
  rockRiprapAdditionalInMetric: number;
  geotextileType = GeotextileUnderlayType.NonWoven;
  geotextileAreaInMetric: number;
  numberOfUnits = Constants.DEFAULT_CS_NUM_OF_UNITS;
  isAntiSeepageCollarRequired = false;
  isInletGrateRequired = false;
  inletGrateType: number;
  inletGrateBarSpacingInMetric: number;
  gradeControlType = GradeControlType.GabionBasket;
  outletPipeJointType = TypeOfJoint.Butt;
  outletPipeOutfallType = OutfallType.Flush;
  outletPipeDiameterInMetric: number;
  outletPipeMinimumInMetric: number;
  outletPipeCantileverInMetric: number;
  isSoilErosionOverride = false;
  soilErosionInMetric: number;
  cropId = Constants.DEFAULT_SOIL_ERO_CROP_ID;
  tillagePracticeId = TillagePractice.UpDownSlope;
  soilErosionSlope: number;
  volumeCalculationType = ContourCalculationType.VolumeFactorMethod;
  maximumFloodingTime = Constants.DEFAULT_AGE_CS_WASCOB_MAX_FLOODING_TIME;
  spillwayType = SpillwayType.GrassLined;
  spillwayNotchDepthInMetric: number;
  bermLengthInMetric: number;
  bermTopWidthInMetric = Constants.DEFAULT_AGE_CS_WASCOB_BERM_TOP_WIDTH;
  bermSideSlope = Constants.DEFAULT_AGE_CS_WASCOB_SIDE_SLOPE;
  pondSlope: number;
  pondSideSlopeLeft: number;
  pondSideSlopeRight: number;
  pondWidthInMetric: number;
  inputBottomWidthInMetric: number;
  inputSideSlope: number;
  inputChannelSlope: number;
  inputCoefficientOfRoughness: number;
  outputBottomWidthInMetric: number;
  outputSideSlope: number;
  outputChannelSlope: number;
  outputCoefficientOfRoughness: number;
  rockRiprapAngularity: number;
  costRock: number;
  costGeotextile: number;
  costExcavation: number;
  costEarthFill: number;
  costSeeding: number;
  worksheetId: string;
  watershedId: string;
  upperStructureId: string;
  dropPipe: DropPipe;
  isbashCurve: IsbashCurve;
  contourStages: ContourStage[];
  todo: string;

  constructor(init?: Partial<ControlStructure>) {
    super();
    this.name = this.isEn ? 'New structure' : 'Nouvelle structure';
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(50), Validators.minLength(3), ValidationFunctions.empty]
      }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      structureType: new UntypedFormControl(this.structureType),
      peakFlowStormType: new UntypedFormControl(this.peakFlowStormType),
      isMultipleStructure: new UntypedFormControl(this.isMultipleStructure),
      isUppermostStructure: new UntypedFormControl(this.isUppermostStructure),
      inputDescription: new UntypedFormControl(this.inputDescription, {
        validators: [Validators.maxLength(50)]
      }),
      inputTopWidthInMetric: new UntypedFormControl(this.inputTopWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      inputDepthInMetric: new UntypedFormControl(this.inputDepthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      outputDescription: new UntypedFormControl(this.outputDescription, {
        validators: [Validators.maxLength(50)]
      }),
      outputTopWidthInMetric: new UntypedFormControl(this.outputTopWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      outputDepthInMetric: new UntypedFormControl(this.outputDepthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      soilTexture: new UntypedFormControl(this.soilTexture),
      waterwayLining: new UntypedFormControl(this.waterwayLining),
      useSideSlopeTarget: new UntypedFormControl(this.useSideSlopeTarget),
      sideSlopeTarget: new UntypedFormControl(this.sideSlopeTarget),
      dimensionLengthInMetric: new UntypedFormControl(this.dimensionLengthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionElevationInMetric: new UntypedFormControl(this.dimensionElevationInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionFreeboardInMetric: new UntypedFormControl(this.dimensionFreeboardInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionTopWidthInMetric: new UntypedFormControl(this.dimensionTopWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      dimensionBottomWidthInMetric: new UntypedFormControl(this.dimensionBottomWidthInMetric),
      dimensionDepthInMetric: new UntypedFormControl(this.dimensionDepthInMetric),
      dimensionChuteSlope: new UntypedFormControl(this.dimensionChuteSlope),
      dimensionSideSlope: new UntypedFormControl(this.dimensionSideSlope),
      apronEntranceInMetric: new UntypedFormControl(this.apronEntranceInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      apronExitInMetric: new UntypedFormControl(this.apronExitInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      weirWidthInMetric: new UntypedFormControl(this.weirWidthInMetric),
      weirHeightInMetric: new UntypedFormControl(this.weirHeightInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      rockRiprapDepthInMetric: new UntypedFormControl(this.rockRiprapDepthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      rockRiprapAdditionalInMetric: new UntypedFormControl(this.rockRiprapAdditionalInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      geotextileType: new UntypedFormControl(this.geotextileType),
      geotextileAreaInMetric: new UntypedFormControl(this.geotextileAreaInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      numberOfUnits: new UntypedFormControl(this.numberOfUnits, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      isAntiSeepageCollarRequired: new UntypedFormControl(this.isAntiSeepageCollarRequired),
      isInletGrateRequired: new UntypedFormControl(this.isInletGrateRequired),
      inletGrateType: new UntypedFormControl(this.inletGrateType),
      inletGrateBarSpacingInMetric: new UntypedFormControl(this.inletGrateBarSpacingInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      gradeControlType: new UntypedFormControl(this.gradeControlType),
      outletPipeJointType: new UntypedFormControl(this.outletPipeJointType),
      outletPipeOutfallType: new UntypedFormControl(this.outletPipeOutfallType),
      outletPipeDiameterInMetric: new UntypedFormControl(this.outletPipeDiameterInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      outletPipeMinimumInMetric: new UntypedFormControl(this.outletPipeMinimumInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      outletPipeCantileverInMetric: new UntypedFormControl(this.outletPipeCantileverInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      isSoilErosionOverride: new UntypedFormControl(this.isSoilErosionOverride),
      soilErosionInMetric: new UntypedFormControl(this.soilErosionInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      cropId: new UntypedFormControl(this.cropId),
      tillagePracticeId: new UntypedFormControl(this.tillagePracticeId),
      soilErosionSlope: new UntypedFormControl(this.soilErosionSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      volumeCalculationType: new UntypedFormControl(this.volumeCalculationType),
      maximumFloodingTime: new UntypedFormControl(this.maximumFloodingTime, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      spillwayType: new UntypedFormControl(this.spillwayType),
      spillwayNotchDepthInMetric: new UntypedFormControl(this.spillwayNotchDepthInMetric),
      bermLengthInMetric: new UntypedFormControl(this.bermLengthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      bermTopWidthInMetric: new UntypedFormControl(this.bermTopWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      bermSideSlope: new UntypedFormControl(this.bermSideSlope),
      pondSlope: new UntypedFormControl(this.pondSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      pondSideSlopeLeft: new UntypedFormControl(this.pondSideSlopeLeft, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      pondSideSlopeRight: new UntypedFormControl(this.pondSideSlopeRight, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      pondWidthInMetric: new UntypedFormControl(this.pondWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      inputBottomWidthInMetric: new UntypedFormControl(this.inputBottomWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      inputSideSlope: new UntypedFormControl(this.inputSideSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      inputChannelSlope: new UntypedFormControl(this.inputChannelSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      inputCoefficientOfRoughness: new UntypedFormControl(this.inputCoefficientOfRoughness, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      outputBottomWidthInMetric: new UntypedFormControl(this.outputBottomWidthInMetric, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      outputSideSlope: new UntypedFormControl(this.outputSideSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      outputChannelSlope: new UntypedFormControl(this.outputChannelSlope, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      outputCoefficientOfRoughness: new UntypedFormControl(this.outputCoefficientOfRoughness, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      rockRiprapAngularity: new UntypedFormControl(this.rockRiprapAngularity),
      costRock: new UntypedFormControl(this.costRock, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      costGeotextile: new UntypedFormControl(this.costGeotextile, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      costExcavation: new UntypedFormControl(this.costExcavation, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      costEarthFill: new UntypedFormControl(this.costEarthFill, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      costSeeding: new UntypedFormControl(this.costSeeding, {
        validators: [Validators.min(0), Validators.max(1000000)]
      }),
      worksheetId: new UntypedFormControl(this.worksheetId),
      watershedId: new UntypedFormControl(this.watershedId),
      upperStructureId: new UntypedFormControl(this.upperStructureId),
      dropPipe: new DropPipe(this.dropPipe).toForm(),
      isbashCurve: new IsbashCurve(this.isbashCurve).toForm(),
      contourStages: new UntypedFormArray(this.contourStages?.length ? this.contourStages?.map(x => new ContourStage(x).toForm()) : []),
      todo: new UntypedFormControl(this.todo)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const dropPipe = form.get('dropPipe') as UntypedFormGroup;
    const isbashCurve = form.get('isbashCurve') as UntypedFormGroup;
    const contourStages = form.get('contourStages') as UntypedFormArray;
    return new ControlStructure({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      notes: v.notes,
      structureType: v.structureType,
      peakFlowStormType: v.peakFlowStormType,
      isMultipleStructure: v.isMultipleStructure,
      isUppermostStructure: v.isUppermostStructure,
      inputDescription: v.inputDescription,
      inputTopWidthInMetric: v.inputTopWidthInMetric,
      inputDepthInMetric: v.inputDepthInMetric,
      outputDescription: v.outputDescription,
      outputTopWidthInMetric: v.outputTopWidthInMetric,
      outputDepthInMetric: v.outputDepthInMetric,
      soilTexture: v.soilTexture,
      waterwayLining: v.waterwayLining,
      useSideSlopeTarget: v.useSideSlopeTarget,
      sideSlopeTarget: v.sideSlopeTarget,
      dimensionLengthInMetric: v.dimensionLengthInMetric,
      dimensionElevationInMetric: v.dimensionElevationInMetric,
      dimensionFreeboardInMetric: v.dimensionFreeboardInMetric,
      dimensionTopWidthInMetric: v.dimensionTopWidthInMetric,
      dimensionBottomWidthInMetric: v.dimensionBottomWidthInMetric,
      dimensionDepthInMetric: v.dimensionDepthInMetric,
      dimensionChuteSlope: v.dimensionChuteSlope,
      dimensionSideSlope: v.dimensionSideSlope,
      apronEntranceInMetric: v.apronEntranceInMetric,
      apronExitInMetric: v.apronExitInMetric,
      weirWidthInMetric: v.weirWidthInMetric,
      weirHeightInMetric: v.weirHeightInMetric,
      rockRiprapDepthInMetric: v.rockRiprapDepthInMetric,
      rockRiprapAdditionalInMetric: v.rockRiprapAdditionalInMetric,
      geotextileType: v.geotextileType,
      geotextileAreaInMetric: v.geotextileAreaInMetric,
      numberOfUnits: v.numberOfUnits,
      isAntiSeepageCollarRequired: v.isAntiSeepageCollarRequired,
      isInletGrateRequired: v.isInletGrateRequired,
      inletGrateType: v.inletGrateType,
      inletGrateBarSpacingInMetric: v.inletGrateBarSpacingInMetric,
      gradeControlType: v.gradeControlType,
      outletPipeJointType: v.outletPipeJointType,
      outletPipeOutfallType: v.outletPipeOutfallType,
      outletPipeDiameterInMetric: v.outletPipeDiameterInMetric,
      outletPipeMinimumInMetric: v.outletPipeMinimumInMetric,
      outletPipeCantileverInMetric: v.outletPipeCantileverInMetric,
      isSoilErosionOverride: v.isSoilErosionOverride,
      soilErosionInMetric: v.soilErosionInMetric,
      cropId: v.cropId,
      tillagePracticeId: v.tillagePracticeId,
      soilErosionSlope: v.soilErosionSlope,
      volumeCalculationType: v.volumeCalculationType,
      maximumFloodingTime: v.maximumFloodingTime,
      spillwayType: v.spillwayType,
      spillwayNotchDepthInMetric: v.spillwayNotchDepthInMetric,
      bermLengthInMetric: v.bermLengthInMetric,
      bermTopWidthInMetric: v.bermTopWidthInMetric,
      bermSideSlope: v.bermSideSlope,
      pondSlope: v.pondSlope,
      pondSideSlopeLeft: v.pondSideSlopeLeft,
      pondSideSlopeRight: v.pondSideSlopeRight,
      pondWidthInMetric: v.pondWidthInMetric,
      inputBottomWidthInMetric: v.inputBottomWidthInMetric,
      inputSideSlope: v.inputSideSlope,
      inputChannelSlope: v.inputChannelSlope,
      inputCoefficientOfRoughness: v.inputCoefficientOfRoughness,
      outputBottomWidthInMetric: v.outputBottomWidthInMetric,
      outputSideSlope: v.outputSideSlope,
      outputChannelSlope: v.outputChannelSlope,
      outputCoefficientOfRoughness: v.outputCoefficientOfRoughness,
      rockRiprapAngularity: v.rockRiprapAngularity,
      costRock: v.costRock,
      costGeotextile: v.costGeotextile,
      costExcavation: v.costExcavation,
      costEarthFill: v.costEarthFill,
      costSeeding: v.costSeeding,
      worksheetId: v.worksheetId,
      watershedId: v.watershedId,
      upperStructureId: v.upperStructureId,
      dropPipe: new DropPipe(dropPipe.getRawValue()),
      isbashCurve: new IsbashCurve(isbashCurve.getRawValue()),
      contourStages: contourStages.controls.map((w: UntypedFormGroup) => new ContourStage().toModel(w)),
      todo: v.todo
    });
  }
}
