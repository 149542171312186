import { WorksheetEconomic } from '@shared/models/common/worksheet-economic.model';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { MaterialType } from './material-type.model';
import { OrganicAmendmentFarm } from './organic-amendment-farm.model';

export class OrganicAmendmentWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.MATERIAL_APPLICATION;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  farms: OrganicAmendmentFarm[] = [];
  materialTypes: MaterialType[] = [];
  isLocal = true;
  worksheetEconomic: WorksheetEconomic;

  constructor(init?: Partial<OrganicAmendmentWorksheet>) {
    super();
    this.name = this.isEn ? 'Organic Amendment 1' : 'Amendement organique 1';
    this.farms[0] = new OrganicAmendmentFarm(init);
    this.materialTypes[0] = new MaterialType(init);
    Object.assign(this, init);
  }
}
