import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { URLs } from '@core/conf/urls';
import { FormService } from '@shared/services/form.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ContactInformation } from '../../../shared/models/common/contact-information.model';
import { NmspWorksheet } from '../model/nmsp-worksheet.model';

@Injectable({ providedIn: 'root' })
export class NmspService {

  displayReport$ = of(false);

  constructor(private httpClient: HttpClient,
    private formService: FormService) { }

  get(id: string): Observable<NmspWorksheet> {
    return this.httpClient.get(`${URLs.NUTRIENT_MGMT_ENDPOINT}/${id}`) as Observable<NmspWorksheet>;
  }

  save(worksheet: NmspWorksheet): Observable<any> {
    return this.httpClient.post(URLs.NUTRIENT_MGMT_ENDPOINT, worksheet);
  }

  updateWorksheetEconomics(worksheet: NmspWorksheet): Observable<any> {
    const url = `${URLs.NUTRIENT_MGMT_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    return this.httpClient.put(url, worksheet, { params });
  }

  update(worksheet: any): Observable<any> {
    return this._update(worksheet);
  }

  updateWorksheetFlat(worksheet: any): Observable<any> {
    return this._update(worksheet, true);
  }

  private _update(worksheet: any, ignoreChildren: boolean = false): Observable<any> {
    const url = `${URLs.NUTRIENT_MGMT_ENDPOINT}/${worksheet.id}`;
    let params = new HttpParams();
    if (ignoreChildren) {
      params = params.append('ignore', 'child-elements');
    }
    return this.httpClient.put(url, worksheet, { params }).pipe(tap((res: any) => {
      this.contacts.clear();
      res?.contacts?.forEach(contact => {
        this.contacts.push(new ContactInformation(contact).toForm());
      });
    }));
  }

  /**
   * Since there is not CRUD service for contact information, refresh the worksheet contact list
   * based on the worksheet update result.
   */

  get contacts(): UntypedFormArray {
    return this.f.get('contacts') as UntypedFormArray;
  }

  get f(): UntypedFormGroup {
    return this.formService?.f;
  }
}
