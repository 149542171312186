import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';

export class BuildingMonth extends BaseModel {
  monthId: number;
  isEmpty = false;
  isLightingUsed = true;
  lightingTimeMaximum: number;
  lightingTimeOn: number;
  temperatureSetpointDay: number;
  temperatureSetpointNight: number;
  relativeHumiditySetpoint: number;
  buildingId: string;

  constructor(init?: Partial<BuildingMonth>) {
    super();
    this.id = Utils.uuid();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      monthId: new UntypedFormControl(this.monthId),
      isEmpty: new UntypedFormControl(this.isEmpty),
      isLightingUsed: new UntypedFormControl(this.isLightingUsed),
      lightingTimeMaximum: new UntypedFormControl(this.lightingTimeMaximum, {
        validators: [Validators.min(0), Validators.max(24)]
      }),
      lightingTimeOn: new UntypedFormControl(this.lightingTimeOn, {
        validators: [Validators.min(0), Validators.max(24)]
      }),
      temperatureSetpointDay: new UntypedFormControl(this.temperatureSetpointDay, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      temperatureSetpointNight: new UntypedFormControl(this.temperatureSetpointNight, {
        validators: [Validators.min(-100), Validators.max(150)]
      }),
      relativeHumiditySetpoint: new UntypedFormControl(this.relativeHumiditySetpoint, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      buildingId: new UntypedFormControl(this.buildingId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new BuildingMonth({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      monthId: v.monthId,
      isEmpty: v.isEmpty,
      isLightingUsed: v.isLightingUsed,
      lightingTimeMaximum: v.lightingTimeMaximum,
      lightingTimeOn: v.lightingTimeOn,
      temperatureSetpointDay: v.temperatureSetpointDay,
      temperatureSetpointNight: v.temperatureSetpointNight,
      relativeHumiditySetpoint: v.relativeHumiditySetpoint,
      buildingId: v.buildingId
    });
  }
}
