import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FlagComment } from '../models/flag/flag-comment.model';
@Injectable({
  providedIn: 'root'
})
export class SharedFlagCommentService {

  input$ = new BehaviorSubject<any>(undefined);

  constructor(private httpClient: HttpClient) { }

  list(worksheetId: string): Observable<FlagComment[]> {
    return this.httpClient.get(`${URLs.SHARED_FLAG_COMMENT_ENDPOINT}/${worksheetId}`) as Observable<FlagComment[]>;
  }

  get(id: string): Observable<FlagComment> {
    return this.httpClient.get(`${URLs.SHARED_FLAG_COMMENT_ENDPOINT}/${id}`) as Observable<FlagComment>;
  }

  /**
   * input
   * {
   *  "entityId": "string",
   *  "flagType": 0,
   *  "workspaceId": "string"
   * }
   */
  getByCompositeKey(input: any): Observable<FlagComment> {
    const url = `${URLs.SHARED_FLAG_COMMENT_ENDPOINT}/composite-key`;
    return this.httpClient.put(url, input) as Observable<FlagComment>;
  }

  getByWorksheetId(worksheetId: string): Observable<FlagComment[]> {
    const url = `${URLs.SHARED_FLAG_COMMENT_WORKSHEET_ENDPOINT}/${worksheetId}`;
    return this.httpClient.get(url) as Observable<FlagComment[]>;
  }

  update(comment: FlagComment): Observable<FlagComment> {
    const url = `${URLs.SHARED_FLAG_COMMENT_ENDPOINT}/${comment.id}`;
    return this.httpClient.put(url, comment).pipe(
      map(res => {
        this.input$.next(res);
        return res;
      })) as Observable<FlagComment>;
  }

  save(comment: FlagComment): Observable<FlagComment> {
    return this.httpClient.post(URLs.SHARED_FLAG_COMMENT_ENDPOINT, comment) as Observable<FlagComment>;
  }

  delete(id: string): Observable<any> {
    return this.httpClient.delete(`${URLs.SHARED_FLAG_COMMENT_ENDPOINT}/${id}`);
  }
}
