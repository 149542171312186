<article class="page-container">
    <!-- page title -->
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ "manage.label.title" | translate | async }}
    </h1>
    <div mat-dialog-content class="content">
        <p>
            {{ "manage.label.content.p1" | translate | async }}
        </p>
        <p class="red-text">
            {{ "manage.label.content.p2" | translate | async }}
        </p>
        <p>
            {{ "manage.label.content.p3" | translate | async }}
        </p>
        <div class="flex flex--cols-2">
            <div class="flex-child flex-child--flex-grow">
                <mat-form-field [formGroup]="formAdd" appearance="outline" color="accent">
                    <mat-label>{{ "manage.label.hint" | translate | async }}</mat-label>
                    <input matInput placeholder="" (keydown.enter)="add()" formControlName="label" maxlength="20" />
                    <mat-error *ngIf="formAddCtls.label.errors?.minlength">
                        {{ "validation.message.minLength.3" | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="formAddCtls.label.errors?.maxlength">
                        {{ "validation.message.maxLength.20" | translate | async }}
                    </mat-error>
                    <mat-error *ngIf="formAddCtls.label.errors?.empty">
                        {{ "validation.message.empty" | translate | async }}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="flex-child flex-child--flex-shrink">
                <button
                    attr.aria-label=""
                    [disabled]="formAddCtls.label.pristine || formAddCtls.label.invalid"
                    type="button"
                    mat-stroked-button
                    class="button button--green-secondary btn-add-label"
                    (click)="add()">
                    {{ "button.text.add" | translate | async }}
                </button>
            </div>
        </div>
        <fieldset class="fieldset">
            <legend>
                {{ "manage.label.legend" | translate | async }}
            </legend>
            <section class="example-section">
                <ng-container *ngIf="labels?.length; else noLabels">
                    <p *ngFor="let item of labels; let i = index">
                        <span class="flex-child flex-child--flex-shrink" *ngIf="!item.editMode">
                            {{ item.labelName }}
                        </span>
                        <mat-form-field [formGroup]="formEdit" class="flex-child flex-child--flex-shrink" color="accent" *ngIf="item.editMode">
                            <input
                                matInput
                                trim="blur"
                                [value]="formEditCtls.label.value"
                                autoFocus
                                placeholder=""
                                (change)="update(item)"
                                (keydown.enter)="update(item)"
                                formControlName="label"
                                maxlength="20" />
                            <mat-error *ngIf="formEditCtls.label.errors?.minlength">
                                {{ "validation.message.minLength.3" | translate | async }}
                            </mat-error>
                            <mat-error *ngIf="formEditCtls.label.errors?.maxlength">
                                {{ "validation.message.maxLength.20" | translate | async }}
                            </mat-error>
                            <mat-error *ngIf="formEditCtls.label.errors?.empty">
                                {{ "validation.message.empty" | translate | async }}
                            </mat-error>
                        </mat-form-field>
                        <button
                            type="button"
                            *ngIf="!item.editMode"
                            mat-icon-button
                            class="button button--green-tertiary print--hide flex-child flex-child--flex-shrink"
                            matTooltip="{{ 'button.text.edit' | translate | async }} {{ item.labelName }}"
                            matTooltipPosition="above"
                            (click)="edit(item.id)"
                            attr.aria-label="{{ 'button.text.edit' | translate | async }} {{ item.labelName }}">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button
                            type="button"
                            *ngIf="!item.editMode"
                            mat-icon-button
                            class="button button--red-tertiary print--hide flex-child flex-child--flex-shrink"
                            matTooltip="{{ 'dialog.action.delete' | translate | async }} {{ item.labelName }}"
                            matTooltipPosition="above"
                            (click)="delete(item.id)"
                            attr.aria-label="{{ 'dialog.action.delete' | translate | async }} {{ item.labelName }}">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </p>
                </ng-container>
                <ng-template #noLabels>
                    <p>
                        {{ "filter.menu.no.label" | translate | async }}
                    </p>
                </ng-template>
            </section>
        </fieldset>
    </div>
    <!-- border line -->
    <hr class="border-line" />
    <!-- action toolbar -->
    <div class="footer">
        <div class="flex-child">
            <!-- close button -->
            <button type="button" class="button button--black-primary" mat-stroked-button (click)="close()">
                {{ "dialog.action.close" | translate | async }}
            </button>
        </div>
    </div>
</article>
