<article id="convertWorksheetComponent">
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>

    <div class="form-container">
        <mat-dialog-content [formGroup]="calculatorNameForm">
            <div class="flex-child flex-child--full-width mb-1">
                {{ "calculator.convert.worksheet.dialog.desc" | translate: [srcWorksheetName] | async }}
            </div>
            <div class="flex-child flex-child--full-width">
                <mat-form-field color="accent" floatLabel="never" appearance="outline">
                    <mat-label>
                        {{ "calculator.create.nmsp.placeholder" | translate | async }}
                    </mat-label>
                    <input matInput autoFocusByContent formControlName="calculatorName" maxlength="30" placeholder="{{ 'calculator.name.placeholder' | translate | async }}" />

                    <mat-error *ngIf="calculatorName.errors?.minlength">
                        {{ "validation.message.minLength.3" | translate | async }}
                    </mat-error>

                    <mat-error *ngIf="calculatorName.errors?.maxlength">
                        {{ "validation.message.maxLength.30" | translate | async }}
                    </mat-error>

                    <mat-error *ngIf="calculatorName.errors?.required || calculatorName.errors?.empty">
                        {{ "validation.message.empty" | translate | async }}
                    </mat-error>
                </mat-form-field>
            </div>
        </mat-dialog-content>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button mat-button class="button button--black-secondary" (click)="cancel()">
                {{ data.btnCancel | translate | async }}
            </button>
            <button mat-button (click)="convert()" class="button button--green-primary">
                {{ "dialog.action.create" | translate | async }}
            </button>
        </div>
    </div>
</article>
