export enum DeleteKey {
  Farm = 1,
  Field = 2,
  FertilizerApplication = 3,
  MaterialApplication = 4,
  CropApplication = 5,
  StorageSystem = 6,
  MdsCalculation = 7,
  Storage = 8,
  MaterialType = 9,
  TransferContact = 10,
  SoilSample = 11,
  MdsBuildingPermit = 12,
  NasmComponent = 13,
  MaterialSample = 14,
  Building = 15,
  Watershed = 16,
  ControlStructure = 17,
  RunoffCurve = 18,
  ContourStage = 19
}
