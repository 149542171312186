<article class="confirmation-dialog2-component">
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ data.title | translate | async }}
    </h1>

    <div mat-dialog-content>
        <p innerHTML="{{ data.message | translate | async }} {{ data.msgSuffix }} {{ data.customMessage$ | async }}">
            {{ data.message | translate | async }} {{ data.msgSuffix }} {{ data.customMessage$ | async }}
        </p>
    </div>

    <div mat-dialog-actions class="flex flex--end-vertical">
        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button" [ngClass]="data.displayBtnOk ? 'button--black-secondary' : 'button--black-primary'" (click)="onNoClick()">
                {{ data.btnCancel | translate | async }}
            </button>
        </div>

        <div class="flex-child flex-child--flex-shrink">
            <button type="button" mat-stroked-button class="button button--green-primary" *ngIf="data.displayBtnOk" [mat-dialog-close]="true" cdkFocusInitial>
                {{ data.btnOk | translate | async }}
            </button>
        </div>
    </div>
</article>
