import { environment } from 'src/environments/environment';

/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
  names: {
    signUpSignIn: 'B2C_1_susi_v2',
    forgotPassword: 'B2C_1_reset_password_v2',
    editProfile: 'B2C_1_edit_profile_v2',
    signUp: 'B2C_1_signup_v2'
  },
  authorities: {
    signUpSignIn: {
      authority: environment.susiAuthority
    },
    forgotPassword: {
      authority: environment.forgotPasswordAuthority
    },
    signUp: {
      authority: environment.signUpAuthority
    }
  },
  authorityDomain: environment.authDomain
};

/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: { scopes: string[]; uri: string } = {
  scopes: [environment.clientId],
  uri: '/secure-home'
};
