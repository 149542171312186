/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-prototype-builtins */
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { AppConstants } from '@core/conf/app-constants';
import { CropYieldAverageType } from '@crop-nutrient/model/crop-yield-average-type';
import { MaterialApplicationSeasonType } from '@organic-amendment/model/material-application-season-type.enum';
import * as lodash from 'lodash';
import moment from 'moment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class Utils {
  static MaterialApplicationSeasonType;
  static SILENT = { onlySelf: true, emitEvent: false };
  static UPDATE_MODEL_ONLY = { onlySelf: true, emitEvent: false, emitModelToViewChange: false };
  static UPDATE_VIEW_ONLY = { onlySelf: true, emitEvent: false, emitViewToModelChange: false };

  public static toFixed(value: number, decimals?: number): string | undefined {
    if (value === undefined) {
      return undefined;
    }
    const temp = Utils._toFixed(value, decimals);
    return Utils._toFixed(Number(temp), decimals);
  }

  private static _toFixed(value: number, decimals?: number): string | undefined {
    if (value === undefined) {
      return undefined;
    }
    if (decimals === undefined) {
      decimals = Number.isInteger(value) ? 0 : AppConstants.DEFAULT_DECIMALS;
    } else {
      decimals = Number.isInteger(value) ? 0 : decimals;
    }
    return Number.parseFloat(value.toString()).toFixed(decimals);
  }

  public static sort(sortings: any[], sorting, descend?: boolean) {
    if (sortings?.sort) {
      sortings.sort((el1, el2) => {
        let r: number;
        if (el1[sorting] > el2[sorting]) {
          r = 1;
        } else if (el1[sorting] === el2[sorting]) {
          r = 0;
        } else {
          r = -1;
        }
        if (descend) {
          r = -r;
        }
        return r;
      });
    }
    return sortings;
  }

  public static sortWithReturn(sortings: any[], sorting, descend?: boolean) {
    sortings.sort((el1, el2) => {
      let r: number;
      if (el1[sorting] > el2[sorting]) {
        r = 1;
      } else if (el1[sorting] === el2[sorting]) {
        r = 0;
      } else {
        r = -1;
      }
      if (descend) {
        r = -r;
      }
      return r;
    });
    return sortings;
  }

  public static sortFormArray(array: Array<any>, args: string, descend?: boolean, isDate?: boolean): Array<any> {
    if (array !== undefined) {
      return array.sort((a: any, b: any) => {
        let r: number;
        let aValue = a.controls[args].value;
        let bValue = b.controls[args].value;

        // when comparing dates, value types have to be matched
        if (isDate) {
          aValue = typeof aValue !== 'string' && aValue.toISOString ? aValue.toISOString() : aValue;
          bValue = typeof bValue !== 'string' && bValue.toISOString ? bValue.toISOString() : bValue;
        }

        if (aValue < bValue) {
          r = -1;
        } else if (aValue > bValue) {
          r = 1;
        } else {
          r = 0;
        }
        if (descend) {
          r = -r;
        }
        return r;
      });
    }
    return array;
  }

  public static isArray(element) {
    return Array.isArray(element);
  }

  public static isEmptyArray(element) {
    return element.length && element.length === 0;
  }

  public static isObject(element) {
    return element !== null && typeof element === 'object' && !Array.isArray(element);
  }
  public static isEmptyObject(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  public static isEmpty(value: number | string): boolean {
    return (
      value === undefined ||
      value.toString().trim() === '' ||
      (Utils.isArray(value) && Utils.isEmptyArray(value)) ||
      (Utils.isObject(value) && Utils.isEmptyObject(value))
    );
  }

  public static findI18nKey(topic: string, name) {
    let i18nkey: string = null;
    if (topic != null && topic === 'yieldAverage') {
      switch (name) {
        case CropYieldAverageType.Geotownship:
          i18nkey = 'calculator.cropnutri.yieldaverage.geotownship.name';
          return i18nkey;
        case CropYieldAverageType.Provincial:
          i18nkey = 'calculator.cropnutri.yieldaverage.provincial.name';
          return i18nkey;
        case CropYieldAverageType.UpperTierMunicipality:
          i18nkey = 'calculator.cropnutri.yieldaverage.uppertiermunicipality.name';
          return i18nkey;
      }
    }
  }

  /*
   * params
   * stack: haystack
   * form.field name of object
   * hay: value of the form field
   */
  public static findItem(stack: any[], member: string, hay: string | number) {
    let found;
    for (const item of stack) {
      if (item[member] === hay) {
        found = item;
        break;
      }
    }
    return found;
  }

  public static findItemIgnoreCase(stack: any[], member: string, hay: string) {
    let found;
    for (const item of stack) {
      if (item[member].toUpperCase() === hay.toUpperCase()) {
        found = item;
        break;
      }
    }
    return found;
  }

  public static matchStr(a: string, b: string) {
    if (!a || !b) {
      return false;
    }
    return a.toUpperCase() === b.toUpperCase();
  }

  public static isEqual(value, other) {
    const type = Object.prototype.toString.call(value);
    if (type !== Object.prototype.toString.call(other)) {
      return false;
    }

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
      return false;
    }

    // Compare the length of the length of the two items
    const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) {
      return false;
    }

    // Compare two items
    // tslint:disable-next-line: only-arrow-functions
    const compare = function (item1, item2) {
      const itemType = Object.prototype.toString.call(item1);
      if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
        if (!Utils.isEqual(item1, item2)) {
          return false;
        }
      } else {
        if (itemType !== Object.prototype.toString.call(item2)) {
          return false;
        }
        // Else if it's a function, convert to a string and compare
        // Otherwise, just compare
        if (itemType === '[object Function]') {
          if (item1.toString() !== item2.toString()) {
            return false;
          }
        } else {
          if (item1 !== item2) {
            return false;
          }
        }
      }
    };
    // Compare properties
    if (type === '[object Array]') {
      for (let i = 0; i < valueLen; i++) {
        if (compare(value[i], other[i]) === false) {
          return false;
        }
      }
    } else {
      for (const key in value) {
        if (value.hasOwnProperty(key)) {
          if (compare(value[key], other[key]) === false) {
            return false;
          }
        }
      }
    }
    // If nothing failed, return true
    return true;
  }

  static isEqual2(a: any, b: any) {
    return lodash.isEqual(a, b);
  }

  static clone(original: any) {
    return lodash.cloneDeep(original);
  }

  static newRef(obj: any) {
    return Object.assign({}, obj);
  }

  /**
   * @param a is the full object
   * @param b is not nessisarrily the full object but contains partial update for src and
   * has the same structure as a while missing some properties
   * @return is the a object containing new values from b object
   */
  static partialUpdate(a: any, b: any) {
    // the update portion
    for (const key in b) {
      if (b.hasOwnProperty(key) && b[key] && !a.hasOwnProperty(key)) {
        a[key] = b[key];
      } else {
        if (typeof b[key] === 'object') {
          Utils.partialUpdate(a[key], b[key]);
        } else {
          if (b[key] !== undefined) {
            a[key] = b[key];
          }
        }
      }
    }
    return a;
  }

  static getApplicationDateBySeason(season: MaterialApplicationSeasonType, baseYear: number): Date {
    if (season === MaterialApplicationSeasonType.LateSummer) {
      return Utils.utcDate(baseYear - 1, 8, 1); // when the user selects Late Summer, the application date should be set to Sept 1
    } else if (season === MaterialApplicationSeasonType.EarlyFall) {
      return Utils.utcDate(baseYear - 1, 8, 21);
    } else if (season === MaterialApplicationSeasonType.LateFall) {
      return Utils.utcDate(baseYear - 1, 10, 10);
    } else if (season === MaterialApplicationSeasonType.Winter) {
      return Utils.utcDate(baseYear - 1, 11, 1);
    } else if (season === MaterialApplicationSeasonType.Summer) {
      return Utils.utcDate(baseYear, 5, 21);
    } else {
      return Utils.utcDate(baseYear, 4, 1);
    }
  }

  static getApplicationSeasonByDate(date: Date) {
    if (!date) {
      return MaterialApplicationSeasonType.Spring;
    }
    // Establish base year and update application date to fall within base year
    const baseYear = date.getUTCFullYear();
    const appDate = Utils.utcDate(baseYear, date.getUTCMonth(), date.getUTCDate());

    // Winter:      December 1 - March 31
    // Spring:      April 1 - June 20
    // Summer:      June 21 - July 31
    // Late Summer: August 1 - September 20
    // Early Fall:  September 21 - November 9
    // Late Fall:   November 10 - November 30

    // Determine application season
    if (appDate < Utils.utcDate(baseYear, 3, 1)) {
      return MaterialApplicationSeasonType.Winter;
    } else if (appDate < Utils.utcDate(baseYear, 5, 21)) {
      return MaterialApplicationSeasonType.Spring;
    } else if (appDate < Utils.utcDate(baseYear, 7, 1)) {
      return MaterialApplicationSeasonType.Summer;
    } else if (appDate < Utils.utcDate(baseYear, 8, 21)) {
      return MaterialApplicationSeasonType.LateSummer;
    } else if (appDate < Utils.utcDate(baseYear, 10, 10)) {
      return MaterialApplicationSeasonType.EarlyFall;
    } else if (appDate < Utils.utcDate(baseYear, 11, 1)) {
      return MaterialApplicationSeasonType.LateFall;
    } else {
      return MaterialApplicationSeasonType.Winter;
    }
  }

  static getBaseYear() {
    const now = moment().utc();
    return now.isBefore(moment({ year: now.year(), month: 8, day: 1 })) ? now.year() : now.year() + 1;
  }

  static getDefaultApplicationDate() {
    const baseYear = Utils.getBaseYear();
    return Utils.utcDate(baseYear, 4, 1);
  }

  static getCroppingYearFromDate(date: moment.Moment) {
    if (!date) {
      return undefined;
    }
    const fromDate = date.year ? date : moment(date);
    return fromDate.isBefore(Utils.momentByUtcDate(fromDate.year(), 8, 1)) ? fromDate.year() : fromDate.year() + 1;
  }

  static isDateWithinTwentyYears(dateStr: string): boolean {
    if (!dateStr) {
      return undefined;
    }
    const date = moment(Utils.utcDateOf(dateStr));
    const startMoment = moment({ year: moment().year() - 20, month: 0, day: 1 });
    const endMoment = moment({ year: moment().year() + 20, month: 11, day: 31 });
    return date.isBetween(startMoment, endMoment, undefined, '[]');
  }

  /**
   * Cropping Year: The cropping year goes from Sept 1 of one year to Aug 31 of the following year.
   *  For example, if today's date is May 12, 2020, the cropping year is 2020.
   */
  static getDefaultCroppingYear() {
    const now = moment();
    return now.isBefore(moment({ year: now.year(), month: 8, date: 1 })) ? now.year() : now.year() + 1;
  }

  /**
   *
   * Adjust the planting date year to correspond with the cropping year.
   *  If the default plant date is on or after Sept 1st (i.e. Sept - Dec), the planting year is the cropping year - 1.
   *    Example: for wheat, winter, the default planting date is Oct 1.
   *      If the cropping year is 2020, then the default planting date is Oct 1, 2019.
   *      If the cropping year is 2021, then the default planting date is Oct 1, 2020.
   *  If the current date is before Sept 1st (i.e. Jan - Aug), the planting year is the cropping year.
   *    Example: for corn, grain, the default planting date is May 1.
   *      If the cropping year is 2020, then the default planting date is May 1, 2020.
   *      If the cropping year is 2021, then the default planting date is May 1, 2021.
   */

  static adjustPlantingDateByCroppingYear(dateStr: string, croppingYear: number) {
    if (!dateStr) {
      return undefined;
    }
    const date = Utils.utcDateOf(dateStr);
    const dateMoment = Utils.momentByUtcDate(croppingYear, date.getUTCMonth(), date.getUTCDate());
    if (!croppingYear) {
      return date;
    }
    dateMoment.isBefore(Utils.momentByUtcDate(croppingYear, 8, 1))
      ? date.setUTCFullYear(croppingYear)
      : date.setUTCFullYear(croppingYear - 1);
    return date;
  }

  /**
   *
   * Adjust the harvest date to correspond with the cropping year.
   * The harvest year is always the same as the cropping year.
   */
  static adjustHarvestDateByCroppingYear(dateStr: string, year: number) {
    if (!dateStr) {
      return undefined;
    }
    const date = moment(dateStr);
    if (!year) {
      return date;
    }
    date.year(year);
    return date;
  }

  static simpleCopy(obj: any) {
    return JSON.parse(JSON.stringify(obj));
  }

  static getCurrentYearYY() {
    return new Date().getUTCFullYear() % 100;
  }

  // Get range array based on inputs
  static range = (start, stop, step) => Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

  /**
   * format tokenized text
   * e.g text = 'I am {0} {1}'
   * params = ['John', 'Smith']
   * output 'I am John Smith'
   */
  static format(text: string, params: any[]): string {
    if (params && params.length > 0) {
      params.forEach((param, index) => {
        text = text?.replace(new RegExp('\\{' + index + '\\}', 'g'), param);
      });
      return text;
    }
    return text;
  }

  /**
   * this is to keep observable alive after error posted
   */
  static errorsomeStream(stream: Observable<any>) {
    return stream.pipe(catchError(e => e));
  }

  static uuid() {
    let dt = new Date().getTime();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
      // tslint:disable-next-line: no-bitwise
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      // tslint:disable-next-line: no-bitwise
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
    return uuid;
  }

  static swapIds(obj: any) {
    // tslint:disable-next-line: forin
    for (const prop in obj) {
      const isString = typeof obj[prop] === 'string' || obj[prop] instanceof String;
      const isArray = obj[prop] && Array.isArray(obj[prop]) && obj[prop].constructor === Array;
      const isObject = obj[prop] && typeof obj[prop] === 'object' && obj[prop].constructor === Object;
      if (prop === 'id' && isString) {
        obj['id'] = Utils.uuid();
      } else if (prop === 'fieldId' && isString) {
        obj['fieldId'] = Utils.uuid();
      } else if (isObject) {
        Utils.swapIds(obj[prop]);
      } else if (isArray) {
        if (obj[prop] && obj[prop].length) {
          for (const item of obj[prop]) {
            Utils.swapIds(item);
          }
        }
      }
    }
  }

  static copy(obj: any) {
    if (obj) {
      return JSON.parse(JSON.stringify(obj));
    }
    return undefined;
  }

  static isNullInteger(v: any) {
    // tslint:disable-next-line: quotemark
    return v === '' || v === '' || v === undefined || v === null;
  }

  static patchAuditFields(control: UntypedFormGroup, data: any) {
    control.patchValue({
      createdBy: data.createdBy,
      createdDateTime: data.createdDateTime,
      updatedBy: data.updatedBy,
      updatedDateTime: data.updatedDateTime
    });
  }

  static isValidValue(val) {
    return [undefined, null, ''].indexOf(val) === -1;
  }

  static isValidNumber(val) {
    return [undefined, null, ''].indexOf(val) === -1 && !isNaN(val);
  }

  /**
   * Pick all flag objects from calculation result.
   */
  static pickFlags(obj) {
    if (!!obj) {
      let retArr = [];
      const arr = [];
      Object.keys(obj).forEach(key => {
        if (key.startsWith('flag') || key.endsWith('Flag')) {
          if (obj[key] && obj[key] !== null && lodash.isObject(obj[key]) && obj[key].hasOwnProperty('flagTypeId')) {
            arr.push(obj[key]);
          }
        } else if (lodash.isObject(obj[key])) {
          retArr = Utils.pickFlags(obj[key]);
          if (!!retArr && retArr.length > 0) {
            arr.push(...retArr);
          }
        } else if (lodash.isArray(obj[key])) {
          retArr = obj[key].map(arrayObj => Utils.pickFlags(arrayObj));
          if (!!retArr && retArr.length > 0) {
            arr.push(...retArr);
          }
        }
      });
      return Utils.sortWithReturn(arr, 'flagLevelId');
    }
    return undefined;
  }

  // Finding an object in a nested object/array structure by id
  static findById(data, id) {
    if (data === null || data === undefined) {
      return undefined;
    }

    if (typeof data === 'object' && data.hasOwnProperty('id') && Utils.matchStr(data['id'], id)) {
      return data;
    }

    for (let i = 0; i < Object.keys(data).length; i++) {
      if (typeof data[Object.keys(data)[i]] === 'object') {
        let obj = Utils.findById(data[Object.keys(data)[i]], id);
        if (obj != null) {
          return obj;
        }
      }
    }

    return undefined;
  }

  static mergeObject(target, source) {
    for (let key in source) {
      if (target.hasOwnProperty(key)) {
        target[key] = source[key];
      } else if (source[key] !== null && source[key] !== undefined) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      }
    }
    return target;
  }

  static addObject(target, source) {
    for (let key in source) {
      if (target.hasOwnProperty(key)) {
        target[key] = source[key];
      } else if (source[key] !== null && source[key] !== undefined) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      }
    }
    return target;
  }

  static utcNow() {
    const now = new Date();
    return Utils.utcDate(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate());
  }

  static utcDate(year, month, date) {
    return new Date(Date.UTC(year, month, date));
  }

  static utcDateOf(date: any) {
    if (!date) {
      return undefined;
    }
    if (typeof date.getMonth === 'function') {
      return Utils.utcDate(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
    }
    if (typeof date === 'string') {
      const d = new Date(date);
      return Utils.utcDate(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
    }
    // return moment(date).utc(true);
  }

  static momentByUtcDate(year, month, date) {
    return moment(Utils.utcDate(year, month, date));
  }

  static invalidControls(form: UntypedFormGroup | UntypedFormArray): string[] {
    const _recursiveInvalidControls = (form: UntypedFormGroup | UntypedFormArray): string[] => {
      return Object.keys(form.controls).reduce((invalidControls: string[], controlName) => {
        const control = form.get(controlName);
        if (control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
          invalidControls = invalidControls.concat(_recursiveInvalidControls(control));
        }
        if (control.invalid) {
          invalidControls.push(controlName);
        }
        return invalidControls;
      }, []);
    };
    return form && form.controls ? _recursiveInvalidControls(form) : [];
  }
}
