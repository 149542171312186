import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';


@Directive({ selector: '[precisionDirective]' })
export class PrecisionDirective {

  @Input() precisionDirective: AbstractControl;
  @Input() precision: number;

  constructor(private el: ElementRef, private changeDetectorRef: ChangeDetectorRef) { }

  @HostListener('blur', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const current = this.el.nativeElement.value;
    if (!!current && !isNaN(current)) {
      this.el.nativeElement.value = Number(parseFloat(this.el.nativeElement.value).toFixed(this.precision));
      this.precisionDirective.patchValue(this.el.nativeElement.value);
      this.changeDetectorRef.markForCheck();
    }
    return;
  }
}
