<article class="page-container">
    <!-- page title -->
    <h1 mat-dialog-title class="h1 h1--component-title">
        {{ 'apply.label.title' | translate | async }}
    </h1>
    <div mat-dialog-content class="content">
        <p>
            {{ 'apply.label.content.p1' | translate | async }}
        </p>
        <p>
            {{ 'apply.label.content.p2' | translate | async }}
        </p>
        <fieldset>
            <legend>
                {{ 'apply.label.legend' | translate | async }}
            </legend><br />
            <section *ngIf="labelList" class="example-section" [formGroup]="labelList">
                <ng-container *ngIf="canSeeLabels; else noLabels">
                    <p *ngFor="let key of object.keys(labelList.controls); let i = index">
                        <mat-checkbox role="checkbox" attr.aria-label="{{ labelName(key) }}"
                            (change)="toggleLabel($event, key)" [formControlName]="key">{{labelName(key)}}
                        </mat-checkbox>
                    </p>
                </ng-container>
                <ng-template #noLabels>
                    <p>
                        {{ 'apply.label.no.label' | translate | async }}
                    </p>
                </ng-template>
            </section>
        </fieldset>
    </div>
    <!-- border line -->
    <hr class="border-line">
    <!-- action toolbar -->
    <div class="footer">
        <div class="flex-child">
            <!-- manage labels button -->
            <button type="button" class="button button--green-secondary" mat-stroked-button
                (click)="openManageLabelsDialog()">
                {{ 'dialog.action.manage.labels' | translate | async }}
            </button>
            <!-- close button -->
            <button type="button" class="button button--black-primary" mat-stroked-button (click)="close()">
                {{ 'dialog.action.close' | translate | async }}
            </button>
        </div>
    </div>
</article>