import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { FmpFieldSection } from './fmp-field-section.model';

export class FmpField extends BaseModel {
  farmId: string;
  name: string;
  fieldSections: FmpFieldSection[] = [];

  constructor(init?: Partial<FmpField>) {
    super();
    this.id = Utils.uuid();
    this.name = this.isEn ? 'New field' : 'Nouvelle champ';
    if (init?.fieldSections?.length) {
      this.fieldSections[0] = new FmpFieldSection(init.fieldSections[0]);
    } else {
      this.fieldSections.push(new FmpFieldSection());
    }
    Object.assign(this, init);
    this.fieldSections[0].fieldId = this.id;
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      farmId: new UntypedFormControl(this.farmId),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), ValidationFunctions.empty]
      }),
      fieldSections: new UntypedFormArray(this.fieldSections.map(v => new FmpFieldSection(v).toForm())),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] })
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const fieldSections = form.get('fieldSections') as UntypedFormArray;
    return new FmpField({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      farmId: v.farmId,
      name: v.name,
      fieldSections: fieldSections.controls.map((w: UntypedFormGroup) => new FmpFieldSection().toModel(w)),
      notes: v.notes
    });
  }
}
