import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { FmpMaterialType } from '@fmp/model/fmp-material-type.model';
import { WorksheetEconomic } from '@shared/models/common/worksheet-economic.model';
import { Storage } from '@mstor/model/storage.model';
import { ExemptionReason } from '@nmsp/model/exemption-reason.enum';
import { Constants } from '@shared/constants/constants';
import { DataType } from '@shared/models/common/data-type.enum';
import { MaterialCategory } from '@shared/models/common/material-category.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { WorksheetOverviewOperation } from '@shared/models/worksheet/worksheet-overview-operation.model';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { ContactInformation } from '../../../shared/models/common/contact-information.model';
import { WorksheetPreviousSubmission } from '../../../shared/models/worksheet/worksheet-previous-submission.model';
import { NasmFarm } from './nasm-farm.model';
import { NasmMaterialType } from './nasm-material-type.model';

export class NasmWorksheet extends BaseModel {
  name: string;
  worksheetTypeId = WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL;
  measureSystemName = 'Imperial';
  langPreference = 'en';
  userAccountId = '';
  worksheetGroupName: string;
  worksheetSubGroupName: string;
  applicationDate: Date;
  startYear: number;
  endYear: number;
  farms: NasmFarm[] = [];
  materialTypes = [];
  contacts: ContactInformation[] = [];
  worksheetPreviousSubmissions: WorksheetPreviousSubmission[] = [];
  isLocal = true;
  currentFarmId: string;
  currentFieldId: string;
  currentStorageSystemId: string;
  currentTransferContactId: string;
  currentMaterialTypeId: string;
  currentStorageId: string;
  worksheetEconomic: WorksheetEconomic;
  submissionId: string;
  hasMunicipalWells = false;
  isNmpRequired = false;
  isOwnerSameAsOperator = false;
  hasPreviousSubmission = false;
  reasonForSubmission = Constants.DEFAULT_WORKSHEET_SUBMISSION_REASON;
  reasonDetails: string;
  isOverviewPrepared = false;
  federalBusinessNumber: string;
  federalBusinessExempt = ExemptionReason.CRA;
  isFederalBusinessExempt = false;
  legalFarmName: string;
  operationType: number;
  operationTypeComment: string;
  noPreviousNasmApplied: false;
  worksheetOverviewOperations: WorksheetOverviewOperation[] = [];
  currentTab = 0;
  fileNumber: string;
  landUseTypeId: number;
  nasmStorages: Storage[] = [];

  constructor(init?: Partial<NasmWorksheet>) {
    super();
    this.name = this.isEn ? 'NASM 1' : 'MSNA 1';
    this.configureFarms(init);
    this.configureContacts(init);
    this.configureMaterialTypes(init);
    Object.assign(this, init);
    this.setDefaultDates();
    this.configureCurrentIds();
    this.configureContactParents();
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name, {
        validators: [Validators.maxLength(30), Validators.minLength(3), ValidationFunctions.empty]
      }),
      worksheetTypeId: new UntypedFormControl(this.worksheetTypeId),
      measureSystemName: new UntypedFormControl(this.measureSystemName),
      langPreference: new UntypedFormControl(this.langPreference),
      userAccountId: new UntypedFormControl(this.userAccountId),
      worksheetGroupName: new UntypedFormControl(this.worksheetGroupName),
      worksheetSubGroupName: new UntypedFormControl(this.worksheetSubGroupName),
      applicationDate: new UntypedFormControl(this.applicationDate),
      startYear: new UntypedFormControl(this.startYear),
      endYear: new UntypedFormControl(this.endYear),
      farms: new UntypedFormArray(this.farms.map(v => new NasmFarm(v).toForm())),
      materialTypes: new UntypedFormArray(this.materialTypes.map(v => this.fromModel(v))),
      contacts: new UntypedFormArray(
        this.contacts.map(w => new ContactInformation(w).toForm()),
        [ValidationFunctions.nutrientMgmtContactTypes]
      ),
      worksheetPreviousSubmissions: new UntypedFormArray(this.worksheetPreviousSubmissions.map(v => new WorksheetPreviousSubmission(v).toForm())),
      currentFarmId: new UntypedFormControl(this.currentFarmId),
      currentFieldId: new UntypedFormControl(this.currentFieldId),
      currentStorageSystemId: new UntypedFormControl(this.currentStorageSystemId),
      currentTransferContactId: new UntypedFormControl(this.currentTransferContactId),
      currentMaterialTypeId: new UntypedFormControl(this.currentMaterialTypeId),
      currentStorageId: new UntypedFormControl(this.currentStorageId),
      worksheetEconomic: new WorksheetEconomic(this.worksheetEconomic).toForm(),
      submissionId: new UntypedFormControl(this.submissionId, { validators: [Validators.maxLength(15)] }),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      hasMunicipalWells: new UntypedFormControl(this.hasMunicipalWells),
      isNmpRequired: new UntypedFormControl(this.isNmpRequired),
      isOwnerSameAsOperator: new UntypedFormControl(this.isOwnerSameAsOperator),
      hasPreviousSubmission: new UntypedFormControl(this.hasPreviousSubmission),
      reasonForSubmission: new UntypedFormControl(this.reasonForSubmission),
      reasonDetails: new UntypedFormControl(this.reasonDetails, { validators: [Validators.maxLength(250)] }),
      isOverviewPrepared: new UntypedFormControl(this.isOverviewPrepared),
      federalBusinessNumber: new UntypedFormControl(this.federalBusinessNumber, {
        validators: [Validators.maxLength(9), Validators.pattern(/[0-9]/)]
      }),
      federalBusinessExempt: new UntypedFormControl(this.federalBusinessExempt),
      isFederalBusinessExempt: new UntypedFormControl(this.isFederalBusinessExempt),
      legalFarmName: new UntypedFormControl(this.legalFarmName, { validators: [Validators.maxLength(50)] }),
      operationType: new UntypedFormControl(this.operationType),
      operationTypeComment: new UntypedFormControl(this.operationTypeComment),
      noPreviousNasmApplied: new UntypedFormControl(this.noPreviousNasmApplied),
      worksheetOverviewOperations: new UntypedFormArray(this.worksheetOverviewOperations.map(v => new WorksheetOverviewOperation(v).toForm())),
      fileNumber: new UntypedFormControl(this.fileNumber),
      landUseTypeId: new UntypedFormControl(this.landUseTypeId),
      nasmStorages: new UntypedFormArray(this.nasmStorages.map(v => new Storage(v).toForm())),
      currentTab: new UntypedFormControl(this.currentTab)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const farms = form.get('farms') as UntypedFormArray;
    const worksheetEconomic = form.get('worksheetEconomic') as UntypedFormGroup;
    const materialTypes = form.get('materialTypes') as UntypedFormArray;
    const contacts = form.get('contacts') as UntypedFormArray;
    const worksheetPreviousSubmissions = form.get('worksheetPreviousSubmissions') as UntypedFormArray;
    const worksheetOverviewOperations = form.get('worksheetOverviewOperations') as UntypedFormArray;
    const nasmStorages = form.get('nasmStorages') as UntypedFormArray;
    return new NasmWorksheet({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      name: v.name,
      worksheetTypeId: v.worksheetTypeId,
      measureSystemName: v.measureSystemName,
      langPreference: v.langPreference,
      userAccountId: v.userAccountId,
      worksheetGroupName: v.worksheetGroupName,
      worksheetSubGroupName: v.worksheetSubGroupName,
      applicationDate: v.applicationDate,
      startYear: v.startYear,
      endYear: v.endYear,
      farms: farms.controls.map((w: UntypedFormGroup) => new NasmFarm().toModel(w)),
      materialTypes: materialTypes?.controls.map((w: UntypedFormGroup) => this.fromFormGroup(w)),
      contacts: contacts?.controls.map((w: UntypedFormGroup) => new ContactInformation().toModel(w)),
      worksheetPreviousSubmissions: worksheetPreviousSubmissions?.controls.map((w: UntypedFormGroup) =>
        new WorksheetPreviousSubmission().toModel(w)
      ),
      currentFarmId: v.currentFarmId,
      currentFieldId: v.currentFieldId,
      currentStorageSystemId: v.currentStorageSystemId,
      currentTransferContactId: v.currentTransferContactId,
      currentMaterialTypeId: v.currentMaterialTypeId,
      currentStorageId: v.currentStorageId,
      worksheetEconomic: new WorksheetEconomic().toModel(worksheetEconomic),
      submissionId: v.submissionId,
      hasMunicipalWells: v.hasMunicipalWells,
      isNmpRequired: v.isNmpRequired,
      isOwnerSameAsOperator: v.isOwnerSameAsOperator,
      hasPreviousSubmission: v.hasPreviousSubmission,
      notes: v.notes,
      reasonForSubmission: v.reasonForSubmission,
      reasonDetails: v.reasonDetails,
      isOverviewPrepared: v.isOverviewPrepared,
      federalBusinessNumber: v.federalBusinessNumber,
      federalBusinessExempt: v.federalBusinessExempt,
      isFederalBusinessExempt: v.isFederalBusinessExempt,
      legalFarmName: v.legalFarmName,
      operationType: v.operationType,
      operationTypeComment: v.operationTypeComment,
      noPreviousNasmApplied: v.noPreviousNasmApplied,
      worksheetOverviewOperations: worksheetOverviewOperations?.controls.map((w: UntypedFormGroup) => new WorksheetOverviewOperation().toModel(w)),
      fileNumber: v.fileNumber,
      landUseTypeId: v.landUseTypeId,
      nasmStorages: nasmStorages?.controls.map((w: UntypedFormGroup) => new Storage().toModel(w)),
      currentTab: v.currentTab
    });
  }

  private fromFormGroup(w: UntypedFormGroup) {
    const test =
      w.get('materialCategory').value === MaterialCategory.NASM ? new NasmMaterialType().toModel(w) : new FmpMaterialType().toModel(w);
    return test;
  }

  private fromModel(w: FmpMaterialType | NasmMaterialType) {
    const test = w.materialCategory === MaterialCategory.NASM ? new NasmMaterialType(w).toForm() : new FmpMaterialType(w).toForm();
    return test;
  }

  private setDefaultDates() {
    this.applicationDate = this.applicationDate
      ? Utils.utcDateOf(this.applicationDate)
      : Utils.utcDate(new Date().getUTCFullYear() + 0, 0, 1);
    this.startYear = this.startYear ? this.startYear : this.applicationDate.getUTCFullYear();
    this.endYear = this.endYear ? this.endYear : this.startYear;
  }

  private configureContactParents() {
    if (this.contacts && this.contacts.length > 0) {
      this.contacts.forEach(e => {
        e.parentType = DataType.WORKSHEET;
        e.parentId = this.id ? this.id : e.parentId;
      });
    }
  }

  private configureFarms(init: Partial<NasmWorksheet>) {
    this.farms[0] = init ? new NasmFarm(init.farms ? init.farms[0] : undefined) : new NasmFarm();
  }

  private configureCurrentIds() {
    this.currentFarmId = this.farms && this.farms[0] ? this.farms[0].id : undefined;
    this.currentFieldId =
      this.farms && this.farms[0] && this.farms[0].fields && this.farms[0].fields[0] ? this.farms[0].fields[0].id : undefined;
    this.currentStorageId = this.nasmStorages && this.nasmStorages[0] ? this.nasmStorages[0].id : undefined;
    this.currentMaterialTypeId = this.materialTypes && this.materialTypes[0] ? this.materialTypes[0].id : undefined;
  }

  private configureMaterialTypes(init: Partial<NasmWorksheet>) {
    if (init && init.materialTypes && init.materialTypes.length > 0) {
      init.materialTypes.forEach((element, index) => (this.materialTypes[index] = element[index]));
    } else {
      this.materialTypes[0] = new NasmMaterialType({ name: this.isEn ? 'New NASM' : 'Nouvelle MSNA' });
    }
  }

  private configureContacts(init: Partial<NasmWorksheet>) {
    if (init && init.contacts && init.contacts.length > 0) {
      init.contacts.forEach((element, index) => (this.contacts[index] = element[index]));
    } else {
      this.contacts[0] = new ContactInformation();
      this.contacts[1] = new ContactInformation();
      this.contacts[2] = new ContactInformation();
    }
  }
}
