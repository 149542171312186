import { AfterViewInit, Component, Input, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
@Component(
	{
		selector: 'app-calculator-side-navigation-toggle',
		templateUrl: './calculator-side-navigation-toggle.component.html',
		styleUrls: ['./calculator-side-navigation-toggle.component.scss'],
		encapsulation: ViewEncapsulation.None //Don't use angulars view encapsulation; it generates inaccessible templates
	})

export class CalculatorSideNavigationToggleComponent implements OnInit, AfterViewInit {
	@Input("sideNavigation") sidenav: MatSidenav; //Requires the side navigation element as a paramater to be passed to this component
	@ViewChildren("sideNavOpen") sideNavOpen: QueryList<HTMLButtonElement>; //List of all side navigations

	sideNavTabInfo: DOMRect; //Information pertaining to the side navigation tab
	sideNavTab: HTMLButtonElement; //The actual side navigation tab

	constructor() { } //Constructor

	ngOnInit(): void { } //Init

	ngAfterViewInit(): void //After View Init
	{
		this.sideNavOpen.changes.subscribe((component: QueryList<HTMLButtonElement>) => //subscribe to changes to side navigation
		{
			this.sideNavTab = component.first["nativeElement"]; //Grab the first side navigation that exists (there was only ever one in existanc at any given time during development of this component)
			window.addEventListener("resize", () => { this.calculateSideTabPosition(); }); //Add an event listener on the resize of the window and trigger the calculateSideTabPosition method
			window.addEventListener("scroll", () => { this.calculateSideTabPosition(); }); //Add an event listener on the scroll of the window and trigger the calculateSideTabPosition method
			this.calculateSideTabPosition(); //Envoke the calculateSideTabPosition method
		});
	}

	calculateSideTabPosition(): void //calculateSideTabPosition method
	{
		if (this.sideNavTab !== undefined) //Perform if the side navigation tab has been identifed (there is a possibility that on init this will = undefined)
		{
			this.sideNavTabInfo = this.sideNavTab.getBoundingClientRect(); //Get dimensions and other relevant info of the side navigation tab
			let scrollY: number = (window.scrollY < 86) ? window.scrollY : 86; //Assign the placement of the scroll on the y-axis if its between 0 and 85; otherwise assign 86 (this accounts for the sticky header)

			if (((window.innerHeight - 186) + scrollY) > this.sideNavTabInfo["height"] - 128) //If the button is smaller than the window height minus the sticky header
			{
				this.sideNavTab.classList.remove("hideButton-scrolled"); //Ensure the side navigation tab is visible
				this.sideNavTab.style.top = ((window.innerHeight + 186 - scrollY) / 2) - (this.sideNavTabInfo["height"] / 2) + "px"; //Calculate the vertical middle placement the side navigation tab should be placed, accounting for the sticky header
			}
			else //If the button is not smaller than the window height minus the blackbar and calculator header area
			{
				this.sideNavTab.classList.add("hideButton-scrolled"); //Hide the side navigation tab
			}
		}
	}
}