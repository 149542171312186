import { Pipe, PipeTransform } from '@angular/core';
import { MaterialSampleBeneficialUse } from '@nasm/model/nasm-calculation.model';
import { MaterialSampleSummaryService } from '@nasm/service/material-sample-summary.service';

@Pipe({ name: 'msBeneficialUsePipe' })
export class MaterialSampleBeneficialUsePipe implements PipeTransform {

  constructor(private summaryService: MaterialSampleSummaryService) { }

  transform(beneficialUse: MaterialSampleBeneficialUse, showAdditional: boolean, defaultText: string): string {
    return this.summaryService.beneficialUseText(beneficialUse, defaultText, showAdditional);
  }
}
