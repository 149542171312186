import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { CropSoilSample } from './crop-soil-sample.model';
import { Crop } from './crop.model';

export class CropNutrientFieldSection extends BaseModel {
  fieldId: string;
  name: string;
  soilTextureId = 0;
  soilSeriesId = -1;
  cropHeatUnits: number;
  fieldAreaInMetric = 0;
  crops: Crop[] = [];
  soilSamples: CropSoilSample[] = [];

  constructor(init?: Partial<CropNutrientFieldSection>) {
    super(init);
    this.crops[0] = new Crop(init?.crops?.[0]);
    this.soilSamples[0] = new CropSoilSample(init?.soilSamples?.[0]);

    this.fieldId = init?.fieldId;
    this.name = init?.name;
    this.soilTextureId = init?.soilTextureId || 0;
    this.soilSeriesId = init?.soilSeriesId || -1;
    this.cropHeatUnits = init?.cropHeatUnits;
    this.fieldAreaInMetric = init?.fieldAreaInMetric || 0;
  }
}
