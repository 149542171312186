import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { URLs } from '@core/conf/urls';
import { Utils } from '@core/utilities/utils';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { WorksheetTypeIds } from '@shared/models/common/worksheet-type-ids.enum';
import { FormService } from '@shared/services/form.service';
import { BehaviorSubject, EMPTY, of } from 'rxjs';
import { distinctUntilChanged, startWith, switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CalculationService {
  constructor(protected httpClient: HttpClient, protected formService?: FormService) {}

  calculation$ = new BehaviorSubject<any>(undefined);
  lastCalc: any | null = null;

  // tslint:disable-next-line: variable-name
  input$ = new BehaviorSubject(undefined);

  defaultMaterialForm: MaterialForm;
  defaultNumberOfYears: number;

  calculate(arg1?: any, arg2?: any) {}
  update() {
    //default to old behaviour where everything calculates
    this.calculate();
  }

  bind() {
    return this.input$.pipe(
      distinctUntilChanged(),
      // tslint:disable-next-line: deprecation
      startWith(undefined),
      switchMap(data => (data ? Utils.errorsomeStream(this.httpClient.post(this.endPoint, data)) : EMPTY)),
      tap(res => this.calculation$.next(res))
    );
  }

  bindCalculationData(form: any, entityType: string) {
    return of(undefined);
  }

  get endPoint() {
    switch (this.worksheetTypeId?.value) {
      case WorksheetTypeIds.FIELD_MANAGEMENT_PLAN:
        return URLs.FMP_CALCULATION_ENDPOINT;
      case WorksheetTypeIds.MANURE_STORAGE_SIZING:
        return URLs.STORAGE_SIZING_CALCULATION_ENDPOINT;
      case WorksheetTypeIds.NUTRIENT_MANAGEMENT_STRATEGY_PLAN:
        return URLs.NMSP_CALCULATION_ENDPOINT;
      case WorksheetTypeIds.NON_AGRICULTURAL_SOURCE_MATERIAL:
        return URLs.NASM_CALCULATION_ENDPOINT;
    }
  }

  get worksheetTypeId() {
    return this.formService.f?.controls.worksheetTypeId;
  }
}
