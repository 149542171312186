import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Injector, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseComponent } from '@shared/components/base.component';
import { ConversionConstants } from '@shared/constants/conversion-constants';
import { LanguageType } from '@shared/models/common/language-type.enum';
import { MaterialForm } from '@shared/models/common/material-form.enum';
import { MeasurementSystem } from '@shared/models/common/measurement-system.enum';
import { DecimalPipe } from '@shared/pipes/decimal.pipe';
import { UnitPipe } from '@shared/pipes/unit.pipe';
import { UnitConverterService } from '@shared/services/unit-converter.service';
import { ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import 'chartjs-adapter-moment';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [DatePipe, DecimalPipe, UnitPipe]
})
export class LineChartComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  _data: any;
  @Input('data') set data(data: any) {
    this._data = data;
    this.refresh_chart();
  }

  get data() {
    return this._data;
  }

  @Input() autoResize = true;

  lineChartLegend = true;

  primaryBlack = 'rgba(26,26,26,1)';
  primaryWhite = 'rgba(255,255,255,1)';
  primaryGreen = 'rgba(17,136,71,1)';
  primaryGreenAlpha = 'rgba(17,136,71,0.2)';
  primaryBlue = 'rgba(0,121,159,1)';
  primaryBlueAlpha = 'rgba(0,121,159,0.1)';
  primaryRed = 'rgba(205,0,0,1)';
  primaryRedAlpha = 'rgba(205,0,0,0.2)';
  primaryYellow = 'rgba(144,103,0,1)';
  primaryYellowAlpha = 'rgba(144,103,0,0.03)';

  private graphData: any;
  private materialForm: MaterialForm;

  constructor(
    private injector: Injector,
    private datePipe: DatePipe,
    private decimalPipe: DecimalPipe,
    private unitConverterService: UnitConverterService
  ) {
    super(injector);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.unitConverterService.from = MeasurementSystem.Metric;
    this.unitConverterService.to = this.measurementService.measurementSystem;
    this.graphData = this.data.graphData;
    this.materialForm = this.data.materialForm;
  }

  refresh_chart() {
    this.graphData = this.data.graphData;
    this.materialForm = this.data.materialForm;
    if (this.chart && this.chart.chart && this.chart.chart.config) {
      this.chart.datasets = [this.dataLine, this.minLine, this.maxLine];
      this.chart.chart.update();
    }
  }

  get to(): MeasurementSystem {
    return this.measurementService.measurementSystem;
  }

  get unit() {
    return this.materialForm === MaterialForm.Liquid
      ? this.unitConverterService.unitTypes[ConversionConstants.UNIT_TYPE_LIQUID_AMOUNT][MeasurementSystem[this.to]]
      : this.unitConverterService.unitTypes[ConversionConstants.UNIT_TYPE_MASS_LARGE][MeasurementSystem[this.to]];
  }

  get points() {
    return this.graphData.materialGraphPoints;
  }

  get amountPoints(): number[] {
    return this.materialForm === MaterialForm.Liquid
      ? this.points.map(v => this.decimalPipe.transform(this.unitConverterService.convertAmount(v.amount), 0))
      : this.points.map(v => this.decimalPipe.transform(this.unitConverterService.convertMassLarge(v.amount), 0));
  }

  get minimumStorageCapacity(): number {
    return this.materialForm === MaterialForm.Liquid
      ? this.decimalPipe.transform(this.unitConverterService.convertAmount(this.graphData.minimumStorageCapacity), 0)
      : this.decimalPipe.transform(this.unitConverterService.convertMassLarge(this.graphData.minimumStorageCapacity), 0);
  }

  get maximumStorageCapacity(): number {
    return this.materialForm === MaterialForm.Liquid
      ? this.decimalPipe.transform(this.unitConverterService.convertAmount(this.graphData.maximumStorageCapacity), 0)
      : this.decimalPipe.transform(this.unitConverterService.convertMassLarge(this.graphData.maximumStorageCapacity), 0);
  }

  get datePoints(): string[] {
    return this.points.map(v => v.date);
  }

  get count(): number {
    return this.points.length;
  }

  get minLine(): ChartDataset {
    return {
      data: new Array(this.count).fill(this.minimumStorageCapacity),
      fill: '+1',
      tension: 0.1,
      label: this.languageService.languageType === LanguageType.English ? 'Min capacity' : 'Capacité minimale',
      // "Min capacity" colours
      backgroundColor: this.primaryBlueAlpha,
      // borderColor: this.primaryBlue,

      pointBackgroundColor: this.primaryBlue,
      pointBorderColor: this.primaryBlue,

      pointHoverBackgroundColor: this.primaryWhite,
      pointHoverBorderColor: this.primaryBlue
    };
  }

  get maxLine(): ChartDataset {
    return {
      data: new Array(this.count).fill(this.maximumStorageCapacity),
      fill: false,
      tension: 0.1,
      label: this.languageService.languageType === LanguageType.English ? 'Max capacity' : 'Capacité maximale',
      // "Max capaticy" colours
      // backgroundColor: 'yellow', //Doesnt work?
      borderColor: this.primaryRed,
      // backgroundColor: this.primaryRed,

      pointBackgroundColor: this.primaryRed,
      pointBorderColor: this.primaryRed,

      pointHoverBackgroundColor: this.primaryWhite,
      pointHoverBorderColor: this.primaryRed
    };
  }

  get dataLine(): ChartDataset {
    return {
      data: this.amountPoints,
      fill: false,
      // tension: 0.1,
      label: this.languageService.languageType === LanguageType.English ? 'Amount' : 'Quantité',
      // "Amount" colours
      // Sets the line colour and "Amount" toggle
      borderColor: this.primaryBlack,
      backgroundColor: this.primaryBlack,

      // Data point and inner square colour
      pointBackgroundColor: this.primaryBlack,
      pointBorderColor: this.primaryBlack,

      // Data point hover
      pointHoverBackgroundColor: this.primaryWhite,
      pointHoverBorderColor: this.primaryBlack
    };
  }

  get lines() {
    return {
      datasets: [this.dataLine, this.minLine, this.maxLine],
      labels: this.datePoints
    };
  }

  get options(): ChartOptions {
    return {
      responsive: this.autoResize,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          title: {
            display: true,
            text:
              this.languageService.languageType === LanguageType.English
                ? `Storage capacity (${this.unit})`
                : `Capacité d'entreposage (${this.unit})`
          }
        },
        x: {
          display: true,
          type: 'time',
          time: {
            unit: 'day',
            parser: 'YYYY-MM-DD',
            tooltipFormat: 'MMMM DD, YYYY',
            displayFormats: {
              day: 'MMM YYYY'
            }
          }
        }
      }
    };
  }
}
