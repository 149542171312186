import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MeasurementSystem } from '../models/common/measurement-system.enum';
import { CacheService } from './cache.service';

@Injectable({
  providedIn: 'root'
})
export class MeasurementService {

  private _measurementSystem: MeasurementSystem = undefined;
  private subject = new BehaviorSubject(this._measurementSystem);
  private _measurementSystem$: Observable<MeasurementSystem> = this.subject.asObservable();

  constructor(private cache: CacheService) { }

  get measurementSystem() {
    return this._measurementSystem;
  }

  set measurementSystem(measurementSystem: MeasurementSystem) {
    this._measurementSystem = measurementSystem;
    const preference = this.cache.preference;
    preference.measurementSystem = measurementSystem;
    this.cache.preference = preference;
    this.subject.next(this._measurementSystem);
  }

  get measurementSystem$() {
    return this._measurementSystem$;
  }

  get metric() {
    return MeasurementSystem[MeasurementSystem.Metric];
  }
  get imperial() {
    return MeasurementSystem[MeasurementSystem.Imperial];
  }
  get US() {
    return MeasurementSystem[MeasurementSystem.US];
  }
}
