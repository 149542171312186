import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { Constants } from "@shared/constants/constants";
import { BaseModel } from "@shared/models/worksheet/BaseModel";

export class MDSMaterialCapacity extends BaseModel {
  materialId: string;
  capacityType = Constants.DEFAULT_MDS_1_MATERIAL_CAPACITY_TYPE_VALUE;
  numberOfLivestock: number;
  areaInMetric: number;
  massInMetric: number;
  volumeInMetric: number;
  averageWeightInMetric: number;
  storageShape: number;
  dimensionDepthInMetric: number;
  dimensionDiameterInMetric: number;
  dimensionLengthInMetric: number;
  dimensionWidthInMetric: number;
  dimensionSlope: number;
  dimensionWallHeightInMetric: number;

  constructor(init?: Partial<MDSMaterialCapacity>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      notes: new UntypedFormControl(this.notes, { validators: [Validators.maxLength(250)] }),
      materialId: new UntypedFormControl(this.materialId),
      capacityType: new UntypedFormControl(this.capacityType),
      numberOfLivestock: new UntypedFormControl(this.numberOfLivestock, { validators: [Validators.min(0)] }),
      areaInMetric: new UntypedFormControl(this.areaInMetric),
      massInMetric: new UntypedFormControl(this.massInMetric),
      volumeInMetric: new UntypedFormControl(this.volumeInMetric),
      averageWeightInMetric: new UntypedFormControl(this.averageWeightInMetric, { validators: [Validators.min(0)] }),
      storageShape: new UntypedFormControl(this.storageShape),
      dimensionDepthInMetric: new UntypedFormControl(this.dimensionDepthInMetric),
      dimensionDiameterInMetric: new UntypedFormControl(this.dimensionDiameterInMetric),
      dimensionLengthInMetric: new UntypedFormControl(this.dimensionLengthInMetric),
      dimensionWidthInMetric: new UntypedFormControl(this.dimensionWidthInMetric),
      dimensionSlope: new UntypedFormControl(this.dimensionSlope),
      dimensionWallHeightInMetric: new UntypedFormControl(this.dimensionWallHeightInMetric)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new MDSMaterialCapacity({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      notes: v.notes,
      materialId: v.materialId,
      capacityType: v.capacityType,
      numberOfLivestock: v.numberOfLivestock,
      areaInMetric: v.areaInMetric,
      massInMetric: v.massInMetric,
      volumeInMetric: v.volumeInMetric,
      averageWeightInMetric: v.averageWeightInMetric,
      storageShape: v.storageShape,
      dimensionDepthInMetric: v.dimensionDepthInMetric,
      dimensionDiameterInMetric: v.dimensionDiameterInMetric,
      dimensionLengthInMetric: v.dimensionLengthInMetric,
      dimensionWidthInMetric: v.dimensionWidthInMetric,
      dimensionSlope: v.dimensionSlope,
      dimensionWallHeightInMetric: v.dimensionWallHeightInMetric
    });
  }
}
