import { Pipe, PipeTransform } from '@angular/core';
import { ArrayConstants } from '@shared/constants/array-constants';

@Pipe({ name: 'flagLevelNamePipe' })
export class FlagLevelNamePipe implements PipeTransform {

  transform(flagLevelId: number): string {
    return this.flagLevels.find(v => v.flagLevelId === flagLevelId).i18nkey;
  }

  get flagLevels() {
    return ArrayConstants.FLAG_LEVELS;
  }
}
