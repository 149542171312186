import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NutrientValueBasis } from '@shared/models/common/nutrient-value-basis.enum';

@Pipe({ name: 'msNvBasis' })
export class MaterialSampleNutrientValueBasisPipe implements PipeTransform {

  constructor() { }

  isWetBasis(nvBasis: number): boolean {
    return nvBasis === NutrientValueBasis.Wet;
  }

  nvBasis(materialSample: UntypedFormGroup): number {
    return materialSample?.controls.nutrientValueBasis?.value;
  }

  transform(materialSample: UntypedFormGroup): string {
    const nvBasis = this.nvBasis(materialSample);
    return this.isWetBasis(nvBasis) ? 'calculator.material.basis.wet' : 'calculator.material.basis.dry';
  }
}
