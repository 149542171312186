import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { Crop } from '@shared/models/worksheet/crop.model';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { FmpFertilizerApplication } from './fmp-fertilizer-application.model';
import { FmpMaterialApplication } from './fmp-material-application.model';
import { FmpSoilSample } from './fmp-soil-sample.model';

export class FmpFieldSection extends BaseModel {
  id: string;
  fieldId: string;
  name: string;
  soilTextureId = -1;
  soilSeriesId = -1;
  cropHeatUnits = 2355;
  fieldAreaInMetric = 0;
  bufferZone = 2;
  tileDrainageSystem = 2;
  tileDrainageSpacingInMetric = 10;
  precipitationInMetric = 914.74;
  slopeMaximumInPercent = 7;
  fieldAreaMaterialInMetric = 0;
  slopeNearSurfaceWaterInPercent = 0;
  isFieldNearSurfaceWater = false;
  doesFieldContainSurfaceWater = false;
  overrideCropHeatUnits = false;
  overridePrecipitation = false;
  materialApplicationFrequency = 0;
  displayNutrientBalanceType = 2; // Hybrid as default
  displayStartYear = 0;
  displayNumberOfYears = 0;
  displayFieldInputsFilter = 0;
  depthToBedrock = 0;
  nasmAppliedThisPlan = true;
  crops: Crop[] = [];
  soilSamples: FmpSoilSample[] = [];
  fertilizerApplications: FmpFertilizerApplication[] = [];
  materialApplications: FmpMaterialApplication[] = [];

  constructor(init?: Partial<FmpFieldSection>) {
    super();
    this.id = Utils.uuid();
    if (init && init.crops && init.crops[0]) {
      this.crops[0] = new Crop(init.crops[0]);
    }
    this.soilSamples[0] = init?.soilSamples ? new FmpSoilSample(init.soilSamples[0]) : new FmpSoilSample();
    Object.assign(this, init);

    if (this.crops && this.crops.length > 0) {
      this.crops.map(v => (v.fieldSectionId = this.id));
    }
    if (this.soilSamples && this.soilSamples.length > 0) {
      this.soilSamples.map(v => (v.fieldSectionId = this.id));
    }
    if (this.fertilizerApplications && this.fertilizerApplications.length > 0) {
      this.fertilizerApplications.map(v => (v.fieldSectionId = this.id));
    }
    if (this.materialApplications && this.materialApplications.length > 0) {
      this.materialApplications.map(v => (v.fieldSectionId = this.id));
    }
  }

  toForm() {
    const slopeMaximumInPercent = new UntypedFormControl(this.slopeMaximumInPercent, {
      validators: [Validators.min(0), Validators.max(100)]
    });
    const overridePrecipitation = new UntypedFormControl(this.overridePrecipitation);
    const overrideCropHeatUnits = new UntypedFormControl(this.overrideCropHeatUnits);
    return new UntypedFormGroup({
      fieldId: new UntypedFormControl(this.fieldId),
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      name: new UntypedFormControl(this.name),
      soilTextureId: new UntypedFormControl(this.soilTextureId),
      soilSeriesId: new UntypedFormControl(this.soilSeriesId),
      cropHeatUnits: new UntypedFormControl(this.cropHeatUnits, { validators: [Validators.min(2000), Validators.max(3700)] }),
      areas: new UntypedFormGroup(
        {
          fieldAreaInMetric: new UntypedFormControl(this.fieldAreaInMetric, {
            validators: [Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
          }),
          fieldAreaMaterialInMetric: new UntypedFormControl(this.fieldAreaMaterialInMetric, {
            validators: [Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
          })
        },
        [ValidationFunctions.fieldAreaMaterial()],
        []
      ),
      bufferZone: new UntypedFormControl(this.bufferZone),
      tileDrainageSystem: new UntypedFormControl(this.tileDrainageSystem),
      tileDrainageSpacingInMetric: new UntypedFormControl(this.tileDrainageSpacingInMetric, { validators: [Validators.min(0)] }),
      precipitationInMetric: new UntypedFormControl(this.precipitationInMetric, {
        validators: [Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)]
      }),
      slopeMaximumInPercent,
      slopeNearSurfaceWaterInPercent: new UntypedFormControl(this.slopeNearSurfaceWaterInPercent, {
        validators: [Validators.min(0), Validators.max(100)]
      }),
      isFieldNearSurfaceWater: new UntypedFormControl(this.isFieldNearSurfaceWater),
      overrideCropHeatUnits,
      overridePrecipitation,
      doesFieldContainSurfaceWater: new UntypedFormControl(this.doesFieldContainSurfaceWater),
      materialApplicationFrequency: new UntypedFormControl(this.materialApplicationFrequency),
      displayNutrientBalanceType: new UntypedFormControl(this.displayNutrientBalanceType),
      displayStartYear: new UntypedFormControl(this.displayStartYear),
      displayNumberOfYears: new UntypedFormControl(this.displayNumberOfYears, { validators: [Validators.pattern(/^[0-9]+$/)] }),
      displayFieldInputsFilter: new UntypedFormControl(this.displayFieldInputsFilter),
      nasmAppliedThisPlan: new UntypedFormControl(this.nasmAppliedThisPlan),
      depthToBedrock: new UntypedFormControl(this.depthToBedrock, { validators: [Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      crops: new UntypedFormArray(this.crops.map(v => new Crop(v).toForm())),
      soilSamples: new UntypedFormArray(this.soilSamples.map(v => new FmpSoilSample(v).toForm())),
      fertilizerApplications: this.fertilizerApplications
        ? new UntypedFormArray(this.fertilizerApplications.map(v => new FmpFertilizerApplication(v).toForm()))
        : undefined,
      materialApplications: this.materialApplications
        ? new UntypedFormArray(this.materialApplications.map(v => new FmpMaterialApplication(v).toForm()))
        : undefined
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    const crops = form.get('crops') as UntypedFormArray;
    const soilSamples = form.get('soilSamples') as UntypedFormArray;
    const fertilizerApplications = form.get('fertilizerApplications') as UntypedFormArray;
    const materialApplications = form.get('materialApplications') as UntypedFormArray;
    return new FmpFieldSection({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      fieldId: v.fieldId,
      name: v.name,
      soilTextureId: v.soilTextureId,
      soilSeriesId: v.soilSeriesId,
      cropHeatUnits: v.cropHeatUnits,
      fieldAreaInMetric: v.areas.fieldAreaInMetric,
      fieldAreaMaterialInMetric: v.areas.fieldAreaMaterialInMetric,
      bufferZone: v.bufferZone,
      tileDrainageSystem: v.tileDrainageSystem,
      tileDrainageSpacingInMetric: v.tileDrainageSpacingInMetric,
      precipitationInMetric: v.precipitationInMetric,
      slopeMaximumInPercent: v.slopeMaximumInPercent,
      slopeNearSurfaceWaterInPercent: v.slopeNearSurfaceWaterInPercent,
      isFieldNearSurfaceWater: v.isFieldNearSurfaceWater,
      doesFieldContainSurfaceWater: v.doesFieldContainSurfaceWater,
      overrideCropHeatUnits: v.overrideCropHeatUnits,
      overridePrecipitation: v.overridePrecipitation,
      materialApplicationFrequency: v.materialApplicationFrequency,
      displayNutrientBalanceType: v.displayNutrientBalanceType,
      displayStartYear: v.displayStartYear,
      displayNumberOfYears: v.displayNumberOfYears,
      displayFieldInputsFilter: v.displayFieldInputsFilter,
      nasmAppliedThisPlan: v.nasmAppliedThisPlan,
      depthToBedrock: v.depthToBedrock,
      crops: crops.controls.map((w: UntypedFormGroup) => new Crop().toModel(w)),
      soilSamples: soilSamples.controls.map((w: UntypedFormGroup) => new FmpSoilSample().toModel(w)),
      fertilizerApplications: fertilizerApplications
        ? fertilizerApplications.controls.map((w: UntypedFormGroup) => new FmpFertilizerApplication().toModel(w))
        : undefined,
      materialApplications: materialApplications
        ? materialApplications.controls.map((w: UntypedFormGroup) => new FmpMaterialApplication().toModel(w))
        : undefined
    });
  }
}
