import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Utils } from '@core/utilities/utils';
import { Constants } from '@shared/constants/constants';
import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { ValidationFunctions } from '@shared/validators/validation-functions';
import { OutgoingTransferRecurringFrequency } from './outgoing-transfer-recurring-frequency.enum';

const today = Utils.utcNow();

export class OutgoingTransfer extends BaseModel {
  isRecurring: boolean;
  recurringFrequency = OutgoingTransferRecurringFrequency.Weekly;
  recurringPeriod = Constants.OUTGOING_TRANSFER_DEFAULT_FREQUENCY_PERIOD;
  startDate = today;
  endDate = today;
  amountInMetric: number;
  materialTypeId: string;
  transferContactId: string;

  constructor(init?: Partial<any>) {
    super();
    Object.assign(this, init);
  }

  toForm() {
    return new UntypedFormGroup({
      id: new UntypedFormControl(this.id),
      createdBy: new UntypedFormControl(this.createdBy),
      updatedBy: new UntypedFormControl(this.updatedBy),
      createdDateTime: new UntypedFormControl(this.createdDateTime),
      updatedDateTime: new UntypedFormControl(this.updatedDateTime),
      isRecurring: new UntypedFormControl(this.isRecurring),
      recurringFrequency: new UntypedFormControl(this.recurringFrequency),
      recurringPeriod: new UntypedFormControl(this.recurringPeriod,
        { validators: [Validators.min(1), ValidationFunctions.empty, Validators.pattern(/^[0-9]+$/)] }),
      transferDates: new UntypedFormGroup({
        startDate: new UntypedFormControl(this.startDate),
        endDate: new UntypedFormControl(this.endDate),
      }, { validators: [ValidationFunctions.minEndDate()] }),
      amountInMetric: new UntypedFormControl(this.amountInMetric,
        { validators: [ValidationFunctions.greaterThanZero, Validators.pattern(/^-?(0|[1-9]\d*)(\.\d+)?$/)] }),
      materialTypeId: new UntypedFormControl(this.materialTypeId),
      transferContactId: new UntypedFormControl(this.transferContactId)
    });
  }

  toModel(form: UntypedFormGroup) {
    const v = form.getRawValue();
    return new OutgoingTransfer({
      id: v.id,
      createdBy: v.createdBy,
      updatedBy: v.updatedBy,
      createdDateTime: v.createdDateTime,
      updatedDateTime: v.updatedDateTime,
      isRecurring: v.isRecurring,
      recurringFrequency: v.recurringFrequency,
      recurringPeriod: v.recurringPeriod,
      startDate: v.transferDates.startDate,
      endDate: v.transferDates.endDate,
      amountInMetric: v.amountInMetric,
      materialTypeId: v.materialTypeId,
      transferContactId: v.transferContactId
    });
  }
}
