import { BaseModel } from '@shared/models/worksheet/BaseModel';
import { FertilizerApplication } from './fertilizer-application.model';

export class FertilizerFieldSection extends BaseModel {
    id: string;
    fieldId: string;
    name: string;
    soilTextureId: number;
    cropHeatUnits: number;
    fieldAreaInMetric = 0;
    fertilizerApplications: FertilizerApplication[] = [];

    constructor(init?: Partial<FertilizerFieldSection>) {
        super();
        this.fertilizerApplications[0] = new FertilizerApplication(init);
        Object.assign(this, init);
    }
}
